import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { changeConfigSidebarTab } from '@actions';
import { configSidebarTabSelector } from '@selectors';
import { configSidebar } from '@content';

import { Button } from '@components';

const propTypes = {};
const defaultProps = {};

export const ConfigSidebar = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(configSidebarTabSelector);

  const handleTabChange = (tab) => {
    dispatch(changeConfigSidebarTab(tab));
  };

  const makeTab = ({ key, title, description }) => (
    <Button
      theme
      key={key}
      className="group w-full border-b border-transparent pb-2 text-left transition-colors hover:border-grey-6"
      onClick={() => handleTabChange(key)}
    >
      <p
        className={cx('text-title-1 text-grey-1 group-hover:text-primary-1', {
          'text-primary-1': key === activeTab,
        })}
      >
        {title}
      </p>
      <p className="text-body-2-r text-text-2">{description}</p>
    </Button>
  );

  return (
    <div className="flex shrink-0 basis-[320px] flex-col gap-8 border-r border-grey-6 bg-white p-6">
      {configSidebar.map(makeTab)}
    </div>
  );
};

ConfigSidebar.propTypes = propTypes;
ConfigSidebar.defaultProps = defaultProps;
