import React, { Suspense } from 'react';

import { IGNORE_MAINTENANCE } from '@constants';
import { useInitinalization } from '@hooks/useInitialization';

import * as Page from '@pages';
import { Render, SpinnerChase, Transition } from '@components';
import { ApplicationRoutes } from '@components/Routes';
import { ToastContainer } from '@components/Toast';

import 'react-toastify/dist/ReactToastify.css';

const propTypes = {};
const defaultProps = {};

export const Application = () => {
  const { maintenance, authLoading, initialized } = useInitinalization();

  const Loader = (
    <div className="absolute inset-0 z-40 flex content-center rounded-xl bg-white">
      <SpinnerChase className="h-12 w-12" />
    </div>
  );

  if (maintenance && !IGNORE_MAINTENANCE) {
    return <Page.Maintenance />;
  }

  return (
    <Suspense fallback={Loader}>
      <Transition
        mountOnEnter
        unmountOnExit
        in={!initialized || authLoading}
        className="fade"
      >
        {Loader}
      </Transition>

      <ToastContainer />

      <Render if={initialized}>
        <ApplicationRoutes />
      </Render>
    </Suspense>
  );
};

Application.propTypes = propTypes;
Application.defaultProps = defaultProps;
