import { createSelector } from 'reselect';

export const cacheSelector = (state) => state.cache;

export const uiSelector = createSelector(cacheSelector, ({ ui }) => ui);

export const sharedSelector = createSelector(
  cacheSelector,
  ({ shared }) => shared
);

export const sidebarCollapsedSelector = createSelector(
  uiSelector,
  ({ sidebarCollapsed }) => sidebarCollapsed
);

export const subscriptionPeriodSelector = createSelector(
  uiSelector,
  ({ subscriptionPeriod }) => subscriptionPeriod
);

export const cachedPhoneSelector = createSelector(
  sharedSelector,
  ({ phone }) => phone
);

export const geolocationSelector = createSelector(
  cacheSelector,
  ({ geolocation }) => geolocation
);

export const restaurantsSelector = createSelector(
  cacheSelector,
  ({ restaurants }) => restaurants
);

export const configSidebarTabSelector = createSelector(
  uiSelector,
  ({ configSidebarTab }) => configSidebarTab
);

export const paginationSelector = createSelector(
  sharedSelector,
  ({ pagination }) => pagination
);
