import { useSelector } from 'react-redux';

import { as } from '@utils';

import { configFeaturesSelector } from '@selectors';

export const useFeature = (featureName) => {
  const { [featureName]: feature } = useSelector(configFeaturesSelector);

  return as.o(feature);
};
