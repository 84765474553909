import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import cx from 'classnames';

import { COLOR, PAGES_NAME, PATH, SUBSCRIPTION } from '@constants';
import { useSubscriptionPlans, useTranslationWithPrefix } from '@hooks';
import { getColor, getFormattedPriceWithCurrency, noop } from '@utils';

import { getBillingHistory } from '@actions';
import { userIdSelector, userSubscriptionSelector } from '@selectors';

import {
  Button,
  Card,
  IconBox,
  Layout,
  Link,
  Modal,
  Modals,
  Render,
  Table,
  Theme,
  WithLoader,
} from '@components';
import { SubscriptionLimits } from '@components/SubscriptionLimits';
import { Info } from '@components/ui';

const propTypes = {};
const defaultProps = {};

export const SubscriptionPage = () => {
  const dispatch = useDispatch();
  const { subscriptionPlanId, plan, nextPaymentDate, period, canceled } =
    useSelector(userSubscriptionSelector);
  const userId = useSelector(userIdSelector);

  const navigate = useNavigate();

  const { getSubscriptionPlan } = useSubscriptionPlans();
  const { t, createPrefix } = useTranslationWithPrefix();

  const [history, setHistory] = useState(null);

  const tt = createPrefix('web:subscription');
  const ttt = createPrefix('web:billing');

  const { limits = [] } = getSubscriptionPlan(plan);

  const unsubscribed = !plan || plan === SUBSCRIPTION.PLAN.FREE;
  const formattedNextPaymentData = canceled
    ? '-'
    : dayjs(nextPaymentDate).format('DD.MM.YYYY');

  useEffect(() => {
    dispatch(getBillingHistory(userId)).then(setHistory).catch(noop);
  }, [userId, dispatch]);

  useEffect(() => {
    if (unsubscribed) {
      navigate(PATH.SUBSCRIPTION_PLANS, { replace: true });
    }
  }, [unsubscribed, navigate]);

  const makeHistoryItem = ({
    cardBrand,
    currency,
    lastFour,
    paidAt,
    price,
    receiptUrl,
    status,
    item: type,
  }) => (
    <tr key={receiptUrl}>
      <Table.Cell>{dayjs(paidAt).format('DD.MM.YYYY')}</Table.Cell>
      <Table.Cell>{getFormattedPriceWithCurrency(price, currency)}</Table.Cell>
      <Table.Cell>
        **** {lastFour} | {cardBrand}
      </Table.Cell>
      <Table.Cell>{type}</Table.Cell>
      <Table.Cell
        className={cx('font-500', getColor(COLOR.BILLING_STATUS[status]))}
      >
        {ttt('status', status)}
      </Table.Cell>
      <Table.Cell align="center" width={48}>
        <Link theme external blank to={receiptUrl}>
          <IconBox
            icon={faArrowUpRightFromSquare}
            className="text-[18px] text-primary-1"
          />
        </Link>
      </Table.Cell>
    </tr>
  );

  const tableHead = (
    <tr>
      <Table.Head>{ttt('label.paid')}</Table.Head>
      <Table.Head>{t('label.total')}</Table.Head>
      <Table.Head>{t('paymentMethod.card')}</Table.Head>
      <Table.Head>{ttt('label.type')}</Table.Head>
      <Table.Head>{t('label.status')}</Table.Head>
      <Table.Head />
    </tr>
  );

  const tableBody = history?.map(makeHistoryItem);

  return (
    <Layout
      className="relative flex flex-1 flex-col"
      title={PAGES_NAME.SUBSCRIPTION}
    >
      <Helmet>
        <title>Subscription</title>
      </Helmet>
      <WithLoader in={unsubscribed}>
        <div className="flex justify-end">
          <Link to={PATH.SUBSCRIPTION_PLANS}>
            {tt('toSubscriptionPlanLink')}
          </Link>
        </div>
        <div className="mt-6 flex items-start gap-6">
          <div className="flex flex-1 flex-col gap-6">
            <Theme.Container className="flex flex-col gap-4">
              <Theme.Title title={tt('currentPlan')} />

              <Info.SubscriptionPlan
                subscriptionPlanId={subscriptionPlanId}
                period={period}
                nextPaymentDate={nextPaymentDate}
              />

              <div className="h-px bg-grey-7" />

              <h4 className="text-body-2-m text-grey-1">{tt('limitsTitle')}</h4>

              <SubscriptionLimits
                className="grid grid-cols-2 gap-y-6"
                itemClassName="subscription-limits-item"
                limits={limits}
              />
              <Render if={!canceled}>
                <div className="h-px bg-grey-7" />
                <Modal
                  content={
                    <Modals.Unsubscribe date={formattedNextPaymentData} />
                  }
                >
                  <Button variant="ghost" className="self-start px-0">
                    {t('web:button.unsubscribe')}
                  </Button>
                </Modal>
              </Render>
            </Theme.Container>

            <Theme.Container>
              <Theme.Title title={ttt('title.history')} />
              <Table head={tableHead} body={tableBody} />
            </Theme.Container>
          </div>
          <Card.AddCard />
        </div>
      </WithLoader>
    </Layout>
  );
};

SubscriptionPage.propTypes = propTypes;
SubscriptionPage.defaultProps = defaultProps;
