import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { PLATFORM, STATUS, USER_ROLE } from '@constants';
import { toast } from '@services';
import { useRole } from '@hooks';
import { as, getFullName, isObject, path } from '@utils';

import { getRestaurantById } from '@actions';
import { userIdSelector, userRoleSelector } from '@selectors';

import { Layout, Link, Render, Theme, WithLoader } from '@components';
import { AppImage } from '@components/AppImage';
import { Review } from '@components/Review';
import * as View from '@components/View';

const propTypes = {};
const defaultProps = {};

export const RestaurantPage = () => {
  const dispatch = useDispatch();
  const role = useSelector(userRoleSelector);
  const userId = useSelector(userIdSelector);

  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const { t } = useTranslation();
  const { operator, consumer, management } = useRole();

  const [restaurant, setRestaurant] = useState({});

  const {
    name,
    paymentMethods = {},
    reviews = [],
    schedule = {},
    shipping = {},
    status,
  } = as.o(restaurant);

  useEffect(() => {
    dispatch(getRestaurantById(restaurantId))
      .then(setRestaurant)
      .catch((error) => {
        toast.error(error.message);
      });
  }, [restaurantId, role, navigate, dispatch]);

  const loading = isObject.empty(restaurant);
  const qrValue = `${window.location.origin}${path.restaurant(
    restaurantId
  )}?sidebarCollapsed=true`;

  const makeReview = ({
    id: reviewId,
    orderId,
    createdAt,
    customer: { id: customerId, firstName, lastName, avatar },
    restaurant: { rating, review },
    status,
  }) => (
    <Review
      key={reviewId}
      isAuthor={userId === customerId}
      managable={management}
      avatar={avatar}
      createdAt={createdAt}
      userName={getFullName(firstName, lastName)}
      orderId={orderId}
      rating={rating}
      review={review}
      reviewId={reviewId}
      status={status}
    />
  );

  return (
    <Layout className="flex flex-1 flex-col bg-grey-8" title={name}>
      <Helmet>
        <title>Restaurant</title>
      </Helmet>
      <WithLoader in={loading}>
        <Render if={operator}>
          <div className="flex justify-end">
            <Link variant="secondary" to={path.restaurant.edit(restaurantId)}>
              {t('button.edit')}
            </Link>
          </div>
        </Render>
        <div className="mt-6 flex items-start gap-6">
          <div className="flex flex-1 flex-col gap-6">
            <Theme.Container className="flex-1">
              <Theme.Title
                className="mb-6"
                title={t('title.restaurantDetails')}
              />
              <View.RestaurantDetails {...restaurant} />
            </Theme.Container>

            <Theme.Container>
              <Theme.Title className="mb-4" title={t('title.dishes')}>
                <Link variant="ghost" to={path.menu(restaurantId)}>
                  {t('button.seeAll')}
                </Link>
              </Theme.Title>
              <View.RestaurantMenu restaurantId={restaurantId} />
            </Theme.Container>

            <Render if={reviews}>
              <Theme.Container>
                <Theme.Title className="mb-4" title="Reviews" />
                <div className="flex flex-col gap-2">
                  {reviews.map(makeReview)}
                </div>
              </Theme.Container>
            </Render>
          </div>
          <div className="flex flex-col gap-6">
            <Render if={consumer}>
              <Theme.Card>
                <Theme.Title className="mb-4" title={t('web:orderFromApp')} />
                <div className="grid grid-cols-2 gap-2">
                  <AppImage platform={PLATFORM.IOS} role={USER_ROLE.CUSTOMER} />
                  <AppImage
                    platform={PLATFORM.ANDROID}
                    role={USER_ROLE.CUSTOMER}
                  />
                </div>
              </Theme.Card>
            </Render>

            <Render if={operator}>
              <Theme.Card>
                <Theme.Title className="mb-2" title={t('label.status')} />
                <View.CheckList value={status === STATUS.RESTAURANT.PUBLISHED}>
                  {t('status.published')}
                </View.CheckList>
              </Theme.Card>
            </Render>

            <Theme.Card>
              <Theme.Title
                className="mb-2"
                title={t('web:title.paymentMethod')}
              />
              <View.CheckList value={paymentMethods.cash}>
                {t('paymentMethod.cash')}
              </View.CheckList>
              <View.CheckList className="mt-4" value={paymentMethods.card}>
                {t('paymentMethod.card')}
              </View.CheckList>
            </Theme.Card>

            <Theme.Card>
              <Theme.Title className="mb-2" title={t('title.shipping')} />
              <View.CheckList value={shipping.delivery}>
                {t('shipping.delivery')}
              </View.CheckList>
              <View.CheckList className="mt-4" value={shipping.pickup}>
                {t('shipping.pickup')}
              </View.CheckList>
            </Theme.Card>

            <Theme.Card>
              <Theme.Title className="mb-6" title={t('schedule.title')} />
              <View.RestaurantSchedule schedule={schedule} />
            </Theme.Card>

            <Theme.Card>
              <Theme.Title
                className="mb-6"
                title={t('web:restaurantCodePromo')}
              />
              <div className="flex content-center">
                <QRCode value={qrValue} size={148} />
              </div>
            </Theme.Card>
          </div>
        </div>
      </WithLoader>
    </Layout>
  );
};

RestaurantPage.propTypes = propTypes;
RestaurantPage.defaultProps = defaultProps;
