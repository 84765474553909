import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { configSupportEmailSelector } from '@redux/selectors';
import { faCopyright } from '@fortawesome/pro-light-svg-icons';

import { PATH } from '@constants';

import {
  IconBox,
  LanguageSwitcher,
  Link,
  Render,
  WithDotSeparator,
} from '@components';

export const AuthFooter = () => {
  const supportEmail = useSelector(configSupportEmailSelector);
  const { t } = useTranslation();

  const url = `mailto:${supportEmail}`;
  const year = new Date().getFullYear();

  return (
    <footer className="flex items-center justify-between border-t border-grey-6 pr-6 pt-4 text-label-1">
      <div className="flex items-center">
        <IconBox className="mr-1 h-3 w-3 icon-12" icon={faCopyright} />
        <span>2021-{year} Caribbean Apps, Inc. DBA Akicomo</span>
        <Render if={supportEmail}>
          <WithDotSeparator>
            <Link
              external
              theme
              className="primary-1-with-hover transition-colors"
              to={url}
            >
              {t('contactSupport')}
            </Link>
          </WithDotSeparator>
        </Render>
        <WithDotSeparator>
          <Link
            theme
            className="primary-1-with-hover transition-colors"
            to={PATH.TERMS_AND_CONDITIONS}
          >
            Terms and Conditions
          </Link>
        </WithDotSeparator>
      </div>
      <LanguageSwitcher />
    </footer>
  );
};
