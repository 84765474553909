import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { QUERY_PARAM } from '@constants';
import { toast } from '@services';
import { getNormalizedParams } from '@utils';

import { setPagination } from '@actions';

const DEFAULT_EMPTY_LIST = [];

export const useFetchList = ({ getList: getListAction }) => {
  const dispatch = useDispatch();

  /**
   * `true` if list fetched with filters
   */
  const [filter, setFilter] = useState(false);
  const [list, setList] = useState();
  const [params, setParams] = useState();

  const fetched = Boolean(list);
  const loading = !fetched;
  const empty = Array.isArray(list) && list.length === 0;
  /**
   * It means no entries at all
   */
  const noList = empty && !filter;

  const getList = useCallback(
    (params) => {
      const withFilter = !!params;
      const normalizedParams = getNormalizedParams(params);

      setParams(normalizedParams);

      return dispatch(getListAction(normalizedParams))
        .then(({ list, pagination }) => {
          setList(list);
          dispatch(
            setPagination({
              ...pagination,
              enabled: !!pagination?.total,
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setFilter(withFilter);
        });
    },
    /**
     * Exclude getListAction as dependency due to
     * developer's custom application interface
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const onPageChange = useCallback(
    (page) => {
      getList({ ...params, [QUERY_PARAM.PAGE]: page });
    },
    [getList, params]
  );

  useEffect(() => {
    getList();

    return () => {
      dispatch(setPagination({}));
    };
  }, [getList, dispatch]);

  return {
    empty,
    fetched,
    filter,
    list: list ?? DEFAULT_EMPTY_LIST,
    loading,
    noList,
    onPageChange,
    getList,
  };
};
