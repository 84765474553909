import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from '@services';

import { updateConfigNotifications } from '@actions';
import { configNotificationsSelector } from '@selectors';

import { NotificationSettings } from '@components/NotificationSettings';

const propTypes = {};
const defaultProps = {};

export const ConfigNotifications = () => {
  const dispatch = useDispatch();
  const configNotifications = useSelector(configNotificationsSelector);

  const handleSubmit = async (dirtyNotifications) => {
    try {
      const { message } = await dispatch(
        updateConfigNotifications(dirtyNotifications)
      );

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <NotificationSettings
      notifications={configNotifications}
      onSubmit={handleSubmit}
    />
  );
};

ConfigNotifications.propTypes = propTypes;
ConfigNotifications.defaultProps = defaultProps;
