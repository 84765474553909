import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ACTION_TYPE } from '@constants';
import { toast } from '@services';
import { useTranslationWithPrefix } from '@hooks';
import { noop } from '@utils';

import { deletePaymentMethod } from '@actions';
import { createLoadingSelector, userIdSelector } from '@selectors';

import { Button, Theme } from '@components';

const cardDeletingSelector = createLoadingSelector(
  ACTION_TYPE.REMOVE_PAYMENT_METHOD
);

const propTypes = {
  paymentMethodId: PropTypes.string.isRequired,
  lastFour: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  onHide: PropTypes.func,
};
const defaultProps = {
  onHide: noop,
};

export const DeleteCardModal = ({
  paymentMethodId,
  lastFour,
  brand,
  onHide,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(cardDeletingSelector);
  const userId = useSelector(userIdSelector);

  const { t, createPrefix } = useTranslationWithPrefix();

  const tt = createPrefix('web:billing');

  const handleDelete = async () => {
    try {
      await dispatch(deletePaymentMethod({ userId, paymentMethodId }));
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <Theme.Title title={tt('title.deleteCard')} />
      <p className="text-center text-body-2-r">
        <Trans i18nKey="web:billing.deleteCard">
          Are you sure you want to delete
          <span className="space-both font-500">
            {{ brand }} **** {{ lastFour }}
          </span>
          payment method?
        </Trans>
      </p>
      <div className="flex justify-around border-t border-grey-7 pt-4">
        <Button variant="ghost-grey" onClick={onHide}>
          {t('button.cancel')}
        </Button>
        <Button
          variant="ghost"
          loading={loading}
          loaderColor="primary"
          onClick={handleDelete}
        >
          {t('button.delete')}
        </Button>
      </div>
    </div>
  );
};

DeleteCardModal.propTypes = propTypes;
DeleteCardModal.defaultProps = defaultProps;
