import { USER_ROLE_SCOPE } from '@constants/shared.constants';

export const PATH = {
  HOME: '/',
  SETTINGS: '/settings',
  PROFILE: '/profile',
  UNSUPPORTED_ROLE: '/role-in-progress',
  BLOCKED: '/blocked',
  SUBSCRIPTION: '/subscription',
  SUBSCRIPTION_PLANS: '/subscription/plans',
  ONBOARDING_CONCLUSION: '/onboarding',

  RESTAURANT: '/restaurant',
  RESTAURANTS: '/restaurants',
  COURIER: '/courier',
  COURIERS: '/couriers',
  ORDER: '/order',
  ORDERS: '/orders',
  MANAGER: '/manager',

  /**
   * Auth
   */
  LOGIN: '/login',
  SIGNUP: '/signup',

  /**
   * Admin
   */
  CONFIGURATION: '/configuration',
  REFERRALS: '/referrals',
  REVIEWS: '/reviews',
  USER: '/user',
  USERS: '/users',

  /**
   * Public
   */
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  TERMS_AND_CONDITIONS_CLIENT_APPLICATION: '/terms-and-conditions/application',
  TERMS_AND_CONDITIONS_COURIER_APPLICATION:
    '/terms-and-conditions/application/courier',
};

export const PATH_ACTION = {
  CREATE: 'new',
  EDIT: 'edit',
};

/**
 * Redirects
 */
export const ROLE_HOME_PAGE = {
  [USER_ROLE_SCOPE.GUEST]: PATH.LOGIN,
  [USER_ROLE_SCOPE.ADMIN]: PATH.USERS,
  [USER_ROLE_SCOPE.OWNER]: PATH.RESTAURANTS,
  [USER_ROLE_SCOPE.UNSUPPORTED]: PATH.UNSUPPORTED_ROLE,
};
