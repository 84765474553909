import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { noop } from '@utils';

import { configSelector } from '@selectors';

import { Field } from '@components';
import * as Config from '@components/Config';

const propTypes = {
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onSubmit: noop,
};

export const ConfigWhatsAppLink = ({ onSubmit }) => {
  const { whatsAppLink } = useSelector(configSelector);

  const { register, handleSubmit } = useForm({
    defaultValues: { whatsAppLink },
  });

  const handleFeatureSubmit = handleSubmit(({ whatsAppLink }) => {
    onSubmit({
      name: 'whatsAppLink',
      type: 'config',
      value: whatsAppLink,
    });
  });

  return (
    <Config.Container
      className="mt-3"
      title="WhatsApp Link"
      onSubmit={handleFeatureSubmit}
    >
      <Config.Row title="Email">
        <Field.Input {...register('whatsAppLink')} containerClassName="pb-0" />
      </Config.Row>
    </Config.Container>
  );
};

ConfigWhatsAppLink.propTypes = propTypes;
ConfigWhatsAppLink.defaultProps = defaultProps;
