import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { MODEL } from '@constants';
import { useFetchList } from '@hooks';
import { getFormattedPriceWithCurrency } from '@utils';

import { getDishes } from '@actions';

import { Avatar, Render, WithLoader } from '@components';
import emptyMenuImage from '@images/emptyList/empty-dish-list.svg';

const propTypes = {
  restaurantId: PropTypes.string.isRequired,
};
const defaultProps = {};

export const RestaurantMenu = ({ restaurantId }) => {
  const { t } = useTranslation();

  const {
    empty,
    fetched,
    list: menu = [],
  } = useFetchList({
    getList: () => getDishes(restaurantId),
  });

  const makeDish = ({
    id,
    avatar,
    category,
    currency,
    measure,
    name,
    price,
    quantity,
  }) => {
    const formattedMeasure = `${quantity} ${measure}`;
    const formattedPrice = getFormattedPriceWithCurrency(price, currency);
    const formattedCategory = category ? t(`category.${category}`) : '-';

    return (
      <li
        key={id}
        className="flex flex-initial basis-[226px] flex-col rounded-[10px] border border-grey-6 p-4"
      >
        <div className="flex content-center">
          <Avatar src={avatar} model={MODEL.PRODUCT} size={150} />
        </div>
        <div className="mt-2 flex flex-1 flex-col">
          <p className="text-[12px] font-500 uppercase leading-[24px] text-primary-1">
            {formattedCategory}
          </p>
          <p className="flex-1 text-body-2-m text-grey-1">{name}</p>
          <p className="flex items-center justify-between">
            <span className="text-label-1 text-text-2">{formattedMeasure}</span>
            <span className="text-body-2-r text-grey-1">{formattedPrice}</span>
          </p>
        </div>
      </li>
    );
  };

  return (
    <WithLoader in={!fetched} className="relative min-h-[80px]">
      <Render if={empty}>
        <div className="flex flex-col content-center">
          <img src={emptyMenuImage} alt="No restaurant menu" />
          <p className="mt-6 text-[18px]">{t('web:empty.dishes')}</p>
        </div>
      </Render>
      <ul className="flex flex-wrap gap-2">{menu.map(makeDish)}</ul>
    </WithLoader>
  );
};

RestaurantMenu.propTypes = propTypes;
RestaurantMenu.defaultProps = defaultProps;
