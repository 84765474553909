import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ALERT_NAME, PATH } from '@constants';
import { useForceUpdate } from '@hooks';
import { alertLocalStorage, as } from '@utils';

import { userSelector } from '@selectors';
import { alertConfig } from '@content';

import { Layout, Link, Notification, Render } from '@components';

const propTypes = {};
const defaultProps = {};

export const AlertContainer = () => {
  const { alerts = [], id: userId } = useSelector(userSelector);
  const { pathname } = useLocation();

  const forceUpdate = useForceUpdate();

  const getKey = (key) => `web:alert.${key}`;

  const getAlertConfig = (alertName) => as.o(alertConfig[alertName]);

  const curryAlertClose = (alert) => () => {
    alertLocalStorage.skip(userId, alert);
    forceUpdate();
  };

  const getNotificationProps = ({ name, closeable, type }) => {
    const onClose = closeable ? curryAlertClose(name) : null;

    return { type, onClose };
  };

  const findAlert = (alertName) => {
    const { name, include, exclude = [] } = getAlertConfig(alertName);

    if (!name) {
      return false;
    }

    if (alertLocalStorage.skipped(userId, alertName)) {
      return false;
    }

    if (Array.isArray(include)) {
      return include.some((url) => matchPath(url, pathname));
    }

    return exclude.every((url) => !matchPath(url, pathname));
  };

  const alert = alerts.find(findAlert);

  const renderAlert = () => {
    const alertProps = getNotificationProps(getAlertConfig(alert));

    switch (alert) {
      case ALERT_NAME.EMAIL:
        return (
          <Notification {...alertProps}>
            <Trans i18nKey={getKey(alert)}>
              Verify your email to be notified about order events, couriers
              invitation statuses, etc.ss
              <Link theme to={PATH.PROFILE}>
                Go to
                <FontAwesomeIcon icon={faUser} />
                Profile
              </Link>
            </Trans>
          </Notification>
        );
      case ALERT_NAME.DOCUMENTS:
        return (
          <Notification {...alertProps}>
            <Trans i18nKey={getKey(alert)}>
              Verify your email to be notified about order events, couriers
              invitation statuses, etc.
              <Link theme to={PATH.PROFILE}>
                Go to
                <FontAwesomeIcon icon={faUser} />
                Profile
              </Link>
            </Trans>
          </Notification>
        );
      default:
        return null;
    }
  };

  return (
    <Render if={alert}>
      <Layout.Container>{renderAlert(alert)}</Layout.Container>
    </Render>
  );
};

AlertContainer.propTypes = propTypes;
AlertContainer.defaultProps = defaultProps;
