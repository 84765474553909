import React from 'react';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { IconBox } from '@components';

const MINIMAL_RATING = 0;

const propTypes = {
  multi: PropTypes.bool,
  className: PropTypes.string,
  rating: PropTypes.number,
};
const defaultProps = {
  multi: false,
  rating: 0,
  className: undefined,
};

export const RatingStar = ({ className, multi, rating }) => {
  const resolveRatingColor = (comparingRating) => rating >= comparingRating;

  const renderStar = (colored, index = 0) => {
    const color = colored ? 'text-other-5' : 'text-grey-4';

    return (
      <IconBox
        key={index}
        className={cx('h-5 w-5 icon-15', color, className)}
        icon={faStar}
      />
    );
  };

  const makeStar = (_, index) => {
    const colored = resolveRatingColor(index + 1);

    return renderStar(colored, index);
  };

  if (!multi) {
    return renderStar(resolveRatingColor(MINIMAL_RATING));
  }

  return (
    <div className={cx('flex', className)}>{Array(5).fill().map(makeStar)}</div>
  );
};

RatingStar.propTypes = propTypes;
RatingStar.defaultProps = defaultProps;
