import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { PAGINATION_ENTRY_KEY } from '@constants';
import { noop } from '@utils';

import { paginationSelector } from '@selectors';

import { Render } from '@components';
import { AlertContainer } from '@components/AlertContainer';

import { LayoutContainer } from './LayoutContainer';
import { LayoutHeader } from './LayoutHeader';
import { LayoutPagination } from './LayoutPagination';
import { LayoutSidebar } from './LayoutSidebar';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onPageChange: PropTypes.func,
  paginationEnabled: PropTypes.bool,
  paginationEntryName: PropTypes.oneOf(Object.values(PAGINATION_ENTRY_KEY)),
  title: PropTypes.string,
};
const defaultProps = {
  className: '',
  onPageChange: noop,
  paginationEnabled: false,
  paginationEntryName: undefined,
  title: '',
};

export const Layout = ({
  children,
  className,
  onPageChange,
  paginationEnabled,
  paginationEntryName,
  title,
}) => {
  const { enabled: configPaginationEnabled } = useSelector(paginationSelector);

  return (
    <div className="flex w-full flex-col">
      <LayoutSidebar />
      <LayoutHeader title={title} />
      <AlertContainer />
      <LayoutContainer className={className}>
        {children}
        <Render if={paginationEnabled && configPaginationEnabled}>
          <LayoutPagination
            onPageChange={onPageChange}
            entryTanslationKey={paginationEntryName}
          />
        </Render>
      </LayoutContainer>
    </div>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

Layout.Sidebar = LayoutSidebar;
Layout.Container = LayoutContainer;
Layout.Header = LayoutHeader;
Layout.Pagination = LayoutPagination;
