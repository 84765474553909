import React from 'react';
import PropTypes from 'prop-types';

import { tv } from '@utils';

import { Render } from '@components';

const variants = tv({
  base: 'absolute hidden z-10 w-max max-w-[248px] whitespace-normal rounded-xl border-grey-6 bg-other-15 p-4 text-left text-body-1-r text-white before:absolute before:block before:-inset-6 before:-z-10 group-hover:block group-hover:animate-tooltipFadeIn',
  variants: {
    place: {
      top: 'left-1/2 bottom-full -translate-x-1/2 -translate-y-4',
      bottom: 'left-1/2 top-full -translate-x-1/2 translate-y-4',
      right: 'left-full top-1/2 translate-x-4 -translate-y-1/2',
      left: 'right-full top-1/2 -translate-x-4 -translate-y-1/2',
    },
  },
  defaultVariants: {
    place: 'top',
  },
});

const propTypes = {
  place: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  content: PropTypes.node,
  children: PropTypes.node,
};
const defaultProps = {
  place: undefined,
  content: null,
  children: null,
};

export const Tooltip = ({ place, content, children }) => {
  return (
    <span className="group relative inline-block">
      <Render if={content}>
        <div className={variants({ place })}>{content}</div>
      </Render>
      {children}
    </span>
  );
};

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;
