import { isObject } from '@utils';

export const setFormValues = ({ data, fields, setValue }) => {
  if (isObject(data)) {
    fields.forEach((key) => {
      const value = data[key];

      if (value) {
        setValue(key, value);
      }
    });
  }
};
