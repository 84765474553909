import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ACTION_TYPE, PATH, PROJECT_NAME } from '@constants';

import { cachedPhoneSelector, createErrorSelector } from '@selectors';

import { Modal, Modals, Render } from '@components';
import {
  AuthContainer,
  LoginForm,
  VerificationCodeForm,
} from '@components/Auth';

const rolesConflictSelector = createErrorSelector(
  ACTION_TYPE.AUTH_VERIFY_PHONE
);

const propTypes = {};
const defaultProps = {};

export const LoginPage = () => {
  const phone = useSelector(cachedPhoneSelector);
  const rolesConflict = useSelector(rolesConflictSelector);

  const { t } = useTranslation();

  const [view, setView] = useState('auth');

  const handleViewChange = (view) => () => {
    setView(view);
  };

  if (view === 'auth') {
    return (
      <AuthContainer
        backButton={false}
        title={t('welcome', { title: PROJECT_NAME })}
        description={t('misc:enterThePhoneNumberYouUsedToRegister')}
      >
        <Helmet>
          <title>Login</title>
        </Helmet>
        <LoginForm onSubmit={handleViewChange('code')} />
      </AuthContainer>
    );
  }

  return (
    <AuthContainer
      title={t('title.phoneVerification')}
      description={t('backend:auth.verificationCode.sent', { phone })}
      onBackClick={handleViewChange('auth')}
    >
      <Helmet>
        <title>Phone verification</title>
      </Helmet>
      <VerificationCodeForm
        page={PATH.LOGIN}
        submitButtonText={t('button.login')}
      />
      <Render if={rolesConflict}>
        <Modal
          defaultOpen
          className="max-w-[428px]"
          content={<Modals.RolesConflict />}
        />
      </Render>
    </AuthContainer>
  );
};

LoginPage.propTypes = propTypes;
LoginPage.defaultProps = defaultProps;
