import React from 'react';
import PropTypes from 'prop-types';

import { getSlots, tv } from '@utils';

import { Render, Slot } from '@components';

const variants = tv({
  base: 'flex items-center justify-between gap-6',
  variants: {
    bold: {
      true: 'text-body-1-s',
      false: 'text-body-2-r',
    },
  },
});

const propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  bold: PropTypes.bool,
};
const defaultProps = {
  className: '',
  description: null,
  bold: false,
};

export const SwitchContainer = ({
  className,
  title,
  description,
  bold,
  children,
}) => {
  const { content, ...slot } = getSlots(children);

  return (
    <div className={variants({ bold, className })}>
      <div>
        <Slot slot={slot.title}>
          <p className="text-grey-1">{title}</p>
        </Slot>
        <Render if={description}>
          <p className="text-body-2-r text-grey-3">{description}</p>
        </Render>
      </div>
      {content}
    </div>
  );
};

SwitchContainer.propTypes = propTypes;
SwitchContainer.defaultProps = defaultProps;
