import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { AvatarUserSize, Role } from '@types';
import { MODEL } from '@constants';
import { getColor } from '@utils';

import { Render } from '@components';

import { Avatar } from './Avatar';

const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  role: Role,
  size: AvatarUserSize,
  src: PropTypes.string,
};
const defaultProps = {
  className: undefined,
  name: undefined,
  role: undefined,
  size: 64,
  src: undefined,
};

export const UserAvatar = ({ className, name, role, size, src }) => {
  const { t } = useTranslation();

  return (
    <div className={cx('flex items-center gap-4', className)}>
      <Avatar model={MODEL.USER} size={size} src={src} />
      <div className="flex flex-col gap-1">
        <Render if={role}>
          <p
            className={cx(
              'text-[12px] uppercase leading-[16px]',
              getColor.role(role)
            )}
          >
            {t(`role.${role}`)}
          </p>
        </Render>
        <p className="text-body-1-m text-grey-1">{name}</p>
      </div>
    </div>
  );
};

UserAvatar.propTypes = propTypes;
UserAvatar.defaultProps = defaultProps;
