import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { SUBSCRIPTION } from '@constants';
import { useSubscriptionPlans, useTranslationWithPrefix } from '@hooks';
import { as, getColor, getFormattedPriceWithCurrency } from '@utils';

import { Badge, Render } from '@components';

const propTypes = {
  className: PropTypes.string,
  nextPaymentDate: PropTypes.string,
  period: PropTypes.oneOf([
    SUBSCRIPTION.PERIOD.MONTH,
    SUBSCRIPTION.PERIOD.ANNUAL,
  ]),
  subscriptionPlanId: PropTypes.string,
};
const defaultProps = {
  className: undefined,
  nextPaymentDate: null,
  period: null,
  subscriptionPlanId: null,
};

export const SubscriptionPlanInfo = ({
  className,
  subscriptionPlanId,
  period,
  nextPaymentDate,
}) => {
  const { getSubscriptionPlan } = useSubscriptionPlans();
  const {
    id: planId,
    plan,
    price: { currency, [period]: price, discount: discounts } = {},
    canceled,
  } = getSubscriptionPlan(subscriptionPlanId);

  const { createPrefix } = useTranslationWithPrefix();

  const t = createPrefix('web:subscription');
  const discount = as.o(discounts)[period];
  const formattedNextPaymentData = canceled
    ? '-'
    : dayjs(nextPaymentDate).format('DD.MM.YYYY');

  const renderPartial = (title, value) => (
    <div className="flex flex-col gap-1">
      <p className="text-[14px] font-600 leading-[16px] text-grey-1">{title}</p>
      <p className="text-[14px] font-500 leading-[16px] text-text-1">{value}</p>
    </div>
  );

  if (!planId) {
    return <p>Subscription not found by this subscription plan ID</p>;
  }

  return (
    <div className={cx('grid grid-cols-4 items-center gap-8', className)}>
      <div className="col-span-2 flex flex-initial basis-1/3 flex-col gap-1">
        <p
          className={cx(
            'flex items-center text-[14px] font-500 leading-[20px]',
            getColor.subscriptionPlan(plan)
          )}
        >
          {t('plan', plan)}
          <Render if={Boolean(discount)}>
            <Badge className="ml-2 h-5 rounded-[4px] border-none bg-primary-3 px-2 text-[12px] font-700 text-primary-1">
              -{getFormattedPriceWithCurrency(discount, currency)}
            </Badge>
          </Render>
        </p>
        <p className="text-[18px] font-600 leading-[24px] text-grey-1">
          {getFormattedPriceWithCurrency(price, currency)}
        </p>
      </div>
      {renderPartial(t('subscriptionPeriod'), t('period', period))}
      <Render if={nextPaymentDate}>
        {renderPartial(t('nextPayment'), formattedNextPaymentData)}
      </Render>
    </div>
  );
};

SubscriptionPlanInfo.propTypes = propTypes;
SubscriptionPlanInfo.defaultProps = defaultProps;
