import { getFileImage, isNullable } from '@utils';

import documentPlaceholder from '@images/document-placeholder.png';

export const getNormalizedDocuments = (documents, documentName) => {
  if (isNullable(documents)) {
    return {};
  }

  const normalizedDocument = Object.entries(documents).reduce(
    (
      acc,
      [document, { status, source, verificationSource, declineMessage }]
    ) => ({
      ...acc,
      [document]: {
        status,
        source: getFileImage(verificationSource ?? source, documentPlaceholder),
        message: declineMessage,
      },
    }),
    {}
  );

  return normalizedDocument[documentName] ?? normalizedDocument;
};
