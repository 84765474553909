import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';

import { noop } from '@utils';

import { Button, IconBox } from '@components';
import * as Config from '@components/Config';

/**
 * It is based on paddings and delete icon width
 */
const WIDTH_OFFSET = 48;

const propTypes = {
  name: PropTypes.string.isRequired,
  translationKey: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  defaultValues: PropTypes.shape(),
  onSubmit: PropTypes.func,
};

const defaultProps = {
  className: '',
  translationKey: '',
  title: '',
  onSubmit: noop,
  defaultValues: undefined,
};

export const ConfigList = ({
  className,
  name,
  translationKey,
  title,
  defaultValues,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation();
  const { control, handleSubmit } = useForm({ defaultValues });
  const { fields, remove, append } = useFieldArray({ name, control });

  const getInputWidth = ({ value }) => {
    const FACTOR = 8;

    return `${(value.length + 1) * FACTOR + WIDTH_OFFSET}px`;
  };

  const handleAppend = () => append('');

  const getSupportNote = () => {
    if (i18n.exists(translationKey)) {
      const translations = Object.keys(
        t(translationKey, { returnObjects: true })
      ).join(', ');

      return `Support translations: ${translations}`;
    }

    return null;
  };

  const makeField = ({ id }, index) => (
    <Controller
      key={id}
      name={`${name}.${index}`}
      control={control}
      render={({ field }) => (
        <div className="relative">
          <input
            {...field}
            className="h-8 min-w-[64px] rounded-lg border-none bg-grey-6 py-2 pl-3 pr-8 text-center text-[14px] text-grey-1"
            style={{ width: getInputWidth(field) }}
          />
          <Button
            theme
            className="absolute inset-y-0 right-2 m-auto h-4"
            onClick={() => remove(index)}
          >
            <IconBox icon={faXmark} className="h-4 w-4 text-other-3 icon-14" />
          </Button>
        </div>
      )}
    />
  );

  return (
    <Config.Container
      className={className}
      title={title}
      subtitle={getSupportNote()}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-wrap gap-2">
        {fields.map(makeField)}
        <Button
          className="h-8 w-8 p-2"
          variant="secondary"
          onClick={handleAppend}
        >
          <IconBox icon={faPlus} className="text-white icon-14" />
        </Button>
      </div>
    </Config.Container>
  );
};

ConfigList.propTypes = propTypes;
ConfigList.defaultProps = defaultProps;
