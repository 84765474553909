import i18n from 'i18next';

export const message = {
  required: (fieldName = 'Field', options) =>
    i18n.t('validation.required', {
      ...options,
      fieldName,
    }),
  invalid: (fieldName = 'Field', options) =>
    i18n.t('validation.invalid', { ...options, fieldName }),
};
