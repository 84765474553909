import dayjs from 'dayjs';

import { ACTION_TYPE } from '@constants';
import { axios, i18n } from '@services';
import { as, isObject, jwt, localeLocalStorage, reduce } from '@utils';

import { cacheGeolocation, cachePhone, setConfig } from '@actions';

export const setUser = (user, token) => {
  const { locale } = as.o(user);
  if (token) {
    jwt.set(token);
  }

  if (locale) {
    localeLocalStorage.locale = locale;
    i18n.changeLanguage(locale);
    dayjs.locale(locale);
  }

  return reduce.set(ACTION_TYPE.SET_USER, { user });
};

export const setUserBilling = (billing) =>
  reduce.set(ACTION_TYPE.SET_USER_BILLING, { billing });

export const resetUser = () => {
  jwt.set();

  return reduce.set(ACTION_TYPE.RESET_USER);
};

export const setUserLocale = (user) => {
  const { locale } = as.o(user);

  if (locale) {
    localeLocalStorage.locale = locale;
    i18n.changeLanguage(locale);
  }
};

export const authInitialize = () => (dispatch) => {
  return axios
    .get('/user')
    .then(({ config, location, user }) => {
      dispatch(setUser(user));
      dispatch(setConfig(config));
      dispatch(cacheGeolocation(location));

      return Promise.resolve({ user, location });
    })
    .catch((error) => Promise.reject(error));
};

export const setDeviceToken = (userId, deviceToken) => () => {
  return axios
    .post(`/users/${userId}/device-tokens`, { deviceToken })
    .catch((error) => Promise.reject(error));
};

export const authLogin = (payload) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.AUTH_LOGIN);

  dispatch(request);

  return axios
    .post('/user/login', payload)
    .then(({ code, message }) => {
      dispatch(success);

      return Promise.resolve({ code, message });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const authLogout = (payload) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.AUTH_LOGOUT);

  dispatch(request);

  return axios
    .post('/user/logout', payload)
    .then((response) => {
      dispatch(resetUser());
      dispatch(success);

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const authSignup = (payload) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.AUTH_SIGNUP);

  dispatch(request);

  return axios
    .post('/user/register', payload)
    .then(({ code, message }) => {
      dispatch(success);

      return Promise.resolve({ code, message });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const authVerifyPhone = (payload) => (dispatch) => {
  const [{ request, success, failure }, actions] = reduce.get(
    ACTION_TYPE.AUTH_VERIFY_PHONE
  );

  dispatch(request);

  return axios
    .post('/user/verifyPhone', payload)
    .then(({ user, token, roles }) => {
      /**
       * It happens due to user roles conflict.
       * We got a few user roles on the same phone
       */
      if (roles) {
        const error = new Error();
        error.roles = roles;

        return Promise.reject(error);
      }

      dispatch(setUser(user, token));
      dispatch(success);

      return Promise.resolve(user);
    })
    .catch((error) => {
      const { roles } = error;

      if (roles) {
        dispatch(actions.failure(roles));
        dispatch(cachePhone(payload.phone));
      } else {
        dispatch(failure);
      }

      return Promise.reject(error);
    });
};

export const getUsers = (params) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.GET_ADMIN_USERS
  );

  dispatch(request);

  return axios
    .get('/users', { params })
    .then(({ users, pagination }) => {
      dispatch(success);

      return Promise.resolve({ list: users, pagination });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const getUserById = (userId) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.GET_ADMIN_USER
  );

  dispatch(request);

  return axios
    .get(`/users/${userId}`)
    .then(({ user }) => {
      dispatch(success);

      return Promise.resolve(user);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const updateUser = (userId, payload) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.UPDATE_USER);

  dispatch(request);

  return axios
    .post(`/users/${userId}`, payload)
    .then(({ user }) => {
      dispatch(success);

      return Promise.resolve(user);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const deleteUser = (userId) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.UPDATE_USER);

  dispatch(request);

  return axios
    .delete(`/users/${userId}`)
    .then(({ user, message }) => {
      dispatch(success);

      return Promise.resolve({ user, message });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const restoreUser = (userId) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.UPDATE_USER);

  dispatch(request);

  return axios
    .post(`/users/${userId}/restore`)
    .then(({ user, message }) => {
      dispatch(success);

      return Promise.resolve({ user, message });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const verifyDocuments = (userId, documents) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.VERIFY_DOCUMENTS
  );

  if (isObject.empty(documents)) {
    return Promise.resolve();
  }

  dispatch(request);

  return axios
    .post(`/users/${userId}/reviewDocuments`, { documents })
    .then(({ user }) => {
      dispatch(success);

      return Promise.resolve(user);
    })
    .catch((error) => {
      dispatch(failure);

      if (error.errors) {
        return Promise.reject(error.errors);
      }

      return Promise.reject(error);
    });
};

export const updateUserLocale = (locale, userId) => (dispatch) => {
  return axios
    .put(`/users/${userId}`, { locale })
    .then(({ user }) => {
      return Promise.resolve(user);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const blockUser =
  ({ userId, blocked }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(ACTION_TYPE.UPDATE_USER);

    dispatch(request);

    return axios
      .put(`/users/${userId}`, { blocked })
      .then(({ user }) => {
        dispatch(success);

        return Promise.resolve(user);
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const deleteUserTokens = (userId) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.UPDATE_USER);

  dispatch(request);

  return axios
    .delete(`/users/${userId}/device-tokens`)
    .then(({ message }) => {
      dispatch(success);

      return Promise.resolve(message);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const addPaymentMethod =
  ({ userId, token }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(
      ACTION_TYPE.ADD_PAYMENT_METHOD
    );

    dispatch(request);

    return axios
      .post(`/users/${userId}/paymentMethods`, { token })
      .then(({ paymentMethods }) => {
        dispatch(success);
        dispatch(setUserBilling({ paymentMethods }));

        return Promise.resolve({ paymentMethods });
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const setDefaultPaymentMethod =
  ({ userId, paymentMethodId }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(
      ACTION_TYPE.SET_DEFAULT_PAYMENT_METHOD
    );

    dispatch(request);

    return axios
      .put(`/users/${userId}/paymentMethods/${paymentMethodId}`)
      .then(({ paymentMethods }) => {
        dispatch(success);

        dispatch(setUserBilling({ paymentMethods }));

        return Promise.resolve({ paymentMethods });
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const deletePaymentMethod =
  ({ userId, paymentMethodId }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(
      ACTION_TYPE.REMOVE_PAYMENT_METHOD
    );

    dispatch(request);

    return axios
      .delete(`/users/${userId}/paymentMethods/${paymentMethodId}`)
      .then(({ paymentMethods }) => {
        dispatch(success);
        dispatch(setUserBilling({ paymentMethods }));

        return Promise.resolve({ paymentMethods });
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const subscribeUser =
  ({ userId, subscriptionPlanId, period }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(
      ACTION_TYPE.SUBSCRIBE_USER
    );

    dispatch(request);

    return axios
      .post(`/users/${userId}/subscriptions`, { subscriptionPlanId, period })
      .then(({ message, subscription }) => {
        dispatch(setUser({ subscription }));
        dispatch(success);

        return Promise.resolve({ message, subscription });
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const unsubscribeUser = (userId) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.UNSUBSCRIBE_USER
  );

  dispatch(request);

  return axios
    .delete(`/users/${userId}/subscriptions`)
    .then(({ message, subscription }) => {
      dispatch(setUser({ subscription }));
      dispatch(success);

      return Promise.resolve({ message, subscription });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const updateUserNotifications =
  ({ userId, notifications }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(ACTION_TYPE.UPDATE_USER);

    dispatch(request);

    return axios
      .put(`/users/${userId}/settings/notifications`, { notifications })
      .then(({ notifications, message }) => {
        dispatch(setUser({ notifications }));
        dispatch(success);

        return Promise.resolve({ message });
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error?.errors ?? error);
      });
  };

export const setUserOnboarding =
  ({ userId, active }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(ACTION_TYPE.UPDATE_USER);

    dispatch(request);

    return axios
      .patch(`/users/${userId}/onboarding`, { active })
      .then(({ onboarding }) => {
        dispatch(setUser({ onboarding }));
        dispatch(success);

        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error?.errors ?? error);
      });
  };

export const getReferrals = (params) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.GET_REFERRALS);

  dispatch(request);

  return axios
    .get('/referrals', { params })
    .then(({ users, pagination }) => {
      dispatch(success);

      return Promise.resolve({ list: users, pagination });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const getReferrers = () => {
  return axios
    .get('/referrers')
    .then(({ referrers }) => Promise.resolve(referrers))
    .catch((error) => Promise.reject(error));
};
