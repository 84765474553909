import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { userAuthorizedSelector, userStatusSelector } from '@redux/selectors';

import {
  CONFIG,
  IGNORE_MAINTENANCE,
  PATH,
  PATH_ACTION,
  STATUS,
  USER_ROLE,
  USER_ROLE_SCOPE,
} from '@constants';
import { useFeature, useRole } from '@hooks';
import { getRoleHomePage, path } from '@utils';

import * as Page from '@pages';

import { ProductRoutes } from './ProductRoutes';

const propTypes = {};
const defaultProps = {};

export const ApplicationRoutes = () => {
  const userStatus = useSelector(userStatusSelector);
  const authorized = useSelector(userAuthorizedSelector);
  const { enabled: maintenance } = useFeature(CONFIG.FEATURE.MAINTENANCE);

  const { role, roleScope } = useRole();

  const getOperatorRoutes = () => (
    <>
      <Route path={PATH.RESTAURANTS} element={<Page.Restaurants />} />
      <Route
        path={path.restaurant.edit()}
        element={<Page.RestaurantEdit pathAction={PATH_ACTION.EDIT} />}
      />
      <Route
        path={path.create(PATH.RESTAURANT)}
        element={<Page.RestaurantEdit pathAction={PATH_ACTION.CREATE} />}
      />

      <Route path={path.menu()} element={<Page.Products />} />
      <Route path={path.catchAll(path.menu)} element={<ProductRoutes />} />

      <Route path={path.order()} element={<Page.Order />} />
      <Route path={PATH.ORDERS} element={<Page.Orders />} />

      <Route path={path.courier()} element={<Page.Courier />} />
      <Route path={PATH.COURIERS} element={<Page.Couriers />} />
    </>
  );

  const renderRoleScopeRoutes = () => {
    switch (roleScope) {
      case USER_ROLE_SCOPE.OWNER:
        return (
          <>
            {getOperatorRoutes()}
            <Route
              path={PATH.ONBOARDING_CONCLUSION}
              element={<Page.OnboardingConclusion />}
            />
          </>
        );

      case USER_ROLE_SCOPE.ADMIN:
        return (
          <>
            <Route path={PATH.USERS} element={<Page.Users />} />
            <Route path={path.user()} element={<Page.User />} />
            <Route path={path.user.edit()} element={<Page.UserEdit />} />

            {getOperatorRoutes()}

            <Route path={PATH.REVIEWS} element={<Page.Reviews />} />
            <Route path={PATH.REFERRALS} element={<Page.Referrals />} />
            <Route path={PATH.CONFIGURATION} element={<Page.Configuration />} />
          </>
        );

      case USER_ROLE_SCOPE.UNSUPPORTED:
      default:
        return (
          <Route
            path={PATH.UNSUPPORTED_ROLE}
            element={<Page.UnsupportedRole />}
          />
        );
    }
  };

  const renderRoleRoutes = () => {
    switch (role) {
      case USER_ROLE.OWNER:
        return (
          <>
            <Route path={path.catchAll(PATH.SUBSCRIPTION)}>
              <Route index element={<Page.Subscription />} />
              <Route path="plans" element={<Page.SubscriptionPlans />} />
              <Route
                path=":subscriptionPlan"
                element={<Page.SubscriptionPlan />}
              />
            </Route>
            <Route
              path={PATH.ONBOARDING_CONCLUSION}
              element={<Page.OnboardingConclusion />}
            />
          </>
        );

      default:
        return null;
    }
  };

  const renderPublicRoutes = () => (
    <>
      <Route path={path.restaurant()} element={<Page.Restaurant />} />
      <Route
        path={PATH.TERMS_AND_CONDITIONS_CLIENT_APPLICATION}
        element={<Page.ApplicationTerms />}
      />
      <Route
        path={PATH.TERMS_AND_CONDITIONS_COURIER_APPLICATION}
        element={<Page.CourierApplicationTerms />}
      />
      <Route path={PATH.TERMS_AND_CONDITIONS} element={<Page.Terms />} />
    </>
  );

  if (maintenance && !IGNORE_MAINTENANCE) {
    return <Page.Maintenance />;
  }

  if (userStatus === STATUS.VERIFICATION.BLOCKED) {
    return (
      <Routes>
        <Route path="*" element={<Page.BlockedUser />} />
      </Routes>
    );
  }

  if (!authorized) {
    return (
      <Routes>
        <Route path={PATH.LOGIN} element={<Page.Login />} />
        <Route path={PATH.SIGNUP} element={<Page.Signup />} />

        {renderPublicRoutes()}

        <Route path="*" element={<Navigate replace to={PATH.LOGIN} />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {renderPublicRoutes()}

      {renderRoleScopeRoutes()}
      {renderRoleRoutes()}

      {/* Shared routes */}
      <Route path={PATH.PROFILE} element={<Page.Profile />} />
      <Route path={PATH.SETTINGS} element={<Page.Settings />} />
      <Route
        path="*"
        element={<Navigate replace to={getRoleHomePage(role)} />}
      />
    </Routes>
  );
};

ApplicationRoutes.propTypes = propTypes;
ApplicationRoutes.defaultProps = defaultProps;
