import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { cn } from '@utils';

import { sidebarCollapsedSelector } from '@selectors';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  className: null,
  children: null,
};

export const LayoutContainer = ({ className, children }) => {
  const sidebarCollapsed = useSelector(sidebarCollapsedSelector);

  return (
    <div
      className={cn('ml-[240px] bg-grey-8 p-6 transition-[margin]', className, {
        'ml-[90px]': sidebarCollapsed,
      })}
    >
      {children}
    </div>
  );
};

LayoutContainer.propTypes = propTypes;
LayoutContainer.defaultProps = defaultProps;
