import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  className: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
};
const defaultProps = {
  className: '',
  align: 'left',
  children: null,
};

export const TableHead = ({ className, align, children }) => (
  <th
    align={align}
    title={children}
    className={cx('truncate px-2 py-6 text-subtitle font-500', className)}
  >
    {children}
  </th>
);

TableHead.propTypes = propTypes;
TableHead.defaultProps = defaultProps;
