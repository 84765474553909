import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  ACTION_TYPE,
  COLOR,
  CONFIG,
  MODEL,
  PATH,
  PATH_ACTION,
  PAYMENT_METHOD,
  PHOTO_MIMETYPES,
  SHIPPING,
  STATUS,
} from '@constants';
import { toast } from '@services';
import { useRouterParams } from '@hooks';
import {
  createFormData,
  getFileImage,
  getFormErrors,
  isObject,
  isRoleScope,
  noop,
  path,
  pick,
  processField,
  setBackendErrors,
  setFormValues,
  validate,
  validationSchema,
} from '@utils';

import {
  addRestaurant,
  cacheRestaurants,
  getRestaurantById,
  restoreRestaurant,
  updateRestaurant,
} from '@actions';
import {
  createLoadingSelector,
  geolocationSelector,
  restaurantsSelector,
  userOnboardingSelector,
  userRoleSelector,
} from '@selectors';

import {
  Avatar,
  Badge,
  Button,
  Field,
  Layout,
  MapGeocoder,
  Modal,
  Modals,
  Render,
  SwitchContainer,
  Theme,
  WithLoader,
} from '@components';
import { ConfigSelectOptions } from '@components/ConfigSelectOptions';
import { OnboardingContainer } from '@components/OnboardingContainer';
import * as Restaurant from '@components/Restaurant';
import imagePlaceholder from '@images/image-placeholder.jpg';

const restaurantUpdatingSelector = createLoadingSelector(
  ACTION_TYPE.ADD_RESTAURANT,
  ACTION_TYPE.UPDATE_RESTAURANT
);

const restaurantRestoringSelector = createLoadingSelector(
  ACTION_TYPE.RESTORE_RESTAURANT
);

const propTypes = {
  pathAction: PropTypes.oneOf([PATH_ACTION.CREATE, PATH_ACTION.EDIT]),
};
const defaultProps = {
  pathAction: PATH_ACTION.CREATE,
};

export const RestaurantEditPage = ({ pathAction }) => {
  const dispatch = useDispatch();
  const restaurantUpdating = useSelector(restaurantUpdatingSelector);
  const restaurantRestoring = useSelector(restaurantRestoringSelector);
  const { timezone } = useSelector(geolocationSelector);
  const role = useSelector(userRoleSelector);
  const restaurants = useSelector(restaurantsSelector);
  const { active: onboarding } = useSelector(userOnboardingSelector);

  const { restaurantId } = useRouterParams('restaurantId');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    formState,
    watch,
    handleSubmit,
    getValues,
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      taxReceipt: false,
      paymentMethods: {
        [PAYMENT_METHOD.CASH]: true,
        [PAYMENT_METHOD.CARD]: false,
      },
      shipping: {
        [SHIPPING.DELIVERY]: false,
        [SHIPPING.PICKUP]: true,
      },
      schedule: {
        monday: { open: false, from: '08:00', to: '23:00' },
        tuesday: { open: false, from: '08:00', to: '23:00' },
        wednesday: { open: false, from: '08:00', to: '23:00' },
        thursday: { open: false, from: '08:00', to: '23:00' },
        friday: { open: false, from: '08:00', to: '23:00' },
        saturday: { open: false, from: '08:00', to: '23:00' },
        sunday: { open: false, from: '08:00', to: '23:00' },
      },
    },
  });

  const [restaurant, setRestaurant] = useState({});

  register('location', validationSchema.required(t('label.location')));

  const { name, location, status, couriers } = restaurant;

  const editing = pathAction === PATH_ACTION.EDIT;
  const deleted = status === STATUS.RESTAURANT.DELETED;
  const unverified = status === STATUS.RESTAURANT.UNVERIFIED;
  const { name: locationName } = location ?? {};
  const watchAvatar = watch('avatar');
  const watchSchedule = watch('schedule');
  const avatar = getFileImage(watchAvatar, imagePlaceholder);
  const errors = getFormErrors(formState.errors);
  const avatarError = errors.avatar;
  const buttonText = editing ? 'button.save' : 'button.create';
  const loading = isObject.empty(restaurant) && editing;
  const title =
    !isObject.empty(restaurant) && editing
      ? name
      : t('web:title.creatingRestaurant');
  const qrValue = `${window.location.origin}${path.restaurant(
    restaurantId
  )}?sidebarCollapsed=true`;
  const shippingDeliveryDisabled = !editing || !couriers?.length;
  const shippingDeliveryNoteContext = editing ? '' : 'creation';

  useEffect(() => {
    if (editing) {
      dispatch(getRestaurantById(restaurantId))
        .then(setRestaurant)
        .catch((error) => {
          toast.error(error.message);
        });
    }
  }, [editing, restaurantId, dispatch]);

  useEffect(() => {
    setFormValues({
      data: restaurant,
      fields: pick(
        restaurant,
        'avatar',
        'city',
        'cuisine',
        'currency',
        'deliveryCharge',
        'deliveryRadius',
        'deliveryTime',
        'location',
        'name',
        'orderMinAmount',
        'paymentMethods',
        'phone',
        'published',
        'schedule',
        'shipping',
        'taxReceipt'
      ),
      setValue,
    });
  }, [restaurant, setValue]);

  const handleLocationChange = (location) => {
    setValue('location', { ...location, timezone }, { shouldDirty: true });
  };

  const handleRestoreRestaurant = () => {
    dispatch(restoreRestaurant(restaurantId))
      .then((restaurant) => {
        toast.success('web:toast.restaurantRestored');
        setRestaurant(restaurant);
      })
      .catch(noop);
  };

  const getSubmitRestaurant = (restaurant) => {
    const { location: locationDirty } = formState.dirtyFields;
    const { location } = restaurant;

    if (locationDirty) {
      return restaurant;
    }

    return { ...restaurant, location: { ...location, timezone } };
  };

  const handleFormSubmit = handleSubmit(async (restaurant) => {
    const restaurantMethod = editing ? updateRestaurant : addRestaurant;
    const data = processField.form(getSubmitRestaurant(restaurant));
    const payload = createFormData({
      data,
      put: editing,
      images: ['avatar'],
    });
    const toastMessage = editing
      ? 'web:toast.restaurantUpdated'
      : 'web:toast.restaurantCreated';

    try {
      const restaurant = await dispatch(
        restaurantMethod(payload, restaurantId)
      );

      toast.success(toastMessage);

      if (onboarding) {
        dispatch(cacheRestaurants([...restaurants, restaurant]));
        navigate(path.product('new', restaurant.id));
        return;
      }

      navigate(path.restaurant(restaurant.id));
    } catch (error) {
      toast.error(error.message);

      setBackendErrors(error, { getValues, setError });
    }
  });

  const handleRestaurantDelete = (restaurant) => {
    if (isRoleScope.owner(role)) {
      navigate(PATH.RESTAURANTS);
    } else {
      setRestaurant(restaurant);
    }
  };

  return (
    <Layout className="flex flex-1 flex-col" title={title}>
      <Helmet>
        <title>Restaurant</title>
      </Helmet>
      <form className="flex flex-1 flex-col" onSubmit={handleFormSubmit}>
        <div className="flex items-center justify-end">
          <Render if={!loading}>
            <div className="flex">
              <Button
                type="submit"
                variant="secondary"
                loading={restaurantUpdating || restaurantRestoring}
              >
                {t(buttonText)}
              </Button>
            </div>
          </Render>
        </div>
        <OnboardingContainer className="mt-6" />
        <WithLoader in={loading}>
          <div className="mt-5 flex items-start gap-6">
            <div className="flex flex-1 flex-col gap-6">
              <Theme.Container>
                <Theme.Title
                  required
                  className="mb-6"
                  title={t('title.restaurantDetails')}
                />
                <div className="grid flex-1 grid-cols-2 gap-2">
                  <Field.Input
                    {...register(
                      'name',
                      validationSchema.required(t('web:label.restaurantTitle'))
                    )}
                    requiredLabel
                    className="col-span-2"
                    label={t('web:label.restaurantTitle')}
                    error={errors.name}
                  />
                  <Field.Select
                    {...register(
                      'cuisine',
                      validationSchema.required(t('label.cuisine'))
                    )}
                    requiredLabel
                    className="col-span-2"
                    label={t('label.cuisine')}
                    error={errors.cuisine}
                  >
                    <ConfigSelectOptions
                      emptyble
                      translationKey="cuisine"
                      configName={CONFIG.SHARED.CUISINE}
                    />
                  </Field.Select>
                  <Field.Input
                    {...register('city')}
                    label={t('label.city')}
                    error={errors.city}
                  />
                  <Field.Input
                    {...register('phone')}
                    type="tel"
                    inputMode="tel"
                    label={t('web:label.contactPhone')}
                    error={errors.phone}
                  />
                  <MapGeocoder
                    {...location}
                    requiredLabel
                    label={t('label.location')}
                    placeholder={locationName}
                    className="col-span-2"
                    name="map-geocoder"
                    title={locationName}
                    error={errors.location}
                    onChange={handleLocationChange}
                  />
                  <Theme.Info className="col-span-2">
                    {t('web:restaurantMapInfo')}
                  </Theme.Info>
                  <Field.Select
                    {...register(
                      'currency',
                      validationSchema.required(t('label.currency'))
                    )}
                    requiredLabel
                    label={t('label.currency')}
                    error={errors.currency}
                  >
                    <ConfigSelectOptions
                      emptyble
                      configName={CONFIG.SHARED.CURRENCY}
                    />
                  </Field.Select>
                  <Field.Input
                    {...register('deliveryCharge')}
                    type="number"
                    inputMode="numeric"
                    label={t('label.deliveryCharge')}
                    placeholder="0"
                    error={errors.deliveryCharge}
                  />
                  <Field.Input
                    {...register(
                      'deliveryRadius',
                      validationSchema.required(t('label.deliveryRadius'))
                    )}
                    requiredLabel
                    type="number"
                    inputMode="numeric"
                    label={t('label.deliveryRadius')}
                    placeholder="10"
                    postfix={t('km')}
                    error={errors.deliveryRadius}
                  />
                  <Field.Input
                    {...register('orderMinAmount', {
                      pattern: validate.numeric(
                        t('validation.invalid', {
                          fieldName: t('web:label.orderMinAmount'),
                        })
                      ),
                    })}
                    type="number"
                    inputMode="numeric"
                    label={t('web:label.orderMinAmount')}
                    placeholder="0"
                    error={errors.orderMinAmount}
                  />
                  <Field.Input
                    {...register('deliveryTime')}
                    type="number"
                    inputMode="numeric"
                    label={t('web:label.deliveryTime')}
                    placeholder="30"
                    postfix={t('min')}
                    error={errors.deliveryTime}
                  />
                </div>
              </Theme.Container>

              <Theme.Container>
                <Theme.Title className="mb-6" title={t('label.taxReceipt')} />
                <SwitchContainer
                  title={t('web:text.taxReceipt.title')}
                  description={t('web:text.taxReceipt.description')}
                >
                  <Field.Switch {...register('taxReceipt')} />
                </SwitchContainer>
              </Theme.Container>

              <Theme.Container className="flex flex-col gap-4">
                <Theme.Title required title={t('schedule.title')} />
                <Theme.Info>{t('web:workHoursInfo')}</Theme.Info>
                <Restaurant.Schedule
                  register={register}
                  schedule={watchSchedule}
                />
              </Theme.Container>

              <div>
                <Render if={deleted && editing && isRoleScope.admin(role)}>
                  <Button variant="ghost" onClick={handleRestoreRestaurant}>
                    {t('generic.restore', { title: t('web:net.restaurant') })}
                  </Button>
                </Render>
                <Render if={!deleted && editing}>
                  <Modal
                    className="max-w-[540px]"
                    content={
                      <Modals.DeleteRestaurant
                        id={restaurantId}
                        name={name}
                        onDelete={handleRestaurantDelete}
                      />
                    }
                  >
                    <Button variant="ghost">
                      {t('generic.delete', {
                        title: t('web:net.restaurant'),
                      })}
                    </Button>
                  </Modal>
                </Render>
              </div>
            </div>

            <div className="flex flex-col gap-6">
              <Theme.Card>
                <Theme.Title className="mb-6" title={t('web:label.cover')} />
                <Field.File {...register('avatar')} accept={PHOTO_MIMETYPES}>
                  <Avatar model={MODEL.BUSINESS} size={272} src={avatar} />
                  <Field.Error variant="static" error={avatarError} />
                  <Button
                    variant="ghost"
                    className="pointer-events-none mx-auto mt-2 flex"
                  >
                    {t('web:button.uploadRestaurantAvatar')}
                  </Button>
                </Field.File>
              </Theme.Card>

              <Render if={editing}>
                <Badge
                  large
                  className="w-full"
                  color={COLOR.RESTAURANT[status]}
                >
                  {t(`status.${status}`)}
                </Badge>
              </Render>

              <Theme.Card>
                <Theme.Title className="mb-4" title={t('label.status')} />
                <SwitchContainer title={t('web:label.publish')}>
                  <Field.Switch
                    {...register('published')}
                    disabled={unverified || !editing}
                  />
                </SwitchContainer>
                <Render if={unverified}>
                  <Theme.Note className="mt-2">
                    {t('web:restaurantStatusNote')}
                  </Theme.Note>
                </Render>
              </Theme.Card>
              <Theme.Card>
                <Theme.Title
                  required
                  className="mb-4"
                  title={t('web:title.paymentMethod')}
                />
                <SwitchContainer title={t('paymentMethod.cash')}>
                  <Field.Switch {...register('paymentMethods.cash')} />
                </SwitchContainer>
                <SwitchContainer
                  className="mt-4"
                  title={t('paymentMethod.card')}
                >
                  <Field.Switch {...register('paymentMethods.card')} />
                </SwitchContainer>
              </Theme.Card>
              <Theme.Card>
                <Theme.Title
                  required
                  className="mb-4"
                  title={t('title.shipping')}
                />
                <SwitchContainer title={t('shipping.pickup')}>
                  <Field.Switch {...register('shipping.pickup')} />
                </SwitchContainer>
                <SwitchContainer
                  className="mt-4"
                  title={t('shipping.delivery')}
                >
                  <Field.Switch
                    {...register('shipping.delivery')}
                    disabled={shippingDeliveryDisabled}
                  />
                </SwitchContainer>
                <Render if={shippingDeliveryDisabled}>
                  <Theme.Note className="mt-4">
                    {t('web:shippingDeliveryNote', {
                      context: shippingDeliveryNoteContext,
                    })}
                  </Theme.Note>
                </Render>
              </Theme.Card>
              <Render if={editing}>
                <Theme.Card>
                  <Theme.Title
                    className="mb-6"
                    title={t('web:restaurantCodePromo')}
                  />
                  <div className="flex justify-center">
                    <QRCode value={qrValue} size={148} />
                  </div>
                </Theme.Card>
              </Render>
            </div>
          </div>
        </WithLoader>
      </form>
    </Layout>
  );
};

RestaurantEditPage.propTypes = propTypes;
RestaurantEditPage.defaultProps = defaultProps;
