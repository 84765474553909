import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const propTypes = {
  schedule: PropTypes.shape().isRequired,
};
const defaultProps = {};

export const RestaurantSchedule = ({ schedule }) => {
  const { t } = useTranslation();

  const days = t('schedule.day', { returnObjects: true });

  const resolveSchedule = ({ open, from, to }) => {
    if (open) {
      return `${from} - ${to}`;
    }

    return t('close');
  };

  const makeSchedule = ([day, title]) => {
    const { open } = schedule[day];
    const color = open ? 'text-grey-1' : 'text-other-3';

    return (
      <li key={day} className="flex justify-between text-body-2-r">
        <span className="text-text-2">{title}</span>
        <span className={color}>{resolveSchedule(schedule[day])}</span>
      </li>
    );
  };

  return (
    <ul className="flex flex-col gap-4">
      {Object.entries(days).map(makeSchedule)}
    </ul>
  );
};

RestaurantSchedule.propTypes = propTypes;
RestaurantSchedule.defaultProps = defaultProps;
