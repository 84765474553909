import { COLOR } from '@constants';
import { as } from '@utils';

export const getColor = (
  color,
  { defaultColor = 'text-1', as: prefix = 'text' } = {}
) => {
  const formattedColor = as(COLOR.MAP[color], defaultColor);

  return `${prefix}-${formattedColor}`;
};

getColor.role = (role, as) => getColor(COLOR.ROLE[role], { as });

getColor.subscriptionPlan = (plan, as) =>
  getColor(COLOR.SUBSCRIPTION_PLAN[plan], { as });

getColor.boolean = (value, as) => getColor(COLOR.BOOLEAN[value], { as });
