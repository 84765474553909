import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  faChevronsLeft,
  faChevronsRight,
} from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { PAGINATION_ENTRY_KEY } from '@constants';
import { usePagination } from '@hooks';
import { noop } from '@utils';

import { paginationSelector } from '@selectors';

import { Button, Render } from '@components';

import { LayoutPaginationNavigator as Navigator } from './LayoutPaginationNavigator';

const propTypes = {
  pagination: PropTypes.bool,
  informable: PropTypes.bool,
  className: PropTypes.string,
  onPageChange: PropTypes.func,
  entryTanslationKey: PropTypes.oneOf(Object.values(PAGINATION_ENTRY_KEY)),
};
const defaultProps = {
  pagination: true,
  informable: true,
  className: '',
  onPageChange: noop,
  entryTanslationKey: PAGINATION_ENTRY_KEY.ENTRIES,
};

export const LayoutPagination = ({
  pagination,
  informable,
  className,
  onPageChange,
  entryTanslationKey,
}) => {
  const {
    total: totalCount = 0,
    currentPage: page = 1,
    perPage = 1,
    lastPage = 1,
  } = useSelector(paginationSelector);
  const { t } = useTranslation();

  const count = perPage >= totalCount ? totalCount : perPage;

  const paginationRange = usePagination({
    page,
    lastPage,
  });

  const isFirstPage = page === 1;
  const isLastPage = page === lastPage;

  const handleNextClick = () => {
    onPageChange(page + 1);
  };

  const handleBackClick = () => {
    onPageChange(page - 1);
  };

  const handlePageClick = (page) => () => {
    onPageChange(page);
  };

  const makePagination = (iteratedPage) => {
    const active = iteratedPage === page;

    return (
      <Button
        theme
        key={iteratedPage}
        className={cx(
          'h-8 w-8 rounded-[4px] bg-grey-6 text-[14px] font-500 text-text-1 transition-colors hover:bg-grey-5',
          {
            'text-white bg-primary-1 pointer-events-none': active,
          }
        )}
        onClick={handlePageClick(iteratedPage)}
      >
        {iteratedPage}
      </Button>
    );
  };

  return (
    <div
      className={cx(
        'sticky bottom-0 -mx-6 -mb-6 flex items-center justify-between border-t border-grey-6 bg-white p-4',
        className
      )}
    >
      <div>
        <Render if={informable}>
          <Trans
            i18nKey="web:pagination"
            values={{
              totalCount,
              entryTanslationKey: t(entryTanslationKey, { count }),
            }}
          >
            Showing <strong className="font-semibold">{{ totalCount }}</strong>{' '}
            {{ entryTanslationKey }}
          </Trans>
        </Render>
      </div>
      <Render if={pagination}>
        <div className="flex items-center gap-2">
          <Navigator
            disabled={isFirstPage}
            icon={faChevronsLeft}
            onClick={handleBackClick}
          />
          {paginationRange.map(makePagination)}
          <Navigator
            disabled={isLastPage}
            icon={faChevronsRight}
            onClick={handleNextClick}
          />
        </div>
      </Render>
    </div>
  );
};

LayoutPagination.propTypes = propTypes;
LayoutPagination.defaultProps = defaultProps;
