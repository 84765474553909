import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { faBellSlash } from '@fortawesome/pro-solid-svg-icons';

import {
  ACTION_TYPE,
  NOTIFICATION_TYPE,
  PAGES_NAME,
  PUSH_NOTIFICATION_PERMISSIONS,
} from '@constants';
import { toast } from '@services';
import { getNotificationPermission } from '@utils';

import { updateUserNotifications } from '@actions';
import {
  createLoadingSelector,
  userIdSelector,
  userNotificationsSelector,
  userRoleSelector,
} from '@selectors';

import { IconBox, Layout, Notification, Render } from '@components';
import { NotificationSettings } from '@components/NotificationSettings';

const loadingSelector = createLoadingSelector(ACTION_TYPE.UPDATE_USER);

const propTypes = {};
const defaultProps = {};

export const SettingsPage = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(userNotificationsSelector);
  const userId = useSelector(userIdSelector);
  const role = useSelector(userRoleSelector);
  const loading = useSelector(loadingSelector);
  const { t } = useTranslation();

  const displayWarning =
    getNotificationPermission() !== PUSH_NOTIFICATION_PERMISSIONS.GRANTED;

  const handleNotificationsSubmit = async (dirtyNotifications) => {
    try {
      const { message } = await dispatch(
        updateUserNotifications({ userId, notifications: dirtyNotifications })
      );

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Layout className="flex flex-1 flex-col gap-6" title={PAGES_NAME.SETTINGS}>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Render if={displayWarning}>
        <Notification
          type={NOTIFICATION_TYPE.WARNING}
          title={t('web:toast.pushNotificationLimited:title')}
        >
          {t('web:toast.pushNotificationLimited:text')}
          <IconBox slot="icon" className="icon-20" icon={faBellSlash} />
        </Notification>
      </Render>
      <NotificationSettings
        loading={loading}
        defaultRole={role}
        notifications={notifications}
        onSubmit={handleNotificationsSubmit}
      />
    </Layout>
  );
};

SettingsPage.propTypes = propTypes;
SettingsPage.defaultProps = defaultProps;
