export const getNotificationPermission = (permission) => {
  if (!('Notification' in window)) {
    // eslint-disable-next-line no-console
    console.warn('Application does not support notifications');

    return undefined;
  }

  return permission
    ? Notification.permission === permission
    : Notification.permission;
};
