import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ACTION_TYPE, SUBSCRIPTION } from '@constants';
import { toast } from '@services';
import { useTranslationWithPrefix } from '@hooks';
import { noop } from '@utils';

import { unsubscribeUser } from '@actions';
import { createLoadingSelector, userIdSelector } from '@selectors';

import { Button, Theme } from '@components';

const loadingSelector = createLoadingSelector(ACTION_TYPE.UNSUBSCRIBE_USER);

const propTypes = {
  date: PropTypes.string.isRequired,
  onHide: PropTypes.func,
};
const defaultProps = {
  onHide: noop,
};

export const UnsubscribeModal = ({ date, onHide }) => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const userId = useSelector(userIdSelector);

  const { t, createPrefix } = useTranslationWithPrefix();

  const tt = createPrefix('web:subscription.plan');
  const freeSubscriptionPlanName = tt(SUBSCRIPTION.PLAN.FREE);

  const handleUnsubscribe = async () => {
    try {
      const { message } = await dispatch(unsubscribeUser(userId));

      toast.success(message);
      onHide();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <Theme.Title title={t('web:button.unsubscribe')} />
      <p className="text-center text-body-2-r">
        <Trans i18nKey="web:subscription.unsubscribeText">
          Are you sure you want to unsubscribe? Your subscription plan will be
          changed to
          <span className="font-600">{{ name: freeSubscriptionPlanName }}</span>
          . Current subscription is saved until {{ date }}
        </Trans>
      </p>
      <div className="flex justify-around border-t border-grey-7 pt-4">
        <Button variant="ghost-grey" loaderColor="grey" onClick={onHide}>
          {t('button.cancel')}
        </Button>
        <Button variant="ghost" loading={loading} onClick={handleUnsubscribe}>
          {t('web:button.unsubscribe')}
        </Button>
      </div>
    </div>
  );
};

UnsubscribeModal.propTypes = propTypes;
UnsubscribeModal.defaultProps = defaultProps;
