import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ACTION_TYPE, PATH, PROJECT_NAME } from '@constants';
import { toast } from '@services';
import { noop, onboardingLocalStorage } from '@utils';

import { setUserOnboarding } from '@actions';
import { createLoadingSelector, userIdSelector } from '@selectors';

import { Button, Theme } from '@components';
import onboardingImage from '@images/onboarding.png';

const loadingSelector = createLoadingSelector(ACTION_TYPE.UPDATE_USER);

const propTypes = {
  onHide: PropTypes.func,
};
const defaultProps = {
  onHide: noop,
};

export const OnboardingModal = ({ onHide }) => {
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const loading = useSelector(loadingSelector);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleUserOnboarding = async (active) => {
    try {
      await dispatch(setUserOnboarding({ userId, active }));

      onboardingLocalStorage.hide(userId);
      onHide();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleActivate = async () => {
    try {
      await handleUserOnboarding(true);

      navigate(PATH.PROFILE);
    } catch (error) {
      /**
       * Continue regardless error
       */
    }
  };

  return (
    <div className="flex flex-col gap-4 p-6">
      <Theme.Title title={t('welcome', { title: PROJECT_NAME })} />
      <p className="py-4 text-center text-body-1-r text-text-1">
        {t('web:onboarding.message')}
      </p>
      <p className="text-center">
        <img src={onboardingImage} alt="Onboarding" className="inline" />
      </p>
      <div className="flex justify-around border-t border-grey-7 pt-2">
        <Button
          loading={loading}
          variant="ghost-grey"
          loaderColor="grey"
          onClick={() => handleUserOnboarding(false)}
        >
          {t('button.skip')}
        </Button>
        <Button
          variant="ghost"
          loaderColor="primary"
          loading={loading}
          onClick={handleActivate}
        >
          {t('button.continue')}
        </Button>
      </div>
    </div>
  );
};

OnboardingModal.propTypes = propTypes;
OnboardingModal.defaultProps = defaultProps;
