import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ACTION_TYPE, PAGES_NAME, PATH } from '@constants';
import { toast } from '@services';
import { useSubscriptionPlans, useTranslationWithPrefix } from '@hooks';
import { as, getFormattedPriceWithCurrency } from '@utils';

import { subscribeUser } from '@actions';
import {
  createLoadingSelector,
  subscriptionPeriodSelector,
  userIdSelector,
  userPaymentMethodsSelector,
} from '@selectors';

import {
  BackButton,
  Button,
  Card,
  Layout,
  Theme,
  WithLoader,
} from '@components';
import { SubscriptionLimits } from '@components/SubscriptionLimits';
import { SubscriptionPeriodSwitcher } from '@components/TabSwitcher';
import { Info } from '@components/ui';

const subscribingSelector = createLoadingSelector(ACTION_TYPE.SUBSCRIBE_USER);

const propTypes = {};
const defaultProps = {};

export const SubscriptionPlanPage = () => {
  const dispatch = useDispatch();
  const { subscriptionPlan } = useParams();
  const period = useSelector(subscriptionPeriodSelector);
  const subscribing = useSelector(subscribingSelector);
  const userId = useSelector(userIdSelector);
  const paymentMethods = useSelector(userPaymentMethodsSelector);

  const navigate = useNavigate();

  const { empty: emptyPlanList, getSubscriptionPlan } = useSubscriptionPlans();
  const { t, createPrefix } = useTranslationWithPrefix();

  const tt = createPrefix('web:subscription');
  const {
    id: subscriptionPlanId,
    plan,
    price: { currency, [period]: price, discount: discounts } = {},
    limits = [],
  } = getSubscriptionPlan(subscriptionPlan);
  const discount = as.o(discounts)[period];

  useEffect(() => {
    if (!plan && !emptyPlanList) {
      navigate(PATH.SUBSCRIPTION_PLANS);
      toast.warning('t(Got unknown subscription plan)');
    }
  }, [plan, emptyPlanList, navigate]);

  const handleSubscribe = async () => {
    try {
      const { message } = await dispatch(
        subscribeUser({ userId, subscriptionPlanId, period })
      );

      toast.success(message);

      navigate(PATH.SUBSCRIPTION);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Layout className="flex flex-1 flex-col" title={PAGES_NAME.SUBSCRIPTION}>
      <Helmet>
        <title>Subscription</title>
      </Helmet>
      <div className="mb-4">
        <BackButton />
      </div>
      <WithLoader in={!plan} className="relative flex flex-1 items-start gap-6">
        <Theme.Container className="flex flex-1 flex-col gap-4">
          <Theme.Title title={tt('selectedPlan')}>
            <SubscriptionPeriodSwitcher />
          </Theme.Title>

          <Info.SubscriptionPlan
            subscriptionPlanId={subscriptionPlanId}
            period={period}
          />

          <div className="h-px bg-grey-7" />
          <div>
            <h4 className="mb-4 text-body-2-m text-grey-1">
              {tt('limitsTitle')}
            </h4>
            <SubscriptionLimits
              className="grid grid-cols-2 gap-y-6"
              limits={limits}
            />
          </div>
          <p className="border-t border-grey-7 pt-4 text-body-2-m text-text-1">
            {tt('unsubscribeAtAnyTime')}
          </p>
        </Theme.Container>

        <div className="flex flex-col gap-6">
          <Card.AddCard />
          <Theme.Card className="flex flex-col gap-4">
            <Theme.Title title={t('web:title.checkout')} />
            <div className="flex flex-col gap-2">
              <p className="flex justify-between text-body-1-r text-text-2">
                {tt('save')}
                <span className="text-body-1-s">
                  {getFormattedPriceWithCurrency(discount, currency)}
                </span>
              </p>
              <p className="flex justify-between text-title-1 text-grey-1">
                {t('label.total')}
                <span>{getFormattedPriceWithCurrency(price, currency)}</span>
              </p>
            </div>
            <div className="h-px bg-grey-7" />
            <Button
              disabled={!paymentMethods.length}
              loading={subscribing}
              className="w-full"
              onClick={handleSubscribe}
            >
              {t('web:button.subscribe')}
            </Button>
            <Theme.Note className="text-text-2">
              {tt('checkoutNote')}
            </Theme.Note>
          </Theme.Card>
        </div>
      </WithLoader>
    </Layout>
  );
};

SubscriptionPlanPage.propTypes = propTypes;
SubscriptionPlanPage.defaultProps = defaultProps;
