import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ACTION_TYPE } from '@constants';
import { firebase, toast } from '@services';
import { getBackendErrors, getRoleHomePage, noop } from '@utils';

import { authVerifyPhone, cacheAny, setDeviceToken } from '@actions';
import {
  createErrorSelector,
  createLoadingSelector,
  sharedSelector,
} from '@selectors';

import { Button, IconBox, Theme } from '@components';

const loadingSelector = createLoadingSelector(ACTION_TYPE.AUTH_VERIFY_PHONE);
const rolesConflictSelector = createErrorSelector(
  ACTION_TYPE.AUTH_VERIFY_PHONE
);

const propTypes = {
  onHide: PropTypes.func,
};
const defaultProps = {
  onHide: noop,
};

export const RolesConflictModal = ({ onHide }) => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const { phone, code } = useSelector(sharedSelector);
  const roles = useSelector(rolesConflictSelector);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeRole, setActiveRole] = useState(null);

  const handleRoleChange = (role) => () => {
    setActiveRole(role);
  };

  const handleSubmit = async () => {
    const payload = { phone, code, role: activeRole };

    if (!payload.role) {
      return null;
    }

    try {
      onHide();

      const { role, id: userId } = await dispatch(authVerifyPhone(payload));

      dispatch(cacheAny({ phone: null, code: null }));

      if (firebase.token) {
        dispatch(setDeviceToken(userId, firebase.token));
      }

      navigate(getRoleHomePage(role));
    } catch (error) {
      const { code } = getBackendErrors(error);

      toast.error(code);
      toast.error(error.message);
    }

    return null;
  };

  const roleList = roles.map((role) => {
    const active = role === activeRole;
    const icon = active ? faCircleCheck : faCircle;

    return (
      <Button
        theme
        key={role}
        className={cx(
          'flex h-11 w-full items-center gap-2 rounded-lg border border-grey-6 bg-grey-9 px-3 py-2 text-body-2-r capitalize text-text-1 transition-colors hover:border-primary-1',
          {
            'border-primary-1': active,
          }
        )}
        onClick={handleRoleChange(role)}
      >
        <IconBox icon={icon} className="h-5 w-5 text-primary-1 icon-20" />
        <span>{t(`role.${role}`)}</span>
      </Button>
    );
  });

  return (
    <div className="flex flex-col gap-4 p-6">
      <Theme.Title title={t('web:title.rolesConflict')} />
      <div className="grid grid-cols-2 gap-2">{roleList}</div>
      <div className="h-px bg-grey-6" />
      <Theme.Note>{t('web:rolesTooltip')}</Theme.Note>
      <Button loading={loading} className="w-full" onClick={handleSubmit}>
        {t('button.login')}
      </Button>
    </div>
  );
};

RolesConflictModal.propTypes = propTypes;
RolesConflictModal.defaultProps = defaultProps;
