import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { TableCell } from './TableCell';
import { TableEditIcon } from './TableEditIcon';
import { TableHead } from './TableHead';
import { TableList } from './TableList';
import { TableRow } from './TableRow';

const propTypes = {
  className: PropTypes.string,
  head: PropTypes.node,
  body: PropTypes.node,
  children: PropTypes.node,
};
const defaultProps = {
  className: undefined,
  head: null,
  body: null,
  children: null,
};

export const Table = ({ className, head, body, children }) => {
  const renderTable = () => {
    if (children) {
      return children;
    }

    return (
      <>
        <thead>{head}</thead>
        <tbody>{body}</tbody>
      </>
    );
  };

  return (
    <div className="w-full overflow-x-auto whitespace-nowrap">
      <table
        className={cx(
          'w-full border-separate border-spacing-x-0 border-spacing-y-1',
          className
        )}
      >
        {renderTable()}
      </table>
    </div>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

Table.Cell = TableCell;
Table.EditIcon = TableEditIcon;
Table.Head = TableHead;
Table.List = TableList;
Table.Row = TableRow;
