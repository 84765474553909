import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { CONFIG, PAGES_NAME } from '@constants';

import { configSidebarTabSelector } from '@selectors';

import { Layout } from '@components';
import * as Config from '@components/Config';

const propTypes = {};
const defaultProps = {};

export const ConfigurationPage = () => {
  const tab = useSelector(configSidebarTabSelector);

  const renderTab = () => {
    if (tab === CONFIG.KEY.SHARED) {
      return <Config.Shared />;
    }

    if (tab === CONFIG.KEY.FEATURES) {
      return <Config.Features />;
    }

    if (tab === CONFIG.KEY.SUBSCRIPTION) {
      return <Config.SubscriptionPlans />;
    }

    if (tab === CONFIG.KEY.NOTIFICATIONS) {
      return <Config.Notifications />;
    }

    return null;
  };

  return (
    <Layout className="flex flex-1 p-0" title={PAGES_NAME.CONFIGURATION}>
      <Helmet>
        <title>Configuration</title>
      </Helmet>
      <div className="flex flex-1">
        <Config.Sidebar />
        <div className="flex flex-1 flex-col gap-6 bg-grey-8 p-6">
          {renderTab()}
        </div>
      </div>
    </Layout>
  );
};

ConfigurationPage.propTypes = propTypes;
ConfigurationPage.defaultProps = defaultProps;
