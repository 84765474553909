import { loadStripe } from '@stripe/stripe-js';

import { STRIPE } from '@constants';

export const stripe = {
  promise: null,

  init() {
    if (this.promise) {
      return;
    }

    this.promise = loadStripe(STRIPE.TOKEN);
  },
};
