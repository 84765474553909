import React from 'react';
import { useTranslation } from 'react-i18next';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';

import { cn } from '@utils';

import { IconBox, Render } from '@components';

const UNLIMITED_VALUE = -1;

const propTypes = {
  limits: PropTypes.shape().isRequired,
  itemClassName: PropTypes.string,
  className: PropTypes.string,
};
const defaultProps = {
  itemClassName: undefined,
  className: undefined,
};

export const SubscriptionLimits = ({ limits, itemClassName, className }) => {
  const { t } = useTranslation();

  const makeLimit = ([limit, value]) => {
    const unlimited = value === UNLIMITED_VALUE;
    /**
     * Makes plural if `0`, and singular if `1`
     *
     */
    const count = unlimited ? 0 : value;
    const text = unlimited ? t('web:subscription.unlimited') : value;

    return (
      <Render key={limit} if={!!value}>
        <li
          className={cn(
            'flex items-center gap-1 text-body-2-m text-text-1',
            itemClassName
          )}
        >
          <IconBox icon={faCheck} className="text-primary-1 icon-18" />
          <span>
            {text} {t(limit, { count })}
          </span>
        </li>
      </Render>
    );
  };

  return <ul className={className}>{Object.entries(limits).map(makeLimit)}</ul>;
};

SubscriptionLimits.propTypes = propTypes;
SubscriptionLimits.defaultProps = defaultProps;
