import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ACTION_TYPE, PATH } from '@constants';
import { toast } from '@services';
import { useRole } from '@hooks';
import { getRoleHomePage } from '@utils';

import { setUserOnboarding } from '@actions';
import {
  configSupportEmailSelector,
  createLoadingSelector,
  userIdSelector,
  userOnboardingSelector,
  userSubscriptionSelector,
} from '@selectors';

import { Button, Layout, Link, Theme } from '@components';
import { OnboardingContainer } from '@components/OnboardingContainer';
import { SubscriptionLimits } from '@components/SubscriptionLimits';
import onboardingImage from '@images/onboarding.png';

const loadingSelector = createLoadingSelector(ACTION_TYPE.UPDATE_USER);

const propTypes = {};
const defaultProps = {};

export const OnboardingConclusionPage = () => {
  const dispatch = useDispatch();
  const { plan, limits } = useSelector(userSubscriptionSelector);
  const email = useSelector(configSupportEmailSelector);
  const userId = useSelector(userIdSelector);
  const loading = useSelector(loadingSelector);
  const { active: onboarding } = useSelector(userOnboardingSelector);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { role } = useRole();

  const planName = t(`web:subscription.plan.${plan}`);

  const handleNavigate = async () => {
    try {
      await dispatch(setUserOnboarding({ userId, active: false }));

      navigate(PATH.RESTAURANTS);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (!onboarding) {
      navigate(getRoleHomePage(role));
    }
  }, [navigate, role, onboarding]);

  return (
    <Layout
      className="flex flex-1 flex-col gap-6"
      title={t('web:onboarding.conclusion')}
    >
      <Helmet>
        <title>Onboarding Conclusion</title>
      </Helmet>
      <div className="flex justify-end">
        <Button loading={loading} color="secondary" onClick={handleNavigate}>
          {t('web:gotIt')}
        </Button>
      </div>
      <OnboardingContainer />
      <Theme.Container className="flex items-center">
        <img
          src={onboardingImage}
          alt="Onboarding Conclusion"
          className="mr-5 basis-[356px] object-contain"
        />
        <div className="flex-1">
          <Trans i18nKey="web:onboarding.conclusionScope">
            <h3 className="text-body-1-s text-grey-1">Whats next?</h3>
            <p className="mt-4 text-body-2-r">
              1. The user will be able to see your restaurant in the application
              when an administrator verifies your profile
            </p>

            <p className="mt-2 text-body-2-r">
              You can check the verification status in
              <Link
                theme
                className="primary-1-with-hover inline-block transition-colors"
                to={PATH.PROFILE}
              >
                Profile page
              </Link>
              .
            </p>
            <p className="mt-4 text-body-2-r">
              2. Your current <span className="font-600">{{ planName }}</span>
              plan includes: for more options
            </p>
            <SubscriptionLimits
              className="mt-4 grid grid-cols-2 gap-y-4"
              itemClassName="text-body-2-r"
              limits={limits}
            />
            <div className="mt-4 text-body-2-r">
              See
              <Link
                theme
                className="space-both primary-1-with-hover inline-block transition-colors"
                to={PATH.SUBSCRIPTION_PLANS}
              >
                Subscription Plans
              </Link>
              for more options.
            </div>
            <p className="mt-4 text-body-2-r">3. Have a nice job</p>
            <p className="mt-4 text-body-2-r">
              For more information contact us
              <Link
                external
                theme
                className="primary-1-with-hover transition-colors"
                to={`mailto:${email}`}
              >
                {{ email }}
              </Link>
            </p>
          </Trans>
        </div>
      </Theme.Container>
    </Layout>
  );
};

OnboardingConclusionPage.propTypes = propTypes;
OnboardingConclusionPage.defaultProps = defaultProps;
