export const ACTION_TYPE = {
  CACHE_ANY: 'CACHE_ANY',
  CACHE_GEO_LOCATION: 'CACHE_GEO_LOCATION',
  CACHE_PHONE: 'CACHE_PHONE',
  CACHE_RESTAURANTS: 'CACHE_RESTAURANTS',
  UPDATE_CONFIG: 'UPDATE_CONFIGURATION',
  SET_CONFIG: 'SET_CONFIGURATION',
  SET_CONFIG_SHARED: 'SET_SHARED_CONFIGURATION',
  SET_CONFIG_FEATURE: 'SET_FEATURE_CONFIGURATION',
  SET_SIDEBAR_COLLAPSE: 'SET_SIDEBAR_COLLAPSE',
  SET_SUBSCRIPTION_PERIOD: 'SET_SUBSCRIPTION_PERIOD',
  SET_CONFIG_SIDEBAR_TAB: 'SET_CONFIG_SIDEBAR_TAB',
  SET_PAGINATION: 'SET_PAGINATION',

  /**
   * Order
   */
  GET_ORDERS: 'GET_RESTAURANT_ORDERS',
  GET_ORDER: 'GET_RESTAURANT_ORDER',
  UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
  ORDER_ASSIGN_COURIER: 'ORDER_ASSIGN_COURIER',

  /**
   * Restaurant
   */
  GET_RESTAURANTS: 'GET_ALL_RESTAURANTS',
  GET_RESTAURANT: 'GET_RESTAURANT_BY_ID',
  ADD_RESTAURANT: 'ADD_NEW_RESTAURANT',
  DELETE_RESTAURANT: 'DELETE_RESTAURANT',
  UPDATE_RESTAURANT: 'UPDATE_RESTAURANT',
  RESTORE_RESTAURANT: 'RESTORE_RESTAURANT',
  FIRE_COURIER: 'FIRE_COURIER_FROM_RESTAURANT',
  GET_REVIEWS: 'GET_REVIEWS',

  GET_DISHES: 'GET_RESTAURANT_DISHES',
  GET_DISH: 'GET_DISH_BY_ID',
  CREATE_DISH: 'CREATE_RESTAURANT_DISH',
  UPDATE_DISH: 'UPDATE_RESTAURANT_DISH',
  DELETE_DISH: 'DELETE_RESTAURANT_DISH',
  RESTORE_DISH: 'RESTORE_RESTAURANT_DISH',

  GET_COURIER: 'GET_COURIER_BY_PHONE',
  INVITE_COURIER_TO_RESTAURANT: 'INVITE_COURIER_TO_RESTAURANT',

  /**
   * User
   */
  AUTH_SIGNUP: 'AUTH_SIGNUP',
  AUTH_LOGIN: 'AUTH_LOGIN',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_VERIFY_PHONE: 'AUTH_VERIFY_PHONE',

  ADD_PAYMENT_METHOD: 'ADD_PAYMENT_METHOD',
  REMOVE_PAYMENT_METHOD: 'REMOVE_PAYMENT_METHOD',
  SET_DEFAULT_PAYMENT_METHOD: 'SET_DEFAULT_PAYMENT_METHOD',

  SET_USER: 'SET_USER',
  SET_USER_BILLING: 'SET_USER_BILLING',
  RESET_USER: 'RESET_USER',

  GET_ADMIN_USERS: 'GET_ADMIN_USERS',
  GET_ADMIN_USER: 'GET_ADMIN_USER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_LOCALE: 'UPDATE_USER_LOCALE',
  VERIFY_DOCUMENTS: 'USER_VERIFY_DOCUMENTS',
  GET_REFERRALS: 'GET_REFERRALS',

  GET_BILLING_HISTORY: 'GET_BILLING_HISTORY',

  /**
   * Subscription
   */
  GET_SUBSCRIPTION_PLANS: 'GET_SUBSCRIPTION_PLANS',
  SET_SUBSCRIPTION_PLANS: 'SET_SUBSCRIPTION_PLANS',
  UPDATE_SUBSCRIPTION_PLAN: 'UPDATE_SUBSCRIPTION_PLAN',
  SUBSCRIBE_USER: 'SUBSCRIBE_USER',
  UNSUBSCRIBE_USER: 'UNSUBSCRIBE_USER',
};
