import { createSelector } from 'reselect';

import { as, isObject } from '@utils';

export const userSelector = (state) => state.user;

export const userRoleSelector = createSelector(
  userSelector,
  ({ role }) => role
);

export const userStatusSelector = createSelector(
  userSelector,
  ({ status }) => status
);

export const userAuthorizedSelector = createSelector(
  userSelector,
  (user) => !isObject.empty(user)
);

export const userDocumentsSelector = createSelector(
  userSelector,
  ({ documents }) => documents
);

export const userVerificationStatusSelector = createSelector(
  userSelector,
  ({ status }) => status
);

export const userIdSelector = createSelector(userSelector, ({ id }) => id);

export const userSubscriptionSelector = createSelector(
  userSelector,
  ({ subscription }) => as.o(subscription)
);

export const userBillingSelector = createSelector(
  userSelector,
  ({ billing }) => billing
);

export const userPaymentMethodsSelector = createSelector(
  userBillingSelector,
  ({ paymentMethods }) => paymentMethods
);

export const userNotificationsSelector = createSelector(
  userSelector,
  ({ notifications }) => notifications
);

export const userOnboardingSelector = createSelector(
  userSelector,
  ({ onboarding }) => as.o(onboarding)
);
