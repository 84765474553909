import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

import {
  COLOR,
  MODEL,
  PAGES_NAME,
  PAGINATION_ENTRY_KEY,
  PATH_ACTION,
  RESTAURANT_STATUS,
  STATUS,
} from '@constants';
import { useFetchList, useRole } from '@hooks';
import {
  createClassName,
  getFormattedPriceWithCurrency,
  makeFilterStatuses,
  path,
} from '@utils';

import { getDishes } from '@actions';

import {
  Avatar,
  Badge,
  Filter,
  Layout,
  Link,
  Render,
  Table,
  WithLoader,
} from '@components';
import { EmptyList } from '@components/EmptyList';
import { ProductStatusTooltip } from '@components/Tooltips/ProductStatusTooltip';
import * as emptyListImage from '@images/emptyList';

const propTypes = {};
const defaultProps = {};

export const ProductsPage = () => {
  const { restaurantId } = useParams();
  const { t } = useTranslation();
  const { owner } = useRole();

  const { loading, noList, empty, list, getList, onPageChange } = useFetchList({
    getList: (filters) => getDishes(restaurantId, filters),
  });

  const getClass = createClassName(noList);

  const createDishLink = (
    <Render slot="link" if={owner}>
      <Link
        to={path.product(PATH_ACTION.CREATE, restaurantId)}
        className="flex items-center"
      >
        {t('generic.add', { title: t('web:net.dish') })}
      </Link>
    </Render>
  );

  const statusFilterOptions = useMemo(() => {
    const statuses = [
      RESTAURANT_STATUS.DELETED,
      RESTAURANT_STATUS.LIMITED,
      RESTAURANT_STATUS.PUBLISHED,
      RESTAURANT_STATUS.UNPUBLISHED,
    ];

    return makeFilterStatuses(statuses, t);
  }, [t]);

  const tableHead = (
    <tr>
      <Table.Head align="center">{t('web:label.cover')}</Table.Head>
      <Table.Head>{t('web:label.dishTitle')}</Table.Head>
      <Table.Head align="center">{t('label.price')}</Table.Head>
      <Table.Head align="center">{t('label.status')}</Table.Head>
      <Table.Head align="center">{t('web:label.edit')}</Table.Head>
    </tr>
  );

  const makeBodyRow = ({
    avatar,
    category,
    currency,
    description,
    id,
    measure,
    name,
    price,
    quantity,
    status,
  }) => (
    <Table.Row
      key={id}
      to={path.product(id, restaurantId)}
      className={cx('bg-white', {
        'bg-grey-7': status !== STATUS.RESTAURANT.PUBLISHED,
      })}
    >
      <Table.Cell align="center" width={112}>
        <Avatar src={avatar} model={MODEL.PRODUCT} size={96} />
      </Table.Cell>
      <Table.Cell className="flex flex-col justify-center" width={400}>
        <Render if={category}>
          <p className="text-label-1 font-500 uppercase text-primary-1">
            {t(`category.${category}`)}
          </p>
        </Render>
        <p className="text-[14px] font-500 leading-[20px] text-grey-1">
          {name}
        </p>
        <Render if={description}>
          <p className="truncate text-body-2-r text-text-1" title={description}>
            {description}
          </p>
        </Render>
        <p className="text-subtitle text-grey-3">
          {quantity} {t(`measure.${measure}`)}
        </p>
      </Table.Cell>
      <Table.Cell align="center" width={80}>
        {getFormattedPriceWithCurrency(price, currency)}
      </Table.Cell>
      <Table.Cell truncate={false} align="center" width={120}>
        <ProductStatusTooltip status={status} productId={id}>
          <Badge color={COLOR.RESTAURANT[status]}>
            {t(`status.${status}`)}
          </Badge>
        </ProductStatusTooltip>
      </Table.Cell>
      <Table.EditIcon to={path.product.edit(id, restaurantId)} />
    </Table.Row>
  );

  const tableBody = list?.map(makeBodyRow);

  const renderContent = () => {
    if (noList) {
      return (
        <EmptyList image={emptyListImage.dish} title={t('web:empty.dishes')}>
          {createDishLink}
        </EmptyList>
      );
    }

    if (empty) {
      return (
        <EmptyList
          image={emptyListImage.dish}
          title={t('web:text.emptyFilterList')}
        />
      );
    }

    return (
      <div className="mt-4">
        <Table head={tableHead} body={tableBody} />
      </div>
    );
  };

  return (
    <Layout
      paginationEnabled
      className="flex flex-1 flex-col"
      title={PAGES_NAME.DISHES}
      onPageChange={onPageChange}
      paginationEntryName={PAGINATION_ENTRY_KEY.PRODUCTS}
    >
      <Helmet>
        <title>Dishes</title>
      </Helmet>
      <WithLoader in={loading}>
        <Render if={!noList}>
          <div
            className={cx('flex', getClass('justify-end', 'justify-between'))}
          >
            <Filter
              filters={{
                name: {
                  title: t('web:label.dishTitle'),
                },
                status: {
                  title: t('label.status'),
                  options: statusFilterOptions,
                },
              }}
              onFilter={getList}
            />
            {createDishLink}
          </div>
        </Render>
        {renderContent()}
      </WithLoader>
    </Layout>
  );
};

ProductsPage.propTypes = propTypes;
ProductsPage.defaultProps = defaultProps;
