import React from 'react';
import PropTypes from 'prop-types';

import { buttonVariants, cn, noop } from '@utils';

import { Render, SpinnerChase } from '@components';

const propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  size: PropTypes.oneOf(['default']),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'ghost',
    'ghost-grey',
    'outline',
    'outline-grey',
  ]),
  loaderColor: PropTypes.oneOf(['primary', 'white', 'grey']),
  onClick: PropTypes.func,
};

const defaultProps = {
  theme: false,
  loading: false,
  disabled: false,
  className: undefined,
  type: 'button',
  size: 'default',
  variant: 'primary',
  loaderColor: 'white',
  onClick: noop,
};

export const Button = ({
  children,
  theme,
  loading,
  disabled,
  className,
  size,
  variant,
  loaderColor,
  type,
  ...props
}) => {
  const isLoading = !theme && loading;

  const classNames = theme
    ? className
    : cn(buttonVariants({ variant, size, className }), {
        'pointer-events-none': loading || disabled,
        'text-transparent': loading,
      });

  return (
    // eslint-disable-next-line react/button-has-type
    <button {...props} type={type} disabled={disabled} className={classNames}>
      <Render if={isLoading}>
        <SpinnerChase
          className="absolute inset-0 m-auto transition-all"
          color={loaderColor}
        />
      </Render>
      {children}
    </button>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
