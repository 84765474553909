import { useCallback, useMemo } from 'react';

/*
 * Only odd numbers are valid
 */
const PAGE_LIMIT = 5;
const MAX_PAGES_TO_SIDES = PAGE_LIMIT % 2 ? (PAGE_LIMIT - 1) / 2 : 1;

export const usePagination = ({ page, lastPage }) => {
  const range = useCallback((start, end) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  }, []);

  const pagination = useMemo(() => {
    const lessPagesThanLimit = PAGE_LIMIT >= lastPage;

    /**
     * There is less pages than PAGE_LIMIT;
     * Show only needed pages
     */
    if (lessPagesThanLimit) {
      return range(1, lastPage);
    }

    /**
     * First page
     */
    if (page === 1) {
      const end = lessPagesThanLimit ? lastPage : PAGE_LIMIT;

      return range(1, end);
    }

    /**
     * Last page
     */
    if (page === lastPage) {
      const end = lessPagesThanLimit ? 1 : lastPage - PAGE_LIMIT + 1;

      return range(end, lastPage);
    }

    /**
     * Page in range
     */
    if (page > 1 && page < lastPage) {
      const spaceToLeft = page - MAX_PAGES_TO_SIDES - 1;
      const spaceToRight = lastPage - page;
      const canFitLeft = spaceToLeft > 0;
      const canFitRight = spaceToRight >= MAX_PAGES_TO_SIDES;
      const additionalPagesOnRight = !canFitLeft ? Math.abs(spaceToLeft) : 0;
      const additionalPagesOnLeft = !canFitRight
        ? MAX_PAGES_TO_SIDES - spaceToRight
        : 0;

      const start = canFitLeft
        ? page - MAX_PAGES_TO_SIDES - additionalPagesOnLeft
        : 1;
      const end = canFitRight
        ? page + MAX_PAGES_TO_SIDES + additionalPagesOnRight
        : lastPage;

      return range(start, end);
    }

    return range(page, page);
  }, [page, range, lastPage]);

  return pagination;
};
