import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { mapboxgl } from '@services';

import { geolocationSelector } from '@selectors';

const propTypes = {
  className: PropTypes.string,
  initialCenter: PropTypes.arrayOf(PropTypes.number),
  setMapRef: PropTypes.func,
  makeMarker: PropTypes.func,
  onMapClick: PropTypes.func,
  removeGeocoderMarker: PropTypes.func,
};
const defaultProps = {
  className: '',
  initialCenter: null,
  setMapRef: () => {},
  makeMarker: () => {},
  onMapClick: () => {},
  removeGeocoderMarker: () => {},
};

export const Map = ({
  className,
  initialCenter,
  setMapRef,
  makeMarker,
  onMapClick,
  removeGeocoderMarker,
}) => {
  const geolocation = useSelector(geolocationSelector);

  const map = useRef(null);
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const center = initialCenter ?? [geolocation.lng, geolocation.lat];

    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: { enableHighAccuracy: true },
      trackUserLocation: true,
      showUserLocation: false,
      speed: 8,
    });

    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center,
      zoom: initialCenter ? 16 : 12,
      attributionControl: false,
      cooperativeGestures: true,
      customAttribution: false,
    });

    setMapRef(map.current);

    map.current.on('load', () => {
      map.current.addControl(geolocate);

      if (initialCenter) {
        makeMarker(center);
      }
    });

    map.current.on('click', ({ lngLat: { lat, lng } }) => {
      removeGeocoderMarker();

      const center = [lng, lat];

      onMapClick({ lng, lat });

      map.current.flyTo({ center, zoom: 16, speed: 8 });
    });

    geolocate.on(
      'geolocate',
      ({ coords: { longitude: lng, latitude: lat } }) => {
        onMapClick({ lng, lat });
      }
    );

    return () => {
      map.current.remove();
    };

    /**
     * `setMapRef` has be excluded
     * `onClick` has be excluded
     * `removeGeocoderMarker` has be excluded
     */
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="relative">
      <div
        ref={mapContainerRef}
        className={cx('flex h-[320px] overflow-hidden rounded-md', className)}
      />
    </div>
  );
};

Map.propTypes = propTypes;
Map.defaultProps = defaultProps;
