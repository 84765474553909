import React from 'react';
import PropTypes from 'prop-types';

import { cn } from '@utils';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  className: '',
  children: null,
};

export const ThemeNote = ({ className, children }) => (
  <p className={cn('text-footnote italic text-grey-3', className)}>
    {children}
  </p>
);

ThemeNote.propTypes = propTypes;
ThemeNote.defaultProps = defaultProps;
