import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Currency } from '@types';
import { MODEL } from '@constants';
import { getFormattedPriceWithCurrency } from '@utils';

import { Avatar, Render, Table } from '@components';

const propTypes = {
  currency: PropTypes.string,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      count: PropTypes.number,
      category: PropTypes.string,
      currency: Currency.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};
const defaultProps = {
  currency: null,
  menu: [],
};

export const OrderDetails = ({ currency, menu }) => {
  const { t } = useTranslation();

  const tableHead = (
    <tr className="bg-white">
      <Table.Head>{t('web:label.menu')}</Table.Head>
      <Table.Head align="center">{t('label.quantity')}</Table.Head>
      <Table.Head align="center">{t('label.price')}</Table.Head>
      <Table.Head align="center">{t('label.total')}</Table.Head>
    </tr>
  );

  const makeBodyRow = ({ id, avatar, category, name, count, price, total }) => (
    <tr key={id} className="bg-white">
      <Table.Cell>
        <div className="flex items-center">
          <Avatar
            className="mr-4"
            src={avatar}
            model={MODEL.PRODUCT}
            size={64}
          />
          <div>
            <Render if={category}>
              <p className="text-12 text-primary-1">
                {t(`category.${category}`)}
              </p>
            </Render>
            <p className="text-14">{name}</p>
          </div>
        </div>
      </Table.Cell>
      <Table.Cell align="center">{count}</Table.Cell>

      <Table.Cell align="center">
        {getFormattedPriceWithCurrency(price, currency)}
      </Table.Cell>
      <Table.Cell align="center">
        <Render if={total} else="-">
          {getFormattedPriceWithCurrency(total, currency)}
        </Render>
      </Table.Cell>
    </tr>
  );

  const tableBody = menu.map(makeBodyRow);

  return <Table head={tableHead} body={tableBody} />;
};

OrderDetails.propTypes = propTypes;
OrderDetails.defaultProps = defaultProps;
