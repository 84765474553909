import PropTypes from 'prop-types';

import {
  COLOR,
  CONFIG,
  MODEL,
  NOTIFICATION_TYPE,
  PLATFORM,
  STATUS,
  SUBSCRIPTION,
  TOAST_TYPE,
  USER_ROLE,
  USER_ROLE_SCOPE,
} from '@constants';

export const Currency = PropTypes.string;

export const Icon = PropTypes.shape();

export const BoxSize = PropTypes.oneOf(['16', '24', '32', '40', '48', '64']);

export const InputType = PropTypes.oneOf([
  'text',
  'password',
  'email',
  'tel',
  'number',
]);

export const InputMode = PropTypes.oneOf([
  'text',
  'decimal',
  'email',
  'tel',
  'numeric',
]);

export const Photo = PropTypes.oneOfType([PropTypes.object, PropTypes.string]);

export const PageRoleScope = PropTypes.oneOf([
  USER_ROLE_SCOPE.ADMIN,
  USER_ROLE_SCOPE.OWNER,
]);

export const Role = PropTypes.oneOf(Object.values(USER_ROLE));

export const Platform = PropTypes.oneOf(Object.values(PLATFORM));

export const OrderStatus = PropTypes.oneOf(Object.values(STATUS.ORDER));

export const RestaurantStatus = PropTypes.oneOf(
  Object.values(STATUS.RESTAURANT)
);

export const ReviewStatus = PropTypes.oneOf(Object.values(STATUS.REVIEW));

export const ConfigName = PropTypes.oneOf(Object.values(CONFIG.SHARED));

export const Notification = PropTypes.oneOf(Object.values(NOTIFICATION_TYPE));

export const ToastType = PropTypes.oneOf([
  TOAST_TYPE.SUCCESS,
  TOAST_TYPE.ERROR,
  TOAST_TYPE.WARNING,
  TOAST_TYPE.INFO,
  TOAST_TYPE.DEFAULT,
]);

export const BadgeColor = PropTypes.oneOf(Object.keys(COLOR.MAP));

export const Shipping = PropTypes.oneOf(['delivery', 'pickup']);

export const Typography = PropTypes.oneOf([
  'text-body-1-m',
  'text-body-1-r',
  'text-body-1-s',
  'text-headline',
  'text-label-1',
  'text-subtitle',
  'text-title-1',
  'text-title-2',
]);

export const SubscriptionPlan = PropTypes.oneOf(
  Object.values(SUBSCRIPTION.PLAN)
);

export const Model = PropTypes.oneOf(Object.values(MODEL));

export const AvatarSize = PropTypes.oneOf([48, 64, 96, 150, 160, 272]);
export const AvatarUserSize = PropTypes.oneOf([150, 64, 48]);
