import React from 'react';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import {
  faCircleCheck,
  faCircleInfo,
  faDiamondExclamation,
  faHexagonExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';

import { Notification as NotificationType } from '@types';
import { NOTIFICATION_TYPE } from '@constants';
import { getSlots, tv } from '@utils';

import { Button, IconBox, Render, Slot } from '@components';

const variants = tv({
  base: 'flex items-start gap-2 p-4 border rounded-lg p-4 shadow-[0px_0px_8px_rgba(62,73,84,0.08)]',
  variants: {
    variant: {
      [NOTIFICATION_TYPE.INFO]: 'border-other-9 text-other-9 bg-secondary-7',
      [NOTIFICATION_TYPE.SUCCESS]: 'border-other-8 text-other-8 bg-secondary-3',
      [NOTIFICATION_TYPE.WARNING]: 'border-other-5 text-other-5 bg-other-7',
      [NOTIFICATION_TYPE.ERROR]: 'border-other-3 text-other-3 bg-other-6',
    },
  },
  defaultVariants: {
    variant: NOTIFICATION_TYPE.INFO,
  },
});

const iconMap = {
  [NOTIFICATION_TYPE.ERROR]: faHexagonExclamation,
  [NOTIFICATION_TYPE.INFO]: faCircleInfo,
  [NOTIFICATION_TYPE.SUCCESS]: faCircleCheck,
  [NOTIFICATION_TYPE.WARNING]: faDiamondExclamation,
};

const propTypes = {
  iconable: PropTypes.bool,
  className: PropTypes.string,
  type: NotificationType,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};
const defaultProps = {
  iconable: true,
  className: '',
  type: NOTIFICATION_TYPE.INFO,
  title: '',
  children: null,
  onClose: null,
};

export const Notification = ({
  iconable,
  className,
  type: variant,
  title,
  children,
  onClose,
}) => {
  const { content, ...slots } = getSlots(children);

  return (
    <div className={variants({ variant, className })}>
      <Render if={iconable}>
        <Slot slot={slots.icon}>
          <IconBox className="icon-22" icon={iconMap[variant]} />
        </Slot>
      </Render>
      <div className="flex-1 text-body-1-r">
        <Render if={title}>
          <h4 className="text-body-1-s">{title}</h4>
        </Render>
        {content}
      </div>
      <Render if={onClose}>
        <Button theme className="ml-2" onClick={onClose}>
          <IconBox icon={faCircleXmark} className="icon-22" />
        </Button>
      </Render>
    </div>
  );
};

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;
