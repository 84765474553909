import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@types';
import { cn } from '@utils';

import { IconBox, Render } from '@components';

const propTypes = {
  className: PropTypes.string,
  icon: Icon,
  label: PropTypes.string,
  text: PropTypes.string,
  footnote: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  className: undefined,
  icon: null,
  label: undefined,
  text: undefined,
  footnote: undefined,
  children: undefined,
};

export const RowItem = ({
  className,
  icon,
  label,
  text,
  footnote,
  children,
}) => {
  return (
    <div
      className={cn(
        'flex items-start gap-4 border-t border-grey-7 py-3 text-text-1',
        className
      )}
    >
      <IconBox icon={icon} className="shrink-0 icon-18" />
      <div>
        <Render if={label}>
          <p className="text-label-1 text-text-2">{label}</p>
        </Render>
        <p className="text-body-2-m">{text ?? children}</p>
        <Render if={footnote}>
          <p className="mt-1 text-footnote italic text-grey-3">{footnote}</p>
        </Render>
      </div>
    </div>
  );
};

RowItem.propTypes = propTypes;
RowItem.defaultProps = defaultProps;
