import React from 'react';
import PropTypes from 'prop-types';

import { InputMode, InputType } from '@types';
import { calculateTextWidth, cn, fieldVariants } from '@utils';

import { Field, Render } from '@components';

import { FieldContainer } from './FieldContainer';

const propTypes = {
  name: PropTypes.string.isRequired,
  requiredLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
  error: PropTypes.string,
  type: InputType,
  inputMode: InputMode,
};

const defaultProps = {
  requiredLabel: false,
  disabled: false,
  className: '',
  containerClassName: '',
  label: '',
  prefix: '',
  postfix: '',
  error: '',
  type: 'text',
  inputMode: 'text',
};

/**
 * Padding value with salt
 */
const INPUT_AFFIX_PADDING = 14;

export const FieldInput = React.forwardRef(
  (
    {
      name,
      requiredLabel,
      disabled,
      className,
      containerClassName,
      label,
      prefix,
      postfix,
      error,
      ...props
    },
    ref
  ) => (
    <FieldContainer className={containerClassName}>
      <Field.Label required={requiredLabel} for={name}>
        {label}
      </Field.Label>
      <Render if={prefix}>
        <span className="pointer-events-none absolute bottom-[35px] left-3 text-body-2-m leading-[1] text-text-2">
          {prefix}
        </span>
      </Render>
      <input
        {...props}
        ref={ref}
        id={name}
        style={{
          paddingLeft:
            prefix && calculateTextWidth(prefix) + INPUT_AFFIX_PADDING,
          paddingRight:
            postfix && calculateTextWidth(postfix) + INPUT_AFFIX_PADDING,
        }}
        className={cn(
          'h-9 w-full px-3 py-2',
          fieldVariants({ error: !!error, className })
        )}
        name={name}
        disabled={disabled}
      />
      <Render if={postfix}>
        <span className="pointer-events-none absolute bottom-[35px] right-3 text-body-2-m leading-[1] text-text-2">
          {postfix}
        </span>
      </Render>
      <Field.Error error={error} />
    </FieldContainer>
  )
);

FieldInput.displayName = 'Field.Input';
FieldInput.propTypes = propTypes;
FieldInput.defaultProps = defaultProps;
