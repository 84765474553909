import { toast } from 'react-toastify';

import { ANDROID_ID, APPLE_ID } from '@constants/app.constants';

export const PROJECT_NAME = 'Akicomo';

export const LOCALE = {
  EN: 'en',
  ES: 'es',
  UA: 'ua',
};

export const CONFIG = {
  KEY: {
    FEATURES: 'features',
    NOTIFICATIONS: 'notifications',
    SHARED: 'shared',
    SUBSCRIPTION: 'subscription',
  },
  TYPE: {
    ACCESSIBILITY: 'accessibility',
    FEATURE: 'feature',
    OBJECT: 'object',
    SHARED: 'array',
  },
  FEATURE: {
    MAINTENANCE: 'maintenance',
    ONBOARDING: 'onboarding',
    PAGINATION: 'pagination',
    TWILIO: 'twilio',
  },
  SHARED: {
    CATEGORY: 'categories',
    CUISINE: 'cuisines',
    CURRENCY: 'currencies',
    LOCALE: 'locales',
    MEASURE: 'measures',
    VERSIONS: 'versions',
  },
};

export const USER_ROLE = {
  ADMIN: 'admin',
  SUPPORT: 'support',
  OWNER: 'owner',
  MANAGER: 'manager',
  COURIER: 'courier',
  CUSTOMER: 'customer',
};

export const USER_ROLE_SCOPE = {
  ADMIN: 'admin',
  CONSUMER: 'consumer',
  GUEST: 'guest',
  MANAGEMENT: 'management',
  OPERATOR: 'operator',
  OWNER: 'owner',
  UNSUPPORTED: 'unsupported',

  get [USER_ROLE.ADMIN]() {
    return this.ADMIN;
  },
  get [USER_ROLE.SUPPORT]() {
    return this.ADMIN;
  },
  get [USER_ROLE.OWNER]() {
    return this.OWNER;
  },
  get [USER_ROLE.MANAGER]() {
    return this.OWNER;
  },
  get [USER_ROLE.COURIER]() {
    return this.UNSUPPORTED;
  },
  get [USER_ROLE.CUSTOMER]() {
    return this.UNSUPPORTED;
  },
};

export const PAYMENT_METHOD = {
  CARD: 'card',
  CASH: 'cash',
};

export const SHIPPING = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
};

export const DOCUMENT = {
  NAME: {
    ID: 'photoOfId',
    WITH_ID: 'photoWithId',
  },
  STATUS: {
    DECLINE: 'decline',
    APPROVE: 'approve',
  },
};

export const VERIFICATION_STATUS = {
  BLOCKED: 'blocked',
  DECLINED: 'declined',
  DELETED: 'deleted',
  PENDING: 'pending',
  UNVERIFIED: 'unverified',
  VERIFIED: 'verified',
};

export const RESTAURANT_STATUS = {
  DELETED: 'deleted',
  /**
   * There is no dishes in the restaurant
   */
  DISHES: 'dishes',
  /**
   * Limited by subscription limits
   */
  LIMITED: 'limited',
  PUBLISHED: 'published',
  /**
   * Owner doesn't publish a restaurant
   */
  UNPUBLISHED: 'unpublished',
  /**
   * Owner is not verified
   */
  UNVERIFIED: 'unverified',
};

export const ORDER_STATUS = {
  ACCEPTED: 'accepted',
  ASSIGN: 'assigned',
  CANCELED: 'canceled',
  CREATED: 'created',
  DECLINED: 'declined',
  DELIVERED: 'delivered',
  DELIVERING: 'delivering',
  READY: 'ready',
  PICKED: 'pickedUp',
};

const COURIER_STATUS = {
  HIRED: 'hired',
  INVITED: 'invited',
  LIMITED: 'limited',
};

const REVIEW_STATUS = {
  PENDING: 'unreviewed',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
};

export const STATUS = {
  COURIER: COURIER_STATUS,
  ORDER: ORDER_STATUS,
  RESTAURANT: RESTAURANT_STATUS,
  REVIEW: REVIEW_STATUS,
  VERIFICATION: VERIFICATION_STATUS,
};

export const THEME_ICON_NAME = {
  AKICOMO_LOGO: 'AkicomoLogo',
};

export const TOAST_TYPE = {
  SUCCESS: toast.TYPE.SUCCESS,
  WARNING: toast.TYPE.WARNING,
  ERROR: toast.TYPE.ERROR,
  INFO: toast.TYPE.INFO,
};

export const RESTAURANT_SCHEDULE = {
  DAYS: [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ],
  SCHEDULE: [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
    '24:00',
  ],
};

export const PLATFORM = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
};

export const APP_ID = {
  [PLATFORM.IOS]: {
    [USER_ROLE.CUSTOMER]: APPLE_ID.CLIENT,
    [USER_ROLE.COURIER]: APPLE_ID.COURIER,
  },
  [PLATFORM.ANDROID]: {
    [USER_ROLE.CUSTOMER]: ANDROID_ID.CLIENT,
    [USER_ROLE.COURIER]: ANDROID_ID.COURIER,
  },
};

export const SUBSCRIPTION = {
  PLAN: {
    FREE: 'free',
    STANDARD: 'standard',
    PREMIUM: 'premium',
  },
  PERIOD: {
    MONTH: 'month',
    ANNUAL: 'annual',
  },
};

export const BILLING = {
  STATUS: {
    FAILED: 'failed',
    PENDING: 'pending',
    SUCCEEDED: 'succeeded',
  },
};

export const PHOTO_MIMETYPES = 'image/png, image/jpeg, image/gif, image/webp';

export const PUSH_NOTIFICATION_PERMISSIONS = {
  GRANTED: 'granted',
  DENIED: 'denied',
  DEFAULT: 'default',
};

export const MODEL = {
  BUSINESS: 'business',
  PRODUCT: 'product',
  USER: 'user',
};
