import { useParams } from 'react-router-dom';

import { PATH_ACTION } from '@constants';

export const useRouterParams = (...paramNames) => {
  const params = useParams();

  const resolveRestParam = () => {
    const restParam = params['*'];

    return {
      creating: PATH_ACTION.CREATE === restParam,
      editing: PATH_ACTION.EDIT === restParam,
    };
  };

  return paramNames.reduce(
    (acc, param) => ({ ...acc, [param]: params[param] }),
    resolveRestParam()
  );
};
