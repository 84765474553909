import { combineReducers } from 'redux';

import { errorReducer } from './api/error.reducer';
import { fetchedReducer } from './api/fetched.reducer';
import { loadingReducer } from './api/loading.reducer';
import { geolocationReducer } from './cache/geolocation.reducer';
import { restaurantsReducer } from './cache/restaurants.reducer';
import { sharedReducer } from './cache/shared.reducer';
import { uiReducer } from './cache/ui.reducer';
import { configReducer } from './config.reducer';
import { userReducer } from './user.reducer';

export const rootReducer = combineReducers({
  api: combineReducers({
    error: errorReducer,
    fetched: fetchedReducer,
    loading: loadingReducer,
  }),
  config: configReducer,
  user: userReducer,
  cache: combineReducers({
    geolocation: geolocationReducer,
    restaurants: restaurantsReducer,
    shared: sharedReducer,
    ui: uiReducer,
  }),
});
