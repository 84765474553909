import { ACTION_TYPE } from '@constants';

const initialState = {
  pagination: {},
};

export const sharedReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPE.CACHE_ANY:
      return { ...state, ...payload };

    case ACTION_TYPE.CACHE_PHONE:
      return { ...state, phone: payload.phone };

    case ACTION_TYPE.SET_PAGINATION:
      return { ...state, pagination: payload.pagination };

    default:
      return state;
  }
};
