import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  faChevronRight,
  faHeadset,
  faLanguage,
  faRightFromLine,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';

import { PATH, ROLE_HOME_PAGE } from '@constants';
import { useClickableState } from '@hooks';
import { cn } from '@utils';

import { authLogout } from '@actions';
import { configSupportEmailSelector } from '@selectors';

import {
  Button,
  IconBox,
  LanguageSwitcher,
  Link,
  Render,
  Transition,
} from '@components';

const propTypes = {};
const defaultProps = {};

export const HeaderContextMenu = () => {
  const dispatch = useDispatch();
  const supportEmail = useSelector(configSupportEmailSelector);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const [opened, setOpened] = useClickableState(menuRef);

  const mailto = `mailto:${supportEmail}`;

  const handleOpen = () => {
    setOpened((state) => !state);
  };

  const handleLogoutClick = async () => {
    try {
      await dispatch(authLogout());

      navigate(ROLE_HOME_PAGE.GUEST);
    } catch (error) {
      /**
       * Continue regardless error
       */
    }
  };

  const cb = (className) =>
    cn(
      'flex w-full items-center gap-1 py-1.5 text-[16px] leading-normal text-black',
      className
    );

  const renderIcon = (icon, className) => (
    <IconBox icon={icon} className={cx('h-8 w-8 icon-20', className)} />
  );

  return (
    <div ref={menuRef} className="relative">
      <Button
        theme
        className="primary-1-with-hover ml-2 flex content-center transition-colors"
        onClick={handleOpen}
      >
        <IconBox
          icon={faChevronDown}
          className={cx('leading-normal transition-transform icon-16', {
            'rotate-180': opened,
          })}
        />
      </Button>
      <Transition mountOnEnter unmountOnExit in={opened} className="fade">
        <div className="absolute -right-4 top-full z-20 w-[192px] translate-y-2 rounded-lg bg-white p-4 shadow-[0px_0px_4px_rgba(0,0,0,0.16)]">
          <Link theme className={cb()} to={PATH.PROFILE}>
            {renderIcon(faUser)}
            {t('title.profile')}
          </Link>

          <Render if={supportEmail}>
            <Link theme external className={cb()} to={mailto}>
              {renderIcon(faHeadset)}
              {t('web:support')}
              <IconBox
                icon={faArrowUpRightFromSquare}
                className="ml-auto text-primary-1 icon-16"
              />
            </Link>
          </Render>
          <LanguageSwitcher
            containerClassName="h-11"
            className="flex-1 p-0 pl-9 text-[16px]"
          >
            <IconBox
              slot="prefix"
              icon={faLanguage}
              className="absolute h-8 w-8 icon-20"
            />
            <div slot="postfix" className="absolute right-0">
              <IconBox icon={faChevronRight} className="icon-16" />
            </div>
          </LanguageSwitcher>

          <div className="my-2 h-px bg-grey-6" />

          <Button
            theme
            className={cb('text-primary-1')}
            onClick={handleLogoutClick}
          >
            {renderIcon(faRightFromLine)}
            {t('button.logout')}
          </Button>
        </div>
      </Transition>
    </div>
  );
};

HeaderContextMenu.propTypes = propTypes;
HeaderContextMenu.defaultProps = defaultProps;
