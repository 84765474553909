import { ACTION_TYPE } from '@constants';
import { reduce } from '@utils';

export const cacheAny = (payload) => reduce.set(ACTION_TYPE.CACHE_ANY, payload);

export const cachePhone = (phone) =>
  reduce.set(ACTION_TYPE.CACHE_PHONE, { phone });

export const cacheGeolocation = (geolocation) =>
  reduce.set(ACTION_TYPE.CACHE_GEO_LOCATION, { geolocation });

export const cacheRestaurants = (restaurants) =>
  reduce.set(ACTION_TYPE.CACHE_RESTAURANTS, { restaurants });
