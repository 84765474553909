import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { noop } from '@utils';

import { configSelector } from '@selectors';

import { Field } from '@components';
import * as Config from '@components/Config';

const propTypes = {
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onSubmit: noop,
};

export const ConfigSupportEmail = ({ onSubmit }) => {
  const { supportEmail } = useSelector(configSelector);

  const { register, handleSubmit } = useForm({
    defaultValues: { supportEmail },
  });

  const handleFeatureSubmit = handleSubmit(({ supportEmail }) => {
    onSubmit({
      name: 'supportEmail',
      type: 'config',
      value: supportEmail,
    });
  });

  return (
    <Config.Container title="Support Email" onSubmit={handleFeatureSubmit}>
      <Config.Row title="Email">
        <Field.Input
          {...register('supportEmail')}
          type="email"
          inputMode="email"
          containerClassName="pb-0"
        />
      </Config.Row>
    </Config.Container>
  );
};

ConfigSupportEmail.propTypes = propTypes;
ConfigSupportEmail.defaultProps = defaultProps;
