import { createSelector } from 'reselect';

export const configSelector = (state) => state.config;

export const configFeaturesSelector = createSelector(
  configSelector,
  ({ features }) => features
);

export const configVersionsSelector = createSelector(
  configSelector,
  ({ versions }) => versions
);

export const configSupportEmailSelector = createSelector(
  configSelector,
  ({ supportEmail }) => supportEmail
);

export const configSubscriptionPlansSelector = createSelector(
  configSelector,
  ({ subscriptionPlans }) => subscriptionPlans
);

export const configNotificationsSelector = createSelector(
  configSelector,
  ({ notifications }) => notifications
);
