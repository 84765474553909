import React from 'react';
import { faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons';

import { IconBox } from '@components';

const propTypes = {};
const defaultProps = {};

export const MaintenancePage = () => {
  return (
    <div className="flex flex-col content-center bg-primary-1 text-white">
      <IconBox
        icon={faScrewdriverWrench}
        className="h-24 w-24 rounded-full bg-white text-primary-1 icon-48"
      />
      <h2 className="mt-8 text-[36px]">Sorry, we are down for maintenance</h2>
      <h3 className="mt-4 text-[20px] ">We will be back shortly</h3>
    </div>
  );
};

MaintenancePage.propTypes = propTypes;
MaintenancePage.defaultProps = defaultProps;
