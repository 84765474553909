import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Link } from '@components';

const propTypes = {
  asLink: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  asLink: true,
  className: '',
  to: null,
  children: null,
};

export const TableRow = ({ asLink, className, to, children }) => {
  const Component = asLink ? Link : 'tr';

  return (
    <Component
      theme
      className={cx(
        'table-row overflow-hidden bg-white text-inherit',
        className,
        {
          'cursor-pointer': to,
        }
      )}
      to={to}
    >
      {children}
    </Component>
  );
};

TableRow.propTypes = propTypes;
TableRow.defaultProps = defaultProps;
