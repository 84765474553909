import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

import { PAGES_NAME } from '@constants';
import { useSubscriptionPlans, useTranslationWithPrefix } from '@hooks';
import { as, getFormattedPriceWithCurrency, path } from '@utils';

import {
  subscriptionPeriodSelector,
  userSubscriptionSelector,
} from '@selectors';

import { Layout, Link, Render, Theme, WithLoader } from '@components';
import { SubscriptionLimits } from '@components/SubscriptionLimits';
import { SubscriptionPeriodSwitcher } from '@components/TabSwitcher';

const propTypes = {};
const defaultProps = {};

export const SubscriptionPlansPage = () => {
  const period = useSelector(subscriptionPeriodSelector);
  const userSubscriptionPlan = useSelector(userSubscriptionSelector);

  const { plans: subscriptionPlans } = useSubscriptionPlans();
  const { createPrefix } = useTranslationWithPrefix();

  const tt = createPrefix('web:subscription');
  const {
    subscriptionPlanId: userSubscriptionPlanId,
    status: subscriptionStatus,
  } = as.o(userSubscriptionPlan);
  const userSubscriptionIndex = subscriptionPlans.findIndex(
    ({ id }) => id === userSubscriptionPlanId
  );

  const getSubscriptionButtonText = (index) => {
    if (!userSubscriptionPlanId) {
      return tt('button.get');
    }

    if (userSubscriptionIndex === index && subscriptionStatus === 'active') {
      return t('web:current');
    }

    if (userSubscriptionIndex < index) {
      return t('web:button.upgrade');
    }

    if (userSubscriptionIndex > index) {
      return t('web:button.downgrade');
    }

    return tt('button.get');
  };

  const makePlan = ({ id, plan, price: prices, limits }, index) => {
    const subscribeDisabled = id === userSubscriptionPlanId;
    const { currency, [period]: price, discount: discounts } = prices;
    const discount = as.o(discounts)[period];

    return (
      <Theme.Container key={id} className="w-[250px]">
        <div className="flex items-center justify-between">
          <h3 className="text-[18px] capitalize leading-[24px] text-grey-1">
            {tt('plan', plan)}
          </h3>
          <Render if={Boolean(discount)}>
            <span className="flex h-4 items-center rounded-[4px] bg-primary-3 px-2 text-[12px] font-700 text-primary-1">
              -{getFormattedPriceWithCurrency(discount, currency)}
            </span>
          </Render>
        </div>
        <div className="mt-2 flex items-center">
          <span className="text-[24px] font-600 text-grey-1">
            {getFormattedPriceWithCurrency(price, currency)}
          </span>
          <span className="ml-2 text-[14px] font-500 lowercase text-grey-3">
            / {tt('period', period)}
          </span>
        </div>
        <Link
          className="my-6 w-full"
          disabled={subscribeDisabled}
          to={path.subscriptionPlan(plan)}
        >
          {getSubscriptionButtonText(index)}
        </Link>
        <SubscriptionLimits
          className="flex flex-col gap-3 border-t border-grey-7 pt-4"
          limits={limits}
        />
      </Theme.Container>
    );
  };

  return (
    <Layout className="flex flex-1 flex-col" title={PAGES_NAME.SUBSCRIPTION}>
      <Helmet>
        <title>Subscription</title>
      </Helmet>
      <WithLoader in={!subscriptionPlans}>
        <div className="flex flex-col items-center gap-6">
          <h2 className="text-[24px] text-grey-1">Simple plans for everyone</h2>
          <SubscriptionPeriodSwitcher />
          <div className="flex gap-4">{subscriptionPlans.map(makePlan)}</div>
        </div>
      </WithLoader>
    </Layout>
  );
};

SubscriptionPlansPage.propTypes = propTypes;
SubscriptionPlansPage.defaultProps = defaultProps;
