import { isString } from '@utils';

export const getFileImage = (source, placeholder = '') => {
  if (!source) {
    return placeholder;
  }

  if (isString(source)) {
    return source;
  }

  const file = source?.[0];

  if (file) {
    return URL.createObjectURL(file);
  }

  return placeholder;
};
