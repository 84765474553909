import React from 'react';
import PropTypes from 'prop-types';

import { cn } from '@utils';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  className: undefined,
  children: null,
};

export const FieldContainer = ({ className, children }) => {
  return <div className={cn('relative pb-6', className)}>{children}</div>;
};

FieldContainer.propTypes = propTypes;
FieldContainer.defaultProps = defaultProps;
