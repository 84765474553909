import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { PACKAGE_VERSION, SENTRY_DSN } from '@constants';

export const sentry = {
  init: ({ debug = false } = {}) => {
    if (SENTRY_DSN) {
      Sentry.init({
        dsn: SENTRY_DSN,
        release: `akicomo-web@${PACKAGE_VERSION}`,
        integrations: [new BrowserTracing()],

        /**
         * Set tracesSampleRate to 1.0 to capture 100%
         * of transactions for performance monitoring.
         */
        tracesSampleRate: 1.0,

        /**
         * If debug is enabled SDK will attempt to print out useful debugging
         * information if something goes wrong with sending the event.
         */
        debug,
      });
    }
  },

  setUser: Sentry.setUser,
};
