import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { COLOR, MODEL, PATH, SUBSCRIPTION } from '@constants';
import { as, getColor } from '@utils';

import { userAuthorizedSelector, userSelector } from '@selectors';

import { Avatar, IconBox, Layout, Link, Render } from '@components';
import { HeaderContextMenu } from '@components/HeaderContextMenu';

const propTypes = {
  title: PropTypes.string,
};
const defaultProps = {
  title: '',
};

export const LayoutHeader = ({ title }) => {
  const { firstName, avatar, role, subscription } = useSelector(userSelector);
  const authorized = useSelector(userAuthorizedSelector);

  const { t } = useTranslation();

  const { plan } = as.o(subscription);
  const subscriptionPlanColor =
    plan !== SUBSCRIPTION.PLAN.FREE && COLOR.SUBSCRIPTION_PLAN[plan];

  return (
    <Layout.Container className="sticky top-0 z-10 flex min-h-[72px] shrink-0 items-center justify-between border-b border-grey-6 bg-white px-6 py-3">
      <div>
        <Render if={title}>
          <h1 className="text-[20px] font-500 text-grey-1">{t(title)}</h1>
        </Render>
      </div>
      <Render if={!authorized}>
        <Link variant="ghost" to={PATH.LOGIN}>
          {t('button.login')}
        </Link>
      </Render>
      <Render if={authorized}>
        <div className="flex items-center">
          <div className="flex flex-col items-end justify-center">
            <span className="text-[12px] capitalize leading-[16px] text-text-2">
              {t(`role.${role}`)}
            </span>
            <Render if={firstName}>
              <span className="text-[16px] font-500 leading-[16px]">
                {firstName}
              </span>
            </Render>
          </div>
          <div className="relative ml-2 shrink-0">
            <Avatar model={MODEL.USER} size={48} src={avatar} />
            <Render if={subscriptionPlanColor}>
              <IconBox
                className={cx(
                  'absolute bottom-0 right-0 h-5 w-5 rounded-full border border-white text-white icon-10',
                  getColor(subscriptionPlanColor, { as: 'bg' })
                )}
                icon={faStar}
              />
            </Render>
          </div>
          <HeaderContextMenu />
        </div>
      </Render>
    </Layout.Container>
  );
};

LayoutHeader.propTypes = propTypes;
LayoutHeader.defaultProps = defaultProps;
