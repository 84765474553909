import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { CONFIG } from '@constants';
import { createFeatureName, noop } from '@utils';

import { configFeaturesSelector } from '@selectors';

import { Field, SwitchContainer } from '@components';
import * as Config from '@components/Config';

const propTypes = {
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onSubmit: noop,
};

export const ConfigOnboarding = ({ onSubmit }) => {
  const { onboarding } = useSelector(configFeaturesSelector);

  const getFieldName = createFeatureName(CONFIG.FEATURE.ONBOARDING);

  const { register, handleSubmit } = useForm({
    defaultValues: { onboarding },
  });

  const handleFeatureSubmit = handleSubmit(({ onboarding }) => {
    onSubmit({ name: getFieldName(), ...onboarding });
  });

  return (
    <Config.Container title="Onboarding" onSubmit={handleFeatureSubmit}>
      <SwitchContainer title="Enable">
        <Field.Switch {...register(getFieldName('enabled'))} />
      </SwitchContainer>
    </Config.Container>
  );
};

ConfigOnboarding.propTypes = propTypes;
ConfigOnboarding.defaultProps = defaultProps;
