import React from 'react';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { LOCALE } from '@constants';
import { cn, fieldVariants, noop } from '@utils';

import { Field, IconBox } from '@components';

const propTypes = {
  name: PropTypes.string.isRequired,
  requiredLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
};

const defaultProps = {
  requiredLabel: false,
  disabled: false,
  defaultValue: LOCALE.EN,
  className: '',
  containerClassName: '',
  label: '',
  error: '',
  children: null,
  onChange: noop,
};

export const FieldSelect = React.forwardRef(
  (
    {
      requiredLabel,
      disabled,
      defaultValue,
      name,
      className,
      containerClassName,
      label,
      error,
      children,
      ...props
    },
    ref
  ) => (
    <Field.Container className={containerClassName}>
      <Field.Label required={requiredLabel} for={name}>
        {label}
      </Field.Label>
      <div
        style={{ gridTemplateAreas: 'select' }}
        className={cn(
          'h-9 w-full cursor-pointer',
          fieldVariants({ error: !!error, className })
        )}
      >
        <select
          {...props}
          className={cx(
            'peer h-full w-full cursor-pointer appearance-none border-none bg-transparent py-2 pl-3 pr-10 font-500',
            className
          )}
          ref={ref}
          name={name}
          id={name}
          disabled={disabled}
        >
          {children}
        </select>
        <IconBox
          icon={faChevronDown}
          className={cx(
            'pointer-events-none absolute right-3 top-1 text-primary-1 icon-14 peer-disabled:text-primary-3',
            {
              'top-6': !!label,
            }
          )}
        />
        <Field.Error className="bottom-1" error={error} />
      </div>
    </Field.Container>
  )
);

FieldSelect.propTypes = propTypes;
FieldSelect.defaultProps = defaultProps;
