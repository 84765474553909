import React from 'react';
import { cva } from 'class-variance-authority';
import PropTypes from 'prop-types';

import { cn } from '@utils';

const variants = cva(
  'absolute top-0 left-0 w-full h-full before:block before:w-1/4 before:h-1/4 before:rounded-full before:bg-white transition-colors',
  {
    variants: {
      color: {
        primary: 'before:bg-primary-1',
        white: 'before:bg-white',
        grey: 'before:bg-grey-3',
      },
    },
    defaultVariants: {
      color: 'primary',
    },
  }
);

const propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'white', 'grey']),
};

const defaultProps = {
  className: '',
  color: 'primary',
};

export const SpinnerChase = ({ className, color }) => {
  const chaseDotClassName = cn(variants({ color }));

  return (
    <div className={cn(`chase-spinner relative h-6 w-6`, className)}>
      <div className={chaseDotClassName} />
      <div className={chaseDotClassName} />
      <div className={chaseDotClassName} />
      <div className={chaseDotClassName} />
      <div className={chaseDotClassName} />
      <div className={chaseDotClassName} />
    </div>
  );
};

SpinnerChase.propTypes = propTypes;
SpinnerChase.defaultProps = defaultProps;
