import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  className: '',
  children: null,
};

export const ThemeContainer = ({ className, children }) => (
  <div
    className={cx('rounded-xl border border-grey-6 bg-white p-6', className)}
  >
    {children}
  </div>
);

ThemeContainer.propTypes = propTypes;
ThemeContainer.defaultProps = defaultProps;
