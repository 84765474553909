import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Role } from '@types';
import { isObject } from '@utils';

import { Render, TabSwitcher } from '@components';
import * as Config from '@components/Config';

import { NotificationItem } from './NotificationItem';

const propTypes = {
  loading: PropTypes.bool,
  defaultRole: Role,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      role: Role,
      email: PropTypes.bool,
      push: PropTypes.bool,
      sms: PropTypes.bool,
    })
  ),
  onSubmit: PropTypes.func,
};
const defaultProps = {
  loading: false,
  defaultRole: null,
  notifications: [],
  onSubmit: () => {},
};

export const NotificationSettings = ({
  loading,
  defaultRole,
  notifications,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const join = (...args) => args.filter(Boolean).join('-');

  const defaultValues = notifications.reduce(
    (notifications, { name, userId, role = defaultRole, ...notification }) => ({
      ...notifications,
      [join(name, role)]: notification,
    }),
    {}
  );
  const defaultActiveRole = defaultRole ?? notifications[0].role;

  const {
    register,
    watch,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm({
    defaultValues,
  });

  const [activeRole, setActiveRole] = useState(defaultActiveRole);

  const notificationRoles = notifications.reduce(
    (roles, { role = defaultRole }) =>
      role && roles.includes(role) ? roles : [...roles, role],
    []
  );

  const roleTabs = notificationRoles.map((role) => ({
    value: role,
    title: t(`role.${role}`),
  }));

  const filterNotificationByActiveRole = ({ role = defaultRole }) =>
    role === activeRole;

  const handleNotificationsSubmit = handleSubmit((notifications) => {
    if (isObject.empty(dirtyFields)) {
      return;
    }

    const dirtyNotifications = Object.keys(dirtyFields).map((fieldName) => {
      const [name, role] = fieldName.split('-');

      return {
        ...notifications[fieldName],
        name,
        role,
      };
    });

    onSubmit(dirtyNotifications);
  });

  const makeNotification = ({ name, role = defaultRole }) => (
    <NotificationItem
      key={join(name, role)}
      register={register}
      watch={watch}
      name={name}
      role={role}
    />
  );

  return (
    <Config.Container
      title={t('title.notifications')}
      loading={loading}
      onSubmit={handleSubmit(handleNotificationsSubmit)}
    >
      <Render if={notificationRoles.length > 1}>
        <div className="mb-6 flex justify-center">
          <TabSwitcher
            tabs={roleTabs}
            activeTab={activeRole}
            onChange={setActiveRole}
          />
        </div>
      </Render>
      {notifications
        .filter(filterNotificationByActiveRole)
        .map(makeNotification)}
    </Config.Container>
  );
};

NotificationSettings.propTypes = propTypes;
NotificationSettings.defaultProps = defaultProps;

NotificationSettings.Item = NotificationItem;
