import { PATH, PATH_ACTION, ROLE_HOME_PAGE, USER_ROLE_SCOPE } from '@constants';

const EDITABLE = true;

const createPath = (fn, editable = false) => {
  if (editable) {
    // eslint-disable-next-line no-param-reassign
    fn.edit = (...args) => `${fn(...args)}/${PATH_ACTION.EDIT}`;
  }

  return fn;
};

const p = {
  user: (userId = ':userId') => `${PATH.USER}/${userId}`,
  restaurant: (restaurantId = ':restaurantId') =>
    `${PATH.RESTAURANT}/${restaurantId}`,
  menu: (restaurantId = ':restaurantId') =>
    `${PATH.RESTAURANT}/${restaurantId}/menu`,
  product: (productId = ':productId', restaurantId = ':restaurantId') =>
    `${PATH.RESTAURANT}/${restaurantId}/menu/${productId}`,
  courier: (courierId = ':courierId') => `${PATH.COURIER}/${courierId}`,
  order: (orderId = ':orderId') => `${PATH.ORDER}/${orderId}`,
  subscriptionPlan: (subscriptionPlan = ':subscriptionPlan') =>
    `${PATH.SUBSCRIPTION}/${subscriptionPlan}`,
};

export const path = {
  user: createPath(p.user, EDITABLE),
  restaurant: createPath(p.restaurant, EDITABLE),
  menu: createPath(p.menu),
  product: createPath(p.product, EDITABLE),
  courier: createPath(p.courier),
  order: createPath(p.order),
  subscriptionPlan: createPath(p.subscriptionPlan),
  catchAll: (target) => {
    const primitive = typeof target !== 'function';
    const path = primitive ? target : target();

    return `${path}/*`;
  },
  create: (...args) => args.concat(PATH_ACTION.CREATE).join('/'),
};

export const getRoleHomePage = (role) => {
  const roleScope = role ? USER_ROLE_SCOPE[role] : USER_ROLE_SCOPE.GUEST;

  return ROLE_HOME_PAGE[roleScope];
};
