import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { MAPBOX_TOKEN } from '@constants';
import { axios, mapboxgl } from '@services';

import { Field, Map } from '@components';

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  requiredLabel: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  title: PropTypes.string,
  lng: PropTypes.number,
  lat: PropTypes.number,
  onChange: PropTypes.func,
};
const defaultProps = {
  className: undefined,
  requiredLabel: false,
  title: '',
  lng: null,
  lat: null,
  label: '',
  error: '',
  onChange: () => {},
};

export const MapGeocoder = ({
  name,
  className,
  requiredLabel,
  label,
  error,
  title,
  lng,
  lat,
  onChange,
}) => {
  const geocoder = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);

  const center = useMemo(() => {
    if (lng && lat) {
      return [lng, lat];
    }

    return null;
  }, [lng, lat]);

  const removeMarker = useCallback(() => {
    marker.current?.remove();
  }, []);

  const makeMarker = useCallback(
    (center) => {
      removeMarker();

      /**
       * todo: resolve marker color
       */
      marker.current = new mapboxgl.Marker({ color: '#d00561' })
        .setLngLat(center)
        .addTo(map.current);
    },
    [removeMarker]
  );

  useEffect(() => {
    if (!geocoder.current) {
      geocoder.current = new MapboxGeocoder({
        accessToken: MAPBOX_TOKEN,
        placeholder: ' ',
        proximity: 'ip',
        types: 'address',
        limit: 3,
        mapboxgl,
        addressAccuracy: 'address',
      });

      geocoder.current.addTo(`#${name}`);

      geocoder.current.on(
        'result',
        ({ result: { center, place_name: name } }) => {
          const [lng, lat] = center;

          makeMarker(center);
          onChange({ name, lng, lat });
          map.current.flyTo({ center, speed: 8, zoom: 16 });
        }
      );

      // eslint-disable-next-line no-underscore-dangle
      geocoder.current._inputEl.value = title;
    }
  }, [name, title, center, makeMarker, onChange]);

  const handleRefSet = (ref) => {
    map.current = ref;
  };

  const handleMapClick = ({ lng, lat }) => {
    makeMarker([lng, lat]);

    axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json`,
        {
          params: {
            access_token: MAPBOX_TOKEN,
            types: 'address',
            proximity: 'ip',
            limit: 1,
          },
        }
      )
      .then(({ features: [feature] }) => {
        const name = feature.place_name;

        onChange({ name, lng, lat });
        // eslint-disable-next-line no-underscore-dangle
        geocoder.current._inputEl.value = name;
      })
      .catch(() => {});
  };

  return (
    <div className={cx('mb-4', className)}>
      <div className="relative">
        <Field.Label required={requiredLabel} for={name}>
          {label}
        </Field.Label>
        <div id={name} className="relative mb-6" />
        <Field.Error>{error}</Field.Error>
      </div>
      <Map
        initialCenter={center}
        setMapRef={handleRefSet}
        makeMarker={makeMarker}
        onMapClick={handleMapClick}
        removeGeocoderMarker={removeMarker}
      />
    </div>
  );
};

MapGeocoder.propTypes = propTypes;
MapGeocoder.defaultProps = defaultProps;
