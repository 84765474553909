import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ACTION_TYPE, CONFIG, USER_ROLE } from '@constants';
import { pusher, toast } from '@services';
import { useFeature, useTranslationWithPrefix } from '@hooks';
import { as, noop } from '@utils';

import { getRestaurants, setUser, setUserOnboarding } from '@actions';
import {
  createFetchedSelector,
  createLoadingSelector,
  restaurantsSelector,
  userIdSelector,
  userOnboardingSelector,
  userRoleSelector,
} from '@selectors';
import { onboardingContent } from '@content';

import { Button, IconBox, Link, Render, Theme } from '@components';

const loadingSelector = createLoadingSelector(ACTION_TYPE.UPDATE_USER);
const restaurantsFetchedSelector = createFetchedSelector(
  ACTION_TYPE.GET_RESTAURANTS
);

const propTypes = {
  className: PropTypes.string,
};
const defaultProps = {
  className: '',
};

export const OnboardingContainer = ({ className }) => {
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const { active, ...onboarding } = useSelector(userOnboardingSelector);
  const role = useSelector(userRoleSelector);
  const loading = useSelector(loadingSelector);
  const [restaurant] = useSelector(restaurantsSelector);
  const restaurantsFetched = useSelector(restaurantsFetchedSelector);

  const { enabled } = useFeature(CONFIG.FEATURE.ONBOARDING);
  const { pathname } = useLocation();
  const { t, createPrefix } = useTranslationWithPrefix();

  const { id: restaurantId } = as.o(restaurant);
  const owner = role === USER_ROLE.OWNER;
  const tt = createPrefix('web:onboarding');

  useEffect(() => {
    if (!restaurantsFetched) {
      dispatch(getRestaurants()).catch(noop);
    }
  }, [restaurantsFetched, dispatch]);

  useEffect(() => {
    const channelName = `users.${userId}`;

    if (owner) {
      const channel = pusher.subscribe(channelName);

      channel.bind('onboarding', ({ onboarding }) => {
        dispatch(setUser({ onboarding }));
      });
    }

    return () => {
      pusher.unsubscribe(channelName);
    };
  }, [owner, userId, dispatch]);

  const handleSkip = async () => {
    try {
      await dispatch(setUserOnboarding({ userId, active: false }));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getPath = (path) => {
    const isFunction = typeof path === 'function';

    if (isFunction && restaurantId) {
      return path(restaurantId);
    }

    if (isFunction && !restaurantId) {
      return '';
    }

    return path;
  };

  const makeStep = (stepName, index) => {
    const done = onboarding[stepName];
    const icon = done ? faCircleCheck : faCircle;
    const path = getPath(onboardingContent.path[stepName]);
    const activePage = matchPath(path, pathname);

    return (
      <Link
        theme
        key={stepName}
        to={path}
        className={cx('flex flex-1 items-start gap-2', {
          'pointer-events-none': done,
        })}
      >
        <IconBox
          icon={icon}
          className={cx('icon-18', {
            'text-primary-3': !activePage && !done,
            'text-primary-1': activePage || done,
          })}
        />
        <div>
          <p
            className={cx('text-body-2-m', {
              'text-primary-1': activePage,
              'text-grey-1': !activePage,
            })}
          >
            {tt(stepName)}
          </p>
          <p className="mt-1 text-label-1 text-text-2">
            {t('web:step', { value: index + 1 })}
          </p>
        </div>
      </Link>
    );
  };

  return (
    <Render if={active && owner && enabled}>
      <Theme.Container className={className}>
        <Theme.Title title={tt('title')} className="mb-6">
          <Button
            variant="ghost"
            loading={loading}
            className="px-0"
            loaderColor="primary"
            onClick={handleSkip}
          >
            {tt('button.skip')}
          </Button>
        </Theme.Title>
        <div className="flex justify-between gap-2">
          {onboardingContent.steps.map(makeStep)}
        </div>
      </Theme.Container>
    </Render>
  );
};

OnboardingContainer.propTypes = propTypes;
OnboardingContainer.defaultProps = defaultProps;
