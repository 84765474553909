import React from 'react';
import {
  faConciergeBell,
  faStore,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { AvatarSize, Model } from '@types';
import { MODEL } from '@constants';
import { cn, tv } from '@utils';

import { UserAvatar } from './UserAvatar';

const formatModelWithSize = (model, size) => `${model}-${size}`;

const variants = tv({
  base: 'shrink-0 bg-grey-8 border border-grey-6 text-grey-6 overflow-hidden',
  variants: {
    variant: {
      [formatModelWithSize(MODEL.USER, 150)]:
        'w-[150px] h-[150px] icon-40 rounded-full',
      [formatModelWithSize(MODEL.USER, 64)]:
        'w-[64px] h-[64px] icon-20 rounded-full',
      [formatModelWithSize(MODEL.USER, 48)]:
        'w-[48px] h-[48px] icon-15 rounded-full',
      [formatModelWithSize(MODEL.BUSINESS, 272)]:
        'w-[272px] h-[150px] icon-40 rounded-[8px]',
      [formatModelWithSize(MODEL.BUSINESS, 160)]:
        'w-[160px] h-[88px] icon-24 rounded-[6px]',
      [formatModelWithSize(MODEL.PRODUCT, 150)]:
        'w-[150px] h-[150px] icon-40 rounded-[8px]',
      [formatModelWithSize(MODEL.PRODUCT, 96)]:
        'w-[96px] h-[96px] icon-30 rounded-[8px]',
      [formatModelWithSize(MODEL.PRODUCT, 64)]:
        'w-[64px] h-[64px] icon-20 rounded-[4px]',
    },
  },
});

const icon = {
  [MODEL.USER]: faUser,
  [MODEL.PRODUCT]: faConciergeBell,
  [MODEL.BUSINESS]: faStore,
};

const propTypes = {
  size: AvatarSize.isRequired,
  className: PropTypes.string,
  src: PropTypes.string,
  model: Model,
};
const defaultProps = {
  className: undefined,
  src: null,
  model: MODEL.USER,
};

export const Avatar = ({ className, size, src, model }) => {
  const classNames = cn(
    'shrink-0 overflow-hidden border-grey-6 bg-grey-8 text-grey-6',
    variants({ variant: formatModelWithSize(model, size) }),
    className
  );

  if (src) {
    return <img className={cx('object-cover', classNames)} src={src} alt="" />;
  }

  return (
    <div className={cx('flex content-center', classNames)}>
      <FontAwesomeIcon icon={icon[model]} />
    </div>
  );
};

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

Avatar.User = UserAvatar;
