import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { CONFIG } from '@constants';
import { createFeatureName, noop } from '@utils';

import { configVersionsSelector } from '@selectors';

import { Field } from '@components';
import * as Config from '@components/Config';

const propTypes = {
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onSubmit: noop,
};

export const ConfigVersions = ({ onSubmit }) => {
  const versions = useSelector(configVersionsSelector);

  const { register, handleSubmit } = useForm({ defaultValues: { versions } });

  const getFieldName = createFeatureName(CONFIG.SHARED.VERSIONS);

  const handleConfigSubmit = handleSubmit(({ versions }) => {
    onSubmit({
      type: CONFIG.TYPE.OBJECT,
      name: getFieldName(),
      ...versions,
    });
  });

  return (
    <Config.Container title="Versions" onSubmit={handleConfigSubmit}>
      <div className="flex flex-col gap-6">
        <Config.Row title="Backend">
          <Field.Input
            {...register(getFieldName('backend'))}
            containerClassName="pb-0"
          />
        </Config.Row>
        <Config.Row title="Client">
          <Field.Input
            {...register(getFieldName('client'))}
            containerClassName="pb-0"
          />
        </Config.Row>
        <Config.Row title="Courier">
          <Field.Input
            {...register(getFieldName('courier'))}
            containerClassName="pb-0"
          />
        </Config.Row>
        <Config.Row title="Fetch interval (milliseconds)">
          <Field.Input
            {...register(getFieldName('interval'))}
            containerClassName="pb-0"
          />
        </Config.Row>
      </div>
    </Config.Container>
  );
};

ConfigVersions.propTypes = propTypes;
ConfigVersions.defaultProps = defaultProps;
