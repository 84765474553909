import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { RestaurantStatus } from '@types';
import { COLOR } from '@constants';
import { getColor, isBoolean } from '@utils';

import { Render, WithDotSeparator } from '@components';
import { RatingStar } from '@components/ui';

const propTypes = {
  address: PropTypes.string,
  className: PropTypes.string,
  open: PropTypes.bool,
  rating: PropTypes.number,
  reviewCount: PropTypes.number,
  status: RestaurantStatus,
  title: PropTypes.string,
};
const defaultProps = {
  address: undefined,
  className: undefined,
  open: undefined,
  rating: 0,
  reviewCount: 0,
  status: undefined,
  title: undefined,
};

export const RestaurantInfo = ({
  address,
  className,
  open,
  rating,
  reviewCount,
  status,
  title,
}) => {
  const { t } = useTranslation();
  const tOpen = open ? t('open') : t('close');
  const formattedRating = Number(rating).toFixed(2);

  return (
    <div className={cx('flex min-w-0 flex-col', className)}>
      <p className="truncate text-body-1-s text-grey-1" title={title}>
        {title}
      </p>
      <p
        className="mt-1 truncate text-[12px] leading-[16px] text-text-2"
        title={address}
      >
        {address}
      </p>
      <div className="mt-1 flex items-center text-[14px] leading-[16px] text-grey-3">
        <RatingStar rating={rating} />
        <p className="ml-1 font-600 text-grey-1">{formattedRating}</p>
        <p className="ml-2">{t('reviews', { count: reviewCount })}</p>
        <Render if={status}>
          <WithDotSeparator>
            <span
              className={cx('font-500', getColor(COLOR.RESTAURANT[status]))}
            >
              {t(`status.${status}`)}
            </span>
          </WithDotSeparator>
        </Render>
        <Render if={isBoolean(open)}>
          <WithDotSeparator>
            <span className={cx('font-500', getColor.boolean(open))}>
              {t(tOpen)}
            </span>
          </WithDotSeparator>
        </Render>
      </div>
    </div>
  );
};

RestaurantInfo.propTypes = propTypes;
RestaurantInfo.defaultProps = defaultProps;
