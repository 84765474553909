import { as } from '@utils';

export const normalizeCardBrand = (unprocessedBrand) => {
  const brandMap = {
    americanexpress: 'amex',
  };
  const brand = as(unprocessedBrand, '').toLowerCase().replace(/\s/g, '');

  return brandMap[brand] ?? brand;
};
