import { APP_ID, PLATFORM } from '@constants';

export const getAppLink = ({ platform = '', role }) => {
  const appId = APP_ID[platform]?.[role];

  if (!appId) {
    return '';
  }

  switch (platform) {
    case PLATFORM.IOS:
      return `itms-apps://apps.apple.com/id/app/${appId}`;
    case PLATFORM.ANDROID:
      return `https://play.google.com/store/apps/details?id=${appId}`;
    default:
      return '';
  }
};
