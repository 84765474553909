import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ThemeContainer } from './ThemeContainer';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  className: '',
  children: null,
};

export const ThemeCard = ({ className, children }) => (
  <ThemeContainer className={cx('w-[320px] shrink-0 p-6', className)}>
    {children}
  </ThemeContainer>
);

ThemeCard.propTypes = propTypes;
ThemeCard.defaultProps = defaultProps;
