import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import cx from 'classnames';

import { COLOR, MODEL, PAGES_NAME, PAGINATION_ENTRY_KEY } from '@constants';
import { useFetchList } from '@hooks';
import { getColor, getFullName, noop, path } from '@utils';

import { getReferrals, getReferrers as getReferrersAction } from '@actions';

import { Avatar, Badge, Filter, Layout, Table, WithLoader } from '@components';
import { SubscriptionIcon } from '@components/SubscriptionIcon';

const propTypes = {};
const defaultProps = {};

export const ReferralsPage = () => {
  const { t } = useTranslation();

  const { loading, list, getList, onPageChange } = useFetchList({
    getList: getReferrals,
  });

  const [referrers, setReferrers] = useState([]);

  useEffect(() => {
    getReferrersAction().then(setReferrers).catch(noop);
  }, []);

  const tableHead = (
    <tr>
      <Table.Head className="px-0">{t('label.photo')}</Table.Head>
      <Table.Head className="capitalize">{t('role.owner')}</Table.Head>
      <Table.Head>{t('web:referrer')}</Table.Head>
      <Table.Head align="center">{t('label.createdAt')}</Table.Head>
      <Table.Head align="center">{t('title.restaurants')}</Table.Head>
      <Table.Head align="center">{t('label.status')}</Table.Head>
    </tr>
  );

  const getReferrers = () =>
    referrers.reduce(
      (referrers, { id, name }) => ({ ...referrers, [id]: name }),
      {}
    );

  const makeBodyRow = ({
    id,
    avatar,
    role,
    firstName,
    lastName,
    createdAt,
    status,
    subscription,
    restaurantCount,
    referrer: { name: referrerName } = {},
  }) => {
    const fullName = getFullName(firstName, lastName);
    const date = dayjs(createdAt).format('DD.MM.YYYY');

    return (
      <Table.Row key={id} className="bg-white" to={path.user(id)}>
        <Table.Cell className="px-0" align="center" width={72}>
          <Avatar src={avatar} size={64} model={MODEL.USER} />
        </Table.Cell>
        <Table.Cell title={fullName} width={160}>
          <div className="flex flex-col">
            <span
              className={cx(
                'text-[12px] uppercase leading-[16px]',
                getColor.role(role)
              )}
            >
              {t(`role.${role}`)}
            </span>
            <div className="flex items-center">
              <span className="text-[14px] font-500 leading-[20px]">
                {fullName}
              </span>
              <SubscriptionIcon plan={subscription?.plan} />
            </div>
          </div>
        </Table.Cell>
        <Table.Cell title={fullName} width={160}>
          {referrerName}
        </Table.Cell>
        <Table.Cell title={date} width={120} align="center">
          {date}
        </Table.Cell>
        <Table.Cell width={64} align="center">
          {restaurantCount}
        </Table.Cell>
        <Table.Cell align="center" width={200}>
          <Badge color={COLOR.RESTAURANT[status]}>{status}</Badge>
        </Table.Cell>
      </Table.Row>
    );
  };

  const tableBody = list?.map(makeBodyRow);

  return (
    <Layout
      paginationEnabled
      className="flex flex-1 flex-col bg-grey-8"
      title={PAGES_NAME.REFERRALS}
      onPageChange={onPageChange}
      paginationEntryName={PAGINATION_ENTRY_KEY.USERS}
    >
      <Helmet>
        <title>Referrals</title>
      </Helmet>
      <WithLoader in={loading}>
        <Filter
          filters={{
            referrerId: {
              title: t('web:referrer'),
              options: getReferrers(),
            },
          }}
          onFilter={getList}
        />
        <div className="mt-4">
          <Table head={tableHead} body={tableBody} />
        </div>
      </WithLoader>
    </Layout>
  );
};

ReferralsPage.propTypes = propTypes;
ReferralsPage.defaultProps = defaultProps;
