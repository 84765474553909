import { extendTailwindMerge } from 'tailwind-merge';
import { tv as tailwindVariants } from 'tailwind-variants';
import cx from 'classnames';

const twMergeConfig = {
  classGroups: {
    'font-size': [
      'text-headline',
      'text-title-1',
      'text-title-2',
      'text-body-1-r',
      'text-body-1-m',
      'text-body-1-s',
      'text-body-2-r',
      'text-body-2-m',
      'text-body-2-s',
      'text-label-1',
      'text-button-1',
      'text-subtitle',
      'text-footnote',
    ],
  },
};

const twMerge = extendTailwindMerge(twMergeConfig);

/**
 * Resolves tailwind class name conflicts
 */
export const cn = (...classNames) => twMerge(cx(classNames));

export const tv = (variants) =>
  tailwindVariants(variants, { twMerge: false, twMergeConfig });
