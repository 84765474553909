import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { COLOR } from '@constants';

import { Badge, Link, Render } from '@components';
import documentPlaceholder from '@images/document-placeholder.png';

import { DocumentFlow } from './DocumentFlow';

const propTypes = {
  className: PropTypes.string,
  openable: PropTypes.bool,
  requiredLabel: PropTypes.bool,
  label: PropTypes.string,
  source: PropTypes.string,
  status: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  className: '',
  openable: false,
  requiredLabel: false,
  label: null,
  source: null,
  status: null,
  message: '',
  children: null,
};

export const Document = ({
  className,
  openable,
  requiredLabel,
  label,
  source,
  status,
  message,
  children,
}) => {
  const { t } = useTranslation();

  const renderDocumentImage = () => {
    const placeholder = source === documentPlaceholder;

    if (!source) {
      return null;
    }

    if (openable && !placeholder) {
      return (
        <Link
          theme
          external
          blank
          to={source}
          className="flex h-[200px] flex-1 content-center rounded-xl"
        >
          <img src={source} className="max-h-full" alt="" />
        </Link>
      );
    }

    return <img src={source} alt="" />;
  };

  return (
    <div className={cx('flex flex-1 basis-[320px] flex-col', className)}>
      <Render if={label}>
        <p
          className={cx('mb-2 text-[12px] text-text-2', {
            'label-required': requiredLabel,
          })}
        >
          {label}
        </p>
      </Render>
      <div className="relative flex flex-1 basis-[200px] content-center rounded-xl bg-grey-7">
        {renderDocumentImage()}
        <Render if={status}>
          <Badge
            color={COLOR.VERIFICATION[status]}
            className="absolute right-4 top-4 h-6 rounded-md px-2"
          >
            {t(`status.${status}`)}
          </Badge>
        </Render>
      </div>
      <Render if={message}>
        <p className="mt-3 text-[14px] leading-[20px]">
          <span className="font-500">{t('status.declined')}:</span> {message}
        </p>
      </Render>
      {children}
    </div>
  );
};

Document.propTypes = propTypes;
Document.defaultProps = defaultProps;

Document.Flow = DocumentFlow;
