import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { PATH, PROJECT_NAME, USER_ROLE } from '@constants';

import { cachedPhoneSelector } from '@selectors';

import {
  AuthContainer,
  SignupForm,
  VerificationCodeForm,
} from '@components/Auth';

const propTypes = {};
const defaultProps = {};

export const SignupPage = () => {
  const phone = useSelector(cachedPhoneSelector);

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [view, setView] = useState('auth');

  const role = searchParams.get('role') ?? USER_ROLE.OWNER;

  const handleViewChange = (view) => () => {
    setView(view);
  };

  if (view === 'auth') {
    return (
      <AuthContainer
        backButton={false}
        title={t('title.signup', { title: PROJECT_NAME })}
        description={t('misc:fillInAllRequiredFieldsBelow')}
      >
        <Helmet>
          <title>Signup</title>
        </Helmet>
        <SignupForm role={role} onSuccess={handleViewChange('code')} />
      </AuthContainer>
    );
  }

  return (
    <AuthContainer
      title={t('title.phoneVerification')}
      description={t('backend:auth.verificationCode.sent', { phone })}
      onBackClick={handleViewChange('auth')}
    >
      <Helmet>
        <title>Phone verification</title>
      </Helmet>
      <VerificationCodeForm
        page={PATH.SIGNUP}
        role={role}
        submitButtonText={t('button.signup')}
      />
    </AuthContainer>
  );
};

SignupPage.propTypes = propTypes;
SignupPage.defaultProps = defaultProps;
