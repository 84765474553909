import {
  faClipboardListCheck,
  faGear,
  faSliders,
  faStar,
  faStore,
  faUser,
  faUserGroup,
} from '@fortawesome/pro-light-svg-icons';
import { faMoneyCheckDollarPen } from '@fortawesome/pro-regular-svg-icons';

import {
  ALERT_NAME,
  CONFIG,
  NOTIFICATION_TYPE,
  PAGES_NAME,
  PATH,
  SUBSCRIPTION,
  USER_ROLE,
} from '@constants';
import { path } from '@utils';

export const profileTabs = {
  personal: 'web:profile.tab.personal',
  documents: 'web:profile.tab.documents',
  notification: 'web:profile.tab.notification',
};

export const sidebarNavigation = {
  /**
   * `name` should be unique as list item key
   */
  adminCouriers: {
    icon: faUserGroup,
    name: PAGES_NAME.COURIERS,
    to: PATH.COURIERS,
    label: PAGES_NAME.COURIERS,
  },
  adminOrders: {
    icon: faClipboardListCheck,
    name: PAGES_NAME.ORDERS,
    to: PATH.ORDERS,
    label: PAGES_NAME.ORDERS,
  },
  adminRestaurants: {
    icon: faStore,
    name: PAGES_NAME.RESTAURANTS,
    to: PATH.RESTAURANTS,
    label: PAGES_NAME.RESTAURANTS,
  },
  configuration: {
    icon: faGear,
    name: PAGES_NAME.CONFIGURATION,
    to: PATH.CONFIGURATION,
    label: PAGES_NAME.CONFIGURATION,
  },
  couriers: {
    icon: faUserGroup,
    name: PAGES_NAME.COURIERS,
    to: PATH.COURIERS,
    label: PAGES_NAME.COURIERS,
  },
  orders: {
    icon: faClipboardListCheck,
    name: PAGES_NAME.ORDERS,
    to: PATH.ORDERS,
    label: PAGES_NAME.ORDERS,
  },
  panel: {
    icon: faUserGroup,
    name: PAGES_NAME.USERS,
    to: PATH.USERS,
    label: PAGES_NAME.USERS,
  },
  profile: {
    icon: faUser,
    name: PAGES_NAME.PROFILE,
    to: PATH.PROFILE,
    label: PAGES_NAME.PROFILE,
  },
  referrals: {
    icon: faUserGroup,
    name: PAGES_NAME.REFERRALS,
    to: PATH.REFERRALS,
    label: PAGES_NAME.REFERRALS,
  },
  restaurants: {
    icon: faStore,
    name: PAGES_NAME.RESTAURANTS,
    to: PATH.RESTAURANTS,
    label: PAGES_NAME.RESTAURANTS,
  },
  reviews: {
    icon: faStar,
    name: PAGES_NAME.REVIEWS,
    to: PATH.REVIEWS,
    label: PAGES_NAME.REVIEWS,
  },
  settings: {
    icon: faSliders,
    name: PAGES_NAME.SETTINGS,
    to: PATH.SETTINGS,
    label: PAGES_NAME.SETTINGS,
  },
  subscription: {
    icon: faMoneyCheckDollarPen,
    name: PAGES_NAME.SUBSCRIPTION,
    to: PATH.SUBSCRIPTION,
    label: PAGES_NAME.SUBSCRIPTION,
  },

  get [USER_ROLE.ADMIN]() {
    return [
      this.panel,
      this.adminRestaurants,
      this.adminOrders,
      this.adminCouriers,
      this.reviews,
      this.referrals,
      this.settings,
      this.configuration,
    ];
  },
  get [USER_ROLE.SUPPORT]() {
    return [
      this.panel,
      this.adminRestaurants,
      this.adminOrders,
      this.adminCouriers,
      this.reviews,
      this.settings,
    ];
  },
  get [USER_ROLE.OWNER]() {
    return [
      this.restaurants,
      this.orders,
      this.couriers,
      this.settings,
      this.subscription,
    ];
  },
  get [USER_ROLE.MANAGER]() {
    return [this.restaurants, this.orders, this.couriers, this.settings];
  },

  indirectUrls: {
    [PATH.USERS]: [path.user()],
    [PATH.ORDERS]: [path.order()],
    [PATH.RESTAURANTS]: [path.restaurant(), path.menu(), path.product()],
    [PATH.SUBSCRIPTION]: [PATH.SUBSCRIPTION_PLANS, path.subscriptionPlan()],
  },
};

export const configSidebar = [
  {
    key: CONFIG.KEY.SHARED,
    title: 'Shared',
    description: 'Shared and regular stuff',
  },
  {
    key: CONFIG.KEY.FEATURES,
    title: 'Features',
    description: 'Manage features',
  },
  {
    key: CONFIG.KEY.SUBSCRIPTION,
    title: 'Subscription',
    description: 'Manage subscription plans',
  },
  {
    key: CONFIG.KEY.NOTIFICATIONS,
    title: 'Notifications',
  },
];

export const alertConfig = {
  [ALERT_NAME.EMAIL]: {
    name: ALERT_NAME.EMAIL,
    closeable: true,
    type: NOTIFICATION_TYPE.WARNING,
    include: [
      path.subscriptionPlan(SUBSCRIPTION.PLAN.STANDARD),
      path.subscriptionPlan(SUBSCRIPTION.PLAN.PREMIUM),
    ],
  },
  [ALERT_NAME.DOCUMENTS]: {
    name: ALERT_NAME.DOCUMENTS,
    type: NOTIFICATION_TYPE.WARNING,
    include: [PATH.RESTAURANTS],
  },
};

export const stripeElementOptions = {
  style: {
    base: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '36px',
      color: '#7c7c7c',
      '::placeholder': {
        color: '#c8c8c8',
      },
    },
  },
};

export const onboardingContent = {
  path: {
    conclusion: PATH.ONBOARDING_CONCLUSION,
    createDish: (restaurantId) => path.product(restaurantId, 'new'),
    createRestaurant: path.restaurant('new'),
    inviteCourier: PATH.COURIERS,
    verifyDocuments: PATH.PROFILE,
  },
  steps: [
    'verifyDocuments',
    'createRestaurant',
    'createDish',
    'inviteCourier',
    'conclusion',
  ],
};
