import React from 'react';
import { cva } from 'class-variance-authority';
import PropTypes from 'prop-types';

import { BadgeColor } from '@types';
import { COLOR } from '@constants';
import { cn, getColor } from '@utils';

const makeColorVariant = (color) =>
  `${getColor(color, { as: 'text' })} ${getColor(color, { as: 'border' })}`;

const colors = Object.keys(COLOR.MAP).reduce(
  (colors, color) => ({ ...colors, [color]: makeColorVariant(color) }),
  {}
);

const variants = cva(
  'content-center inline-flex px-4 uppercase font-600 rounded-md text-[12px] h-9 border',
  {
    variants: {
      color: colors,
    },
  }
);

const propTypes = {
  className: PropTypes.string,
  color: BadgeColor,
  children: PropTypes.node,
};

const defaultProps = {
  className: '',
  color: 'grey',
  children: null,
};

export const Badge = ({ className, color, children }) => (
  <div className={cn(variants({ color, className }))}>{children}</div>
);

Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;
