import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ACTION_TYPE } from '@constants';

import { createLoadingSelector, userPaymentMethodsSelector } from '@selectors';

import { Button, CardBrand, Modal, Modals, Render, Theme } from '@components';
import { CardContextMenu } from '@components/CardContextMenu';

const loadingSelector = createLoadingSelector(
  ACTION_TYPE.SET_DEFAULT_PAYMENT_METHOD,
  ACTION_TYPE.REMOVE_PAYMENT_METHOD
);

const propTypes = {
  className: PropTypes.string,
};
const defaultProps = {
  className: '',
};

export const CardAddCard = ({ className }) => {
  const loading = useSelector(loadingSelector);
  const paymentMethods = useSelector(userPaymentMethodsSelector);

  const { t } = useTranslation();

  const makePaymentMethod = ({ id, brand, lastFour, default: isDefault }) => (
    <li
      key={id}
      className="flex items-center gap-2 border-t border-grey-7 pt-4 last:border-none"
    >
      <CardBrand className="shrink-0" brand={brand} shape="circle" />
      <div className="flex-1">
        <p className="text-label-1 text-grey-3">{brand}</p>
        <p className="text-body-1-s text-grey-1">**** {lastFour}</p>
        <Render if={isDefault}>
          <p className="text-[10px] leading-[12px] text-primary-1">
            {t('web:billing.default')}
          </p>
        </Render>
      </div>
      <CardContextMenu paymentMethodId={id} />
    </li>
  );

  return (
    <Theme.Card className={className}>
      <Theme.Title
        divider={false}
        className="mb-2"
        title={t('web:paymentCards')}
      />
      <Render if={paymentMethods}>
        <ul className="mb-6 flex flex-col gap-4">
          {paymentMethods.map(makePaymentMethod)}
        </ul>
      </Render>
      <div className="mb-6 mt-4 h-px bg-grey-7" />
      <Modal className="max-w-[550px]" content={<Modals.AddCard />}>
        <Button variant="outline" loading={loading} className="w-full">
          {t('web:generic.add', { title: t('paymentMethod.card') })}
        </Button>
      </Modal>
    </Theme.Card>
  );
};

CardAddCard.propTypes = propTypes;
CardAddCard.defaultProps = defaultProps;
