import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CONFIG } from '@constants';
import { cn, getSlots, localeLocalStorage, noop } from '@utils';

import { updateUserLocale } from '@actions';
import { userAuthorizedSelector, userIdSelector } from '@selectors';

import { Flag, Slot } from '@components';
import { ConfigSelectOptions } from '@components/ConfigSelectOptions';

const propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

const defaultProps = {
  className: undefined,
  containerClassName: undefined,
  onChange: noop,
  children: null,
};

export const LanguageSwitcher = ({
  className,
  containerClassName,
  onChange,
  children,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const authorized = useSelector(userAuthorizedSelector);
  const slot = getSlots(children);

  const { i18n } = useTranslation('locale');
  const locale = i18n.language;

  useEffect(() => {
    const initLocale = localeLocalStorage.locale;

    if (initLocale && authorized) {
      i18n.changeLanguage(initLocale);
      dayjs.locale(initLocale);
    }
  }, [i18n, authorized]);

  const handleChange = (event) => {
    const { value } = event.target;
    i18n.changeLanguage(value);
    onChange(value);
    localeLocalStorage.locale = value;
    dayjs.locale(value);

    if (userId) {
      dispatch(updateUserLocale(value, userId)).catch(noop);
    }
  };

  return (
    <div
      className={cx(
        'relative flex items-center bg-white text-label-1',
        containerClassName
      )}
    >
      <Slot slot={slot.prefix}>
        <div className="pointer-events-none absolute inset-y-0 left-0 m-auto flex h-4 w-4 items-center justify-center">
          <Flag flag={locale} />
        </div>
      </Slot>
      <select
        value={locale}
        className={cn(
          'h-full flex-1 cursor-pointer appearance-none p-0 pl-5 text-label-1',
          className
        )}
        onChange={handleChange}
      >
        <ConfigSelectOptions
          translationKey="locale"
          translationKeySeparator=":"
          configName={CONFIG.SHARED.LOCALE}
        />
      </select>
      <Slot slot={slot.postfix} />
    </div>
  );
};

LanguageSwitcher.propTypes = propTypes;
LanguageSwitcher.defaultProps = defaultProps;
