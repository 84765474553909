import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ACTION_TYPE, COLOR, MODEL, PHOTO_MIMETYPES } from '@constants';
import { toast } from '@services';
import {
  createFormData,
  getFileImage,
  getFormErrors,
  getNormalizedDocuments,
  path,
  pick,
  setBackendErrors,
  validationSchema,
} from '@utils';

import { setUser, updateUser } from '@actions';
import {
  createLoadingSelector,
  userOnboardingSelector,
  userSelector,
} from '@selectors';

import { Avatar, Badge, Button, Field, Layout, Theme } from '@components';
import { Document } from '@components/Document';
import { OnboardingContainer } from '@components/OnboardingContainer';

const userUpdatingSelector = createLoadingSelector(ACTION_TYPE.UPDATE_USER);

const propTypes = {};
const defaultProps = {};

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const loading = useSelector(userUpdatingSelector);
  const { active: onboarding } = useSelector(userOnboardingSelector);

  const defaultValues = pick(
    user,
    'location',
    'avatar',
    'city',
    'email',
    'firstName',
    'lastName',
    'phone'
  ).reduce((acc, fieldName) => {
    const value = user[fieldName];

    if (value) {
      return { ...acc, [fieldName]: value };
    }

    return acc;
  }, {});

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, watch, handleSubmit, formState, getValues, setError } =
    useForm({
      defaultValues,
    });
  const errors = getFormErrors(formState.errors);

  const { id: userId, documents, status } = user;
  const watchPhotoOfId = watch('photoOfId');
  const watchPhotoWithId = watch('photoWithId');
  const watchAvatar = watch('avatar');

  const avatar = getFileImage(watchAvatar);

  const getDocuments = () => {
    const { photoOfId = {}, photoWithId = {} } =
      getNormalizedDocuments(documents);

    return {
      photoOfId: {
        ...photoOfId,
        source: getFileImage(watchPhotoOfId) || photoOfId.source,
      },
      photoWithId: {
        ...photoWithId,
        source: getFileImage(watchPhotoWithId) || photoWithId.source,
      },
    };
  };

  const { photoOfId, photoWithId } = getDocuments();

  const handleProfileSubmit = handleSubmit(async (user) => {
    const formData = createFormData({
      data: user,
      put: true,
      images: ['avatar', 'photoOfId', 'photoWithId'],
    });

    try {
      const user = await dispatch(updateUser(userId, formData));
      const { verifyDocuments, active } = user.onboarding;
      const preventRedirection = !verifyDocuments && active;
      dispatch(setUser(user));

      toast.success('shared:toast.profileUpdated');

      if (preventRedirection) return;

      if (onboarding) {
        navigate(path.restaurant('new'));
      }
    } catch (error) {
      setBackendErrors(error, { getValues, setError });
    }
  });

  return (
    <Layout className="flex flex-1 flex-col" title={t('title.profile')}>
      <form className="flex flex-1 flex-col" onSubmit={handleProfileSubmit}>
        <div className="flex justify-end">
          <Button type="submit" color="secondary" loading={loading}>
            {t('button.save')}
          </Button>
        </div>
        <OnboardingContainer className="mt-6" />
        <div className="mt-6 flex flex-1 items-start gap-6">
          <div className="flex flex-1 flex-col gap-6">
            <Theme.Container className=" flex flex-1 flex-col gap-6">
              <Theme.Title required title={t('web:personalInformation')} />
              <div className="grid grid-cols-2 gap-x-6">
                <Field.Input
                  {...register('firstName', validationSchema.firstName())}
                  requiredLabel
                  label={t('label.firstName')}
                  error={errors.firstName}
                />
                <Field.Input
                  {...register('lastName')}
                  label={t('label.lastName')}
                />
                <Field.Input
                  {...register('phone')}
                  disabled
                  label={t('label.phone')}
                  prefix="+"
                />
                <Field.Input
                  {...register('email', validationSchema.email())}
                  type="email"
                  inputMode="email"
                  label={t('label.email')}
                  error={errors.email}
                />
                <Field.Input {...register('city')} label={t('label.city')} />
              </div>
            </Theme.Container>

            <Theme.Container>
              <Theme.Title
                required
                className="mb-4"
                title={t('web:documents')}
              />
              <div className="grid grid-cols-2 gap-6">
                <Document
                  {...photoOfId}
                  requiredLabel
                  label={t('label.photoOfId')}
                >
                  <Field.File
                    {...register('photoOfId')}
                    accept={PHOTO_MIMETYPES}
                    className="mt-2 text-center"
                  >
                    <Button variant="ghost" className="pointer-events-none">
                      {t('web:button.uploadDocument')}
                    </Button>
                  </Field.File>
                </Document>
                <Document
                  {...photoWithId}
                  requiredLabel
                  label={t('label.photoWithId')}
                >
                  <Field.File
                    {...register('photoWithId')}
                    accept={PHOTO_MIMETYPES}
                    className="mt-2 text-center"
                  >
                    <Button variant="ghost" className="pointer-events-none">
                      {t('web:button.uploadDocument')}
                    </Button>
                  </Field.File>
                </Document>
              </div>
            </Theme.Container>
          </div>

          <div className="flex flex-col gap-6">
            <Theme.Card className="flex flex-col">
              <Theme.Title className="mb-4" title={t('label.avatar')} />
              <Field.File
                {...register('avatar')}
                accept={PHOTO_MIMETYPES}
                className="flex flex-col items-center gap-4"
              >
                <Avatar src={avatar} model={MODEL.USER} size={150} />
                <Field.Error variant="static" error={errors.avatar} />
                <Button variant="ghost" className="pointer-events-none">
                  {t('web:button.uploadPhoto')}
                </Button>
              </Field.File>
            </Theme.Card>
            <Badge large className="w-full" color={COLOR.VERIFICATION[status]}>
              {t(`status.${status}`)}
            </Badge>
          </div>
        </div>
      </form>
    </Layout>
  );
};

ProfilePage.propTypes = propTypes;
ProfilePage.defaultProps = defaultProps;
