import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button } from '@components';

const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
const defaultProps = {
  className: undefined,
  onClick: null,
};

export const BackButton = ({ className, onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <Button onClick={handleClick} variant="ghost" className="px-0">
      {t('button.back')}
    </Button>
  );
};

BackButton.propTypes = propTypes;
BackButton.defaultProps = defaultProps;
