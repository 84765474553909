import React from 'react';
import PropTypes from 'prop-types';

import { InputType } from '@types';
import { cn, fieldVariants } from '@utils';

import { Field } from '@components';

const propTypes = {
  name: PropTypes.string.isRequired,
  requiredLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  resizable: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  label: PropTypes.string,
  before: PropTypes.string,
  after: PropTypes.string,
  error: PropTypes.string,
  type: InputType,
};

const defaultProps = {
  requiredLabel: false,
  disabled: false,
  resizable: false,
  className: undefined,
  containerClassName: undefined,
  label: '',
  before: '',
  after: '',
  error: '',
  type: 'text',
};

export const FieldTextarea = React.forwardRef(
  (
    {
      name,
      requiredLabel,
      disabled,
      resizable,
      className,
      containerClassName,
      label,
      before,
      after,
      error,
      ...props
    },
    ref
  ) => (
    <Field.Container className={containerClassName}>
      <Field.Label required={requiredLabel} for={name}>
        {label}
      </Field.Label>
      <textarea
        {...props}
        ref={ref}
        id={name}
        className={cn(
          'min-h-[64px] resize-none px-2 py-3 text-[14px]',
          fieldVariants({ error: !!error, className }),
          { 'resize-y': resizable }
        )}
        name={name}
        disabled={disabled}
      />
      <Field.Error error={error} />
    </Field.Container>
  )
);

FieldTextarea.displayName = 'Field.Textarea';
FieldTextarea.propTypes = propTypes;
FieldTextarea.defaultProps = defaultProps;
