import React from 'react';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getSlots } from '@utils';

import { IconBox, Slot } from '@components';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  className: '',
  children: null,
};

export const ThemeInfo = ({ className, children }) => {
  const { content } = getSlots(children);

  return (
    <div className={cx('flex items-start gap-2', className)}>
      <IconBox className="text-other-9 icon-14" icon={faCircleInfo} />
      <div className="mt-1">
        <Slot slot={content}>
          <p className="text-[12px] font-500 leading-[16px]">{children}</p>
        </Slot>
      </div>
    </div>
  );
};

ThemeInfo.propTypes = propTypes;
ThemeInfo.defaultProps = defaultProps;
