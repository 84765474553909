import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Typography as TypographyType } from '@types';

const propTypes = {
  className: PropTypes.string,
  variant: TypographyType,
  tag: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  className: undefined,
  variant: undefined,
  tag: 'p',
  children: undefined,
};

export const Typography = ({ tag: Tag, className, variant, children }) => {
  return <Tag className={cx(variant, className)}>{children}</Tag>;
};

Typography.propTypes = propTypes;
Typography.defaultProps = defaultProps;
