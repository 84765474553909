import { useSelector } from 'react-redux';

import { USER_ROLE, USER_ROLE_SCOPE } from '@constants';

import { userRoleSelector } from '@selectors';

export const useRole = () => {
  const role = useSelector(userRoleSelector);

  const management = [USER_ROLE.ADMIN, USER_ROLE.SUPPORT].includes(role);
  const operator = [
    USER_ROLE.ADMIN,
    USER_ROLE.SUPPORT,
    USER_ROLE.OWNER,
    USER_ROLE.MANAGER,
  ].includes(role);
  const consumer = !operator;
  const roleScope = USER_ROLE_SCOPE[role];

  return {
    [USER_ROLE.ADMIN]: role === USER_ROLE.ADMIN,
    [USER_ROLE.COURIER]: role === USER_ROLE.COURIER,
    [USER_ROLE.CUSTOMER]: role === USER_ROLE.CUSTOMER,
    [USER_ROLE.MANAGER]: role === USER_ROLE.MANAGER,
    [USER_ROLE.OWNER]: role === USER_ROLE.OWNER,
    [USER_ROLE.SUPPORT]: role === USER_ROLE.SUPPORT,
    [USER_ROLE_SCOPE.CONSUMER]: consumer,
    [USER_ROLE_SCOPE.GUEST]: !!role,
    [USER_ROLE_SCOPE.MANAGEMENT]: management,
    [USER_ROLE_SCOPE.OPERATOR]: operator,
    [USER_ROLE_SCOPE.UNSUPPORTED]: USER_ROLE_SCOPE[role],
    role,
    roleScope,
  };
};
