import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from '@redux/store';
import * as Sentry from '@sentry/react';

import { firebase, mapbox, sentry } from '@services';

import { Application } from '@components/Application';

import '@services/i18next';
import '@services/dayjs';

import 'mapbox-gl/dist/mapbox-gl.css';
import './styles/index.scss';
import './styles/index.css';

mapbox.init();
sentry.init();
firebase.init();

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occured</p>}>
      <Provider store={store}>
        <BrowserRouter>
          <Application />
        </BrowserRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
