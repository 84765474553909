import { ACTION_TYPE } from '@constants';

const initialState = {};

export const geolocationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPE.CACHE_GEO_LOCATION:
      return payload.geolocation;

    default:
      return state;
  }
};
