import { ACTION_TYPE, CONFIG } from '@constants';
import { axios } from '@services';
import { reduce } from '@utils';

export const setConfig = ({ type, ...payload }) => {
  if (type === CONFIG.TYPE.SHARED) {
    return reduce.set(ACTION_TYPE.SET_CONFIG_SHARED, {
      shared: payload,
    });
  }

  if (type === CONFIG.TYPE.FEATURE) {
    return reduce.set(ACTION_TYPE.SET_CONFIG_FEATURE, {
      feature: payload,
    });
  }

  return reduce.set(ACTION_TYPE.SET_CONFIG, payload);
};

export const toggleSidebar = (collapsed = null) => ({
  type: ACTION_TYPE.SET_SIDEBAR_COLLAPSE,
  payload: { collapsed },
});

export const changeConfigSidebarTab = (tab) => ({
  type: ACTION_TYPE.SET_CONFIG_SIDEBAR_TAB,
  payload: { tab },
});

export const setSubscriptionPeriod = (subscriptionPeriod) => ({
  type: ACTION_TYPE.SET_SUBSCRIPTION_PERIOD,
  payload: { subscriptionPeriod },
});

export const updateConfig = (config) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.UPDATE_CONFIG);
  const { type } = config;

  dispatch(request);

  return axios
    .put('/configs', config)
    .then(({ config, message }) => {
      const { name, value } = config;

      dispatch(setConfig({ type, [name]: value }));
      dispatch(success);

      return Promise.resolve({ config, message });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error?.errors ?? error);
    });
};

export const updateConfigNotifications = (notifications) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.UPDATE_CONFIG);

  dispatch(request);

  return axios
    .put('/configs/notifications', { notifications })
    .then(({ notifications, message }) => {
      dispatch(setConfig({ notifications }));
      dispatch(success);

      return Promise.resolve({ message });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error?.errors ?? error);
    });
};

export const setPagination = (pagination) =>
  reduce.set(ACTION_TYPE.SET_PAGINATION, { pagination });
