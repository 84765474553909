import { useTranslation } from 'react-i18next';

import { isObject } from '@utils';

export const useTranslationWithPrefix = () => {
  const { t, i18n } = useTranslation();

  const getTranslation = ({ prefix, options }, ...keys) =>
    t(`${prefix}.${keys.join('.')}`, options);

  const createPrefix =
    (prefix) =>
    (...args) => {
      const lastArgument = args.slice(-1)[0];
      const options = isObject(lastArgument) ? lastArgument : undefined;
      const keys = options ? args.slice(0, -1) : args;

      return getTranslation({ prefix, options }, ...keys);
    };

  return { t, i18n, createPrefix, getTranslation };
};
