import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { OrderStatus } from '@types';
import { ORDER_STATUS } from '@constants';
import { cn } from '@utils';

const GREEN_STATUSES = [
  ORDER_STATUS.ACCEPTED,
  ORDER_STATUS.DELIVERING,
  ORDER_STATUS.DELIVERED,
];
const RED_STATUSES = [ORDER_STATUS.DECLINED, ORDER_STATUS.CANCELED];

const propTypes = {
  currentStatus: OrderStatus,
  statuses: PropTypes.shape({
    [ORDER_STATUS.ACCEPTED]: PropTypes.string,
    [ORDER_STATUS.CANCELED]: PropTypes.string,
    [ORDER_STATUS.DECLINED]: PropTypes.string,
    [ORDER_STATUS.DELIVERED]: PropTypes.string,
    [ORDER_STATUS.DELIVERING]: PropTypes.string,
  }),
};
const defaultProps = {
  currentStatus: null,
  statuses: {},
};

export const OrderTracker = ({ currentStatus, statuses }) => {
  const { t } = useTranslation();

  const currentStatusIndex = GREEN_STATUSES.findIndex(
    (status) => status === currentStatus
  );

  const makeStatus = (status, index) => {
    const passed = !!statuses[status];
    const pending = !!index && index > currentStatusIndex;

    return (
      <div
        key={index}
        className={cn('h-1 flex-1 rounded-full bg-grey-6', {
          'bg-other-8': passed && !pending,
          'bg-other-3': RED_STATUSES.includes(status),
        })}
      />
    );
  };

  const makeStatusesWithClosed = (status) => {
    /**
     * `created` status is set by default
     */
    const MINIMAL_LENGTH = 3;
    const madeStatuses = GREEN_STATUSES.map(
      (status) => statuses[status] && makeStatus(status)
    )
      .filter(Boolean)
      .concat(makeStatus(status));

    return madeStatuses.concat(
      new Array(MINIMAL_LENGTH - madeStatuses.length).fill(makeStatus())
    );
  };

  const renderStatuses = () => {
    if (statuses[ORDER_STATUS.DECLINED]) {
      return makeStatusesWithClosed(ORDER_STATUS.DECLINED);
    }

    if (statuses[ORDER_STATUS.CANCELED]) {
      return makeStatusesWithClosed(ORDER_STATUS.CANCELED);
    }

    return GREEN_STATUSES.map(makeStatus);
  };

  return (
    <div>
      <p className="text-body-2-m">{t(`status.${currentStatus}`)}</p>
      <p className="text-footnote italic text-grey-3">
        {dayjs(statuses[currentStatus]).format('MMM DD, hh:mm a')}
      </p>
      <div className="mt-4 flex gap-1">
        {makeStatus(ORDER_STATUS.CREATED)}
        {renderStatuses()}
      </div>
    </div>
  );
};

OrderTracker.propTypes = propTypes;
OrderTracker.defaultProps = defaultProps;
