import { isNullable, isObject, isString } from '@utils';

export const createFormData = ({
  data,
  put = false,
  images = [],
  omit = [],
}) => {
  const formData = new FormData();

  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    const object = isObject(data[key]);
    const image = images.includes(key);

    const value = data[key];

    if (omit.includes(key)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (isNullable(value)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    /**
     * Skip an image that is not a file
     */
    if (image && isString(value)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (image && value[0]) {
      formData.append(key, value[0]);

      // eslint-disable-next-line no-continue
      continue;
    }

    /**
     * Skip another image states
     */
    if (image) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (object) {
      formData.append(key, JSON.stringify(value));

      // eslint-disable-next-line no-continue
      continue;
    }

    formData.append(key, value);
  }

  if (put) {
    formData.append('_method', 'PUT');
  }

  return formData;
};
