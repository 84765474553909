import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ACTION_TYPE, CONFIG } from '@constants';
import { toast } from '@services';
import { as, capitalize } from '@utils';

import { updateSubscriptionPlan } from '@actions';
import { createLoadingSelector } from '@selectors';

import { Field, SwitchContainer } from '@components';
import * as Config from '@components/Config';
import { ConfigSelectOptions } from '@components/ConfigSelectOptions';

const loadingSelector = createLoadingSelector(
  ACTION_TYPE.UPDATE_SUBSCRIPTION_PLAN
);

const propTypes = {
  id: PropTypes.number.isRequired,
  plan: PropTypes.string.isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    month: PropTypes.number.isRequired,
    annual: PropTypes.number.isRequired,
    discount: PropTypes.shape({
      month: PropTypes.number,
      annual: PropTypes.number,
    }),
  }).isRequired,
  limits: PropTypes.shape({
    restaurants: PropTypes.number.isRequired,
    dishes: PropTypes.number.isRequired,
    couriers: PropTypes.number.isRequired,
    managers: PropTypes.number.isRequired,
  }).isRequired,
};
const defaultProps = {};

export const ConfigSubscription = ({ id, ...subscription }) => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);

  const { plan } = subscription;
  const activeAlias = `${plan}Active`;

  const normalizeSubscription = (
    subscription,
    activeResolver = ['active', activeAlias]
  ) => {
    const [from, to = 'active'] = activeResolver;
    const { [from]: active, ...rest } = as.o(subscription);

    return { ...rest, [to]: active };
  };

  const { register, handleSubmit } = useForm({
    defaultValues: normalizeSubscription(subscription),
  });

  const handleSubscriptionSubmit = handleSubmit(async (subscription) => {
    try {
      const { message } = await dispatch(
        updateSubscriptionPlan(
          id,
          normalizeSubscription(subscription, [activeAlias])
        )
      );

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  });

  return (
    <Config.Container
      loading={loading}
      title={capitalize(plan)}
      onSubmit={handleSubscriptionSubmit}
    >
      <h2 className="mb-2 text-body-2-s">Main</h2>
      <div className="grid grid-cols-2 gap-2">
        <Field.Input {...register('plan')} requiredLabel label="Tariff Name" />
        <Field.Select
          {...register('price.currency')}
          requiredLabel
          label="Currency"
        >
          <ConfigSelectOptions configName={CONFIG.SHARED.CURRENCY} />
        </Field.Select>
      </div>
      <h2 className="mb-2 text-body-2-s">Price</h2>
      <div className="grid grid-cols-4 gap-2">
        <Field.Input
          {...register('price.month')}
          label="Month"
          requiredLabel
          type="number"
          inputMode="numeric"
        />
        <Field.Input
          {...register('price.discount.month')}
          label="Discount month"
          type="number"
          inputMode="numeric"
        />
        <Field.Input
          {...register('price.annual')}
          label="Annual"
          requiredLabel
          type="number"
          inputMode="numeric"
        />
        <Field.Input
          {...register('price.discount.annual')}
          label="Discount annual"
          type="number"
          inputMode="numeric"
        />
      </div>
      <h2 className="mb-2 text-body-2-s">Limits</h2>
      <div className="grid grid-cols-2 gap-2">
        <Field.Input
          {...register('limits.restaurants')}
          label="Restaurants"
          requiredLabel
          type="number"
          inputMode="numeric"
        />
        <Field.Input
          {...register('limits.dishes')}
          label="Dishes"
          requiredLabel
          type="number"
          inputMode="numeric"
        />
        <Field.Input
          {...register('limits.couriers')}
          label="Couriers"
          requiredLabel
          type="number"
          inputMode="numeric"
        />
        <Field.Input
          {...register('limits.managers')}
          label="Managers"
          requiredLabel
          type="number"
          inputMode="numeric"
        />
      </div>
      <SwitchContainer title="Visible">
        <Field.Switch {...register(activeAlias)} />
      </SwitchContainer>
    </Config.Container>
  );
};

ConfigSubscription.propTypes = propTypes;
ConfigSubscription.defaultProps = defaultProps;
