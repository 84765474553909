import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PLATFORM } from '@constants';
import { getAppLink, getRoleHomePage } from '@utils';

import { authLogout } from '@actions';
import { userRoleSelector } from '@selectors';

import { Button } from '@components';
import { AppImage } from '@components/AppImage';
import { AuthContainer } from '@components/Auth';

const propTypes = {};
const defaultProps = {};

export const UnsupportedRolePage = () => {
  const dispatch = useDispatch();
  const role = useSelector(userRoleSelector);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogoutClick = async () => {
    try {
      await dispatch(authLogout());

      navigate(getRoleHomePage());
    } catch (error) {
      /**
       * Continue regardless error
       */
    }
  };

  const renderApp = (platform) => {
    const appLink = getAppLink({ platform, role });

    if (appLink) {
      return (
        <div className="flex flex-col items-center gap-6">
          <QRCode value={appLink} size={148} />
          <AppImage width="180" platform={platform} role={role} />
        </div>
      );
    }

    return null;
  };

  return (
    <AuthContainer
      className="text-center"
      containerClassName="max-w-[360px]"
      backButton={false}
      title={t('web:unsupportedRole.title')}
      description={t('web:unsupportedRole.message', { role })}
    >
      <Helmet>
        <title>Unsupported Role</title>
      </Helmet>
      <div className="flex flex-col justify-center gap-6">
        <p className="mt-6 text-center text-body-1-r text-grey-1">
          {t('web:unsupportedRole.install')}
        </p>
        <div className="flex gap-6">
          {renderApp(PLATFORM.IOS)}
          {renderApp(PLATFORM.ANDROID)}
        </div>
        <Button variant="ghost" onClick={handleLogoutClick}>
          {t('button.logout')}
        </Button>
      </div>
    </AuthContainer>
  );
};

UnsupportedRolePage.propTypes = propTypes;
UnsupportedRolePage.defaultProps = defaultProps;
