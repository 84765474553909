import { tv } from './tailwind';

/**
 * todo: fix disabled state for link
 */
export const buttonVariants = tv({
  base: 'inline-flex items-center justify-center rounded-md py-1 px-3 cursor-pointer transition-all relative px-6',
  variants: {
    variant: {
      primary:
        'text-white bg-primary-1 hover:bg-primary-5 disabled:bg-primary-3',
      secondary:
        'text-white bg-other-8 hover:bg-other-10 disabled:bg-secondary-1',
      ghost: 'text-primary-1 hover:text-primary-5 disabled:text-primary-3',
      'ghost-grey':
        'bg-transparent text-grey-3 hover:text-grey-2 disabled:text-grey-5',
      outline:
        'bg-transparent text-primary-1 border border-primary-1 hover:border-primary-5 hover:text-primary-5 disabled:border-primary-3 disabled:text-primary-3',
      'outline-grey':
        'bg-transparent text-grey-3 border border-grey-3 hover:text-white hover:bg-grey-3 disabled:border-grey-5 disabled:text-grey-5',
    },
    size: {
      default: 'h-9 text-button-1',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'default',
  },
});

export const fieldVariants = tv({
  base: 'rounded-md border border-grey-6 bg-white text-body-2-m text-text-1 transition-colors placeholder:text-grey-4 hover:border-other-1 focus:border-text-1 disabled:border-grey-6',
  variants: {
    error: {
      true: '!border-other-3',
    },
  },
});
