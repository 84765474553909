import i18n from 'i18next';

import { message, validate } from '@utils';

export const validationSchema = {
  firstName: () => ({
    required: message.required(i18n.t('label.firstName'), {
      interpolation: { escapeValue: false },
    }),
  }),
  phone: () => ({
    required: message.required(i18n.t('label.phone')),
    pattern: validate.phone(message.invalid(i18n.t('label.phone'))),
  }),
  password: () => ({
    required: message.required(i18n.t('label.password')),
  }),
  required: (fieldName = 'Field') => ({
    required: message.required(fieldName),
  }),
  email: () => ({
    pattern: validate.email(message.invalid(i18n.t('label.email'))),
  }),
  code: () => ({
    /**
     * todo: add min validation rule
     */
    required: message.required(i18n.t('label.verificationCode')),
  }),
};
