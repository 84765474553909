import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ACTION_TYPE, COLOR, MODEL, PATH } from '@constants';
import { toast } from '@services';
import {
  as,
  debounce,
  getColor,
  getFullName,
  noop,
  processField,
} from '@utils';

import {
  getCourierByPhone as getCourierByPhoneAction,
  getRestaurants,
  inviteCourierToRestaurant,
} from '@actions';
import {
  createFetchedSelector,
  createLoadingSelector,
  restaurantsSelector,
  userOnboardingSelector,
} from '@selectors';

import {
  Avatar,
  Button,
  Field,
  Render,
  Theme,
  WithDotSeparator,
  WithLoader,
} from '@components';

const restaurantsFetchedSelector = createFetchedSelector(
  ACTION_TYPE.GET_RESTAURANTS
);
const courierLoadingSelector = createLoadingSelector(ACTION_TYPE.GET_COURIER);
const invitingCourierSelector = createLoadingSelector(
  ACTION_TYPE.INVITE_COURIER_TO_RESTAURANT
);

const propTypes = {
  onHide: PropTypes.func,
  onInvite: PropTypes.func,
};
const defaultProps = {
  onHide: noop,
  onInvite: noop,
};

export const InviteCourierModal = ({ onHide, onInvite }) => {
  const dispatch = useDispatch();
  const courierLoading = useSelector(courierLoadingSelector);
  const invitingCourier = useSelector(invitingCourierSelector);
  const restaurantsFetched = useSelector(restaurantsFetchedSelector);
  const restaurants = useSelector(restaurantsSelector);
  const { active: onboarding } = useSelector(userOnboardingSelector);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [restaurantId, setRestaurantId] = useState(as.o(restaurants[0]).id);
  const [phone, setPhone] = useState('');
  const [courier, setCourier] = useState(null);

  useEffect(() => {
    if (!restaurantsFetched) {
      dispatch(getRestaurants()).catch(noop);
    }
  }, [restaurantsFetched, dispatch]);

  const resetCourier = () => {
    setCourier(null);
  };

  const handleRestaurantChange = (event) => {
    setRestaurantId(event.target.value);
  };

  const getCourierByPhone = (phone) => {
    dispatch(getCourierByPhoneAction(processField.phone(phone)))
      .then(setCourier)
      .catch(resetCourier);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetCourierByPhone = useCallback(
    debounce(getCourierByPhone, 1000),
    []
  );

  const handlePhoneChange = (event) => {
    const { value } = event.target;

    setPhone(value);

    if (value) {
      debouncedGetCourierByPhone(value);
    } else {
      resetCourier();
    }
  };

  const handleCourierInvite = (courierId) => async () => {
    try {
      const message = await dispatch(
        inviteCourierToRestaurant(courierId, restaurantId)
      );

      toast.success(message);

      if (onboarding) {
        navigate(PATH.ONBOARDING_CONCLUSION);
        return;
      }

      onHide();
      onInvite();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const restaurantOptions = restaurants.map(
    ({ id, name, location: { name: address } }) => (
      <option key={id} value={id}>
        {getFullName(name, address)}
      </option>
    )
  );

  const renderCourier = () => {
    const {
      id: courierId,
      avatar,
      firstName,
      lastName,
      phone,
      restaurants,
    } = as.o(courier);
    const { courierStatus } = as.o(
      as.a(restaurants).find(({ id }) => id === restaurantId)
    );

    return (
      <div className="flex items-center">
        <div className="flex items-center gap-4">
          <Avatar model={MODEL.USER} size={48} src={avatar} />
          <div className="flex flex-col">
            <span className="text-body-2-m text-grey-1">
              {getFullName(firstName, lastName)}
            </span>
            <div className="flex items-center text-body-2-r">
              <span className="text-text-2">+{phone}</span>
              <Render if={courierStatus}>
                <WithDotSeparator className="text-grey-4">
                  <p className={getColor(COLOR.COURIER[courierStatus])}>
                    {t(`status.${courierStatus}`)}
                  </p>
                </WithDotSeparator>
              </Render>
            </div>
          </div>
        </div>

        <Button
          className="ml-auto px-6"
          loading={invitingCourier}
          disabled={!!courierStatus}
          onClick={handleCourierInvite(courierId)}
        >
          {t('button.invite')}
        </Button>
      </div>
    );
  };

  return (
    <div className="p-6">
      <Theme.Title
        className="mb-3"
        title={t('generic.invite', { title: 'courier' })}
      />
      <div className="grid grid-cols-2 gap-4">
        <Field.Input
          name="phone"
          type="tel"
          inputMode="tel"
          label={t('label.phone')}
          prefix="+"
          value={phone}
          onChange={handlePhoneChange}
        />
        <Field.Select
          name="resturant"
          label={t('web:label.toRestaurant')}
          value={restaurantId}
          onChange={handleRestaurantChange}
        >
          {restaurantOptions}
        </Field.Select>
      </div>
      <WithLoader in={courierLoading} className="relative mt-2 min-h-[64px]">
        <Render if={courier}>{renderCourier()}</Render>
      </WithLoader>
    </div>
  );
};

InviteCourierModal.propTypes = propTypes;
InviteCourierModal.defaultProps = defaultProps;
