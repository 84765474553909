import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MODEL } from '@constants';
import { toast } from '@services';
import { getFullName, getRoleHomePage } from '@utils';

import { authLogout } from '@actions';
import { userSelector } from '@selectors';

import { Avatar, Button, Typography } from '@components';
import { AuthContainer } from '@components/Auth';

const propTypes = {};
const defaultProps = {};

export const BlockedUserPage = () => {
  const { avatar, firstName, lastName } = useSelector(userSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogoutClick = async () => {
    try {
      await dispatch(authLogout());

      navigate(getRoleHomePage());
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <AuthContainer
      className="text-center"
      backButton={false}
      title={t('misc:yourAccountIsBlocked')}
    >
      <Helmet>
        <title>Blocked account</title>
      </Helmet>
      <div className="mt-3 px-5">
        <Avatar
          className="mx-auto block"
          src={avatar}
          model={MODEL.USER}
          size={64}
        />
        <Typography className="mt-1 text-center" variant="title-1">
          {getFullName(firstName, lastName)}
        </Typography>
        <div className="mt-3 content-center">
          <Button className="w-full" onClick={handleLogoutClick}>
            {t('button.logout')}
          </Button>
        </div>
      </div>
    </AuthContainer>
  );
};

BlockedUserPage.propTypes = propTypes;
BlockedUserPage.defaultProps = defaultProps;
