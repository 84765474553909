import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { buttonVariants, noop } from '@utils';

const propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  theme: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(['default']),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'ghost',
    'ghost-grey',
    'outline',
    'outline-grey',
  ]),
  external: PropTypes.bool,
  blank: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  theme: false,
  loading: false,
  disabled: false,
  className: '',
  size: 'default',
  variant: 'primary',
  external: false,
  blank: false,
  onClick: noop,
};

export const Link = ({
  children,
  loading,
  disabled,
  external,
  blank,
  theme,
  className,
  size,
  variant,
  to,
  onClick,
  ...props
}) => {
  const target = blank ? '_blank' : '';
  const classNames = theme
    ? className
    : cx(buttonVariants({ variant, size, className }), {
        'pointer-events-none': loading || disabled,
        'text-transparent': loading,
      });
  const options = {
    ...props,
    className: classNames,
    to,
    target,
    disabled,
  };

  if (external) {
    const linkOptions = {
      ...props,
      className: classNames,
      target,
      href: to,
      disabled,
    };

    return (
      // eslint-disable-next-line react/no-invalid-html-attribute
      <a {...linkOptions} rel="noreferrer nooperer">
        {children}
      </a>
    );
  }

  return <RouterLink {...options}>{children}</RouterLink>;
};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;
