import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import {
  COLOR,
  CONFIG,
  MODEL,
  PAGES_NAME,
  PAGINATION_ENTRY_KEY,
  STATUS,
  USER_ROLE,
} from '@constants';
import { useFeature, useFetchList, useRole } from '@hooks';
import {
  createClassName,
  getFullName,
  makeFilterStatuses,
  onboardingLocalStorage,
  path,
} from '@utils';

import { getRestaurants } from '@actions';
import {
  configSelector,
  userIdSelector,
  userOnboardingSelector,
} from '@selectors';

import {
  Avatar,
  Badge,
  Filter,
  Layout,
  Link,
  Modal,
  Modals,
  Render,
  Table,
  WithLoader,
} from '@components';
import { EmptyList } from '@components/EmptyList';
import { OnboardingContainer } from '@components/OnboardingContainer';
import { RestaurantStatusTooltip } from '@components/Tooltips';
import { Info } from '@components/ui';
import * as emptyListImage from '@images/emptyList';

const propTypes = {};
const defaultProps = {};

export const RestaurantsPage = () => {
  const { [CONFIG.SHARED.CURRENCY]: currencies } = useSelector(configSelector);
  const userId = useSelector(userIdSelector);
  const { active: onboardingActive } = useSelector(userOnboardingSelector);

  const { admin, role } = useRole();
  const { t } = useTranslation();
  const { loading, noList, empty, list, getList, onPageChange } = useFetchList({
    getList: getRestaurants,
  });
  const { enabled: configOnboardingEnabled } = useFeature(
    CONFIG.FEATURE.ONBOARDING
  );

  const getClass = createClassName(noList);
  const owner = role === USER_ROLE.OWNER;
  const onboardingEnabled =
    !onboardingLocalStorage.getUsers(userId) &&
    onboardingActive &&
    configOnboardingEnabled;

  const statusFilterOptions = useMemo(
    () => makeFilterStatuses(STATUS.RESTAURANT, t),
    [t]
  );

  const currencyFilterOptions = useMemo(
    () =>
      currencies.reduce(
        (currencies, currency) => ({
          ...currencies,
          [currency]: currency,
        }),
        {}
      ),
    [currencies]
  );

  const createRestaurantLink = (
    <Render slot="link" if={owner}>
      <Link
        slot="link"
        to={path.restaurant('new')}
        className="flex items-center"
      >
        {t('generic.create', { title: t('web:net.restaurant') })}
      </Link>
    </Render>
  );

  const tableHead = (
    <tr>
      <Table.Head>{t('web:label.cover')}</Table.Head>
      <Table.Head>{t('web:label.restaurantInfo')}</Table.Head>
      <Render if={admin}>
        <Table.Head className="capitalize">{t('role.owner')}</Table.Head>
      </Render>
      <Table.Head align="center">{t('web:label.menu')}</Table.Head>
      <Table.Head align="center">{t('label.status')}</Table.Head>
      <Table.Head align="center">{t('web:label.edit')}</Table.Head>
    </tr>
  );

  const makeBodyRow = ({
    id: restaurantId,
    location: { name: address },
    avatar,
    name,
    open,
    rating,
    reviewCount,
    status,
    owner: { id: ownerId, firstName, lastName },
  }) => {
    const ownerFullName = getFullName(firstName, lastName);
    const normalizedStatus = t(`status.${status}`);

    return (
      <Table.Row
        className={cx(
          'border border-transparent bg-white transition-colors hover:border-grey-4',
          {
            'bg-grey-7': status === STATUS.RESTAURANT.UNPUBLISHED,
          }
        )}
        key={restaurantId}
        to={path.restaurant(restaurantId)}
      >
        <Table.Cell width={200}>
          <Avatar src={avatar} model={MODEL.BUSINESS} size={160} />
        </Table.Cell>
        <Table.Cell width={400}>
          <Info.Restaurant
            title={name}
            open={open}
            address={address}
            rating={rating}
            reviewCount={reviewCount}
          />
        </Table.Cell>
        <Render if={admin}>
          <Table.Cell title={ownerFullName} className="px-6">
            <Link
              theme
              key={ownerId}
              className="primary-1-with-hover block truncate transition-colors"
              to={path.user(ownerId)}
            >
              {ownerFullName}
            </Link>
          </Table.Cell>
        </Render>
        <Table.Cell align="center">
          <Link
            theme
            className="flex content-center px-4 text-primary-1 transition-colors"
            to={path.menu(restaurantId)}
          >
            {t('button.view')}
          </Link>
        </Table.Cell>
        <Table.Cell truncate={false} align="center">
          <RestaurantStatusTooltip status={status} restaurantId={restaurantId}>
            <Badge color={COLOR.RESTAURANT[status]}>{normalizedStatus}</Badge>
          </RestaurantStatusTooltip>
        </Table.Cell>
        <Table.EditIcon to={path.restaurant.edit(restaurantId)} />
      </Table.Row>
    );
  };

  const tableBody = list?.map(makeBodyRow);

  const renderContent = () => {
    if (noList) {
      return (
        <EmptyList
          image={emptyListImage.restaurant}
          title={t('web:empty.restaurants')}
        >
          {createRestaurantLink}
        </EmptyList>
      );
    }

    if (empty) {
      return (
        <EmptyList
          image={emptyListImage.restaurant}
          title={t('web:text.emptyFilterList')}
        />
      );
    }

    return (
      <div className="mt-4">
        <Table alias="table-redesign" head={tableHead} body={tableBody} />
      </div>
    );
  };

  return (
    <Layout
      paginationEnabled
      className="flex flex-1 flex-col bg-grey-8"
      title={PAGES_NAME.RESTAURANTS}
      onPageChange={onPageChange}
      paginationEntryName={PAGINATION_ENTRY_KEY.BUSINESSES}
    >
      <Helmet>
        <title>Restaurants</title>
      </Helmet>
      <Render if={onboardingEnabled && owner}>
        <Modal defaultOpen preventClose content={<Modals.Onboarding />} />
      </Render>
      <OnboardingContainer className="mb-6" />
      <WithLoader in={loading}>
        <Render if={!noList}>
          <div
            className={cx(
              'flex items-center',
              getClass('justify-end', 'justify-between')
            )}
          >
            <Filter
              filters={{
                name: {
                  title: t('web:label.restaurantTitle'),
                },
                phone: {
                  title: t('label.phone'),
                },
                status: {
                  title: t('label.status'),
                  options: statusFilterOptions,
                },
                currency: {
                  title: t('label.currency'),
                  options: currencyFilterOptions,
                },
                hiring: {
                  title: t('web:label.hiringCouriers'),
                  boolean: true,
                },
              }}
              onFilter={getList}
            />
            {createRestaurantLink}
          </div>
        </Render>
        {renderContent()}
      </WithLoader>
    </Layout>
  );
};

RestaurantsPage.propTypes = propTypes;
RestaurantsPage.defaultProps = defaultProps;
