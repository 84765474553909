import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';

import { MODEL, PAGES_NAME, USER_ROLE } from '@constants';
import { toast } from '@services';
import { useRole } from '@hooks';
import { as, getFullName, getNormalizedDocuments, isObject } from '@utils';

import { getCourierById } from '@actions';

import {
  Avatar,
  Button,
  Layout,
  Modal,
  Modals,
  Render,
  Theme,
  WithLoader,
} from '@components';
import { Document } from '@components/Document';
import { RowItem } from '@components/ui';

const propTypes = {};
const defaultProps = {};

export const CourierPage = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { courierId } = useParams();
  const { admin } = useRole();

  const [courier, setCourier] = useState({});

  const loading = isObject.empty(courier);
  const {
    avatar,
    documents,
    email,
    firstName,
    lastName,
    phone,
    restaurants = [],
    status,
  } = courier;
  const { photoOfId, photoWithId } = getNormalizedDocuments(documents);

  useEffect(() => {
    dispatch(getCourierById(courierId))
      .then(setCourier)
      .catch((error) => {
        toast.error(error.message);
      });
  }, [courierId, dispatch]);

  const makeFireModal = ({ id: restaurantId, name }) => (
    <Modal
      className="max-w-[540px]"
      content={
        <Modals.FireCourier
          courierId={courierId}
          restaurantId={restaurantId}
          courierName={getFullName(firstName, lastName)}
          restaurantName={name}
          onFire={setCourier}
        />
      }
    >
      <Button variant="ghost">Fire from the restaurant {name}</Button>
    </Modal>
  );

  return (
    <Layout className="flex flex-1 flex-col" title={PAGES_NAME.COURIER}>
      <Helmet>
        <title>Courier</title>
      </Helmet>
      <WithLoader in={loading}>
        <div className="flex items-start gap-6">
          <div className="flex flex-1 flex-col gap-6">
            <Render if={admin}>
              <Theme.Container>
                <Theme.Title className="mb-6" title={t('web:documents')} />
                <div className="flex">
                  <Document {...photoOfId} label="ID photo" />
                  <Document
                    {...photoWithId}
                    className="ml-6"
                    label="Photo with ID"
                  />
                </div>
              </Theme.Container>
            </Render>
            <div className="flex flex-col items-start gap-4">
              {restaurants.map(makeFireModal)}
            </div>
          </div>
          <Theme.Card className="flex flex-col gap-6">
            <Theme.Title title={t('web:personalInformation')} />
            <Avatar.User
              src={avatar}
              model={MODEL.USER}
              size={64}
              name={getFullName(firstName, lastName)}
              role={USER_ROLE.COURIER}
            />
            <div>
              <RowItem icon={faPhone}>+{phone}</RowItem>
              <RowItem icon={faEnvelope} text={as(email, '-')} />
              <RowItem icon={faBadgeCheck} text={t(`status.${status}`)} />
            </div>
          </Theme.Card>
        </div>
      </WithLoader>
    </Layout>
  );
};

CourierPage.propTypes = propTypes;
CourierPage.defaultProps = defaultProps;
