import { CURRENCY_SIGN } from '@constants';

export const getFormattedPriceWithCurrency = (price = 0, currency) => {
  const normalizedPrice = Number.isNaN(Number(price)) ? price : Number(price);

  const currencySign = CURRENCY_SIGN[currency];

  if (currencySign) {
    return `${currencySign}${normalizedPrice}`;
  }

  return `${normalizedPrice} ${currency}`;
};
