import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ACTION_TYPE } from '@constants';
import { toast } from '@services';
import { noop } from '@utils';

import { fireCourier } from '@actions';
import { createLoadingSelector } from '@selectors';

import { Button, Theme } from '@components';

const loadingSelector = createLoadingSelector(ACTION_TYPE.FIRE_COURIER);

const propTypes = {
  courierId: PropTypes.string.isRequired,
  restaurantId: PropTypes.string.isRequired,
  courierName: PropTypes.string,
  restaurantName: PropTypes.string,
  onHide: PropTypes.func,
  onFire: PropTypes.func,
};
const defaultProps = {
  courierName: '',
  restaurantName: '',
  onHide: noop,
  onFire: noop,
};

export const FireCourierModal = ({
  courierId,
  restaurantId,
  courierName,
  restaurantName,
  onHide,
  onFire,
}) => {
  const dispacth = useDispatch();
  const loading = useSelector(loadingSelector);

  const { t } = useTranslation();

  const handleFire = async () => {
    try {
      const courier = await dispacth(fireCourier({ courierId, restaurantId }));

      toast.success('web:toast.courierFired');

      onHide();
      onFire(courier);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <Theme.Title
        title={t('web:generic.fire', { title: t('role.courier') })}
      />
      <p className="text-center">
        {t('web:content.fireCourier', { courierName, restaurantName })}
      </p>
      <div className="flex justify-around border-t border-grey-7 pt-4 ">
        <Button variant="ghost-grey" onClick={onHide}>
          {t('button.cancel')}
        </Button>
        <Button
          variant="ghost"
          loaderColor="primary"
          loading={loading}
          onClick={handleFire}
        >
          {t('web:button.fire')}
        </Button>
      </div>
    </div>
  );
};

FireCourierModal.propTypes = propTypes;
FireCourierModal.defaultProps = defaultProps;
