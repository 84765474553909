import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from '@services';
import { as } from '@utils';

import { getSubscriptionPlans } from '@actions';
import { configSubscriptionPlansSelector } from '@selectors';

export const useSubscriptionPlans = () => {
  const dispatch = useDispatch();
  const plans = useSelector(configSubscriptionPlansSelector);
  const empty = plans.length === 0;

  useEffect(() => {
    const getSubscriptions = async () => {
      try {
        await dispatch(getSubscriptionPlans());
      } catch (error) {
        toast.error(error.message);
      }
    };

    if (empty) {
      getSubscriptions();
    }
  }, [dispatch, empty]);

  const getSubscriptionPlan = (value) => {
    const plan = plans.find(({ id, plan }) => id === value || plan === value);

    return as.o(plan);
  };

  return { plans, empty, getSubscriptionPlan };
};
