import React from 'react';
import PropTypes from 'prop-types';

import { cn } from '@utils';

import { BackButton, Render, Theme } from '@components';
import { AuthFooter, SideIntro } from '@components/Auth';

const propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.bool,
  backButton: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.node,
  children: PropTypes.node,
  onBackClick: PropTypes.func,
};
const defaultProps = {
  className: undefined,
  containerClassName: undefined,
  backButton: true,
  title: '',
  description: '',
  children: null,
  onBackClick: undefined,
};

export const AuthContainer = ({
  className,
  containerClassName,
  backButton,
  title,
  description,
  children,
  onBackClick,
}) => {
  return (
    <div className="flex gap-12 p-6 [&>*]:w-1/2 [&>*]:flex-1 [&>*]:basis-1/2">
      <SideIntro />
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col justify-center py-4">
          <div
            className={cn(
              'mx-auto mt-14 w-full max-w-[280px] -translate-y-5',
              containerClassName
            )}
          >
            <Render if={backButton}>
              <div className="mb-4">
                <BackButton onClick={onBackClick} />
              </div>
            </Render>
            <div>
              <Theme.Title
                className={className}
                title={title}
                variant="text-headline"
                subtitle={description}
                divider={false}
              >
                <p slot="subtitle" className="mt-1 text-label-1 text-text-2">
                  {description}
                </p>
              </Theme.Title>
              <div className="mt-4">{children}</div>
            </div>
          </div>
        </div>
        <AuthFooter />
      </div>
    </div>
  );
};

AuthContainer.propTypes = propTypes;
AuthContainer.defaultProps = defaultProps;
