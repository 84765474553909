import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import {
  COLOR,
  MODEL,
  PAGES_NAME,
  PAGINATION_ENTRY_KEY,
  VERIFICATION_STATUS,
} from '@constants';
import { useFetchList } from '@hooks';
import {
  capitalize,
  getColor,
  getFullName,
  makeFilterStatuses,
  path,
} from '@utils';

import { getUsers } from '@actions';

import { Avatar, Badge, Filter, Layout, Table, WithLoader } from '@components';
import { SubscriptionIcon } from '@components/SubscriptionIcon';

const propTypes = {};
const defaultProps = {};

export const UsersPage = () => {
  const { t } = useTranslation();

  const statusFilterOptions = useMemo(
    () => makeFilterStatuses(VERIFICATION_STATUS, t),
    [t]
  );

  const {
    list: users,
    loading,
    getList,
    onPageChange,
  } = useFetchList({
    getList: getUsers,
  });

  const tableHead = (
    <tr>
      <Table.Head align="center">ID</Table.Head>
      <Table.Head align="center">{t('label.avatar')}</Table.Head>
      <Table.Head>{t('label.role')}</Table.Head>
      <Table.Head>{t('label.fullName')}</Table.Head>
      <Table.Head>{t('label.phone')}</Table.Head>
      <Table.Head align="center">{t('title.restaurants')}</Table.Head>
      <Table.Head align="center">{t('label.status')}</Table.Head>
    </tr>
  );

  const makeBodyRow = ({
    avatar,
    firstName,
    id,
    lastName,
    phone,
    restaurantCount,
    role,
    status,
    subscription,
  }) => {
    const fullName = getFullName(firstName, lastName);
    const normalizedStatus = t(`status.${status}`);
    const normalizedRole = t(`role.${role}`);

    return (
      <Table.Row key={id} className="bg-white" to={path.user(id)}>
        <Table.Cell align="center" title={id} width={64}>
          {id}
        </Table.Cell>
        <Table.Cell align="center" width={96}>
          <Avatar src={avatar} model={MODEL.USER} size={48} />
        </Table.Cell>
        <Table.Cell className="capitalize" title={normalizedRole} width={160}>
          <div className="flex items-center">
            <span className={getColor.role(role)}>{normalizedRole}</span>
            <SubscriptionIcon plan={subscription?.plan} />
          </div>
        </Table.Cell>
        <Table.Cell title={fullName} width={240}>
          {fullName}
        </Table.Cell>
        <Table.Cell title={phone} width={136}>
          +{phone}
        </Table.Cell>

        <Table.Cell width={64} align="center">
          {restaurantCount}
        </Table.Cell>
        <Table.Cell align="center" title={normalizedStatus} width={200}>
          <Badge color={COLOR.VERIFICATION[status]}>{normalizedStatus}</Badge>
        </Table.Cell>
      </Table.Row>
    );
  };

  const tableBody = users?.map(makeBodyRow);

  return (
    <Layout
      paginationEnabled
      className="flex flex-1 flex-col"
      title={PAGES_NAME.USERS}
      onPageChange={onPageChange}
      paginationEntryName={PAGINATION_ENTRY_KEY.USERS}
    >
      <Helmet>
        <title>Control Panel</title>
      </Helmet>
      <div className="mb-6">
        <Filter
          filters={{
            phone: {
              title: t('label.phone'),
            },
            email: {
              title: t('label.email'),
            },
            role: {
              title: t('label.role'),
              options: {
                admin: capitalize(t('role.admin')),
                courier: capitalize(t('role.courier')),
                customer: capitalize(t('role.customer')),
                manager: capitalize(t('role.manager')),
                owner: capitalize(t('role.owner')),
                support: capitalize(t('role.support')),
              },
            },
            status: {
              title: t('label.status'),
              options: statusFilterOptions,
            },
          }}
          onFilter={getList}
        />
      </div>
      <WithLoader in={loading}>
        <Table alias="table-redesign" head={tableHead} body={tableBody} />
      </WithLoader>
    </Layout>
  );
};

UsersPage.propTypes = propTypes;
UsersPage.defaultProps = defaultProps;
