import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { COLOR, MODEL, PAGES_NAME, PAGINATION_ENTRY_KEY } from '@constants';
import { useFetchList } from '@hooks';
import { getColor, getFullName, path } from '@utils';

import { getCouriers } from '@actions';

import {
  Avatar,
  Button,
  Layout,
  Link,
  Modal,
  Modals,
  Render,
  Table,
  WithDotSeparator,
  WithLoader,
} from '@components';
import { EmptyList } from '@components/EmptyList';
import { OnboardingContainer } from '@components/OnboardingContainer';
import * as emptyListImage from '@images/emptyList';

const propTypes = {};
const defaultProps = {};

export const CouriersPage = () => {
  const { t } = useTranslation();

  const { loading, noList, empty, list, getList, onPageChange } = useFetchList({
    getList: getCouriers,
  });

  const inviteCourierLink = (
    <Modal
      slot="link"
      className="max-w-[500px]"
      content={<Modals.InviteCourier onInvite={getList} />}
    >
      <Button className="px-6">
        {t('generic.invite', {
          title: t('role.courier'),
        })}
      </Button>
    </Modal>
  );

  const tableHead = (
    <tr>
      <Table.Head align="center">{t('label.photo')}</Table.Head>
      <Table.Head>{t('label.fullName')}</Table.Head>
      <Table.Head>{t('label.phone')}</Table.Head>
      <Table.Head>{t('title.restaurant')}</Table.Head>
    </tr>
  );

  const makeBodyRow = ({
    id,
    avatar,
    firstName,
    lastName,
    phone,
    restaurants,
  }) => {
    const fullName = getFullName(firstName, lastName);

    const restaurantList = restaurants.map(({ id, name, courierStatus }) => (
      <p key={id} className="flex items-center">
        <Link
          theme
          key={id}
          className="primary-1-with-hover truncate underline"
          title={name}
          to={path.restaurant(id)}
        >
          {name}
        </Link>
        <WithDotSeparator className="text-grey-3">
          <span className={getColor(COLOR.COURIER[courierStatus])}>
            {t(`status.${courierStatus}`)}
          </span>
        </WithDotSeparator>
      </p>
    ));

    return (
      <Table.Row key={id} to={path.courier(id)}>
        <Table.Cell width={96} align="center">
          <Avatar src={avatar} size={64} model={MODEL.USER} />
        </Table.Cell>
        <Table.Cell width={200} title={fullName}>
          {fullName}
        </Table.Cell>
        <Table.Cell width={160} title={phone}>
          +{phone}
        </Table.Cell>
        <Table.Cell width={320}>
          <div className="flex flex-col gap-2">{restaurantList}</div>
        </Table.Cell>
      </Table.Row>
    );
  };

  const tableBody = list?.map(makeBodyRow);

  const renderContent = () => {
    if (noList) {
      return (
        <EmptyList
          image={emptyListImage.courier}
          title={t('web:empty.couriers')}
        >
          {inviteCourierLink}
        </EmptyList>
      );
    }

    if (empty) {
      return (
        <EmptyList
          image={emptyListImage.courier}
          title={t('web:text.emptyFilterList')}
        />
      );
    }

    return (
      <div className="mt-4">
        <Table head={tableHead} body={tableBody} />
      </div>
    );
  };

  return (
    <Layout
      paginationEnabled
      className="flex flex-1 flex-col"
      title={PAGES_NAME.COURIERS}
      onPageChange={onPageChange}
      paginationEntryName={PAGINATION_ENTRY_KEY.COURIERS}
    >
      <Helmet>
        <title>Couriers</title>
      </Helmet>
      <OnboardingContainer />
      <WithLoader in={loading}>
        <Render if={!noList}>
          <div className="flex justify-end">{inviteCourierLink}</div>
        </Render>
        {renderContent()}
      </WithLoader>
    </Layout>
  );
};

CouriersPage.propTypes = propTypes;
CouriersPage.defaultProps = defaultProps;
