import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ACTION_TYPE } from '@constants';
import { toast } from '@services';
import { noop } from '@utils';

import { deleteDish } from '@actions';
import { createLoadingSelector } from '@selectors';

import { Button, Theme } from '@components';

const restaurantDeletingSelector = createLoadingSelector(
  ACTION_TYPE.DELETE_RESTAURANT
);

const propTypes = {
  restaurantId: PropTypes.string.isRequired,
  productId: PropTypes.string,
  productName: PropTypes.string,
  onHide: PropTypes.func,
  onDelete: PropTypes.func,
};
const defaultProps = {
  productId: null,
  productName: null,
  onHide: noop,
  onDelete: noop,
};

export const DeleteDishModal = ({
  restaurantId,
  productId,
  productName,
  onHide,
  onDelete,
}) => {
  const dispacth = useDispatch();
  const deleting = useSelector(restaurantDeletingSelector);

  const { t } = useTranslation();

  const handleDelete = async () => {
    try {
      const dish = await dispacth(
        deleteDish({ restaurantId, dishId: productId })
      ).catch(noop);

      toast.success('web:toast.dishDeleted');

      onHide();
      onDelete(dish);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="flex flex-col gap-8 p-6">
      <Theme.Title title={t('generic.delete', { title: 'dish' })} />
      <p className="text-center text-body-2-r">
        {t('web:content.dishDeleting', { name: productName })}
      </p>
      <div className="flex justify-around border-t border-grey-7 pt-4">
        <Button variant="ghost-grey" onClick={onHide}>
          {t('button.cancel')}
        </Button>
        <Button
          variant="ghost"
          loaderColor="primary"
          loading={deleting}
          onClick={handleDelete}
        >
          {t('button.delete')}
        </Button>
      </div>
    </div>
  );
};

DeleteDishModal.propTypes = propTypes;
DeleteDishModal.defaultProps = defaultProps;
