import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Role } from '@types';
import { ACTION_TYPE, CONFIG, PATH } from '@constants';
import { firebase, toast } from '@services';
import { useFeature } from '@hooks';
import {
  getFormErrors,
  getRoleHomePage,
  removeEmptyProperties,
  setBackendErrors,
  validationSchema,
} from '@utils';

import {
  authLogin,
  authSignup,
  authVerifyPhone,
  cacheAny,
  setDeviceToken,
} from '@actions';
import { createLoadingSelector, sharedSelector } from '@selectors';

import { Button, Field } from '@components';

const loadingSelector = createLoadingSelector(ACTION_TYPE.AUTH_VERIFY_PHONE);
const resendingSelector = createLoadingSelector(
  ACTION_TYPE.AUTH_LOGIN,
  ACTION_TYPE.AUTH_SIGNUP
);

const propTypes = {
  page: PropTypes.oneOf([PATH.LOGIN, PATH.SIGNUP]).isRequired,
  role: Role,
  submitButtonText: PropTypes.string,
};
const defaultProps = {
  role: undefined,
  submitButtonText: '',
};

export const VerificationCodeForm = ({
  page: fromPage,
  role,
  submitButtonText,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const resending = useSelector(resendingSelector);
  const { phone, firstName } = useSelector(sharedSelector);

  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { t } = useTranslation();
  const { register, handleSubmit, formState, getValues, setError } = useForm({
    defaultValues: { phone },
  });

  const { enabled: twilio } = useFeature(CONFIG.FEATURE.TWILIO);

  const errors = getFormErrors(formState.errors);

  const handleResendCode = async () => {
    try {
      const signup = fromPage === PATH.SIGNUP;
      const action = signup ? authSignup : authLogin;
      const referrerId = signup ? search.get('referrerId') : null;
      const params = removeEmptyProperties({
        firstName,
        phone,
        role,
        referrerId,
      });

      const { code, message } = await dispatch(action(params));

      toast.success(message);

      if (!twilio) {
        toast.info(code);
      }
    } catch (error) {
      toast.error(error.message);

      setBackendErrors(error, { setError, getValues });
    }
  };

  const handleFormSubmit = handleSubmit(async ({ code }) => {
    try {
      const user = await dispatch(authVerifyPhone({ phone, code, role }));

      dispatch(cacheAny({ phone: null, code: null }));

      if (firebase.token) {
        dispatch(setDeviceToken(user.id, firebase.token));
      }

      navigate(getRoleHomePage(user.role, { replace: true }));
    } catch (error) {
      dispatch(cacheAny({ code }));

      setBackendErrors(error, { getValues, setError });
    }
  });

  return (
    <form onSubmit={handleFormSubmit}>
      <Field.Input
        {...register('phone')}
        disabled
        label={t('label.phone')}
        requiredLabel
        prefix="+"
        error={errors.phone}
      />
      <Field.Input
        {...register('code', validationSchema.code())}
        type="number"
        inputMode="numeric"
        label={t('label.verificationCode')}
        requiredLabel
        error={errors.code}
      />
      <Button loading={loading} type="submit" className="mt-4 w-full">
        {submitButtonText}
      </Button>
      <Button
        loading={resending}
        variant="ghost"
        loaderColor="primary"
        className="mt-3 w-full"
        onClick={handleResendCode}
      >
        {t('button.resendCode')}
      </Button>
    </form>
  );
};

VerificationCodeForm.propTypes = propTypes;
VerificationCodeForm.defaultProps = defaultProps;
