export const validate = {
  /**
   * For `pattern` usage
   */
  email: (message = 'Email is invalid') => ({
    value:
      // eslint-disable-next-line no-control-regex
      /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/,
    message,
  }),

  /**
   * For `pattern` usage
   */
  name: (message = 'Name is invalid') => ({
    value: /^[a-z ,.'-]{2,50}$/i,
    message,
  }),
  numeric: (message = 'Field should be number only') => ({
    value: /^[0-9]+$/i,
    message,
  }),
  phone: (message = 'Phone is invalid') => ({
    value: /^[0-9\s+]+$/i,
    message,
  }),
};
