import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { noop } from '@utils';

import { Button } from '@components';

const propTypes = {
  activeTab: PropTypes.string,
  tabs: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
};
const defaultProps = {
  activeTab: '',
  tabs: [],
  onChange: noop,
};

export const TabSwitcher = ({ activeTab, tabs, onChange }) => {
  const handleTabChange = (tab) => () => {
    onChange(tab);
  };

  const getTabs = () => {
    if (Array.isArray(tabs)) {
      return tabs.map((tab) => [tab, tab]);
    }

    return Object.entries(tabs);
  };

  const makeTab = ([tab, text]) => {
    const active = tab === activeTab;

    return (
      <Button
        theme
        key={tab}
        className={cx(
          'rounded-sm bg-transparent px-8 text-[12px] capitalize text-grey-3 transition-colors',
          {
            'text-primary-1 bg-white': active,
          }
        )}
        onClick={handleTabChange(tab)}
      >
        {text}
      </Button>
    );
  };

  return (
    <div className="flex h-6 rounded-md bg-grey-7 p-0.5">
      {getTabs().map(makeTab)}
    </div>
  );
};

TabSwitcher.propTypes = propTypes;
TabSwitcher.defaultProps = defaultProps;
