import React from 'react';
import { Helmet } from 'react-helmet';

import { PAGES_NAME } from '@constants';
import { useFetchList } from '@hooks';
import { as, getFullName } from '@utils';

import { getReviews } from '@actions';

import { Layout, WithLoader } from '@components';
import { Review } from '@components/Review';

const propTypes = {};
const defaultProps = {};

export const ReviewsPage = () => {
  const { loading, list, getList, onPageChange } = useFetchList({
    getList: getReviews,
  });

  const makeReview = ({
    id,
    orderId,
    restaurant,
    status,
    createdAt,
    customer: { avatar, firstName, lastName } = {},
  }) => {
    const {
      rating,
      review,
      id: restaurantId,
      name: restaurantTitle,
    } = as.o(restaurant);

    return (
      <Review
        managable
        key={id}
        reviewId={id}
        orderId={orderId}
        avatar={avatar}
        review={review}
        createdAt={createdAt}
        rating={rating}
        status={status}
        restaurantTitle={restaurantTitle}
        restaurantId={restaurantId}
        userName={getFullName(firstName, lastName)}
        onStatusChange={() => getList()}
      />
    );
  };

  return (
    <Layout
      paginationEnabled
      className="flex flex-1 flex-col bg-grey-8"
      title={PAGES_NAME.REVIEWS}
      onPageChange={onPageChange}
    >
      <Helmet>
        <title>Reviews</title>
      </Helmet>
      <WithLoader in={loading}>
        <div className="mt-6 flex flex-col gap-2">{list.map(makeReview)}</div>
      </WithLoader>
    </Layout>
  );
};

ReviewsPage.propTypes = propTypes;
ReviewsPage.defaultProps = defaultProps;
