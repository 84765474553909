/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Helmet } from 'react-helmet';

const propTypes = {};
const defaultProps = {};

export const ApplicationTermsPage = () => {
  return (
    <div className="terms-page">
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <div>
        <h1>Privacy Policy</h1>
        <h2>Who is responsible for data processing?</h2>
        <p>
          The responsible that defines the purposes and means of the treatment
          specified in this privacy policy, is Caribbean Apps LLP (England S2
          4FA S.Yorkshire Shefield Shoreham street 311), hereinafter "Akicomo",
          "we", "our", "data controller". We also use the term "deliverer" or
          "courier" to address you.
        </p>
        <p>
          Please note that you may have been hired as a delivery person by a
          third-party logistics company that is not part of our company or our
          group of companies. In this case, this company usually processes your
          personal data as an independent data controller, acting as a joint
          controller with our company only with respect to the IT applications
          we provide. You are free to assert your legal rights in this context,
          either against us or against the third party with whom you have
          contracted and which acts as joint controller. However, the legal
          relationship of joint controller refers only to the processing of
          personal data on our online platforms. We have no influence and are
          not responsible for the processing of personal data by said third
          party in other contexts.
        </p>
        <h2>Why and what personal data do we process?</h2>
        <p>
          Below you can see what data we need for what purposes and under what
          circumstances we share your data with others.
        </p>
        <p>
          Personal data is information from which we can directly or indirectly
          relate to your person, such as first and last name, address, telephone
          number, date of birth, location data or email address.
        </p>
        <h2>What personal data do we process?</h2>
        <p>
          To provide our delivery service to our customers, we use various tools
          and systems that are absolutely necessary for the delivery of orders.
          We also use external and internal tools and systems to process your
          personal data for management and our general business operations.
        </p>
        <p>
          We collect, process and store the following categories of personal
          data within the framework of the use of the tools and systems:
        </p>
        <h2>Explanation of data categories</h2>
        <p>Identification data: phone number</p>
        <p>Account data: first name, last name, email</p>
        <p>Performance data: application usage time</p>
        <h2>For what purposes do we process personal data?</h2>
        <p>
          We only collect your personal data if it is necessary, if the purpose
          is legal and if the treatment is provided. In the following we would
          like to give you more information about the purposes and the legal
          basis:
        </p>
        <h4>Hiring</h4>
        <h4>Application</h4>
        <p>
          As part of the application process, we collect, process and store your
          personal data on the basis of the data you have made available to us.
          The purpose of the treatment is to make a decision on the hiring or
          refusal of an applicant.
        </p>
        <h4>Categories of personal data</h4>
        <ol>
          <li>Identification data</li>
          <li>Contact data</li>
          <li>Account data</li>
        </ol>
        <h4>Identity verification</h4>
        <p>
          To protect your app profile from potential fraud attacks, we need to
          verify your identity and make sure the profile owner is who they say
          they are. To do this, we use identity verification measures to ensure
          that suspicious behavior patterns are detected at an early stage. We
          will ask you to upload a "selfie" photo to our systems which will be
          verified and reviewed by the system.
        </p>
        <h4>Advertising and marketing</h4>
        <h4>Online marketing</h4>
        <p>
          Our hiring process is largely based on finding potential drivers. To
          reach the right candidates, we run marketing campaigns. For this
          reason, we would like to present our processes to you as transparently
          as possible. The following online marketing processing activities that
          we carry out include segmentation and re-targeting:
        </p>
        <h4>1. Segmentation</h4>
        <p>
          In principle, segmentation means switching and fading banner ads on
          websites that are tailored to specific target groups. The goal is to
          display the most attractive banners in the most individualized way
          possible for the user and potential users. Firstly, we define a target
          group and secondly, we commission our service providers to display our
          advertising to the defined target group. We do not process any
          personal data, as these are initially made anonymous. We segment
          different target groups and place different ads on different portals
          to optimize targeting.
        </p>
        <h4>2. Retargeting</h4>
        <p>
          As soon as you have visited our website to learn more about our driver
          program, we store information about your website visit in cookies. If
          you continue to browse other websites, our advertising partners will
          remind you on our behalf that you have not yet applied. We don't want
          you to miss out on our amazing driver program. You can disable
          retargeting by installing the appropriate plugins for your browser. In
          addition, you can and should regularly delete cookies stored in the
          browser you use.
        </p>
        <h4>Reactivation of the candidate</h4>
        <p>
          If a candidate does not proceed with the application process, they
          will be sent an SMS, email or Whatsapp to remind them of the steps
          they need to follow to complete the application process.
        </p>
        <h4>Location</h4>
        <p>
          We request access to the location of the user (courier) in order to
          share coordinates with other users participating in the delivery of
          the order.
        </p>
        <p>We track the location of the user only if he has an Active Order.</p>
        <p>
          We get the location of the user (courier) even if his application is
          inactive (not to be confused with closed).
        </p>
        <h4>Communication with customers</h4>
        <p>Communication with customers about order or delivery status</p>
        <h4>Photos and videos</h4>
        <p>Take and post photos and videos of drivers</p>
        <h4>Administration of service providers</h4>
        <p>
          We collect, process and store your personal data for the processing
          and creation of legally required documents and evidence, as well as
          for the fees of our couriers.
        </p>
        <h4>Delivery</h4>
        <p>
          To guarantee a fast delivery of the products requested by our
          customers, the coordination data of our couriers are collected and the
          order is assigned to those couriers who are in an optimal region.
        </p>
        <h2>How long do we store personal data? </h2>
        <p>
          We generally delete your data after the purpose has been fulfilled.
          The exact disposal rules are defined in our regional disposal
          concepts. Different deletion rules apply depending on the purpose of
          the treatment. Within our deletion concepts we have defined various
          data classes and assigned rule deletion periods to them. When the
          retention period expires, the stored data will be deleted accordingly.
        </p>
        <p>
          In certain circumstances, deletion requests may be opposed by
          statutory retention periods, which prevent us from deleting data
          stored for a certain minimum period of time. To comply with these
          legal requirements, we block the relevant data once the purpose has
          been fulfilled and thus guarantee the integrity of the data.
        </p>
        <h4>With which data processors we share personal data and why?</h4>
        <p>
          We never provide your data to unauthorized third parties. However, as
          part of our work, we obtain the services of selected service providers
          and give them limited and strictly supervised access to some of our
          data. However, before passing on personal data to these data
          processors for processing on our behalf, each individual company
          undergoes an audit. All data recipients must comply with legal data
          protection requirements and demonstrate their level of data protection
          with appropriate evidence.
        </p>
        <h4>Tax authorities and legal proceedings</h4>
        <p>
          They support our business activities by providing us with IT
          infrastructure and solutions or by ensuring the security of our
          business operations, for example by identifying and rectifying bugs.
          In addition, personal data may also be communicated to external tax
          advisers, lawyers or auditors if they provide the services for which
          they have been entrusted.
        </p>
        <p>
          Within a group, it is sometimes necessary to use resources
          efficiently. In this context, we support each other within our Group
          to optimize our processes. In addition, we offer joint content and
          services. This includes, for example, technical support for systems.
        </p>
        <p>
          Unfortunately, it may happen that some of our couriers and service
          providers do not behave fairly and want to harm us. In these cases, we
          are not only obliged to hand over the personal data due to legal
          obligations, but of course it is also in our interest to prevent
          damage and assert our claims and reject unjustified ones.
        </p>
        <h4>Data processing</h4>
        <p>
          We process your data mainly in the country where you carry out your
          activities. However, some of our service providers are based in other
          area.
        </p>
        <p>
          We adhere to the strictest standards for the transfer of personal
          data. Before transferring your data to a service provider in third
          countries, each service provider is first assessed as to their level
          of Data Protection. They will only be shortlisted as service providers
          if they can demonstrate an adequate level of data protection.
        </p>
        <p>
          Regardless of whether our service providers are located, each service
          provider must sign a data processing agreement with us. Service
          providers must meet other requirements.
        </p>
      </div>
    </div>
  );
};

ApplicationTermsPage.propTypes = propTypes;
ApplicationTermsPage.defaultProps = defaultProps;
