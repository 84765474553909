import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
};

const defaultProps = {
  disabled: false,
  className: '',
  error: '',
};

export const FieldSwitch = React.forwardRef(
  ({ name, className, disabled, error, ...props }, ref) => (
    <label htmlFor={name} className={cx('relative', className)}>
      <input
        {...props}
        type="checkbox"
        ref={ref}
        id={name}
        className="peer hidden"
        name={name}
      />
      <div className="relative h-6 w-11 shrink-0 cursor-pointer rounded-full bg-grey-4 transition-colors after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:bg-white after:transition-all peer-checked:bg-primary-1 peer-checked:after:translate-x-full" />
    </label>
  )
);

FieldSwitch.displayName = 'Field.Switch';
FieldSwitch.propTypes = propTypes;
FieldSwitch.defaultProps = defaultProps;
