import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faCoins, faCubesStacked } from '@fortawesome/pro-light-svg-icons';

import { MODEL, STATUS } from '@constants';
import { toast } from '@services';
import { as, getFormattedPriceWithCurrency, isObject, path } from '@utils';

import { getDishById } from '@actions';

import { Avatar, Layout, Link, Theme, WithLoader } from '@components';
import { RowItem } from '@components/ui';
import * as View from '@components/View';

const propTypes = {};
const defaultProps = {};

export const ProductPage = () => {
  const dispatch = useDispatch();

  const { restaurantId, productId } = useParams();
  const { t } = useTranslation();

  const [product, setProduct] = useState({});

  const loading = isObject.empty(product);
  const {
    avatar,
    name,
    category,
    measure,
    quantity,
    price,
    currency,
    status,
    description,
  } = as.o(product);
  const formattedMeasure = `${quantity} ${measure}`;
  const formattedCategory = category ? t(`category.${category}`) : '-';

  useEffect(() => {
    dispatch(getDishById({ restaurantId, dishId: productId }))
      .then(setProduct)
      .catch((error) => {
        toast.error(error.message);
      });
  }, [restaurantId, productId, dispatch]);

  return (
    <Layout className="flex flex-1 flex-col bg-grey-8" title={name}>
      <Helmet>
        <title>Restaurant</title>
      </Helmet>
      <WithLoader in={loading}>
        <div className="flex justify-end">
          <Link to={path.product.edit(productId, restaurantId)}>
            {t('button.edit')}
          </Link>
        </div>
        <div className="mt-6 flex items-start gap-6">
          <div className="flex-1">
            <Theme.Container className="flex flex-1 flex-col gap-4">
              <Theme.Title title={t('web:title.productDetails')} />
              <div className="flex items-center gap-4">
                <Avatar src={avatar} model={MODEL.PRODUCT} size={150} />
                <div className="flex-1 py-2">
                  <p className="text-label-1 font-500 uppercase text-primary-1">
                    {formattedCategory}
                  </p>
                  <p className="text-body-1-s text-grey-1">{name}</p>
                  <p className="text-body-2-r text-text-2">{description}</p>
                </div>
              </div>
              <div className="grid grid-cols-2">
                <RowItem icon={faCubesStacked} label={t('label.measure')}>
                  {formattedMeasure}
                </RowItem>
                <RowItem icon={faCoins} label={t('label.price')}>
                  {getFormattedPriceWithCurrency(price, currency)}
                </RowItem>
              </div>
            </Theme.Container>
          </div>
          <Theme.Card>
            <Theme.Title className="mb-4" title={t('label.status')} />
            <View.CheckList value={status === STATUS.RESTAURANT.PUBLISHED}>
              {t('status.published')}
            </View.CheckList>
          </Theme.Card>
        </div>
      </WithLoader>
    </Layout>
  );
};

ProductPage.propTypes = propTypes;
ProductPage.defaultProps = defaultProps;
