import React from 'react';
import { Slide, ToastContainer as Toast } from 'react-toastify';

const propTypes = {};
const defaultProps = {};

export const ToastContainer = () => (
  <Toast hideProgressBar icon={false} transition={Slide} closeButton={null} />
);

ToastContainer.propTypes = propTypes;
ToastContainer.defaultProps = defaultProps;
