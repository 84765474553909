import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';

import { cn, debounce, noop } from '@utils';

import { IconBox, Render } from '@components';

const propTypes = {
  /**
   * todo: describe type
   */
  filters: PropTypes.shape().isRequired,
  defaultFilter: PropTypes.string,
  onFilter: PropTypes.func,
};
const defaultProps = {
  defaultFilter: null,
  onFilter: noop,
};

export const Filter = ({ defaultFilter, filters, onFilter }) => {
  const { t } = useTranslation();

  const defaultFilterKey = Object.keys(filters)[0];

  const [filter, setFilter] = useState(defaultFilter ?? defaultFilterKey ?? '');
  const [value, setValue] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilterChange = useCallback(debounce(onFilter, 1000), []);

  const cb = (className) =>
    cn(
      'w-full appearance-none border-none py-0 pl-3 pr-10 text-[14px] font-500',
      className
    );
  cb.icon = (className) =>
    cn(
      'pointer-events-none absolute inset-y-0 right-2 m-auto text-[14px] text-primary-1',
      className
    );

  const handleFilterChange = (event) => {
    const DEFAULT_VALUE = '';

    const { value } = event.target;

    setFilter(value);
    onFilter(value, DEFAULT_VALUE);
    setValue(DEFAULT_VALUE);
  };

  const handleValueChange = (event) => {
    const { value, tagName } = event.target;
    const filterChangeMethod =
      tagName === 'INPUT' ? debouncedFilterChange : onFilter;

    setValue(value);

    filterChangeMethod({ [filter]: value });
  };

  const typeOptions = Object.entries(filters).map(
    ([type, { title = type }]) => (
      <option key={type} value={type}>
        {title}
      </option>
    )
  );

  const makeFilterOption = ([value, title]) => (
    <option key={value} value={value}>
      {title}
    </option>
  );

  const renderFilter = () => {
    const { options, boolean } = filters[filter];
    const input = !options && !boolean;
    const icon = input ? faMagnifyingGlass : faChevronDown;
    const filterOptions =
      options && Object.entries(options).map(makeFilterOption);

    if (input) {
      return (
        <div className="relative flex flex-1 grow-[3]">
          <input
            className={cb('text-secondary-4')}
            value={value}
            onChange={handleValueChange}
          />
          <IconBox icon={icon} className={cb.icon()} />
        </div>
      );
    }

    if (boolean) {
      return (
        <div className="relative flex shrink grow-[3]">
          <select
            className={cb('text-secondary-4')}
            value={value}
            onChange={handleValueChange}
          >
            <option value="">No filter</option>
            <option value>{t('yes')}</option>
            <option value={false}>{t('no')}</option>
          </select>
          <IconBox icon={faChevronDown} className={cb.icon()} />
        </div>
      );
    }

    return (
      <div className="relative flex shrink grow-[3]">
        <select className={cb()} value={value} onChange={handleValueChange}>
          <option value="">No filter</option>
          {filterOptions}
        </select>
        <IconBox icon={faChevronDown} className={cb.icon()} />
      </div>
    );
  };

  return (
    <div className="flex h-9 w-full max-w-[460px] overflow-hidden rounded-[6px] border border-grey-4 bg-white text-[14px]">
      <div className="relative right-0 flex w-px min-w-[136px] flex-initial basis-content bg-grey-4 after:pointer-events-none after:absolute after:inset-y-2 after:right-0 after:block after:w-px after:bg-grey-4">
        <select className={cb()} value={filter} onChange={handleFilterChange}>
          {typeOptions}
        </select>
        <Render if={typeOptions.length > 1}>
          <IconBox icon={faChevronDown} className={cb.icon()} />
        </Render>
      </div>
      {renderFilter()}
    </div>
  );
};

Filter.propTypes = propTypes;
Filter.defaultProps = defaultProps;
