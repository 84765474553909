import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { RESTAURANT_SCHEDULE } from '@constants';

import { Field } from '@components';

const WeekDay = PropTypes.shape({
  open: PropTypes.bool,
  from: PropTypes.string,
  to: PropTypes.string,
});

const propTypes = {
  register: PropTypes.func.isRequired,
  schedule: PropTypes.shape({
    monday: WeekDay,
    tuesday: WeekDay,
    wednesday: WeekDay,
    thursday: WeekDay,
    friday: WeekDay,
    saturday: WeekDay,
    sunday: WeekDay,
  }),
};
const defaultProps = {
  schedule: {},
};

export const RestaurantSchedule = ({ register, schedule }) => {
  const { t } = useTranslation();

  const getFieldName = (day, fieldName) => `schedule.${day}.${fieldName}`;

  const selectOptions = RESTAURANT_SCHEDULE.SCHEDULE.map((value) => (
    <option key={value} value={value}>
      {value}
    </option>
  ));

  const makeScheduleDay = (day) => {
    const isOpen = schedule[day]?.open;
    const status = isOpen ? t('open') : t('close');

    return (
      <div
        key={day}
        className="flex items-center border-t border-grey-7 py-2 text-grey-1 last:border-0"
      >
        <p className="flex-1 basis-[140px] text-body-2-m">
          {t(`schedule.day.${day}`)}
        </p>
        <Field.Switch
          {...register(getFieldName(day, 'open'))}
          className="basis-2 px-4"
        />
        <p className="ml-2 mr-6 text-body-2-m">{status}</p>
        <div
          className={cx('flex items-center', { 'visibility-hidden': !isOpen })}
        >
          <Field.Select
            {...register(getFieldName(day, 'from'))}
            containerClassName="pb-0"
          >
            {selectOptions}
          </Field.Select>
          <p className="mx-6">{t('schedule.to')}</p>
          <Field.Select
            {...register(getFieldName(day, 'to'))}
            containerClassName="pb-0"
          >
            {selectOptions}
          </Field.Select>
        </div>
      </div>
    );
  };

  return <div>{RESTAURANT_SCHEDULE.DAYS.map(makeScheduleDay)}</div>;
};

RestaurantSchedule.propTypes = propTypes;
RestaurantSchedule.defaultProps = defaultProps;
