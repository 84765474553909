import {
  BILLING,
  STATUS,
  SUBSCRIPTION,
  USER_ROLE,
} from '@constants/shared.constants';

export const PAGES_NAME = {
  CONFIGURATION: 'web:title.configuration',
  COURIER: 'title.courier',
  COURIERS: 'title.couriers',
  DISHES: 'title.dishes',
  MANAGER: 'title.manager',
  ORDER: 'title.order',
  ORDERS: 'title.orders',
  PROFILE: 'title.profile',
  REFERRALS: 'web:title.referrals',
  RESTAURANT: 'web:title.restaurant',
  RESTAURANTS: 'web:title.restaurants',
  REVIEWS: 'title.reviews',
  SETTINGS: 'title.settings',
  SUBSCRIPTION: 'Subscription',
  USERS: 'title.users',
};

export const CURRENCY_SIGN = {
  USD: '$',
};

export const NOTIFICATION_TYPE = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const ALERT_NAME = {
  EMAIL: 'email',
  DOCUMENTS: 'documents',
};

export const COLOR = {
  MAP: {
    blue: 'other-9',
    darkred: 'other-2',
    green: 'other-8',
    grey: 'grey-3',
    pink: 'other-13',
    purple: 'other-12',
    red: 'other-3',
    yellow: 'other-5',
  },
  ORDER: {
    [STATUS.ORDER.ACCEPTED]: 'green',
    [STATUS.ORDER.CANCELED]: 'red',
    [STATUS.ORDER.CREATED]: 'blue',
    [STATUS.ORDER.DECLINED]: 'grey',
    [STATUS.ORDER.DELIVERED]: 'green',
    [STATUS.ORDER.DELIVERING]: 'yellow',
  },
  VERIFICATION: {
    [STATUS.VERIFICATION.BLOCKED]: 'grey',
    [STATUS.VERIFICATION.DECLINED]: 'grey',
    [STATUS.VERIFICATION.DELETED]: 'darkred',
    [STATUS.VERIFICATION.PENDING]: 'yellow',
    [STATUS.VERIFICATION.UNVERIFIED]: 'red',
    [STATUS.VERIFICATION.VERIFIED]: 'green',
  },
  RESTAURANT: {
    [STATUS.RESTAURANT.DELETED]: 'darkred',
    [STATUS.RESTAURANT.DISHES]: 'blue',
    [STATUS.RESTAURANT.LIMITED]: 'purple',
    [STATUS.RESTAURANT.PUBLISHED]: 'green',
    [STATUS.RESTAURANT.UNPUBLISHED]: 'grey',
    [STATUS.RESTAURANT.UNVERIFIED]: 'red',
  },
  ROLE: {
    [USER_ROLE.ADMIN]: 'green',
    [USER_ROLE.COURIER]: 'purple',
    [USER_ROLE.CUSTOMER]: 'blue',
    [USER_ROLE.MANAGER]: 'yellow',
    [USER_ROLE.OWNER]: 'red',
    [USER_ROLE.SUPPORT]: 'pink',
  },
  COURIER: {
    [STATUS.COURIER.HIRED]: 'green',
    [STATUS.COURIER.INVITED]: 'yellow',
    [STATUS.COURIER.LIMITED]: 'red',
    [STATUS.VERIFICATION.UNVERIFIED]: 'grey',
    [STATUS.VERIFICATION.VERIFIED]: 'green',
  },
  SUBSCRIPTION_PLAN: {
    [SUBSCRIPTION.PLAN.FREE]: 'green',
    [SUBSCRIPTION.PLAN.STANDARD]: 'blue',
    [SUBSCRIPTION.PLAN.PREMIUM]: 'purple',
  },
  BILLING_STATUS: {
    [BILLING.STATUS.SUCCEEDED]: 'green',
    [BILLING.STATUS.PENDING]: 'yellow',
    [BILLING.STATUS.FAILED]: 'red',
  },
  REVIEW: {
    [STATUS.REVIEW.PENDING]: 'yellow',
    [STATUS.REVIEW.PUBLISHED]: 'green',
    [STATUS.REVIEW.UNPUBLISHED]: 'red',
  },
  BOOLEAN: {
    true: 'green',
    false: 'grey',
  },
};

export const QUERY_PARAM = {
  PAGE: 'page',
};

export const PAGINATION_ENTRY_KEY = {
  ORDERS: 'orders',
  BUSINESSES: 'restaurants',
  COURIERS: 'couriers',
  USERS: 'users',
  PRODUCTS: 'products',
  ENTRIES: 'entries',
};
