import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button, IconBox } from '@components';

const propTypes = {
  icon: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
const defaultProps = {
  className: '',
  disabled: false,
};

export const LayoutPaginationNavigator = ({
  disabled,
  icon,
  onClick,
  className,
}) => {
  return (
    <Button
      theme
      disabled={disabled}
      className={cx(
        'primary-1-with-hover text-[18px] transition-colors disabled:pointer-events-none disabled:text-grey-6',
        className
      )}
      onClick={onClick}
    >
      <IconBox icon={icon} />
    </Button>
  );
};

LayoutPaginationNavigator.propTypes = propTypes;
LayoutPaginationNavigator.defaultProps = defaultProps;
