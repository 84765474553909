import React from 'react';
import PropTypes from 'prop-types';

import { getSlots } from '@utils';

import { Render, Slot } from '@components';

const propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  image: '',
  title: '',
  children: null,
};

export const EmptyList = ({ image, title, children }) => {
  const slot = getSlots(children);

  return (
    <div className="flex flex-1 flex-col content-center gap-6">
      <Render if={image}>
        <img src={image} alt="" />
      </Render>
      <Slot slot={slot.title}>
        <p className="text-[18px] text-text-1">{title}</p>
      </Slot>
      <Slot slot={slot.link} />
    </div>
  );
};

EmptyList.propTypes = propTypes;
EmptyList.defaultProps = defaultProps;
