import React from 'react';
import { cva } from 'class-variance-authority';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { normalizeCardBrand } from '@utils';

import { IconBox } from '@components';
import * as cards from '@images/cards';

const variants = cva('', {
  variants: {
    shape: {
      card: 'w-9 h-6 rounded-[4px]',
      circle: 'h-12 w-12 rounded-full',
    },
    brand: {
      visa: 'bg-[#d8e3f3]',
      mastercard: 'bg-[#fdeace]',
    },
  },
});

const propTypes = {
  className: PropTypes.string,
  shape: PropTypes.oneOf(['card', 'circle']),
  brand: PropTypes.string,
};
const defaultProps = {
  className: '',
  shape: 'card',
  brand: null,
};

export const CardBrand = ({ className, shape, brand: unprocessedBrand }) => {
  const brand = normalizeCardBrand(unprocessedBrand);
  const circle = shape === 'circle';
  const cardBrand = cards[shape][brand];

  if (cardBrand) {
    return (
      <div
        className={cx(
          'inline-flex content-center',
          variants({ brand, shape, className })
        )}
      >
        <img className="max-h-full" src={cardBrand} alt={unprocessedBrand} />
      </div>
    );
  }

  if (circle) {
    return (
      <IconBox
        icon={faCreditCard}
        className={cx(
          'h-12 w-12 bg-primary-3 text-[24px] text-primary-1',
          variants({ shape: 'circle' }),
          className
        )}
      />
    );
  }

  /**
   * todo: add unknown card
   */
  return null;
};

CardBrand.propTypes = propTypes;
CardBrand.defaultProps = defaultProps;
