import { ACTION_TYPE, CONFIG, SUBSCRIPTION } from '@constants';

const params = new URLSearchParams(document.location.search);
const sidebarCollapsed = params.get('sidebarCollapsed');

const initialState = {
  configSidebarTab: CONFIG.KEY.FEATURES,
  sidebarCollapsed: !!sidebarCollapsed,
  subscriptionPeriod: SUBSCRIPTION.PERIOD.MONTH,
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SET_SIDEBAR_COLLAPSE: {
      const { collapsed } = action.payload;

      if (collapsed === null) {
        return { ...state, sidebarCollapsed: !state.sidebarCollapsed };
      }

      return { ...state, sidebarCollapsed: collapsed };
    }

    case ACTION_TYPE.SET_CONFIG_SIDEBAR_TAB: {
      return { ...state, configSidebarTab: action.payload.tab };
    }

    case ACTION_TYPE.SET_SUBSCRIPTION_PERIOD: {
      return {
        ...state,
        subscriptionPeriod: action.payload.subscriptionPeriod,
      };
    }

    default:
      return state;
  }
};
