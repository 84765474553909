import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { CONFIG } from '@constants';
import { createFeatureName, noop } from '@utils';

import { configFeaturesSelector } from '@selectors';

import { Field, SwitchContainer } from '@components';
import * as Config from '@components/Config';

const propTypes = {
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onSubmit: noop,
};

export const ConfigPagination = ({ onSubmit }) => {
  const { pagination } = useSelector(configFeaturesSelector);

  const getFieldName = createFeatureName(CONFIG.FEATURE.PAGINATION);

  const { register, handleSubmit } = useForm({ defaultValues: { pagination } });

  const handleFeatureSubmit = handleSubmit(({ pagination }) => {
    onSubmit({ name: getFieldName(), ...pagination });
  });

  return (
    <Config.Container title="Pagination" onSubmit={handleFeatureSubmit}>
      <div className="flex flex-col gap-6">
        <SwitchContainer title="Enable">
          <Field.Switch {...register(getFieldName('enabled'))} />
        </SwitchContainer>
        <Config.Row title="Entries per page">
          <Field.Input
            {...register(getFieldName('size'))}
            containerClassName="pb-0"
            type="number"
            inputMode="numeric"
          />
        </Config.Row>
      </div>
    </Config.Container>
  );
};

ConfigPagination.propTypes = propTypes;
ConfigPagination.defaultProps = defaultProps;
