import { Children } from 'react';

export const getSlots = (children) => {
  const collection = Children.toArray(children);

  const slots = collection.reduce((components, component) => {
    const { slot } = component.props ?? {};

    if (slot) {
      return { ...components, [slot]: component };
    }

    return components;
  }, {});

  slots.content = collection.filter((component) => !component.props?.slot);

  return slots;
};
