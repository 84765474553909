import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { PATH_ACTION } from '@constants';

import * as Page from '@pages';

const propTypes = {};
const defaultProps = {};

export const ProductRoutes = () => (
  <Routes>
    <Route path=":productId/*">
      <Route index element={<Page.Product />} />
      <Route path={PATH_ACTION.EDIT} element={<Page.ProductEdit />} />
    </Route>
    <Route path={PATH_ACTION.CREATE} element={<Page.ProductEdit />} />
  </Routes>
);

ProductRoutes.propTypes = propTypes;
ProductRoutes.defaultProps = defaultProps;
