import { MAPBOX_TOKEN } from '@constants';

// eslint-disable-next-line
import mapboxgl from '!mapbox-gl';

export const mapbox = {
  init: () => {
    mapboxgl.accessToken = MAPBOX_TOKEN;
  },
};

export { mapboxgl };
