import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ACTION_TYPE, MODEL, STATUS } from '@constants';
import { toast } from '@services';
import { as, getColor, getFullName, noop } from '@utils';

import { assignCourierToOrder, getCouriers } from '@actions';
import { createLoadingSelector } from '@selectors';

import { Avatar, Button, Theme, WithLoader } from '@components';

const courierAssigningSelector = createLoadingSelector(
  ACTION_TYPE.ORDER_ASSIGN_COURIER
);

const propTypes = {
  orderId: PropTypes.string.isRequired,
  restaurantId: PropTypes.string,
  setOrder: PropTypes.func.isRequired,
  onHide: PropTypes.func,
};
const defaultProps = {
  restaurantId: null,
  onHide: noop,
};

export const AssignCourierModal = ({
  orderId,
  restaurantId,
  setOrder,
  onHide,
}) => {
  const dispatch = useDispatch();
  const assigning = useSelector(courierAssigningSelector);

  const { t } = useTranslation();

  const [couriers, setCouriers] = useState(null);

  const loading = !couriers;
  const empty = !couriers?.length;

  useEffect(() => {
    dispatch(getCouriers())
      .then(({ list: couriers }) => {
        const filteredCouriers = couriers.filter(({ restaurants }) => {
          const restaurant = as
            .a(restaurants)
            .find(
              ({ id, courierStatus }) =>
                id === restaurantId && courierStatus === STATUS.COURIER.HIRED
            );

          return !!restaurant;
        });

        setCouriers(filteredCouriers);
      })
      .catch(noop);
  }, [restaurantId, dispatch]);

  const handleCourierAssign = (courierId) => async () => {
    try {
      const order = await dispatch(
        assignCourierToOrder({
          orderId,
          courierId,
          status: STATUS.ORDER.ASSIGN,
        })
      );

      setOrder(order);
      onHide();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const makeCourier = ({ id, avatar, firstName, lastName, status }) => {
    const verified = status === STATUS.VERIFICATION.VERIFIED;

    return (
      <div key={id} className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-4">
          <Avatar model={MODEL.USER} size={48} src={avatar} />
          <div>
            <p className="text-body-2-m text-grey-1">
              {getFullName(firstName, lastName)}
            </p>
            <div className="text-body-2-r text-grey-4">
              <span className={getColor.boolean(verified)}>
                {t(`status.${status}`)}
              </span>
            </div>
          </div>
        </div>
        <Button
          loading={assigning}
          disabled={!verified}
          onClick={handleCourierAssign(id)}
        >
          {t('web:button.assign')}
        </Button>
      </div>
    );
  };

  const renderCouriers = () => {
    if (empty) {
      return <p>No couriers</p>;
    }

    return couriers.map(makeCourier);
  };

  return (
    <div className="p-6">
      <Theme.Title className="mb-4" title={t('web:assignCourier')} />
      <WithLoader
        in={loading}
        className="relative flex min-h-[64px] flex-col gap-4"
      >
        {renderCouriers()}
      </WithLoader>
    </div>
  );
};

AssignCourierModal.propTypes = propTypes;
AssignCourierModal.defaultProps = defaultProps;
