import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { faBagShopping, faMoped } from '@fortawesome/pro-light-svg-icons';
import cx from 'classnames';

import {
  ACTION_TYPE,
  COLOR,
  ORDER_STATUS,
  PAGES_NAME,
  PAGINATION_ENTRY_KEY,
  SHIPPING,
} from '@constants';
import { useFetchList } from '@hooks';
import {
  getFormattedPriceWithCurrency,
  getFullName,
  makeFilterStatuses,
  noop,
  path,
} from '@utils';

import { getOrders, getRestaurants } from '@actions';
import { createFetchedSelector, restaurantsSelector } from '@selectors';

import {
  Badge,
  Filter,
  IconBox,
  Layout,
  Link,
  Render,
  Table,
  WithLoader,
} from '@components';
import { EmptyList } from '@components/EmptyList';
import * as emptyListImage from '@images/emptyList';

const restaurantsFetchedSelector = createFetchedSelector(
  ACTION_TYPE.GET_RESTAURANTS
);

const propTypes = {};
const defaultProps = {};

export const OrdersPage = () => {
  const dispatch = useDispatch();
  const restaurantsFetched = useSelector(restaurantsFetchedSelector);
  const restaurants = useSelector(restaurantsSelector);

  const { t } = useTranslation();

  const { noList, empty, list, loading, getList, onPageChange } = useFetchList({
    getList: getOrders,
  });

  useEffect(() => {
    if (!restaurantsFetched) {
      dispatch(getRestaurants()).catch(noop);
    }
  }, [restaurantsFetched, dispatch]);

  const statusFilterOptions = useMemo(
    () => makeFilterStatuses(ORDER_STATUS, t),
    [t]
  );

  const restaurantFilterOptions = useMemo(
    () =>
      restaurants.reduce(
        (restaurants, { id, name, location: { name: locationName } }) => ({
          ...restaurants,
          [id]: `${name}, ${locationName}`,
        }),
        {}
      ),
    [restaurants]
  );

  const shippingFilterOptions = useMemo(
    () =>
      Object.values(SHIPPING).reduce(
        (options, shipping) => ({
          ...options,
          [shipping]: t(`shipping.${shipping}`),
        }),
        {}
      ),
    [t]
  );

  const tableHead = (
    <tr>
      <Table.Head align="center">ID</Table.Head>
      <Table.Head className="capitalize">{t('role.customer')}</Table.Head>
      <Table.Head>{t('web:title.restaurant')}</Table.Head>
      <Table.Head align="center">{t('label.total')}</Table.Head>
      <Table.Head align="center">{t('title.shipping')}</Table.Head>
      <Table.Head align="center">{t('label.status')}</Table.Head>
    </tr>
  );

  const getShippingIcon = (shipping) => {
    switch (shipping) {
      case SHIPPING.DELIVERY:
        return faMoped;
      case SHIPPING.PICKUP:
        return faBagShopping;
      default:
        return null;
    }
  };

  const makeBodyRow = ({
    id,
    total,
    status,
    shipping,
    customer: { id: customerId, firstName, lastName },
    restaurant: { id: restaurantId, name: restaurantAddress, currency },
  }) => {
    const deleted = !customerId;
    const fullName = deleted
      ? t('anonymous')
      : getFullName(firstName, lastName);
    const normalizedStatus = t(`status.${status}`);

    return (
      <Table.Row key={id} to={path.order(id)} className="bg-white">
        <Table.Cell align="center" width={64} title={id}>
          {id}
        </Table.Cell>
        <Table.Cell
          width={160}
          title={fullName}
          className={cx({ italic: deleted })}
        >
          {fullName}
        </Table.Cell>
        <Table.Cell width={240} title={restaurantAddress}>
          <Link
            theme
            className="primary-1-with-hover text-ellipsis"
            to={path.restaurant(restaurantId)}
          >
            {restaurantAddress}
          </Link>
        </Table.Cell>
        <Table.Cell align="center" width={100}>
          {getFormattedPriceWithCurrency(total, currency)}
        </Table.Cell>
        <Table.Cell align="center" width={48}>
          <IconBox
            icon={getShippingIcon(shipping)}
            className="h-5 w-5 text-grey-1 icon-18"
          />
        </Table.Cell>
        <Table.Cell align="center" title={normalizedStatus} width={160}>
          <Badge color={COLOR.ORDER[status]}>{normalizedStatus}</Badge>
        </Table.Cell>
      </Table.Row>
    );
  };

  const tableBody = list?.map(makeBodyRow);

  const renderContent = () => {
    const emptyListTitle = noList
      ? t('web:empty.orders')
      : t('web:text.emptyFilterList');

    if (empty || noList) {
      return <EmptyList image={emptyListImage.order} title={emptyListTitle} />;
    }

    return (
      <div className="mt-4">
        <Table alias="table-redesign" head={tableHead} body={tableBody} />
      </div>
    );
  };

  return (
    <Layout
      paginationEnabled
      className="flex flex-1 flex-col"
      title={PAGES_NAME.ORDERS}
      onPageChange={onPageChange}
      paginationEntryName={PAGINATION_ENTRY_KEY.ORDERS}
    >
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <WithLoader in={loading}>
        <div className="flex justify-between">
          <Render if={!noList}>
            <Filter
              filters={{
                id: {
                  title: 'ID',
                },
                status: {
                  title: t('label.status'),
                  options: statusFilterOptions,
                },
                restaurantId: {
                  title: t('title.restaurant'),
                  options: restaurantFilterOptions,
                },
                shipping: {
                  title: t('title.shipping'),
                  options: shippingFilterOptions,
                },
              }}
              onFilter={getList}
            />
          </Render>
        </div>
        {renderContent()}
      </WithLoader>
    </Layout>
  );
};

OrdersPage.propTypes = propTypes;
OrdersPage.defaultProps = defaultProps;
