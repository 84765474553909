import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { mapboxgl } from '@services';

const Location = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
});

const propTypes = {
  customerLocation: Location,
  courierLocation: Location,
};
const defaultProps = {
  customerLocation: null,
  courierLocation: null,
};

export const OrderMap = ({ customerLocation, courierLocation }) => {
  const map = useRef(null);
  const mapContainerRef = useRef(null);
  const marker = useRef({ courier: null, customer: null });

  const getLngLat = ({ lng, lat }) => [lng, lat];

  const fitBounds = ({ courierLocation, customerLocation }) => {
    map.current.fitBounds(
      [getLngLat(courierLocation), getLngLat(customerLocation)],
      { padding: { top: 88, left: 24, right: 24, bottom: 48 } }
    );
  };

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: getLngLat(customerLocation),
      zoom: 12,
      attributionControl: false,
      cooperativeGestures: true,
      customAttribution: false,
    });

    map.current.on('load', () => {
      marker.current.courier = new mapboxgl.Marker({
        color: '#9647fe',
      })
        .setLngLat(getLngLat(courierLocation))
        .addTo(map.current);

      marker.current.customer = new mapboxgl.Marker({
        color: '#18a0fb',
      })
        .setLngLat(getLngLat(customerLocation))
        .addTo(map.current);

      fitBounds({ courierLocation, customerLocation });
    });

    return () => {
      map.current.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    marker.current.courier?.setLngLat(getLngLat(courierLocation));
    fitBounds({ courierLocation, customerLocation });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courierLocation, customerLocation]);

  return (
    <div className="relative mb-4">
      <div
        ref={mapContainerRef}
        className="flex h-[320px] overflow-hidden rounded-md"
      />
    </div>
  );
};

OrderMap.propTypes = propTypes;
OrderMap.defaultProps = defaultProps;
