export const localeLocalStorage = {
  name: 'locale',

  get locale() {
    return window.localStorage.getItem(this.name);
  },

  set locale(locale) {
    window.localStorage.setItem(this.name, locale);
  },
};
