import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { DOCUMENT } from '@constants';
import { noop } from '@utils';

import { Button, Modal, Modals } from '@components';

const propTypes = {
  status: PropTypes.oneOf([DOCUMENT.STATUS.APPROVE, DOCUMENT.STATUS.DECLINE]),
  message: PropTypes.string,
  setVerification: PropTypes.func,
};
const defaultProps = {
  status: null,
  message: '',
  setVerification: noop,
};

export const DocumentFlow = ({ status, message, setVerification }) => {
  const { t } = useTranslation();

  const handleApprove = () => {
    setVerification({ status: DOCUMENT.STATUS.APPROVE });
  };

  const handleDecline = (message) => {
    setVerification({ status: DOCUMENT.STATUS.DECLINE, message });
  };

  return (
    <div className="mt-4 flex justify-between gap-2">
      <Modal
        content={
          <Modals.Decline
            title="Decline reason"
            defaultValue={message}
            onDecline={handleDecline}
          />
        }
      >
        <Button
          className={cx(
            'flex-1 bg-grey-7 px-2 text-text-2 transition-colors hover:bg-other-6 hover:text-other-3',
            {
              '!text-other-3 !bg-other-6': status === DOCUMENT.STATUS.DECLINE,
            }
          )}
        >
          {t('button.decline')}
        </Button>
      </Modal>
      <Button
        className={cx(
          'flex-1 bg-grey-7 px-2 text-text-2 transition-colors hover:bg-secondary-2 hover:text-other-8',
          {
            '!text-other-8 !bg-secondary-2': status === DOCUMENT.STATUS.APPROVE,
          }
        )}
        onClick={handleApprove}
      >
        {t('button.approve')}
      </Button>
    </div>
  );
};

DocumentFlow.propTypes = propTypes;
DocumentFlow.defaultProps = defaultProps;
