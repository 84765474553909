import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ACTION_TYPE, CONFIG, PATH } from '@constants';
import { toast } from '@services';
import { useFeature } from '@hooks';
import {
  getBackendErrors,
  getFormErrors,
  noop,
  processField,
  setBackendErrors,
  validationSchema,
} from '@utils';

import { authLogin, cachePhone as cachePhoneAction } from '@actions';
import { cachedPhoneSelector, createLoadingSelector } from '@selectors';

import { Button, Field } from '@components';

const loadingSelector = createLoadingSelector(
  ACTION_TYPE.AUTH_LOGIN,
  ACTION_TYPE.AUTH_VERIFY_PHONE
);

const propTypes = {
  onSubmit: PropTypes.func,
};
const defaultProps = {
  onSubmit: noop,
};

export const LoginForm = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const phone = useSelector(cachedPhoneSelector);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { register, handleSubmit, formState, getValues, setError } = useForm({
    defaultValues: { phone },
  });
  const { enabled: twilio } = useFeature(CONFIG.FEATURE.TWILIO);

  const errors = getFormErrors(formState.errors);

  const cachePhone = () => {
    const { phone } = getValues();

    if (phone) {
      dispatch(cachePhoneAction(processField.phone(getValues().phone)));
    }
  };

  const handleSignupClick = () => {
    cachePhone();
    navigate({ pathname: PATH.SIGNUP, search });
  };

  const handleFormSubmit = handleSubmit(async (user) => {
    const payload = processField.form(user);

    try {
      const { code, message } = await dispatch(authLogin(payload));
      cachePhone();

      toast.success(message);

      if (!twilio) {
        toast.info(code);
      }

      onSubmit();
    } catch (error) {
      const { code } = getBackendErrors(error);

      toast.error(error.message);
      toast.error(code);

      setBackendErrors(error, { getValues, setError });
    }
  });

  return (
    <form onSubmit={handleFormSubmit}>
      <Field.Input
        {...register('phone', validationSchema.phone())}
        type="tel"
        requiredLabel
        inputMode="tel"
        label={t('label.phone')}
        prefix="+"
        placeholder="1 809 123 4567"
        error={errors.phone}
      />
      <Button loading={loading} type="submit" className="mt-4 w-full">
        {t('button.login')}
      </Button>
      <Button
        variant="ghost"
        className="mt-3 w-full"
        onClick={handleSignupClick}
      >
        {t('button.signup')}
      </Button>
    </form>
  );
};

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;
