import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useImmer } from 'use-immer';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { STRIPE } from '@constants';
import { toast } from '@services';
import { useTranslationWithPrefix } from '@hooks';
import { cn } from '@utils';

import { addPaymentMethod } from '@actions';
import { userIdSelector } from '@selectors';
import { stripeElementOptions } from '@content';

import { Button, Field, Theme, WithLoader } from '@components';
import stripePoweredImage from '@images/stripe-powered.png';

const propTypes = {
  onHide: PropTypes.func.isRequired,
};
const defaultProps = {};

export const AddCardForm = ({ onHide }) => {
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);

  const stripe = useStripe();
  const elements = useElements();
  const {
    t,
    createPrefix,
    i18n: { language },
  } = useTranslationWithPrefix();

  const [error, setError] = useImmer({});
  const [ready, setReady] = useImmer({});
  const [loading, setLoading] = useState(false);

  const elementsReady =
    ready[STRIPE.ELEMENT.CARD] &&
    ready[STRIPE.ELEMENT.EXPIRY] &&
    ready[STRIPE.ELEMENT.CVC];
  const addCardTranslations = t('web:generic.add', {
    title: t('paymentMethod.card'),
  });
  const getElementClassName = (error) =>
    cn(
      'overflow-hidden rounded-lg border border-grey-7 px-2 transition-colors focus-within:border-black',
      { 'border-other-3': !!error }
    );

  const tt = createPrefix('web:billing');

  useEffect(() => {
    if (elements) {
      elements.update({ locale: language });
    }
  }, [language, elements]);

  const handleElementChange = ({ error: { message } = {}, elementType }) => {
    setError((draft) => {
      // eslint-disable-next-line no-param-reassign
      draft[elementType] = message;
    });
  };

  const handleElementReady = (element) => () => {
    setReady((draft) => {
      // eslint-disable-next-line no-param-reassign
      draft[element] = true;
    });
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    try {
      setLoading(true);

      const result = await stripe.createToken(
        elements.getElement(CardNumberElement)
      );

      const message = result.error?.message;
      const token = result.token?.id;

      toast.warning(message);

      await dispatch(addPaymentMethod({ userId, token }));

      onHide();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Theme.Title className="mb-5" title={addCardTranslations} />
      <p className="flex items-center justify-between text-[14px] font-500 text-text-2">
        {tt('title.securePayment')}
        <img src={stripePoweredImage} alt="Powered by Stripe" />
      </p>
      <p className="mb-4 mt-2 text-[12px] leading-[18px] text-text-2">
        {tt('securePayment')}
      </p>
      <form onSubmit={handleSubmit}>
        <WithLoader in={!elementsReady} hideContent={false}>
          <Field.Container>
            <Field.Label required for="card">
              {tt('element', STRIPE.ELEMENT.CARD)}
            </Field.Label>
            <div className={getElementClassName(error[STRIPE.ELEMENT.CARD])}>
              <CardNumberElement
                onChange={handleElementChange}
                onReady={handleElementReady(STRIPE.ELEMENT.CARD)}
                options={stripeElementOptions}
              />
            </div>
            <Field.Error>{error.cardNumber}</Field.Error>
          </Field.Container>
          <div className="grid grid-cols-2 gap-x-4">
            <Field.Container>
              <Field.Label required for="exp">
                {tt('element', STRIPE.ELEMENT.EXPIRY)}
              </Field.Label>
              <div
                className={getElementClassName(error[STRIPE.ELEMENT.EXPIRY])}
              >
                <CardExpiryElement
                  onReady={handleElementReady(STRIPE.ELEMENT.EXPIRY)}
                  onChange={handleElementChange}
                  options={stripeElementOptions}
                />
              </div>
              <Field.Error>{error[STRIPE.ELEMENT.EXPIRY]}</Field.Error>
            </Field.Container>
            <Field.Container>
              <Field.Label required for="cvc">
                {tt('element', STRIPE.ELEMENT.CVC)}
              </Field.Label>
              <div
                className={cx(
                  getElementClassName(error[STRIPE.ELEMENT.EXPIRY])
                )}
              >
                <CardCvcElement
                  onReady={handleElementReady(STRIPE.ELEMENT.CVC)}
                  onChange={handleElementChange}
                  options={stripeElementOptions}
                />
              </div>
              <Field.Error>{error[STRIPE.ELEMENT.CVC]}</Field.Error>
            </Field.Container>
          </div>
        </WithLoader>
        <div className="mt-4 flex justify-end">
          <Button type="submit" loading={loading} disabled={!elementsReady}>
            {addCardTranslations}
          </Button>
        </div>
      </form>
    </div>
  );
};

AddCardForm.propTypes = propTypes;
AddCardForm.defaultProps = defaultProps;
