import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ReviewStatus } from '@types';
import { COLOR, MODEL, STATUS } from '@constants';
import { toast } from '@services';
import { useRole } from '@hooks';
import { getColor, noop, path } from '@utils';

import { setReviewStatus } from '@actions';

import {
  Avatar,
  Button,
  Link,
  Render,
  Theme,
  WithDotSeparator,
} from '@components';
import { RatingStar } from '@components/ui';

const propTypes = {
  createdAt: PropTypes.string.isRequired,
  reviewId: PropTypes.string.isRequired,
  status: ReviewStatus.isRequired,
  userName: PropTypes.string.isRequired,
  isAuthor: PropTypes.bool,
  avatar: PropTypes.string,
  review: PropTypes.string,
  managable: PropTypes.bool,
  orderId: PropTypes.string,
  rating: PropTypes.number,
  restaurantId: PropTypes.string,
  restaurantTitle: PropTypes.string,
  onStatusChange: PropTypes.func,
};
const defaultProps = {
  isAuthor: false,
  avatar: null,
  review: undefined,
  managable: false,
  orderId: undefined,
  rating: null,
  restaurantId: null,
  restaurantTitle: null,
  onStatusChange: noop,
};

export const Review = ({
  isAuthor,
  avatar,
  review,
  createdAt,
  reviewId,
  managable,
  orderId,
  rating,
  status,
  restaurantId,
  restaurantTitle,
  userName,
  onStatusChange,
}) => {
  /**
   * Required to update dayjs locale on user locale change
   */
  const { t } = useTranslation();
  const { operator } = useRole();

  const pending = status === STATUS.REVIEW.PENDING;

  const handleStatusChange = (status) => async () => {
    try {
      const { message, review } = await setReviewStatus(reviewId, status);

      toast.success(message);

      onStatusChange(review);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getStatusColor = (comparingStatus) => {
    const DEFAULT_COLOR = 'grey-4';
    const color =
      comparingStatus === status ? COLOR.REVIEW[status] : DEFAULT_COLOR;

    if (pending) {
      return getColor(COLOR.REVIEW[comparingStatus]);
    }

    return getColor(color, { defaultColor: DEFAULT_COLOR });
  };

  const renderButton = (status, text) => (
    <Button
      theme
      className={cx('h-6', getStatusColor(status))}
      onClick={handleStatusChange(status)}
    >
      {text}
    </Button>
  );

  return (
    <Theme.Container>
      <div className="flex items-center gap-4">
        <Avatar src={avatar} model={MODEL.USER} size={64} />
        <div className="flex flex-1 items-center justify-between">
          <div>
            <p className="text-body-1-m text-grey-1">{userName}</p>
            <Render if={rating}>
              <RatingStar multi rating={rating} />
            </Render>
          </div>
          <Render if={isAuthor && pending}>
            <p className={cx('text-body-2-m', getColor(COLOR.REVIEW[status]))}>
              {t(`status.${status}`)}
            </p>
          </Render>
          <Render if={managable}>
            <div className="flex items-center text-body-2-m">
              {renderButton(STATUS.REVIEW.PUBLISHED, t('button.approve'))}
              <WithDotSeparator>
                {renderButton(STATUS.REVIEW.UNPUBLISHED, t('button.decline'))}
              </WithDotSeparator>
            </div>
          </Render>
        </div>
      </div>
      <div className="pl-20">
        <p className="text-body-1-r">{review}</p>
        <div className="mt-2 flex w-full items-center justify-between gap-2 text-body-2-m">
          <Render if={operator && orderId}>
            <Link
              theme
              className="primary-1-with-hover transition-colors"
              to={path.order(orderId)}
            >
              {orderId}
            </Link>
          </Render>
          <Render if={operator && restaurantId}>
            <WithDotSeparator hidden={!orderId} className="mx-0">
              <Link
                theme
                className="primary-1-with-hover transition-colors"
                to={path.restaurant(restaurantId)}
              >
                {restaurantTitle}
              </Link>
            </WithDotSeparator>
          </Render>
          <p className="ml-auto text-grey-3">
            {dayjs(createdAt).format('DD MMMM YYYY')}
          </p>
        </div>
      </div>
    </Theme.Container>
  );
};

Review.propTypes = propTypes;
Review.defaultProps = defaultProps;
