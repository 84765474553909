import React from 'react';
import { cva } from 'class-variance-authority';
import PropTypes from 'prop-types';

import { ToastType } from '@types';
import { TOAST_TYPE } from '@constants';
import { cn } from '@utils';

const variants = cva('flex', {
  variants: {
    type: {
      [TOAST_TYPE.ERROR]: 'text-other-3',
      [TOAST_TYPE.INFO]: 'text-other-9',
      [TOAST_TYPE.SUCCESS]: 'text-other-8',
      [TOAST_TYPE.WARNING]: 'text-other-5',
    },
  },
});

const propTypes = {
  children: PropTypes.node.isRequired,
  type: ToastType,
};
const defaultProps = {
  type: TOAST_TYPE.INFO,
};

export const Toast = ({ type, children }) => {
  return <div className={cn(variants({ type }))}>{children}</div>;
};

Toast.propTypes = propTypes;
Toast.defaultProps = defaultProps;
