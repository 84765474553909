import { ACTION_TYPE } from '@constants';
import { axios } from '@services';
import { reduce } from '@utils';

export const cacheSubscriptionPlans = (subscriptionPlans) =>
  reduce.set(ACTION_TYPE.SET_SUBSCRIPTION_PLANS, { subscriptionPlans });

export const getSubscriptionPlans = () => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.GET_SUBSCRIPTION_PLANS
  );

  dispatch(request);

  return axios
    .get('/subscriptionPlans')
    .then(({ subscriptionPlans }) => {
      dispatch(cacheSubscriptionPlans(subscriptionPlans));
      dispatch(success);

      return Promise.resolve({ subscriptionPlans });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const updateSubscriptionPlan =
  (subscriptionPlanId, payload) => (dispatch) => {
    const [{ request, success, failure }] = reduce.get(
      ACTION_TYPE.UPDATE_SUBSCRIPTION_PLAN
    );

    dispatch(request);

    return axios
      .put(`/subscriptionPlans/${subscriptionPlanId}`, payload)
      .then(({ message, subscriptionPlans }) => {
        dispatch(cacheSubscriptionPlans(subscriptionPlans));
        dispatch(success);

        return Promise.resolve({ message, subscriptionPlans });
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const getBillingHistory = (userId) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.GET_BILLING_HISTORY
  );

  dispatch(request);

  return axios
    .get(`/users/${userId}/payments`)
    .then(({ payments }) => {
      dispatch(success);

      return Promise.resolve(payments);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};
