import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import { useForceUpdate } from '@hooks';
import { noop, stripe } from '@utils';

import { AddCardForm } from './AddCardForm';

const propTypes = {
  onHide: PropTypes.func,
};
const defaultProps = {
  onHide: noop,
};

export const AddCardModal = ({ onHide }) => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    stripe.init();

    /**
     * Force update to update `stripe.promise` value
     */
    forceUpdate();
  }, [forceUpdate]);

  return (
    <div className="p-6">
      <Elements stripe={stripe.promise}>
        <AddCardForm onHide={onHide} />
      </Elements>
    </div>
  );
};

AddCardModal.propTypes = propTypes;
AddCardModal.defaultProps = defaultProps;
