import React from 'react';
import { Helmet } from 'react-helmet';

const propTypes = {};
const defaultProps = {};

export const TermsPage = () => {
  return (
    <div className="terms-page">
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <div>
        <h1>Privacy policies</h1>
        <h2>What information do we collect?</h2>
        <p>
          Information about your computer, and about your visits to and use of
          this website, including your IP address, geographic location, browser
          type, source of referral to the site, duration of visits, and number
          of page views. Information that you provide us for the purpose of
          registering on our website including email, name, surname, place and
          country of residence, address, telephone, avatar and password to enter
          the site. Any other information that you choose to send to us.
        </p>
        <h2>Using your personal data</h2>
        <p>
          The personal data entered on this website will be used for the
          purposes specified in these privacy policies or in the relevant
          sections of the website. We may use your personal information to:
        </p>
        <ul>
          <li>
            <p>Manage the website.</p>
          </li>
          <li>
            <p>
              - Improve your browsing experience by customizing the website.
            </p>
          </li>
          <li>
            <p>Enable you to use the services available on the website.</p>
          </li>
          <li>
            <p>
              Send you general commercial communications. - Send you email
              notifications specifically requested by you.
            </p>
          </li>
          <li>
            <p>
              Send you our newsletter and other website-related communications
              that we think will be of interest to you, where you specifically
              agreed to this, by email.
            </p>
          </li>
          <li>
            <p>
              You can inform us at any time if you do not wish to receive
              commercial communications. You can optionally modify your
              preferences in your account settings under the &quot;Receive
              Newsletters&quot; option, or click on the lower link
              &quot;Unsubscribe&quot; in the service&apos;s emails to
              unsubscribe from the newsletter. - Provide third parties
              (including other users of the website) with statistical
              information about users, this information will not be used to
              identify any particular individual.
            </p>
          </li>
          <li>
            <p>
              Dealing with queries and complaints by or about you related to the
              website.
            </p>
          </li>
          <p>
            We will not provide your personal information to any third party for
            the purpose of direct marketing without your express consent. If an
            online payment is made, at no time do we store the credit card data;
            this task is performed by online payment providers.
          </p>
        </ul>
        <h2>Data Visibility</h2>
        <p>
          The username and avatar will be visible in the event that a user makes
          a comment to a particular restaurant. We may display the user&apos;s
          name with their new registration and their last order to a location in
          the Orders section of our website.
        </p>
        <h2>Statements</h2>
        <p>
          We must disclose information about you to any of our employees,
          agents, as reasonably necessary for the purposes set forth in this
          privacy policy. In addition, we must disclose information about you:
        </p>
        <ul>
          <li>
            <p>To the extent that we are required to do so by law.</p>
          </li>
          <li>
            <p>
              In connection with any legal proceeding or potential legal
              proceeding.
            </p>
          </li>
          <li>
            <p>
              To establish, exercise or defend our legal rights (including
              providing information to others for fraud prevention purposes).
              Except as provided in this privacy policy, we will not provide
              your personal information to third parties.
            </p>
          </li>
        </ul>
        <h2>Cookies</h2>
        <p>
          A cookie consists of information sent by a web server to a web
          browser, and stored by the browser. The information is then sent back
          to the server each time the browser requests a page from the server.
          This enables the server to identify and track the web browser. We will
          use both session cookies and persistent cookies on the website. We
          will use session cookies to keep track of you as you navigate through
          the site and/or application. We will use persistent cookies to enable
          our website to recognize you when you visit our site. Session cookies
          will be removed from your device when you close your web browser.
          Persistent cookies will remain stored on your device until they are
          deleted, or until they reach a specified expiration date. We use
          Google Analytics to analyze the use of this website. Google Analytics
          generates statistical and other information about the use of websites
          through cookies, which are stored on the user&apos;s device. The
          information generated in connection with our website is used to create
          reports on the use of our site. Google will store this information.
          Google&apos;s privacy policies are available at:
          <a
            className="space-before primary-1-with-hover"
            href="http://www.google.com/privacypolicy.html"
          >
            http://www.google.com/privacypolicy.html
          </a>
          . Most web browsers allow you to decline to accept cookies. This is
          achieved through the settings of each particular browser. This
          however, will have a negative impact regarding the usability of
          various websites including this one.
        </p>
        <h2>Security of your personal data</h2>
        <p>
          We will take reasonable technical and organizational precautions to
          prevent the loss, misuse or alteration of your personal information.
          We will store all personal information you provide on secure servers
          behind password-protected firewalls. Data transmission over the
          Internet is inherently insecure, and we cannot guarantee the security
          of data sent over the Internet. You are responsible for keeping your
          password and user details confidential.
        </p>
        <h2>Policy Corrections</h2>
        <p>
          We may update this privacy policy from time to time by posting the new
          version on our website. You should check this page from time to time
          to ensure that you agree to any changes. We will also notify you of
          changes to our privacy policy by email.
        </p>
        <h2>Your rights</h2>
        <p>
          You may notify us not to process your personal data for marketing
          purposes by email at any time. If no objection has been received from
          you, then we take this as your consent to any of our actions.
        </p>
        <h2>Third Party Websites</h2>
        <p>
          The website contains links to other websites. We are not responsible
          for the privacy policies or practices of other websites.
        </p>
        <h2>Update of information</h2>
        <p>
          If your information has changed, you are responsible for changing it
          on the website. This will allow the proper functioning of the site.
        </p>
        <h2>Contact</h2>
        <p>
          If you have any questions about these privacy policies or our
          treatment of your personal data, please email us at
          <a
            className="space-before primary-1-with-hover"
            href="mailto:akicomoapp@gmail.com"
          >
            akicomoapp@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
};

TermsPage.propTypes = propTypes;
TermsPage.defaultProps = defaultProps;
