import get from 'lodash.get';
import set from 'lodash.set';

import { as } from '@utils';

export const onboardingLocalStorage = {
  name: 'onboarding',

  getUsers(userId) {
    const users = as.o(JSON.parse(window.localStorage.getItem(this.name)));

    if (userId) {
      return get(users, userId, false);
    }

    return users;
  },

  hide(userId) {
    const users = this.getUsers();

    set(users, userId, true);

    window.localStorage.setItem(this.name, JSON.stringify(users));
  },
};
