import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { CONFIG } from '@constants';
import { createFeatureName, noop } from '@utils';

import { configFeaturesSelector } from '@selectors';

import { Field, SwitchContainer } from '@components';
import * as Config from '@components/Config';

const propTypes = {
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onSubmit: noop,
};

export const ConfigMaintenance = ({ onSubmit }) => {
  const { maintenance } = useSelector(configFeaturesSelector);

  const getFieldName = createFeatureName(CONFIG.FEATURE.MAINTENANCE);

  const { register, handleSubmit } = useForm({
    defaultValues: { maintenance },
  });

  const handleFeatureSubmit = handleSubmit(({ maintenance }) => {
    onSubmit({ name: getFieldName(), ...maintenance });
  });

  return (
    <Config.Container
      title="Maintenance"
      subtitle="Display maintenance screen and page"
      onSubmit={handleFeatureSubmit}
    >
      <SwitchContainer title="Enable">
        <Field.Switch {...register(getFieldName('enabled'))} />
      </SwitchContainer>
    </Config.Container>
  );
};

ConfigMaintenance.propTypes = propTypes;
ConfigMaintenance.defaultProps = defaultProps;
