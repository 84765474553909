import { ACTION_TYPE } from '@constants';
import { axios } from '@services';
import { reduce } from '@utils';

import { cacheRestaurants } from '@actions';

export const addRestaurant = (payload) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.ADD_RESTAURANT
  );

  dispatch(request);

  return axios
    .post('/restaurants', payload)
    .then(({ restaurant }) => {
      dispatch(success);

      return Promise.resolve(restaurant);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const deleteRestaurant = (restaurantId) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.DELETE_RESTAURANT
  );
  const data = { deleted: true };

  dispatch(request);

  return axios
    .delete(`/restaurants/${restaurantId}`, { data })
    .then(({ restaurant }) => {
      dispatch(success);

      return Promise.resolve(restaurant);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const restoreRestaurant = (restaurantId) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.RESTORE_RESTAURANT
  );

  dispatch(request);

  return axios
    .put(`/restaurants/${restaurantId}`, { deleted: false })
    .then(({ restaurant }) => {
      dispatch(success);

      return Promise.resolve(restaurant);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const updateRestaurant = (payload, restaurantId) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.UPDATE_RESTAURANT
  );

  dispatch(request);

  return axios
    .post(`/restaurants/${restaurantId}`, payload)
    .then(({ restaurant }) => {
      dispatch(success);

      return Promise.resolve(restaurant);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const getRestaurants = (params) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.GET_RESTAURANTS
  );

  dispatch(request);

  return axios
    .get('/restaurants', { params })
    .then(({ restaurants, pagination }) => {
      dispatch(success);

      dispatch(cacheRestaurants(restaurants));

      return Promise.resolve({ list: restaurants, pagination });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const getRestaurantById = (restaurantId) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.GET_RESTAURANT
  );

  dispatch(request);

  return axios
    .get(`/restaurants/${restaurantId}`)
    .then(({ restaurant }) => {
      dispatch(success);

      return Promise.resolve(restaurant);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const getCouriers = (params) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.GET_COURIER);

  dispatch(request);

  return axios
    .get('/couriers', { params })
    .then(({ couriers, pagination }) => {
      dispatch(success);

      return Promise.resolve({ list: couriers, pagination });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const getCourierByPhone = (phone) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.GET_COURIER);

  dispatch(request);

  return axios
    .get('/couriers', { params: { phone } })
    .then(({ courier }) => {
      dispatch(success);

      return Promise.resolve(courier);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const getCourierById = (courierId) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.GET_COURIER);

  dispatch(request);

  return axios
    .get(`/couriers/${courierId}`)
    .then(({ courier }) => {
      dispatch(success);

      return Promise.resolve(courier);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const inviteCourierToRestaurant =
  (courierId, restaurantId) => (dispatch) => {
    const [{ request, success, failure }] = reduce.get(
      ACTION_TYPE.INVITE_COURIER_TO_RESTAURANT
    );

    dispatch(request);

    return axios
      .post(`/couriers/${courierId}/invite`, { restaurantId })
      .then(({ message }) => {
        dispatch(success);

        return Promise.resolve(message);
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const getReviews = (params) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.GET_REVIEWS);

  dispatch(request);

  return axios
    .get('/reviews', { params: { ...params, desc: 'created_at' } })
    .then(({ reviews, pagination }) => {
      dispatch(success);

      return Promise.resolve({ list: reviews, pagination });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const getDishes = (restaurantId, params) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.GET_DISHES);

  dispatch(request);

  return axios
    .get(`/restaurants/${restaurantId}/dishes`, { params })
    .then(({ dishes, pagination }) => {
      dispatch(success);

      return Promise.resolve({ list: dishes, pagination });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const getDishById =
  ({ restaurantId, dishId }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(ACTION_TYPE.GET_DISH);

    dispatch(request);

    return axios
      .get(`/restaurants/${restaurantId}/dishes/${dishId}`)
      .then(({ dish }) => {
        dispatch(success);

        return Promise.resolve(dish);
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const createDish =
  ({ payload, restaurantId }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(ACTION_TYPE.CREATE_DISH);

    dispatch(request);

    return axios
      .post(`/restaurants/${restaurantId}/dishes`, payload)
      .then(({ dish }) => {
        dispatch(success);

        return Promise.resolve(dish);
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const updateDish =
  ({ payload, restaurantId, dishId }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(ACTION_TYPE.UPDATE_DISH);

    dispatch(request);

    return axios
      .post(`/restaurants/${restaurantId}/dishes/${dishId}`, payload)
      .then(({ dish }) => {
        dispatch(success);

        return Promise.resolve(dish);
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const deleteDish =
  ({ restaurantId, dishId }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(ACTION_TYPE.DELETE_DISH);

    dispatch(request);

    return axios
      .delete(`/restaurants/${restaurantId}/dishes/${dishId}`)
      .then(({ dish }) => {
        dispatch(success);

        return Promise.resolve(dish);
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const restoreDish =
  ({ restaurantId, dishId }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(
      ACTION_TYPE.RESTORE_DISH
    );

    dispatch(request);

    return axios
      .put(`/restaurants/${restaurantId}/dishes/${dishId}`, { deleted: false })
      .then(({ dish }) => {
        dispatch(success);

        return Promise.resolve(dish);
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const getOrders = (params) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.GET_ORDERS);

  dispatch(request);

  return axios
    .get('/orders', {
      params: { ...params, desc: 'id' },
    })
    .then(({ orders, pagination }) => {
      dispatch(success);

      return Promise.resolve({ list: orders, pagination });
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const getOrderById = (orderId) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(ACTION_TYPE.GET_ORDER);

  dispatch(request);

  return axios
    .get(`/orders/${orderId}`)
    .then(({ order }) => {
      dispatch(success);

      return Promise.resolve(order);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const fireCourier =
  ({ courierId, restaurantId }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(
      ACTION_TYPE.FIRE_COURIER
    );
    dispatch(request);

    return axios
      .delete(`/restaurants/${restaurantId}/couriers/${courierId}`)
      .then(({ courier }) => {
        dispatch(success);

        return Promise.resolve(courier);
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };

export const setReviewStatus = (reviewId, status) =>
  axios
    .patch(`/reviews/${reviewId}`, { status })
    .then(({ message, review }) => Promise.resolve({ message, review }))
    .catch((error) => Promise.reject(error));
