import React from 'react';
import PropTypes from 'prop-types';

import { isNumber, tv } from '@utils';

const variants = tv({
  base: 'px-2 py-4',
  variants: {
    truncate: {
      true: 'truncate',
    },
  },
});

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  truncate: PropTypes.bool,
  children: PropTypes.node,
};
const defaultProps = {
  className: '',
  title: '',
  align: 'left',
  width: 'collapse',
  truncate: true,
  children: null,
};

export const TableCell = ({
  className,
  title,
  align,
  width,
  truncate,
  children,
}) => {
  const getNormalizedWidth = () => {
    if (isNumber(width)) {
      return `${width}px`;
    }

    if (width === 'icon') {
      return '80px';
    }

    if (width === 'collapse') {
      return '0%';
    }

    return undefined;
  };

  return (
    <td
      className="border-y border-grey-6 align-middle text-subtitle first:rounded-l-xl first:border-l first:pl-2 last:rounded-r-xl last:border-r last:pr-2"
      title={title}
      align={align}
      style={{
        width: getNormalizedWidth(),
        maxWidth: getNormalizedWidth(),
      }}
    >
      <div className={variants({ truncate, className })}>{children ?? '-'}</div>
    </td>
  );
};

TableCell.propTypes = propTypes;
TableCell.defaultProps = defaultProps;
