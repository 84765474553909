import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { cn } from '@utils';

import { Render } from '@components';

const propTypes = {
  icon: PropTypes.shape(),
  className: PropTypes.string,
};

const defaultProps = {
  icon: null,
  className: '',
};

export const IconBox = ({ className, icon, ...props }) => (
  <Render if={icon}>
    <span className={cn('flex h-6 w-6 items-center justify-center', className)}>
      <FontAwesomeIcon {...props} icon={icon} />
    </span>
  </Render>
);

IconBox.propTypes = propTypes;
IconBox.defaultProps = defaultProps;
