import React from 'react';
import { useTranslation } from 'react-i18next';
import { cva } from 'class-variance-authority';
import PropTypes from 'prop-types';

import { cn } from '@utils';

import { Transition } from '@components';

const variants = cva(
  'absolute inset-x-0 bottom-1 w-full overflow-ellipsis text-label-1 text-other-3 truncate',
  {
    variants: {
      variant: {
        absolute: 'absolute',
        static: 'static',
      },
    },
    defaultVariants: {
      variant: 'absolute',
    },
  }
);

const propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  variant: PropTypes.oneOf(['static', 'absolute']),
  children: PropTypes.string,
};

const defaultProps = {
  className: '',
  error: null,
  variant: 'absolute',
  children: null,
};

export const FieldError = ({ className, error, variant, children }) => {
  const { t } = useTranslation('backend');

  const content = t(children || error);

  return (
    <Transition mountOnEnter unmountOnExit in={!!content} className="fade">
      <p
        // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
        className={cn(variants({ variant, className }))}
        title={content}
      >
        {content}
      </p>
    </Transition>
  );
};

FieldError.displayName = 'Field.Error';
FieldError.propTypes = propTypes;
FieldError.defaultProps = defaultProps;
