import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import { useTranslationWithPrefix } from '@hooks';

import { authSlideImages } from '@content';

import { Theme } from '@components';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const propTypes = {};
const defaultProps = {};

export const SideIntro = () => {
  const { createPrefix } = useTranslationWithPrefix();

  const getT = createPrefix('web:intro');

  const makeSlide = (image, index) => (
    <div
      key={image}
      className="relative mx-auto max-w-[630px] text-center text-white"
    >
      <img src={image} alt="Side intro slide" />
      <div className="mx-auto mb-4 mt-10 rounded-xl bg-black/5 p-6">
        <h4 className="text-title-1 text-white">{getT(index, 'title')}</h4>
        <p className="mt-6 text-body-1-r text-grey-6">{getT(index, 'text')}</p>
      </div>
    </div>
  );

  return (
    <div className="">
      <div className="h-full rounded-xl bg-primary-1 px-10 py-8">
        <Theme.Logo
          className="text-white"
          iconContainerClassName="h-[42px] w-[42px] bg-white text-primary-1"
        />
        <Carousel
          className="w-full pb-10 pt-6"
          autoPlay
          stopOnHover
          swipeable
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          interval={15000}
        >
          {authSlideImages.map(makeSlide)}
        </Carousel>
      </div>
    </div>
  );
};

SideIntro.propTypes = propTypes;
SideIntro.defaultProps = defaultProps;
