import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { noop } from '@utils';

import { Button } from '@components';

const propTypes = {
  activeTab: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
};
const defaultProps = {
  activeTab: '',
  tabs: [],
  onChange: noop,
};

export const TabSwitcher = ({ activeTab, tabs, onChange }) => {
  const handleTabChange = (value) => () => {
    onChange(value);
  };

  const makeTab = ({ title, value }) => {
    const active = value === activeTab;

    return (
      <Button
        theme
        key={value}
        className={cx(
          'rounded-sm bg-transparent px-8 text-[12px] capitalize text-grey-3 transition-colors',
          {
            'text-primary-1 bg-white': active,
          }
        )}
        onClick={handleTabChange(value)}
      >
        {title}
      </Button>
    );
  };

  return (
    <div className="flex h-6 rounded-md bg-grey-7 p-0.5">
      {tabs.map(makeTab)}
    </div>
  );
};

TabSwitcher.propTypes = propTypes;
TabSwitcher.defaultProps = defaultProps;
