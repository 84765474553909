import { ACTION_TYPE } from '@constants';

const initialState = {};

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPE.SET_USER:
      return { ...state, ...payload.user };

    case ACTION_TYPE.SET_USER_BILLING:
      return {
        ...state,
        billing: { ...state.billing, ...payload.billing },
      };

    case ACTION_TYPE.RESET_USER:
      return initialState;

    default:
      return state;
  }
};
