export const PACKAGE_VERSION = process.env.REACT_APP_PACKAGE_VERSION ?? '';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN ?? '';
export const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? '';
export const TRANSLATIONS_URL = process.env.REACT_APP_TRANSLATIONS_URL || '';
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN ?? '';

export const PRODUCTION = !!process.env.REACT_APP_PRODUCTION;
export const STAGING = !!process.env.REACT_APP_STAGING;
export const LOCAL = !PRODUCTION && !STAGING;
export const IGNORE_MAINTENANCE = !!process.env.REACT_APP_IGNORE_MAINTENANCE;

export const APPLE_ID = {
  CLIENT: process.env.REACT_APP_CLIENT_APPLE_ID,
  COURIER: process.env.REACT_APP_COURIER_APPLE_ID,
};
export const ANDROID_ID = {
  CLIENT: process.env.REACT_APP_CLIENT_ANDROID_ID,
  COURIER: process.env.REACT_APP_COURIER_ANDROID_ID,
};

export const PUSHER = {
  CLUSTER: process.env.REACT_APP_PUSHER_CLUSTER,
  ID: process.env.REACT_APP_PUSHER_ID,
  KEY: process.env.REACT_APP_PUSHER_KEY || '',
  SECRET: process.env.REACT_APP_PUSHER_SECRET,
};

export const STRIPE = {
  TOKEN: process.env.REACT_APP_STRIPE_TOKEN,
  ELEMENT: {
    CARD: 'cardNumber',
    EXPIRY: 'cardExpiry',
    CVC: 'cardCvc',
  },
};

export const FIREBASE = {
  CONFIG: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MASSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
};
