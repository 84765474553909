import React from 'react';
import PropTypes from 'prop-types';

import { SpinnerChase, Transition } from '@components';

const propTypes = {
  in: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hideContent: PropTypes.bool,
};
const defaultProps = {
  className: 'relative flex flex-col flex-1',
  hideContent: true,
};

export const WithLoader = ({
  in: loading,
  className,
  hideContent,
  children,
}) => {
  const content = loading && hideContent ? <div /> : children;

  return (
    <div className={className}>
      <Transition mountOnEnter unmountOnExit in={loading} className="fade">
        <div className="absolute inset-0 z-40 flex content-center rounded-xl bg-white">
          <SpinnerChase className="h-12 w-12" />
        </div>
      </Transition>
      {content}
    </div>
  );
};

WithLoader.propTypes = propTypes;
WithLoader.defaultProps = defaultProps;
