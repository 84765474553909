import React, { Children, cloneElement, useCallback, useState } from 'react';
import ModalComponent from '@restart/ui/Modal';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';

import { cn } from '@utils';

import { Button, IconBox, Render, Transition } from '@components';

const propTypes = {
  defaultOpen: PropTypes.bool,
  preventClose: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Use it for default button component
   *
   * todo: add shared button type
   */
  button: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  content: PropTypes.node,
  children: PropTypes.node,
};
const defaultProps = {
  defaultOpen: false,
  preventClose: false,
  className: null,
  button: null,
  content: null,
  children: null,
};

export const Modal = ({
  defaultOpen,
  preventClose,
  className,
  button,
  content,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const modalContent = content ?? children;

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const closeIcon = (
    <Render if={!preventClose}>
      <Button
        theme
        className="absolute right-4 top-4 text-grey-4 transition-colors hover:text-grey-5 active:text-grey-3"
        onClick={handleClose}
      >
        <IconBox icon={faCircleXmark} className="icon-22" />
      </Button>
    </Render>
  );

  const renderModalTrigger = () => {
    if (typeof button === 'string') {
      return <Button onClick={handleOpen}>{button}</Button>;
    }

    /**
     * todo: add modal trigger for `button` as `node`
     */

    if (children) {
      return cloneElement(children, { onClick: handleOpen });
    }

    return null;
  };

  const renderBackdrop = (backdropProps) => {
    const props = !preventClose && backdropProps;

    return (
      <div {...props} className="fixed inset-0 z-10 bg-black opacity-50" />
    );
  };

  return (
    <>
      <Transition mountOnEnter unmountOnExit in={open} className="fade">
        <ModalComponent
          role="dialog"
          show={open}
          className={cn(
            'absolute left-1/2 top-1/2 z-20 max-h-full w-full max-w-[640px] -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white',
            className
          )}
          renderBackdrop={renderBackdrop}
          onHide={handleClose}
        >
          <div>
            {closeIcon}
            {Children.map(modalContent, (child) =>
              cloneElement(child, { ...props, onHide: handleClose })
            )}
          </div>
        </ModalComponent>
      </Transition>
      {renderModalTrigger()}
    </>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;
