import { isObject, removeEmptyProperties } from '@utils';

export const getNormalizedParams = (params) => {
  if (isObject(params)) {
    const paramEntries = Object.entries(params).reduce(
      (result, [key, value]) => ({ ...result, [key]: value }),
      {}
    );

    return removeEmptyProperties(paramEntries, '');
  }

  return undefined;
};
