import React from 'react';
import { useDispatch } from 'react-redux';

import { CONFIG } from '@constants';
import { toast } from '@services';

import { updateConfig } from '@actions';

import * as Config from '@components/Config';

const propTypes = {};
const defaultProps = {};

export const ConfigFeatures = () => {
  const dispatch = useDispatch();

  const handleSubmit = async ({
    type = CONFIG.TYPE.FEATURE,
    name,
    ...value
  }) => {
    try {
      const { message } = await dispatch(
        updateConfig({
          type,
          name,
          value,
        })
      );

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Config.Versions onSubmit={handleSubmit} />
      <Config.Maintenance onSubmit={handleSubmit} />
      <Config.Twilio onSubmit={handleSubmit} />
      <Config.Pagination onSubmit={handleSubmit} />
      <Config.Onboarding onSubmit={handleSubmit} />
    </>
  );
};

ConfigFeatures.propTypes = propTypes;
ConfigFeatures.defaultProps = defaultProps;
