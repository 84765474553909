import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Flag as FlagImage } from '@images';

const propTypes = {
  flag: PropTypes.string.isRequired,
  className: PropTypes.string,
};
const defaultProps = {
  className: '',
};

export const Flag = ({ flag, className, ...props }) => (
  <img {...props} className={cx(className)} src={FlagImage[flag]} alt={flag} />
);

Flag.propTypes = propTypes;
Flag.defaultProps = defaultProps;
