import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  list: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
  as: PropTypes.string,
};
const defaultProps = {
  className: '',
  as: 'li',
};

export const TableList = ({ list, className, as: Tag }) => {
  if (!list.length) {
    return '-';
  }

  return (
    <>
      {list.map((item) => (
        <Tag key={item} className={className}>
          {item}
        </Tag>
      ))}
    </>
  );
};

TableList.propTypes = propTypes;
TableList.defaultProps = defaultProps;
