import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { OrderStatus, Shipping } from '@types';
import { ACTION_TYPE, ORDER_STATUS, SHIPPING } from '@constants';
import { toast } from '@services';

import { updateOrderStatus as updateOrderStatusAction } from '@actions';
import { createLoadingSelector } from '@selectors';

import { Button, Render } from '@components';

const orderStatusUpdatingSelector = createLoadingSelector(
  ACTION_TYPE.UPDATE_ORDER_STATUS
);

const propTypes = {
  orderId: PropTypes.string.isRequired,
  shipping: Shipping,
  status: OrderStatus,
};
const defaultProps = {
  shipping: SHIPPING.DELIVERY,
  status: null,
};

export const OrderActions = ({ orderId, shipping, status }) => {
  const dispatch = useDispatch();
  const updating = useSelector(orderStatusUpdatingSelector);

  const { t } = useTranslation();

  const pickup = shipping === SHIPPING.PICKUP;
  const accepted = status === ORDER_STATUS.ACCEPTED;
  const delivering = status === ORDER_STATUS.DELIVERING;
  const opened = [ORDER_STATUS.CREATED].includes(status);
  const closed = [
    ORDER_STATUS.CANCELED,
    ORDER_STATUS.DECLINED,
    ORDER_STATUS.DELIVERED,
  ].includes(status);

  const handleStatusChange = (status) => () => {
    dispatch(updateOrderStatusAction(orderId, status)).catch((error) => {
      toast.error(error.message);
    });
  };

  return (
    <div className="flex gap-4">
      <Render if={opened}>
        <Button
          loading={updating}
          variant="secondary"
          onClick={handleStatusChange(ORDER_STATUS.ACCEPTED)}
        >
          {t('button.accept')}
        </Button>
        <Button
          loading={updating}
          variant="outline"
          onClick={handleStatusChange(ORDER_STATUS.DECLINED)}
        >
          {t('button.decline')}
        </Button>
      </Render>
      <Render if={pickup && accepted}>
        <Button
          loading={updating}
          variant="secondary"
          onClick={handleStatusChange(ORDER_STATUS.READY)}
        >
          {t('status.ready')}
        </Button>
      </Render>
      <Render if={pickup && delivering}>
        <Button
          loading={updating}
          variant="secondary"
          onClick={handleStatusChange(ORDER_STATUS.PICKED)}
        >
          {t('button.complete')}
        </Button>
      </Render>
      <Render if={!opened && !closed}>
        <Button
          loading={updating}
          variant="outline-grey"
          loaderColor="grey"
          onClick={handleStatusChange(ORDER_STATUS.CANCELED)}
        >
          {t('button.cancel')}
        </Button>
      </Render>
    </div>
  );
};

OrderActions.propTypes = propTypes;
OrderActions.defaultProps = defaultProps;
