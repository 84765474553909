import { ACTION_TYPE, CONFIG } from '@constants';

const initialState = {
  features: Object.fromEntries(
    Object.values(CONFIG.FEATURE).map((feature) => [feature, {}])
  ),
  subscriptionPlans: [],
};

export const configReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPE.SET_CONFIG:
      return { ...state, ...payload };

    case ACTION_TYPE.SET_CONFIG_SHARED:
      return { ...state, ...payload.shared };

    case ACTION_TYPE.SET_SUBSCRIPTION_PLANS:
      return { ...state, subscriptionPlans: payload.subscriptionPlans };

    case ACTION_TYPE.SET_CONFIG_FEATURE:
      return {
        ...state,
        features: { ...state.features, ...payload.feature },
      };

    default:
      return state;
  }
};
