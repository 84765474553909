/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { toast as toastify } from 'react-toastify';

import { TOAST_TYPE } from '@constants';
import { as, tt } from '@utils';

import { Toast } from '@components/Toast';

const createToasts = (fn) =>
  Object.fromEntries(Object.values(TOAST_TYPE).map((type) => [type, fn(type)]));

const tosting = createToasts((type) => (message, props) => {
  toastify[type](
    <Toast {...props} type={type}>
      {message}
    </Toast>
  );
});

export const toast = createToasts((type) => (unprocessedMessage, options) => {
  const {
    showEmptyMessage = false,
    translate = true,
    translateOptions = tt.ns.backend,
    ...restOptions
  } = as.o(options);

  const message = translate
    ? tt(unprocessedMessage, translateOptions)
    : unprocessedMessage;

  if (!showEmptyMessage && !message) {
    return;
  }

  tosting[type](message, restOptions);
});
