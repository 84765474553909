import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getSlots } from '@utils';

import { Slot } from '@components';

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  className: '',
  title: '',
  children: null,
};

export const ConfigRow = ({ className, title, children }) => {
  const slot = getSlots(children);

  return (
    <div className={cx('flex items-center justify-between', className)}>
      <Slot slot={slot.title}>
        <h4 className="text-body-2-r">{title}</h4>
      </Slot>
      {children}
    </div>
  );
};

ConfigRow.propTypes = propTypes;
ConfigRow.defaultProps = defaultProps;
