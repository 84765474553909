import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ACTION_TYPE } from '@constants';
import { toast } from '@services';
import { noop } from '@utils';

import { deleteRestaurant } from '@actions';
import { createLoadingSelector } from '@selectors';

import { Button, Theme } from '@components';

const restaurantDeletingSelector = createLoadingSelector(
  ACTION_TYPE.DELETE_RESTAURANT
);

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onHide: PropTypes.func,
  onDelete: PropTypes.func,
};
const defaultProps = {
  id: null,
  name: null,
  onHide: noop,
  onDelete: noop,
};

export const DeleteRestaurantModal = ({ id, name, onHide, onDelete }) => {
  const dispacth = useDispatch();
  const deleting = useSelector(restaurantDeletingSelector);

  const { t } = useTranslation();

  const handleDelete = async () => {
    try {
      const restaurant = await dispacth(deleteRestaurant(id));

      toast.success('web:toast.restaurantDeleted');

      onHide();
      onDelete(restaurant);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <Theme.Title title={t('generic.delete', { title: 'restaurant' })} />
      <p className="px-6 text-center text-subtitle">
        {t('web:content.restaurantDeleting', { name })}
      </p>
      <div className="flex justify-around border-t border-grey-7 pt-4">
        <Button variant="ghost-grey" onClick={onHide}>
          {t('button.cancel')}
        </Button>
        <Button
          variant="ghost"
          loaderColor="primary"
          loading={deleting}
          onClick={handleDelete}
        >
          {t('button.delete')}
        </Button>
      </div>
    </div>
  );
};

DeleteRestaurantModal.propTypes = propTypes;
DeleteRestaurantModal.defaultProps = defaultProps;
