import React from 'react';

import { useSubscriptionPlans } from '@hooks';

import * as Config from '@components/Config';

const propTypes = {};
const defaultProps = {};

export const ConfigSubscriptionPlans = () => {
  const { plans } = useSubscriptionPlans();

  const makePlan = ({ id, ...subscription }) => (
    <Config.Subscription key={id} {...subscription} id={id} />
  );

  return plans.map(makePlan);
};

ConfigSubscriptionPlans.propTypes = propTypes;
ConfigSubscriptionPlans.defaultProps = defaultProps;
