import React from 'react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { cn, tv } from '@utils';

import { Field, IconBox, Render } from '@components';

const variants = tv({
  base: 'flex cursor-pointer items-center gap-2 text-label-1',
  variants: {
    direction: {
      reverse: 'flex-row-reverse',
    },
  },
});

const propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  direction: PropTypes.oneOf(['reverse']),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  className: undefined,
  containerClassName: undefined,
  direction: undefined,
  disabled: false,
  error: '',
  text: '',
  children: null,
};

export const FieldCheckbox = React.forwardRef(
  (
    {
      name,
      className,
      containerClassName,
      direction,
      disabled,
      error,
      text,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <Field.Container className={cn('pb-0', containerClassName)}>
        <label
          htmlFor={name}
          className={cn(variants({ direction, className }))}
        >
          <input
            {...props}
            type="checkbox"
            ref={ref}
            id={name}
            className={cx('peer hidden', { error })}
            name={name}
            disabled={disabled}
          />
          <IconBox
            className="h-[20px] w-[20px] rounded-[4px] border-2 border-primary-1 bg-transparent text-transparent transition-colors icon-14 peer-checked:bg-primary-1  
            peer-checked:text-white peer-disabled:border-primary-3 peer-disabled:peer-checked:bg-primary-3"
            icon={faCheck}
          />
          <Render if={children || text}>
            <p className="text-grey-1">{children || text}</p>
          </Render>
        </label>
        <Field.Error error={error} />
      </Field.Container>
    );
  }
);

FieldCheckbox.displayName = 'Field.Checkbox';
FieldCheckbox.propTypes = propTypes;
FieldCheckbox.defaultProps = defaultProps;
