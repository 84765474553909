import React from 'react';
import PropTypes from 'prop-types';

import { Role } from '@types';
import { PLATFORM } from '@constants';
import { getAppLink } from '@utils';

import { Link, Render } from '@components';
import appStoreImage from '@images/app-store.png';
import googlePlayImage from '@images/google-play.png';

const propTypes = {
  platform: PropTypes.oneOf(['ios', 'android']).isRequired,
  role: Role,
  alt: PropTypes.string,
  className: PropTypes.string,
};
const defaultProps = {
  role: undefined,
  className: undefined,
  alt: '',
};

export const AppImage = ({ className, platform, role, alt, ...props }) => {
  const appImage = {
    [PLATFORM.IOS]: appStoreImage,
    [PLATFORM.ANDROID]: googlePlayImage,
  };
  const image = appImage[platform];
  const appLink = getAppLink({ platform, role });

  return (
    <Render if={appLink}>
      <Link theme external to={appLink} className={className}>
        <img {...props} src={image} alt={alt} />
      </Link>
    </Render>
  );
};

AppImage.propTypes = propTypes;
AppImage.defaultProps = defaultProps;
