import React from 'react';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { SubscriptionPlan } from '@types';
import { COLOR, SUBSCRIPTION } from '@constants';
import { getColor } from '@utils';

import { IconBox, Render } from '@components';

const propTypes = {
  className: PropTypes.string,
  plan: SubscriptionPlan,
};
const defaultProps = {
  className: undefined,
  plan: undefined,
};

export const SubscriptionIcon = ({ className, plan }) => {
  const subscriptionColor =
    plan !== SUBSCRIPTION.PLAN.FREE && COLOR.SUBSCRIPTION_PLAN[plan];

  return (
    <Render if={subscriptionColor}>
      <IconBox
        icon={faStar}
        className={cx(getColor(subscriptionColor), className)}
      />
    </Render>
  );
};

SubscriptionIcon.propTypes = propTypes;
SubscriptionIcon.defaultProps = defaultProps;
