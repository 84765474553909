import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ACTION_TYPE,
  COLOR,
  CONFIG,
  DOCUMENT,
  MODEL,
  PATH,
  STATUS,
  USER_ROLE,
} from '@constants';
import { toast } from '@services';
import {
  createFormData,
  getFormErrors,
  getFullName,
  getNormalizedDocuments,
  pick,
  setBackendErrors,
  setFormValues,
  validationSchema,
} from '@utils';

import {
  blockUser,
  deleteUser,
  deleteUserTokens,
  getUserById,
  restoreUser,
  updateUser,
  verifyDocuments,
} from '@actions';
import { createLoadingSelector } from '@selectors';

import {
  Avatar,
  Badge,
  Button,
  Field,
  Layout,
  Render,
  Theme,
  WithLoader,
} from '@components';
import { ConfigSelectOptions } from '@components/ConfigSelectOptions';
import { Document } from '@components/Document';

const userFetchingSelector = createLoadingSelector(ACTION_TYPE.GET_ADMIN_USER);
const userUpdatingSelector = createLoadingSelector(
  ACTION_TYPE.UPDATE_USER,
  ACTION_TYPE.VERIFY_DOCUMENTS
);

const propTypes = {};
const defaultProps = {};

export const UserEditPage = () => {
  const dispatch = useDispatch();
  const userFetching = useSelector(userFetchingSelector);
  const userUpdating = useSelector(userUpdatingSelector);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId } = useParams();
  const { register, handleSubmit, getValues, setError, setValue, formState } =
    useForm();

  const [user, setUser] = useState({ documents: {} });
  const [documentsVerification, setDocumentsVerification] = useState({});

  const errors = getFormErrors(formState.errors);
  const { role, avatar, status, documents, firstName, lastName } = user;
  const blocked = status === STATUS.VERIFICATION.BLOCKED;
  const deleted = status === STATUS.VERIFICATION.DELETED;
  const documentsRequired = [USER_ROLE.OWNER, USER_ROLE.COURIER].includes(role);
  const fullName = getFullName(firstName, lastName);
  const deleteButtonKey = deleted ? 'generic.restore' : 'generic.delete';

  const getVerificationStatus = (status) => {
    const statusMapping = {
      [STATUS.VERIFICATION.VERIFIED]: DOCUMENT.STATUS.APPROVE,
      [STATUS.VERIFICATION.DECLINED]: DOCUMENT.STATUS.DECLINE,
    };

    return statusMapping[status];
  };

  const updateUserState = useCallback(
    (user) => {
      setUser(user);
      setFormValues({
        data: user,
        fields: pick(
          user,
          'location',
          'avatar',
          'city',
          'email',
          'firstName',
          'lastName',
          'locale',
          'phone',
          'role'
        ),
        setValue,
      });
    },
    [setValue]
  );

  useEffect(() => {
    dispatch(getUserById(userId))
      .then((user) => {
        const documents = getNormalizedDocuments(user.documents);
        const defaultDocumentsVerification = Object.entries(documents).reduce(
          (acc, [documentName, { status, message }]) => ({
            ...acc,
            [documentName]: {
              status: getVerificationStatus(status),
              message,
            },
          }),
          {}
        );

        setDocumentsVerification(defaultDocumentsVerification);
        updateUserState(user);
      })
      .catch((error) => {
        toast.error(error.message);

        navigate(PATH.USERS);
      });
  }, [userId, updateUserState, navigate, dispatch]);

  const getSubmitDocuments = () =>
    Object.entries(documentsVerification).reduce(
      (acc, [document, { status: action, message }]) => {
        const reason = action === DOCUMENT.STATUS.DECLINE ? message : undefined;

        if (action) {
          return { ...acc, [document]: { action, reason } };
        }

        return acc;
      },
      {}
    );

  const handleFormSubmit = handleSubmit(async (user) => {
    const userPayload = createFormData({
      data: user,
      put: true,
      omit: ['avatar', 'phone', 'role'],
    });

    try {
      const documentPayload = getSubmitDocuments();
      const [, user] = await Promise.all([
        await dispatch(verifyDocuments(userId, documentPayload)),
        await dispatch(updateUser(userId, userPayload)),
      ]);

      updateUserState(user);

      toast.success('web:toast.userProfileUpdated');
    } catch (error) {
      setBackendErrors(error, { getValues, setError });
    }
  });

  const handleUserDeleteStatus = async () => {
    try {
      const userMethod = deleted ? restoreUser : deleteUser;

      const { message } = await dispatch(userMethod(userId));

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleBlockUser = async () => {
    try {
      const user = await dispatch(blockUser({ userId, blocked: !blocked }));

      setUser(user);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeleteUserTokens = async () => {
    try {
      const message = await dispatch(deleteUserTokens(userId));

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDocumentVerificationChange =
    (documentName) =>
    ({ status, message }) => {
      setDocumentsVerification((state) => ({
        ...state,
        [documentName]: { status, message },
      }));
    };

  const { photoOfId, photoWithId } = getNormalizedDocuments(documents);

  return (
    <Layout className="flex flex-1 flex-col" title={fullName}>
      <form className="flex flex-1 flex-col" onSubmit={handleFormSubmit}>
        <div className="flex items-center justify-end">
          <Render if={!userFetching}>
            <Button type="submit" variant="secondary" loading={userUpdating}>
              {t('button.save')}
            </Button>
          </Render>
        </div>
        <WithLoader in={userFetching}>
          <div className="mt-10 flex flex-1 items-start gap-6">
            <div className="flex flex-1 flex-col gap-6">
              <Theme.Container className="flex-1">
                <Theme.Title
                  className="mb-4"
                  title={t('web:personalInformation')}
                />
                <div className="grid grid-cols-2 gap-x-6">
                  <Field.Input
                    {...register('firstName', validationSchema.firstName())}
                    requiredLabel
                    label={t('label.firstName')}
                    error={errors.firstName}
                  />
                  <Field.Input
                    {...register('lastName')}
                    label={t('label.lastName')}
                  />
                  <Field.Input
                    {...register('phone')}
                    disabled
                    label={t('label.phone')}
                    prefix="+"
                  />
                  <Field.Input
                    {...register('email')}
                    type="email"
                    inputMode="email"
                    label={t('label.email')}
                  />
                  <Field.Input {...register('city')} label={t('label.city')} />
                  <Field.Select
                    {...register('locale')}
                    label={t('label.locale')}
                  >
                    <ConfigSelectOptions
                      translationKey="locale"
                      translationKeySeparator=":"
                      configName={CONFIG.SHARED.LOCALE}
                    />
                  </Field.Select>
                </div>
              </Theme.Container>
              <Render if={documentsRequired}>
                <Theme.Container>
                  <Theme.Title className="mb-4" title={t('web:documents')} />
                  <div className="flex items-start gap-6">
                    <Document
                      {...photoOfId}
                      openable
                      message={documentsVerification[DOCUMENT.NAME.ID]?.message}
                      label={t('label.photoOfId')}
                    >
                      <Document.Flow
                        {...documentsVerification[DOCUMENT.NAME.ID]}
                        setVerification={handleDocumentVerificationChange(
                          DOCUMENT.NAME.ID
                        )}
                      />
                    </Document>
                    <Document
                      {...photoWithId}
                      openable
                      message={
                        documentsVerification[DOCUMENT.NAME.WITH_ID]?.message
                      }
                      className="ml-3"
                      label={t('label.photoWithId')}
                    >
                      <Document.Flow
                        {...documentsVerification[DOCUMENT.NAME.WITH_ID]}
                        setVerification={handleDocumentVerificationChange(
                          DOCUMENT.NAME.WITH_ID
                        )}
                      />
                    </Document>
                  </div>
                </Theme.Container>
              </Render>
              <div className="flex">
                <Button
                  disabled={userUpdating}
                  variant="ghost"
                  onClick={handleUserDeleteStatus}
                >
                  {t(deleteButtonKey, { title: t('web:user') })}
                </Button>
                <Button
                  disabled={userUpdating}
                  variant="ghost"
                  onClick={handleBlockUser}
                >
                  {/* todd: add translation */}
                  {blocked ? 'Unblock' : 'Block'} user
                </Button>
                <Button
                  disabled={userUpdating}
                  variant="ghost"
                  onClick={handleDeleteUserTokens}
                >
                  Delete user device tokens
                </Button>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <Theme.Card className="flex flex-col items-center">
                <Theme.Title
                  className="mb-4 w-full self-start"
                  title={t('label.avatar')}
                />
                <Avatar src={avatar} size={150} model={MODEL.USER} />
                <Badge className="mt-6" color={COLOR.ROLE[role]}>
                  {t(`role.${role}`)}
                </Badge>
              </Theme.Card>
              <Badge
                large
                className="w-full"
                color={COLOR.VERIFICATION[status]}
              >
                {t(`status.${status}`)}
              </Badge>
            </div>
          </div>
        </WithLoader>
      </form>
    </Layout>
  );
};

UserEditPage.propTypes = propTypes;
UserEditPage.defaultProps = defaultProps;
