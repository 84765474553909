import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ConfigName } from '@types';
import { i18n } from '@services';

import { configSelector } from '@selectors';

import { Render } from '@components';

const propTypes = {
  configName: ConfigName.isRequired,
  emptyble: PropTypes.bool,
  translationKey: PropTypes.string,
  translationKeySeparator: PropTypes.string,
};
const defaultProps = {
  emptyble: false,
  translationKey: '',
  translationKeySeparator: '.',
};

export const ConfigSelectOptions = ({
  configName,
  emptyble,
  translationKey,
  translationKeySeparator,
}) => {
  const { [configName]: config } = useSelector(configSelector);

  const { t } = useTranslation();

  const getTranslation = (translation) => {
    const key = `${translationKey}${translationKeySeparator}${translation}`;

    if (i18n.exists(key)) {
      return t(key);
    }

    return translation;
  };

  const makeOption = (option = '') => (
    <option key={option} value={option}>
      {getTranslation(option)}
    </option>
  );

  if (!Array.isArray(config)) {
    // eslint-disable-next-line no-console
    console.error(`Cannot get config ${configName} options`);
    return null;
  }

  return (
    <>
      <Render if={emptyble}>{makeOption()}</Render>
      {config.map(makeOption)}
    </>
  );
};

ConfigSelectOptions.propTypes = propTypes;
ConfigSelectOptions.defaultProps = defaultProps;
