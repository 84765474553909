import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Role } from '@types';
import { ACTION_TYPE, CONFIG, PATH, USER_ROLE } from '@constants';
import { toast } from '@services';
import { useFeature } from '@hooks';
import {
  getBackendErrors,
  getFormErrors,
  noop,
  processField,
  setBackendErrors,
  validationSchema,
} from '@utils';

import { authSignup, cacheAny, cachePhone } from '@actions';
import { createLoadingSelector, sharedSelector } from '@selectors';

import { Button, Field, Link } from '@components';

const loadingSelector = createLoadingSelector(ACTION_TYPE.AUTH_SIGNUP);

const propTypes = {
  role: Role.isRequired,
  onSuccess: PropTypes.func,
};
const defaultProps = {
  onSuccess: noop,
};

export const SignupForm = ({ role, onSuccess }) => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const { phone, firstName } = useSelector(sharedSelector);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, formState, getValues, setError } = useForm({
    defaultValues: { phone, firstName },
  });
  const { enabled: twilio } = useFeature(CONFIG.FEATURE.TWILIO);

  const errors = getFormErrors(formState.errors);

  const handleLoginClick = () => {
    const { phone } = getValues();

    if (phone) {
      dispatch(cachePhone(processField.phone(getValues().phone)));
    }

    navigate(PATH.LOGIN);
    navigate({ pathname: PATH.LOGIN, search });
  };

  const handleFormSubmit = handleSubmit(async (user) => {
    const processedUser = processField.form(user);
    const { phone, firstName } = processedUser;
    const payload = { ...processedUser, role, locale: i18n.language };
    const restaurantId = searchParams.get('restaurantId');
    const referrerId = searchParams.get('referrerId');

    if (role === USER_ROLE.MANAGER && restaurantId) {
      payload.restaurantId = restaurantId;
    }

    if (referrerId) {
      payload.referrerId = referrerId;
    }

    try {
      const { code, message } = await dispatch(authSignup(payload));
      dispatch(cacheAny({ phone, firstName }));

      toast.success(message);

      if (!twilio) {
        toast.info(code);
      }

      onSuccess();
    } catch (error) {
      const { code } = getBackendErrors(error);

      toast.error(error.message);
      toast.error(code);

      setBackendErrors(error, { getValues, setError });
    }
  });

  return (
    <form onSubmit={handleFormSubmit}>
      <Field.Input
        {...register('firstName', validationSchema.firstName())}
        label={t('label.firstName')}
        requiredLabel
        error={errors.firstName}
      />
      <Field.Input
        {...register('phone', validationSchema.phone())}
        type="tel"
        inputMode="tel"
        label={t('label.phone')}
        requiredLabel
        prefix="+"
        placeholder="1 809 123 4567"
        error={errors.phone}
      />
      <Field.Checkbox
        containerClassName="pb-6"
        {...register('accepted', {
          required: t('web:text.acceptTerms'),
        })}
        error={errors.accepted}
      >
        <Trans i18nKey="web:label.acceptTerms">
          I have read
          <Link
            external
            theme
            className="primary-1-with-hover transition-colors"
            to={PATH.TERMS_AND_CONDITIONS}
          >
            Terms and Conditions
          </Link>
        </Trans>
      </Field.Checkbox>
      <Button loading={loading} type="submit" className="w-full">
        {t('button.signup')}
      </Button>
      <Button
        disabled={loading}
        variant="ghost"
        className="mt-3 w-full"
        onClick={handleLoginClick}
      >
        {t('button.login')}
      </Button>
    </form>
  );
};

SignupForm.propTypes = propTypes;
SignupForm.defaultProps = defaultProps;
