import { ACTION_TYPE } from '@constants';
import { axios } from '@services';
import { reduce } from '@utils';

export const updateOrderStatus = (orderId, status) => (dispatch) => {
  const [{ request, success, failure }] = reduce.get(
    ACTION_TYPE.UPDATE_ORDER_STATUS
  );

  dispatch(request);

  return axios
    .put(`/orders/${orderId}/status`, { status })
    .then(({ order }) => {
      dispatch(success);

      return Promise.resolve(order);
    })
    .catch((error) => {
      dispatch(failure);

      return Promise.reject(error);
    });
};

export const assignCourierToOrder =
  ({ orderId, courierId, status }) =>
  (dispatch) => {
    const [{ request, success, failure }] = reduce.get(
      ACTION_TYPE.ORDER_ASSIGN_COURIER
    );

    dispatch(request);

    return axios
      .put(`/orders/${orderId}/status`, { courierId, status })
      .then(({ order }) => {
        dispatch(success);

        return Promise.resolve(order);
      })
      .catch((error) => {
        dispatch(failure);

        return Promise.reject(error);
      });
  };
