import { as, isObject } from '@utils';

export const setBackendErrors = (error, { getValues, setError }) => {
  const { errors } = as.o(error);

  if (!isObject(errors)) {
    return;
  }

  const fieldNames = Object.keys(getValues());

  fieldNames.forEach((fieldName) => {
    const error = errors[fieldName];

    if (Array.isArray(error)) {
      /**
       * todo: replace translation for field components erorr attribute
       */
      setError(fieldName, { message: error[0] });
    }
  });
};
