import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ACTION_TYPE } from '@constants';
import { noop } from '@utils';

import { createLoadingSelector } from '@selectors';

import { Button, Theme } from '@components';

const loadingSelector = createLoadingSelector(ACTION_TYPE.UPDATE_CONFIG);

const propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  onSubmit: PropTypes.func,
};
const defaultProps = {
  loading: false,
  className: '',
  title: '',
  subtitle: '',
  children: null,
  onSubmit: noop,
};

export const ConfigContainer = ({
  loading,
  className,
  title,
  subtitle,
  children,
  onSubmit,
}) => {
  const updating = useSelector(loadingSelector);

  const { t } = useTranslation();

  return (
    <Theme.Container className={className}>
      <Theme.Title title={title} subtitle={subtitle} className="mb-6">
        <Button
          loading={loading || updating}
          variant="ghost"
          loaderColor="primary"
          onClick={onSubmit}
        >
          {t('button.save')}
        </Button>
      </Theme.Title>
      {children}
    </Theme.Container>
  );
};

ConfigContainer.propTypes = propTypes;
ConfigContainer.defaultProps = defaultProps;
