import React from 'react';
import { Trans } from 'react-i18next';
import {
  faBellConcierge,
  faMoneyCheckDollarPen,
  faPenToSquare,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { RestaurantStatus } from '@types';
import { PATH, STATUS } from '@constants';
import { path } from '@utils';

import { Link, Tooltip } from '@components';

const propTypes = {
  restaurantId: PropTypes.string.isRequired,
  status: RestaurantStatus,
  children: PropTypes.node,
};
const defaultProps = {
  status: undefined,
  children: undefined,
};

export const RestaurantStatusTooltip = ({ restaurantId, status, children }) => {
  const i18nKey = `web:tooltip.restaurant.${status}`;
  const linkProps = {
    theme: true,
    className: 'primary-1-with-hover inline-block',
  };

  const getTooltipContent = () => {
    switch (status) {
      case STATUS.RESTAURANT.PUBLISHED:
        return (
          <Trans i18nKey={i18nKey}>
            The restaurant is verified and visible for other users
          </Trans>
        );
      case STATUS.RESTAURANT.DISHES:
        return (
          <Trans i18nKey={i18nKey}>
            Add a dish on
            <Link {...linkProps} to={path.menu(restaurantId)}>
              <FontAwesomeIcon icon={faBellConcierge} />
              Dish page
            </Link>
            to make the restaurant published
          </Trans>
        );
      case STATUS.RESTAURANT.UNPUBLISHED:
        return (
          <Trans i18nKey={i18nKey}>
            Set publish status on
            <Link {...linkProps} to={path.restaurant(restaurantId)}>
              <FontAwesomeIcon icon={faPenToSquare} />
              Restaurant page
            </Link>
            to make the restaurant published
          </Trans>
        );
      case STATUS.RESTAURANT.UNVERIFIED:
        return (
          <Trans i18nKey={i18nKey}>
            Upload the documents to verify your identity on
            <Link {...linkProps} to={PATH.PROFILE}>
              <FontAwesomeIcon icon={faUser} />
              Profule page
            </Link>
            When the documents are verified you are able to make your restaurant
            published
          </Trans>
        );
      case STATUS.RESTAURANT.LIMITED:
        return (
          <Trans i18nKey={i18nKey}>
            This restaurant under limitation.
            <Link {...linkProps} to={PATH.SUBSCRIPTION_PLANS}>
              <FontAwesomeIcon icon={faMoneyCheckDollarPen} />
              Get subscription
            </Link>
            to change limits
          </Trans>
        );

      default:
        return null;
    }
  };

  return (
    <Tooltip place="left" content={getTooltipContent()}>
      {children}
    </Tooltip>
  );
};

RestaurantStatusTooltip.propTypes = propTypes;
RestaurantStatusTooltip.defaultProps = defaultProps;
