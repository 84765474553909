export const createFeatureName =
  (...featureNames) =>
  (fieldName) => {
    const featureName = featureNames.join('.');

    if (fieldName) {
      return `${featureName}.${fieldName}`;
    }

    return featureName;
  };
