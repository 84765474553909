import { useEffect, useState } from 'react';

export const useClickableState = (ref, defaultValue = false) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setValue(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return [value, setValue];
};
