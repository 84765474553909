import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Role } from '@types';
import { PUSH_NOTIFICATION_PERMISSIONS } from '@constants';
import { createFeatureName, getNotificationPermission } from '@utils';

import { Field, SwitchContainer } from '@components';

const propTypes = {
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  role: Role.isRequired,
};
const defaultProps = {};

export const NotificationItem = ({ register, watch, name, role }) => {
  const { t } = useTranslation();

  const fieldName = [name, role].join('-');
  const permissionsGranted = getNotificationPermission(
    PUSH_NOTIFICATION_PERMISSIONS.GRANTED
  );

  const getFieldName = createFeatureName(fieldName);
  const disabled = !watch(getFieldName('enabled'));

  return (
    <div className="flex flex-col gap-6 border-b border-grey-6 py-6 last:border-b-0">
      <SwitchContainer>
        <Trans i18nKey={`notification.${role}.${name}`} slot="title">
          <p className="text-body-1-s text-grey-1">{name}</p>
          <p className="text-body-2-r text-grey-3">{role}</p>
        </Trans>
        <Field.Switch {...register(getFieldName('enabled'))} />
      </SwitchContainer>
      <div
        className={cx('flex flex-col gap-6 pl-4', {
          'pointer-events-note': disabled,
        })}
      >
        <Field.Checkbox
          {...register(getFieldName('push'))}
          direction="reverse"
          className="justify-between text-body-1-r"
          disabled={!permissionsGranted || disabled}
          text={t('notification.push')}
        />
        <Field.Checkbox
          {...register(getFieldName('sms'))}
          className="justify-between text-body-1-r"
          direction="reverse"
          disabled={disabled}
          text={t('notification.sms')}
        />
        <Field.Checkbox
          {...register(getFieldName('email'))}
          className="justify-between text-body-1-r"
          direction="reverse"
          disabled={disabled}
          text={t('notification.email')}
        />
      </div>
    </div>
  );
};

NotificationItem.propTypes = propTypes;
NotificationItem.defaultProps = defaultProps;
