import { ACTION_TYPE } from '@constants';

const initialState = [];

export const restaurantsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPE.CACHE_RESTAURANTS:
      return payload.restaurants;

    default:
      return state;
  }
};
