import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  faClipboardListCheck,
  faClock,
  faCoins,
  faFileInvoice,
  faHouseBuilding,
  faMoped,
  faPhone,
  faRadar,
} from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';

import { RestaurantStatus } from '@types';
import { MODEL } from '@constants';
import { getFormattedPriceWithCurrency } from '@utils';

import { Avatar, Render } from '@components';
import { Info, RowItem } from '@components/ui';

const propTypes = {
  avatar: PropTypes.string,
  city: PropTypes.string,
  cuisine: PropTypes.string,
  currency: PropTypes.string,
  deliveryCharge: PropTypes.number,
  deliveryRadius: PropTypes.number,
  deliveryTime: PropTypes.string,
  location: PropTypes.shape(),
  name: PropTypes.string,
  phone: PropTypes.string,
  rating: PropTypes.number,
  reviewCount: PropTypes.number,
  status: RestaurantStatus,
  taxReceipt: PropTypes.bool,
};
const defaultProps = {
  avatar: null,
  city: null,
  cuisine: null,
  currency: null,
  deliveryCharge: null,
  deliveryRadius: null,
  deliveryTime: null,
  name: null,
  phone: null,
  rating: undefined,
  reviewCount: undefined,
  taxReceipt: false,
  status: undefined,
  location: {},
};

export const RestaurantDetails = ({
  avatar,
  city,
  cuisine,
  currency,
  deliveryCharge,
  deliveryRadius,
  deliveryTime,
  name,
  phone,
  rating,
  reviewCount,
  taxReceipt,
  status,
  location: { name: address },
}) => {
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="flex items-center gap-6">
        <Avatar src={avatar} size={160} model={MODEL.BUSINESS} />
        <Info.Restaurant
          title={name}
          address={address}
          rating={rating}
          reviewCount={reviewCount}
          status={status}
        />
      </div>
      <div className="mt-6 grid grid-cols-2">
        <RowItem icon={faClipboardListCheck} label={t('label.cuisine')}>
          <Render if={cuisine} else="-">
            {t(`cuisine.${cuisine}`)}
          </Render>
        </RowItem>
        <RowItem icon={faPhone} label={t('label.phone')}>
          <Render if={phone} else="-">
            +{phone}
          </Render>
        </RowItem>
        <RowItem icon={faRadar} label={t('label.deliveryRadius')}>
          {t('generic.deliveryRadius', { count: deliveryRadius })}
        </RowItem>
        <RowItem icon={faHouseBuilding} label={t('label.city')}>
          <Render if={city} else="-">
            {city}
          </Render>
        </RowItem>
        <RowItem icon={faClock} label={t('web:label.deliveryTime')}>
          <Render if={deliveryTime} else="-">
            {t('generic.deliveryTime', { count: Number(deliveryTime) })}
          </Render>
        </RowItem>
        <RowItem icon={faMoped} label={t('label.deliveryCharge')}>
          <Render if={deliveryCharge} else="-">
            {getFormattedPriceWithCurrency(deliveryCharge, currency)}
          </Render>
        </RowItem>
        <RowItem icon={faCoins} label={t('label.currency')}>
          {currency}
        </RowItem>
        <RowItem icon={faFileInvoice} label={t('label.taxReceipt')}>
          <Render if={taxReceipt} else="-">
            {t('web:text.taxReceipt.title')}
          </Render>
        </RowItem>
      </div>
    </div>
  );
};

RestaurantDetails.propTypes = propTypes;
RestaurantDetails.defaultProps = defaultProps;
