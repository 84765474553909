import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { CONFIG } from '@constants';
import {
  createFeatureName,
  noop,
  processField,
  removeEmptyProperties,
} from '@utils';

import { configFeaturesSelector } from '@selectors';

import { Field, SwitchContainer } from '@components';
import * as Config from '@components/Config';

const propTypes = {
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onSubmit: noop,
};

export const ConfigTwilio = ({ onSubmit }) => {
  const { twilio } = useSelector(configFeaturesSelector);

  const { register, handleSubmit } = useForm({ defaultValues: { twilio } });

  const getFieldName = createFeatureName(CONFIG.FEATURE.TWILIO);

  const handleFeatureSubmit = handleSubmit(({ twilio }) => {
    onSubmit(
      processField.form({
        name: getFieldName(),
        ...removeEmptyProperties(twilio, ''),
      })
    );
  });

  return (
    <Config.Container
      title="Twilio"
      subtitle="Service to send verification code via SMS"
      onSubmit={handleFeatureSubmit}
    >
      <div className="flex flex-col gap-6">
        <SwitchContainer title="Enable">
          <Field.Switch {...register(getFieldName('enabled'))} />
        </SwitchContainer>
        <Config.Row title="Timeout (seconds)">
          <Field.Input
            {...register(getFieldName('timeout'))}
            containerClassName="pb-0"
            type="number"
            inputMode="numeric"
          />
        </Config.Row>
        <Config.Row title="Code length">
          <Field.Input
            {...register(getFieldName('length'))}
            containerClassName="pb-0"
            type="number"
            inputMode="numeric"
          />
        </Config.Row>
        <Config.Row title="Test phone">
          <Field.Input
            {...register(getFieldName('phone'))}
            containerClassName="pb-0"
            type="tel"
            inputMode="tel"
          />
        </Config.Row>
        <Config.Row title="Code for test phone">
          <Field.Input
            {...register(getFieldName('code'))}
            containerClassName="pb-0"
            type="password"
            inputMode="numeric"
          />
        </Config.Row>
      </div>
    </Config.Container>
  );
};

ConfigTwilio.propTypes = propTypes;
ConfigTwilio.defaultProps = defaultProps;
