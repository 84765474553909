import React from 'react';
import {
  faCircleCheck,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getColor } from '@utils';

import { IconBox } from '@components';

const propTypes = {
  children: PropTypes.string.isRequired,
  value: PropTypes.bool,
  className: PropTypes.string,
};
const defaultProps = {
  className: undefined,
  value: false,
};

export const CheckList = ({ className, value, children }) => {
  const color = getColor.boolean(value);
  const icon = value ? faCircleCheck : faTimesCircle;

  return (
    <div className={cx('flex items-center justify-between', className)}>
      <p className="text-[14px] leading-[24px]">{children}</p>
      <IconBox icon={icon} className={cx('icon-22', color)} />
    </div>
  );
};

CheckList.propTypes = propTypes;
CheckList.defaultProps = defaultProps;
