import React from 'react';
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';

import { IconBox, Link } from '@components';

import { TableCell } from './TableCell';

const propTypes = {
  to: PropTypes.string,
};
const defaultProps = {
  to: undefined,
};

export const TableEditIcon = ({ to }) => {
  return (
    <TableCell align="center" width="icon">
      <Link theme className="primary-1-with-hover" to={to}>
        <IconBox icon={faPenToSquare} className="h-8 w-8 icon-18" />
      </Link>
    </TableCell>
  );
};

TableEditIcon.propTypes = propTypes;
TableEditIcon.defaultProps = defaultProps;
