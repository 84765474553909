import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import {
  ACTION_TYPE,
  CONFIG,
  MODEL,
  PAGES_NAME,
  PATH,
  PHOTO_MIMETYPES,
  STATUS,
} from '@constants';
import { toast } from '@services';
import { useRouterParams } from '@hooks';
import {
  createClassName,
  createFormData,
  getFileImage,
  getFormErrors,
  isObject,
  isRoleScope,
  noop,
  path,
  pick,
  setBackendErrors,
  setFormValues,
  validationSchema,
} from '@utils';

import { createDish, getDishById, restoreDish, updateDish } from '@actions';
import {
  createLoadingSelector,
  userOnboardingSelector,
  userRoleSelector,
} from '@selectors';

import {
  Avatar,
  Button,
  Field,
  Layout,
  Modal,
  Modals,
  Render,
  SwitchContainer,
  Theme,
  WithLoader,
} from '@components';
import { ConfigSelectOptions } from '@components/ConfigSelectOptions';
import { OnboardingContainer } from '@components/OnboardingContainer';

const dishUpdatingSelector = createLoadingSelector(
  ACTION_TYPE.CREATE_DISH,
  ACTION_TYPE.UPDATE_DISH
);

const propTypes = {};
const defaultProps = {};

export const ProductEditPage = () => {
  const dispatch = useDispatch();
  const dishUpdating = useSelector(dishUpdatingSelector);
  const role = useSelector(userRoleSelector);
  const { active: onboarding } = useSelector(userOnboardingSelector);

  const { restaurantId, productId, editing } = useRouterParams(
    'restaurantId',
    'productId'
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    formState,
    watch,
    handleSubmit,
    setValue,
    getValues,
    setError,
  } = useForm();

  const [product, setProduct] = useState({});

  const { name, status } = product;
  const deleted = status === STATUS.RESTAURANT.DELETED;
  const watchAvatar = watch('avatar');
  const avatar = getFileImage(watchAvatar);
  const errors = getFormErrors(formState.errors);
  const avatarError = errors.avatar;
  const getClass = createClassName(avatarError);
  const buttonText = editing ? 'button.save' : 'button.add';
  const loading = isObject.empty(product) && editing;

  useEffect(() => {
    if (editing) {
      dispatch(getDishById({ restaurantId, dishId: productId }))
        .then(setProduct)
        .catch(noop);
    }
  }, [editing, restaurantId, productId, dispatch]);

  useEffect(() => {
    setFormValues({
      data: product,
      fields: pick(
        product,
        'active',
        'avatar',
        'category',
        'description',
        'measure',
        'name',
        'price',
        'quantity'
      ),
      setValue,
    });
  }, [product, setValue]);

  const handleRestore = () => {
    dispatch(restoreDish({ restaurantId, dishId: productId }))
      .then((product) => {
        toast.success('web:toast.dishRestored');
        setProduct(product);
      })
      .catch(noop);
  };

  const handleFormSubmit = handleSubmit(async (payload) => {
    const dishMethod = editing ? updateDish : createDish;
    const formData = createFormData({
      data: payload,
      put: editing,
      images: ['avatar'],
    });
    const toastMessage = editing
      ? 'web:toast.dishUpdated'
      : 'web:toast.dishCreated';

    try {
      const { id } = await dispatch(
        dishMethod({ payload: formData, restaurantId, dishId: productId })
      );

      toast.success(toastMessage);

      if (onboarding) {
        navigate(PATH.COURIERS);
        return;
      }

      navigate(path.product(id, restaurantId));
    } catch (error) {
      toast.error(error.message);

      setBackendErrors(error, { getValues, setError });
    }
  });

  const handleDishDelete = (product) => {
    if (isRoleScope.owner(role)) {
      navigate(path.menu(restaurantId));
    } else {
      setProduct(product);
    }
  };

  return (
    <Layout className="flex flex-1 flex-col" title={PAGES_NAME.DISHES}>
      <Helmet>
        <title>Menu</title>
      </Helmet>
      <form className="flex flex-1 flex-col" onSubmit={handleFormSubmit}>
        <div className="flex justify-end">
          <Render if={!loading}>
            <div className="flex">
              <Button variant="secondary" type="submit" loading={dishUpdating}>
                {t(buttonText)}
              </Button>
            </div>
          </Render>
        </div>
        <OnboardingContainer className="mt-6" />
        <WithLoader in={loading}>
          <div className="mt-6 flex items-start gap-6">
            <div className="flex flex-1 flex-col gap-6">
              <Theme.Container>
                <Theme.Title
                  required
                  className="mb-6"
                  title={t('web:title.productDetails')}
                />
                <div className="grid grid-cols-4 gap-x-6">
                  <Field.Input
                    {...register(
                      'name',
                      validationSchema.required(t('web:label.dishTitle'))
                    )}
                    requiredLabel
                    containerClassName="col-span-2"
                    label={t('web:label.dishTitle')}
                    error={errors.name}
                  />
                  <Field.Select
                    {...register(
                      'category',
                      validationSchema.required(t('label.category'))
                    )}
                    requiredLabel
                    containerClassName="col-span-2"
                    label={t('label.category')}
                    error={errors.category}
                  >
                    <ConfigSelectOptions
                      emptyble
                      translationKey="category"
                      configName={CONFIG.SHARED.CATEGORY}
                    />
                  </Field.Select>
                  <Field.Textarea
                    {...register('description')}
                    containerClassName="col-span-4"
                    className="min-h-[108px] w-full"
                    label={t('label.description')}
                    error={errors.description}
                  />
                  <Field.Input
                    {...register(
                      'quantity',
                      validationSchema.required(t('label.quantity'))
                    )}
                    requiredLabel
                    type="number"
                    inputMode="decimal"
                    containerClassName="col-span-2"
                    label={t('label.quantity')}
                    error={errors.quantity}
                  />
                  <Field.Input
                    {...register(
                      'price',
                      validationSchema.required(t('label.price'))
                    )}
                    requiredLabel
                    label={t('label.price')}
                    error={errors.price}
                  />
                  <Field.Select
                    {...register(
                      'measure',
                      validationSchema.required(t('label.measure'))
                    )}
                    requiredLabel
                    type="number"
                    inputMode="decimal"
                    label={t('label.measure')}
                    error={errors.measure}
                  >
                    <ConfigSelectOptions
                      translationKey="measure"
                      configName={CONFIG.SHARED.MEASURE}
                    />
                  </Field.Select>
                </div>
              </Theme.Container>
              <div>
                <Render if={deleted && editing}>
                  <Button variant="ghost" onClick={handleRestore}>
                    {t('generic.restore', { title: t('web:net.dish') })}
                  </Button>
                </Render>
                <Render if={!deleted && editing}>
                  <Modal
                    className="max-w-[540px]"
                    content={
                      <Modals.DeleteDish
                        restaurantId={restaurantId}
                        productId={productId}
                        productName={name}
                        onDelete={handleDishDelete}
                      />
                    }
                  >
                    <Button variant="ghost">
                      {t('generic.delete', {
                        title: t('web:net.dish'),
                      })}
                    </Button>
                  </Modal>
                </Render>
              </div>
            </div>

            <div>
              <Theme.Card>
                <Theme.Title className="mb-6" title={t('web:label.cover')} />
                <div>
                  <Field.File {...register('avatar')} accept={PHOTO_MIMETYPES}>
                    <Avatar
                      src={avatar}
                      model={MODEL.PRODUCT}
                      size={150}
                      className={cx('mx-auto block', getClass('mb-1', 'mb-3'))}
                    />
                    <Field.Error variant="static" error={avatarError} />
                    <Button
                      variant="ghost"
                      className="pointer-events-none mx-auto flex"
                    >
                      {t('web:button.uploadDishCover')}
                    </Button>
                  </Field.File>
                </div>
              </Theme.Card>

              <Theme.Card className="mt-3">
                <SwitchContainer title={t('web:label.publish')}>
                  <Field.Switch {...register('active')} />
                </SwitchContainer>
              </Theme.Card>
            </div>
          </div>
        </WithLoader>
      </form>
    </Layout>
  );
};

ProductEditPage.propTypes = propTypes;
ProductEditPage.defaultProps = defaultProps;
