import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { noop } from '@utils';

import { Button, Field, Theme } from '@components';

const propTypes = {
  title: PropTypes.string,
  defaultValue: PropTypes.string,
  onHide: PropTypes.func,
  onDecline: PropTypes.func,
};
const defaultProps = {
  title: '',
  defaultValue: '',
  onHide: noop,
  onDecline: noop,
};

export const DeclineModal = ({ title, defaultValue, onHide, onDecline }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(defaultValue);

  const handleDecline = () => {
    onDecline(value);
    onHide();
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="p-6">
      <Theme.Title className="mb-6" title={title} />
      <Field.Textarea
        className="w-full"
        name="decline"
        value={value}
        onChange={handleChange}
      />
      <footer className="flex justify-end">
        <Button disabled={!value} onClick={handleDecline}>
          {t('button.decline')}
        </Button>
      </footer>
    </div>
  );
};

DeclineModal.propTypes = propTypes;
DeclineModal.defaultProps = defaultProps;
