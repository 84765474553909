import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  faCalendarCheck,
  faEnvelope,
  faHouseBuilding,
  faPhone,
  faUser,
} from '@fortawesome/pro-light-svg-icons';

import { COLOR, MODEL, USER_ROLE } from '@constants';
import { toast } from '@services';
import {
  as,
  capitalize,
  getColor,
  getFullName,
  getNormalizedDocuments,
  isObject,
  path,
} from '@utils';

import { getUserById } from '@actions';

import { Avatar, Layout, Link, Render, Theme, WithLoader } from '@components';
import { Document } from '@components/Document';
import { EmptyList } from '@components/EmptyList';
import { Info, RowItem } from '@components/ui';
import * as emptyListImage from '@images/emptyList';

const propTypes = {};
const defaultProps = {};

export const UserPage = () => {
  const dispatch = useDispatch();

  const { userId } = useParams();
  const { t } = useTranslation();

  const [user, setUser] = useState({});

  const loading = isObject.empty(user);
  const {
    avatar,
    city,
    createdAt,
    documents,
    email,
    firstName,
    lastName,
    phone,
    restaurants,
    role,
    status,
    subscription,
  } = user;
  const { photoOfId, photoWithId } = getNormalizedDocuments(documents);
  const documentsRequired = [USER_ROLE.OWNER, USER_ROLE.COURIER].includes(role);
  const owner = role === USER_ROLE.OWNER;

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await dispatch(getUserById(userId));

        if (isObject(user)) {
          setUser(user);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };

    getUser();
  }, [userId, dispatch]);

  const makeRestaurant = ({
    id: restaurantId,
    name,
    location: { name: address },
    rating,
    reviewCount,
    status,
  }) => {
    return (
      <Link
        theme
        className="flex items-center"
        to={path.restaurant(restaurantId)}
      >
        <Avatar className="mr-3" model={MODEL.BUSINESS} size={160} />
        <Info.Restaurant
          title={name}
          address={address}
          status={status}
          rating={rating}
          reviewCount={reviewCount}
        />
      </Link>
    );
  };

  const renderRestaurantList = () => {
    if (as.a(restaurants).length) {
      return (
        <div className="flex flex-col gap-4">
          {restaurants.map(makeRestaurant)}
        </div>
      );
    }

    return (
      <EmptyList
        image={emptyListImage.restaurant}
        title={t('web:empty.restaurants')}
      />
    );
  };

  return (
    <Layout
      className="flex flex-1 flex-col bg-grey-8"
      title={getFullName(firstName, lastName)}
    >
      <Helmet>
        <title>User</title>
      </Helmet>
      <WithLoader in={loading}>
        <div className="flex justify-end">
          <Link to={path.user.edit(userId)}>{t('button.edit')}</Link>
        </div>
        <div className="mt-6 flex items-start">
          <div className="flex flex-1 flex-col gap-6">
            <Render if={owner}>
              <Theme.Container className="flex-1">
                <Theme.Title
                  className="mb-6"
                  title={capitalize(t('restaurants_other'))}
                />
                {renderRestaurantList()}
              </Theme.Container>
            </Render>

            <Render if={subscription}>
              <Theme.Container className="flex-1">
                <Theme.Title
                  className="mb-4"
                  title={capitalize(t('web:title.subscription'))}
                />
                <Info.SubscriptionPlan {...subscription} />
              </Theme.Container>
            </Render>

            <Render if={documentsRequired}>
              <Theme.Container>
                <Theme.Title className="mb-6" title={t('web:documents')} />
                <div className="flex items-start gap-6">
                  <Document
                    {...photoOfId}
                    openable
                    label={t('label.photoOfId')}
                  />
                  <Document
                    {...photoWithId}
                    openable
                    label={t('label.photoWithId')}
                  />
                </div>
              </Theme.Container>
            </Render>
          </div>

          <div className="ml-6">
            <Theme.Card>
              <Theme.Title
                className="mb-6"
                title={t('web:personalInformation')}
              />
              <Avatar.User
                role={role}
                name={getFullName(firstName, lastName)}
                src={avatar}
              />

              <div className="mt-6">
                <RowItem
                  className={getColor(COLOR.VERIFICATION[status])}
                  icon={faUser}
                >
                  {t(`status.${status}`)}
                </RowItem>
                <RowItem icon={faCalendarCheck}>
                  {t('label.createdAt')}:
                  <span className="space-before font-600">
                    {dayjs(createdAt).format('DD.MM.YYYY')}
                  </span>
                </RowItem>
                <RowItem icon={faPhone}>+{phone}</RowItem>
                <RowItem icon={faEnvelope}>{as(email, '-')}</RowItem>
                <RowItem icon={faHouseBuilding}>{as(city, '-')}</RowItem>
              </div>
            </Theme.Card>
          </div>
        </div>
      </WithLoader>
    </Layout>
  );
};

UserPage.propTypes = propTypes;
UserPage.defaultProps = defaultProps;
