import Pusher from 'pusher-js';

import { PUSHER, SERVER_URL } from '@constants';

const wsUrl = new URL(SERVER_URL);

export const pusher = new Pusher(PUSHER.KEY, {
  cluster: PUSHER.CLUSTER,
  wsHost: wsUrl.hostname,
  wsPort: wsUrl.port || 80,
});
