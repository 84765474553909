import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';

import { PACKAGE_VERSION, PATH } from '@constants';
import { cn, createClassName, getRoleHomePage } from '@utils';

import { toggleSidebar } from '@actions';
import {
  configVersionsSelector,
  sidebarCollapsedSelector,
  userAuthorizedSelector,
  userOnboardingSelector,
  userRoleSelector,
} from '@selectors';
import { sidebarNavigation } from '@content';

import {
  Button,
  CardBrand,
  IconBox,
  Link,
  Render,
  Theme,
  Transition,
} from '@components';

export const LayoutSidebar = () => {
  const dispatch = useDispatch();
  const collapsed = useSelector(sidebarCollapsedSelector);
  const role = useSelector(userRoleSelector);
  const authorized = useSelector(userAuthorizedSelector);
  const { active: onboarding } = useSelector(userOnboardingSelector);
  const { backend: backendVersion } = useSelector(configVersionsSelector);

  const { pathname } = useLocation();
  const { t } = useTranslation();

  const year = new Date().getFullYear();

  const getClass = createClassName(collapsed);

  const isActiveUrl = (to) => {
    const indirectUrls = sidebarNavigation.indirectUrls[to] ?? [];

    if (to === pathname) {
      return true;
    }

    return indirectUrls.some((url) => matchPath(url, pathname));
  };

  const handleCollapseClick = () => {
    dispatch(toggleSidebar());
  };

  const navigation = sidebarNavigation[role]?.map(
    ({ name, to, label, icon }) => {
      const active = isActiveUrl(to);

      return (
        <li key={name}>
          <Link
            theme
            className={cx(
              'relative inline-flex h-10 w-full items-center gap-1 rounded-l-full py-2 font-500 capitalize transition-colors ',
              getClass('px-4', 'px-3'),
              {
                'text-primary-1 bg-grey-8': active,
                'text-primary-2 hover:text-white': !active,
              }
            )}
            to={to}
          >
            <IconBox icon={icon} className="h-8 w-8 icon-16" />
            <Transition
              unmountOnExit
              in={!collapsed}
              timeout={300}
              classNames="fade"
            >
              <span className="whitespace-nowrap text-[16px] leading-normal">
                {t(label)}
              </span>
            </Transition>
          </Link>
        </li>
      );
    }
  );

  return (
    <div
      className={cn(
        'fixed inset-y-0 left-0 flex shrink-0 flex-col overflow-hidden bg-primary-1 px-3 py-8 transition-[width]',
        getClass('w-[90px]', 'w-[240px]')
      )}
    >
      <div
        className={cx('flex items-center justify-center', getClass('', 'pl-3'))}
      >
        <Transition unmountOnExit in={!collapsed} className="fade">
          <Link
            theme
            to={getRoleHomePage(role)}
            className="inline-flex flex-1 self-center overflow-hidden"
          >
            <Theme.Logo
              className="gap-2.5 text-white"
              // iconContainerClassName="w-[30px] h-[30px] bg-white text-primary-1"
              iconClassName="w-[40px] h-[40px]"
            />
          </Link>
        </Transition>
        <Button
          theme
          className="text-white icon-24"
          onClick={handleCollapseClick}
        >
          <IconBox icon={faBars} className="h-8 w-8" />
        </Button>
      </div>
      <Render if={authorized}>
        <ul
          className={cx(
            'mb-auto mt-10 flex flex-col gap-4',
            getClass('-mr-6', '-mr-6'),
            {
              'pointer-events-none': onboarding,
            }
          )}
        >
          {navigation}
        </ul>
      </Render>
      <div className="mt-auto">
        <Transition unmountOnExit in={!collapsed} className="fade">
          <div className="mt-auto whitespace-nowrap text-[12px] leading-4 text-white">
            <div className="mb-2 items-center">
              <CardBrand className="mr-1" brand="mastercard" />
              <CardBrand brand="visa" />
            </div>
            <p className="my-2">
              <Link
                theme
                blank
                className="text-[12px] text-white underline"
                to={PATH.TERMS_AND_CONDITIONS}
              >
                Terms and Conditions
              </Link>
            </p>
            <p className="mb-2 whitespace-normal">
              © 2021-{year} Caribbean Apps, Inc. DBA Akicomo
            </p>

            <Render if={backendVersion}>
              <p>
                Server version:
                <span className="space-before font-700">{backendVersion}</span>
              </p>
            </Render>
            <Render if={PACKAGE_VERSION}>
              <p>
                Package version:
                <span className="space-before font-700">{PACKAGE_VERSION}</span>
              </p>
            </Render>
          </div>
        </Transition>
      </div>
    </div>
  );
};
