import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CONFIG } from '@constants';
import { toast } from '@services';
import { toLowerCase, toUpperCase } from '@utils';

import { updateConfig } from '@actions';
import { configSelector } from '@selectors';

import { ConfigList } from './ConfigList';
import * as Config from './Shared';

const propTypes = {};
const defaultProps = {};

export const ConfigShared = () => {
  const dispatch = useDispatch();
  const { locales, cuisines, categories, currencies, measures } =
    useSelector(configSelector);

  const { t } = useTranslation();

  const processField = (name, config) => {
    const toCase = name === CONFIG.SHARED.CURRENCY ? toUpperCase : toLowerCase;

    return toCase(config[name].filter(Boolean));
  };

  const handleSubmit = (name) => async (config) => {
    try {
      const { message } = await dispatch(
        updateConfig({
          type: CONFIG.TYPE.SHARED,
          name,
          value: processField(name, config),
        })
      );

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSharedSubmit = async ({ type, name, value }) => {
    try {
      const { message } = await dispatch(
        updateConfig({
          type,
          name,
          value,
        })
      );

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Config.SupportEmail onSubmit={handleSharedSubmit} />
      <Config.WhatsAppLink onSubmit={handleSharedSubmit} />
      <ConfigList
        name="locales"
        title={t('label.locale')}
        defaultValues={{ locales }}
        onSubmit={handleSubmit('locales')}
      />
      <ConfigList
        name="cuisines"
        translationKey="cuisine"
        className="mt-3"
        title={t('label.cuisine')}
        defaultValues={{ cuisines }}
        onSubmit={handleSubmit('cuisines')}
      />
      <ConfigList
        name="categories"
        translationKey="category"
        className="mt-3"
        title={t('label.category')}
        defaultValues={{ categories }}
        onSubmit={handleSubmit('categories')}
      />
      <ConfigList
        name="currencies"
        className="mt-3"
        title={t('label.currency')}
        defaultValues={{ currencies }}
        onSubmit={handleSubmit('currencies')}
      />
      <ConfigList
        name="measures"
        className="mt-3"
        translationKey="measure"
        title={t('label.measure')}
        defaultValues={{ measures }}
        onSubmit={handleSubmit('measures')}
      />
    </>
  );
};

ConfigShared.propTypes = propTypes;
ConfigShared.defaultProps = defaultProps;
