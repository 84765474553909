import React from 'react';
import { Helmet } from 'react-helmet';

const propTypes = {};
const defaultProps = {};

export const ApplicationTermsPage = () => {
  const domain = window.location.hostname;

  return (
    <div className="terms-page">
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <div>
        <h1>General Terms and Conditions</h1>
        <h2>1. General</h2>
        <p>
          Below are the Terms and Conditions (hereinafter, the &quot;Terms and
          Conditions&quot;) on which the relationship between Caribbean Apps,
          Inc. and/or Caribbean Apps LLP (hereinafter, &quot;Akicomo&quot;) and
          any person who use the services of Akicomo (hereinafter, the
          &quot;User&quot;).
        </p>
        <p>
          Any person who does not accept these Terms and Conditions, which are
          mandatory and binding, must refrain from using Akicomo&apos;s
          services.
        </p>
        <p>
          By accessing and using the Services you agree to be legally bound by
          these Terms and Conditions, which establish a contractual relationship
          between you and Akicomo. If you do not agree to these Terms, you may
          not access or use the Services. These Terms expressly supersede any
          prior agreements or commitments with you. Akicomo may immediately
          terminate these Terms or any of the Services with respect to you, or
          generally stop offering or deny access to the Services or any part of
          them, at any time and for any reason.
        </p>
        <p>
          At the time of registration, Users must express their express and
          unequivocal acceptance of the Terms and Conditions.
        </p>
        <p>
          If Users have questions regarding these Terms and Conditions, they can
          contact our Customer Service team through the chat available on our
          Portal or by email at
          <a
            href="mailto:akicomoapp@gmail.com"
            className="space-before primary-1-with-hover"
          >
            akicomoapp@gmail.com
          </a>
          .
        </p>
        <h2>2. Definitions</h2>
        <p>
          &quot;Application&quot; means the &quot;Akicomo&quot; application
          available for mobile technologies. &quot;Goods&quot; are the goods,
          products or services that may be offered by the Bidders through the
          Portal and make up the User&apos;s Order. The Goods are supplied by
          the Bidders, being responsible for them before the Users.
        </p>
        <p>
          &quot;We&quot;, &quot;Our&quot;, and &quot;Akicomo&quot;, whenever the
          words are referred to, are directly referring to the company Caribbean
          Apps, Inc. and/or Caribbean Apps LLP and their related companies.
        </p>
        <p>
          &quot;Offerer&quot; refers to external agents and/or third parties
          unrelated to Akicomo, who have previously contracted Akicomo for its
          intermediation services, providing all the information on the Goods
          that are displayed through the Portal (price, characteristics, and in
          general all its objective conditions). These, in their exclusive
          capacity as producer, supplier and/or retailer, are directly in charge
          of complying with all the objective characteristics of the product
          and/or service published on the Portal.
        </p>
        <p>
          &quot;Order&quot; refers to the User&apos;s request through the
          Bidder&apos;s Goods Portal.
        </p>
        <p>
          &quot;Portal&quot; refers to our Application and/or the Website
          through which, as intermediaries, we facilitate contact between
          Offerors, Users and distributors.
        </p>
        <p>
          &quot;User&quot; refers to all human or legal persons who access our
          Portal and place Orders either through the Website or the Application.
        </p>
        <p>
          &quot;Service&quot; refers to the intermediation service requested by
          the User through the Portal. &quot;Website&quot; refers to the website
          <span className="space-before">{domain}</span>.
        </p>
        <p>
          If any of these words defined in the plural are used, they will have
          the same meaning as indicated herein.
        </p>
        <h2>3. Capacity</h2>
        <p>
          The Services are only available to Users who have the legal capacity
          to contract and are of legal age. You cannot register a legal person
          as a User.
        </p>
        <p>
          In case of wishing to purchase alcoholic beverages and/or tobacco
          and/or medicines, in full compliance with the applicable regulations,
          the User must prove that they are over 18 years of age, showing
          identification at the time of delivery. Likewise, in the case of the
          sale of alcoholic beverages, time restrictions may be applicable
          depending on the local regulations of the User&apos;s place of
          residence, additionally, a photograph of the identification with which
          the age of majority was accredited may be requested.
        </p>
        <h2>4. Copyrights</h2>
        <p>
          The content of the Portal, including, but not limited to the texts,
          graphics, images, logos, icons, software and any other material, which
          will hereinafter be referred to as the &quot;Material&quot;, is
          protected under the applicable industrial property laws. and
          intellectual property. All Material is the property of Akicomo or its
          suppliers.
        </p>
        <p>
          It is prohibited to modify, copy, reuse, extract, exploit, reproduce,
          communicate to the public, make second or subsequent publications,
          upload or download files, send by mail, transmit, use, treat or
          distribute in any way all or part of the contents included in the
          Portal.
        </p>
        <p>
          Unauthorized use of the Material may violate copyright, industrial
          property, or other laws. No User may sell or modify the Material in
          any way, or publicly perform or post the Material, or distribute it
          for commercial purposes.
        </p>
        <p>
          Nor will it be allowed to copy or adapt the HTML code that Akicomo
          creates to generate its web page or platform, since it is protected by
          copyright.
        </p>
        <h2>5. Trademarks</h2>
        <p>
          Akicomo, the Akicomo logo, and other marks indicated on the Portal are
          trademarks of Akicomo. Other Akicomo graphics, logos, page headers,
          button icons, scripts, and service names are trademarks or trade dress
          of Akicomo. Akicomo&apos;s trademarks and trade dress may not be used
          in connection with any product or service that is not Akicomo&apos;s,
          if any, in any manner that is likely to cause confusion among Users or
          that dismisses or discredits Akicomo.
        </p>
        <h2>6. Authorized Use of the Portal</h2>
        <p>
          The main purpose of the Portal is the intermediation between Users and
          Offerors to place Orders in the cities that have the service,
          facilitating relations and transactions between Users and Offerors
          adhered to the Portal and providing the shipping service in the cases
          that are indicated to the User. Akicomo contacts the Offeror,
          redirects the order, informs the User about its availability or not in
          accordance with the information provided by the Offeror and manages
          its delivery to the User in those cases where it is so informed.
          Through the Portal, Users are centralized and granted all the
          necessary tools for them to place an Order.
        </p>
        <h2>7. Account Creation</h2>
        <p>
          To use the Portal, Users must create a user account (hereinafter, the
          &quot;Account&quot;) where certain personal data will be requested
          such as: name and surname, address, telephone, email and data to
          process online payments. (hereinafter, the &quot;Data&quot;). Users
          guarantee and are responsible, in any case, for the veracity,
          accuracy, validity, authenticity and updating of their Data. In no
          case Akicomo is responsible for the updating and accuracy of User
          Data.
        </p>
        <p>
          If Users have a Facebook or Google account, they may be offered the
          possibility of creating their Account using the credentials included
          therein.
        </p>
        <p>
          Akicomo may require any additional information or documentation for
          the purposes of verifying or corroborating the Data, and may
          temporarily or permanently suspend those Users whose Data could not be
          confirmed.
        </p>
        <p>
          To access your personal Account, the User must enter your email and a
          password which must always be kept confidential. If the User forgets
          his password, he can reset it by clicking on &quot;I forgot my
          password&quot;.
        </p>
        <p>
          The Account is unique and non-transferable, and it is forbidden for a
          User to register or have more than one Account by email or by
          validated cell phone, so if such non-compliance is detected, Akicomo
          reserves the right to cancel, suspend or disable the accounts.
        </p>
        <p>
          The User is responsible for safeguarding their registration data and
          password, as well as all transactions made in their Account, and must
          notify Akicomo immediately, in a reliable manner, of any unauthorized
          use of the same, as well as any theft, disclosure or loss of your
          access data to the Portal. Akicomo watches over the protection of
          Users&apos; data. However, it will not be responsible for the
          fraudulent use that third parties may make of the User&apos;s Account,
          including improper use of their data associated with online payments.
        </p>
        <p>
          Users have the power to exercise the right of access, at any time and
          without restrictions, of their personal data. Akicomo has the
          authority to deal with complaints and claims filed in relation to
          non-compliance with the rules on personal data protection.
        </p>
        <p>
          You may not: (i) remove any copyright, trademark, or other proprietary
          notice from any portion of the Services; (ii) reproduce, modify,
          prepare derivative works on the Services, distribute, license, lease,
          resell, transfer, publicly display, publicly perform, transmit,
          retransmit, or otherwise exploit the Services, except as expressly
          permitted by Akicomo; (iii) decompile, reverse engineer, or
          disassemble the Services, except as permitted by applicable law; (iv)
          link, mirror or frame any part of the Services; (v) cause or launch
          any program or script for the purpose of mining, indexing, analyzing
          or otherwise mining any part of the Services or unduly overloading or
          blocking the operation and/or functionality of any aspect of the
          Services ; or (vi) attempt to gain unauthorized access to or damage
          any aspect of the Services or its related systems or networks.
        </p>
        <p>
          The Services may be made available or accessible in connection with
          third party services and content (including advertising) that Akicomo
          does not control. You acknowledge that different terms and privacy
          policies may apply to your use of such third party content and
          services. Akicomo does not endorse such third party content and
          services and in no event shall Akicomo be liable for any products or
          services of such third party providers. Additionally, Apple Inc.,
          Google, Inc., Microsoft Corporation or BlackBerry Limited and/or their
          corresponding international subsidiaries or affiliates will be third
          party beneficiaries in this agreement if you access the Services using
          Applications developed for mobile devices with iOS, Android, Microsoft
          Windows, respectively. These third party beneficiaries are not a party
          to this agreement and are not responsible for providing or supporting
          the Services in any way. Your access to the Services using these
          devices is subject to the terms set forth in the applicable third
          party beneficiary terms of service.
        </p>
        <p>
          In order to use most aspects of the Services, you must register for
          and maintain an active personal user account for the Services
          (&quot;Account&quot;). To obtain an Account you must be at least 18
          years of age, or the legal age of majority in your jurisdiction (if
          different than 18). Account registration requires you to provide
          Akicomo with certain personal information, such as your name, address,
          mobile phone number, as well as at least one valid payment method
          (either a credit card or an accepted payment partner). . You agree to
          keep the information in your Account accurate, complete, and
          up-to-date. Failure to maintain accurate, complete, and current
          Account information, including having an invalid or expired payment
          method, may result in your inability to access and use the Services or
          in Akicomo&apos;s termination of this Agreement. celebrated with you.
          You are responsible for all activity that occurs on your Account and
          you agree to keep your Account username and password secure and secret
          at all times. Unless otherwise permitted in writing by Akicomo, you
          may only have one Account.
        </p>
        <p>
          The Service is not available for use by persons under the age of 18.
          You may not authorize third parties to use your Account, nor may you
          allow persons under the age of 18 to receive services from Third Party
          Providers, unless they are accompanied by you. You may not assign or
          otherwise transfer your Account to any other person or entity. You
          agree to comply with all applicable laws when using the Services and
          may only use the Services for lawful purposes. In the use of the
          Services, you will not cause hindrance, annoyance, inconvenience or
          damage to property, either to the Third Party Provider or to any other
          party. In some cases, you may be required to provide an identity
          document or other identity verification item to access or use the
          Services, and you agree that you may be denied access to or use of the
          Services if you refuse to provide the identity document or the
          identity verification element.
        </p>
        <p>
          By creating an Account, you agree that the Services may send you
          informational text messages (SMS) as part of the normal business of
          your use of the Services. You will not be able to request not to
          receive informative text messages (SMS).
        </p>
        <h2>8. Order Procedure and General Clarifications</h2>
        <p>
          Akicomo offers an online intermediation platform so that the Bidders
          can offer their Goods, and the Users can acquire them and receive them
          at home. In this framework, Akicomo displays the information of the
          Bidder&apos;s Assets according to the information and restrictions
          provided by the Bidders.
        </p>
        <p>
          The Services constitute a technology platform that allows users of
          Akicomo&apos;s mobile applications or web pages provided as part of
          the Services (each, an &quot;Application&quot;) to organize and plan
          the Order and/or logistics services with independent third-party
          providers. of such services and/or Orders, including independent third
          party carriers and independent third party providers, pursuant to an
          agreement with Akicomo or certain affiliates of Akicomo (&quot;Third
          Party Providers&quot;). Unless otherwise agreed by Akicomo in a
          separate written agreement with you, the Services are made available
          solely for your personal, non-commercial use.
        </p>
        <p>
          You acknowledge that akicomo does not provide transportation or
          logistics services or operates as a transportation company and that
          such transportation or logistics services are provided by third party
          companies and/or independent persons, who are not employed by akicomo
          or any of its affiliates. You acknowledge that akicomo does not
          provide catering services or operates as a food or beverage
          preparation and/or server company and that such services are provided
          by third party companies and/or independent persons, who are not
          employed by akicomo or any of its affiliates.
        </p>
        <p>
          The User understands and accepts that Akicomo does not produce,
          provide, sell, sell or be an agent, nor in general any type of
          marketer of the exhibited Goods; Therefore, the purchase relationship
          is structured between the User and the Offeror. Likewise, the User
          acknowledges that he is solely responsible for the correct consignment
          of the delivery and collection addresses of the Orders, exempting
          Akicomo and the distributors from liability for any error or
          negligence.
        </p>
        <p>
          The User will be able to see the different options available without
          being registered. However, to be able to formalize an Order, the User
          must be registered with his Account and must enter the delivery
          address. Once the above has been done, the User will be able to see
          the different options of Bidders, Goods, location of the Bidders, etc.
        </p>
        <p>
          The User understands and accepts that Akicomo does not produce,
          provide, sell, sell or be an agent, nor in general any type of
          marketer of the exhibited Goods; Therefore, the purchase relationship
          is structured between the User and the Offeror. Likewise, the User
          acknowledges that he is solely responsible for the correct consignment
          of the delivery and collection addresses of the Orders, exempting
          Akicomo and the distributors from liability for any error or
          negligence.
        </p>
        <p>
          The User will be able to see the different options available without
          being registered. However, to be able to formalize an Order, the User
          must be registered with his Account and must enter the delivery
          address. Once the above has been done, the User will be able to see
          the different options of Bidders, Goods, location of the Bidders, etc.
        </p>
        <p>
          When the User has defined where and what he wants to order, he must
          enter the Bidder&apos;s profile on the Portal and choose the Good(s)
          he wishes to acquire. Once selected, the characteristics, conditions
          and total value of the Order are made available to the User, according
          to the information provided by the Offeror. The User must validate the
          Order and select the payment method chosen through the available means
          of payment informed by each Bidder. Once the payment method has been
          selected, the User must confirm the Order.
        </p>
        <p>
          It is important to clarify that all Users undertake to pay the price
          of the Order from the moment they receive the Order confirmation
          communication as indicated below in these Terms and Conditions. After
          receiving said communication, the User may only refrain from making
          the payment if the Order is canceled correctly, as provided in these
          Terms and Conditions.
        </p>
        <p>
          If the User does not receive the Order at the address indicated for it
          or if there is any setback in which the User&apos;s data is not
          verified and the order is rejected once it was confirmed by Akicomo
          and in that sense no corrections are received once the confirmation,
          all responsibility will fall on the User and the User must indemnify
          the Portal by taking charge of all the costs generated by the error in
          the transaction, for example, the shipping cost that may apply.
        </p>
        <p>
          All the Orders that are made through the Portal are transmitted to the
          Offerors, who may contact the User, for example, if the products or
          services that make up the Order are not available or if any
          observation made by the User was not clear. the User in the Order.
        </p>
        <p>
          When the Bidder accepts or rejects the Order, the User is notified
          with an email, a PUSH notification (pop-up) or other means of
          communication, where the reception of the Order is rejected or
          confirmed, which occurs automatically with the details.
        </p>
        <p>
          In case the Order is rejected by the Offeror, Akicomo will notify the
          User without the obligation to notify the reasons for the rejection.
        </p>
        <p>
          If the order is confirmed, the PUSH (emerging) or other means of
          communication will indicate the delivery time of the Order. Said
          delivery time is exclusively estimated, and the User acknowledges that
          it may undergo small adjustments while the Order is being prepared
          (said adjustments will be reflected in the status of the order that is
          displayed on the Portal). The User, when placing his Order,
          acknowledges and accepts that innumerable factors such as traffic,
          weather, peak hours and the ability to prepare the order and delivery
          of some Bidders, may be limiting to ensure the time of delivery.
        </p>
        <p>
          During the time that elapses between the confirmation of the Order and
          its actual delivery, the User may contact Akicomo at any time for the
          purpose of asking questions, submitting complaints, among others,
          cases in which they will always receive a response in the shortest
          time. possible. The User may cancel the Order once the delivery period
          indicated in the order confirmation has elapsed.
        </p>
        <p>
          Akicomo will manage the shipment of the Order except in those cases in
          which it acts as an intermediary with the delivery service in charge
          of the Bidders and will focus its efforts on resolving all complaints
          or problematic situations that are configured due to delays,
          incomplete or wrong orders. , etc. In all cases, without exception,
          complaints must be presented in decent and respectful language, taking
          into account the minimum requirements of courtesy and education.
          Otherwise, the Portal will not be obliged to provide any response, and
          on the contrary, according to its own criteria, it may proceed to
          block the User from its database, leaving said User unable to use the
          Services again.
        </p>
        <p>
          Users may voluntarily provide their experience to other Users through
          comments. All comments and ratings may be published by Akicomo based
          on their relevance. It will be necessary for Users who wish to make a
          comment about a Bidder to have had an experience with the Bidder.
          Additionally, that the content of the comment does not transgress
          morality and good customs. Akicomo reserves the right to reject or
          remove a comment in the following circumstances: Use of offensive
          language (use of obscene, abusive and racist language is not tolerated
          in our community sections); discrimination based on religion, race,
          gender, national origin, age, marital status, sexual orientation, or
          disability; references to illegal activity; acts of unfair competition
          or Conflicts of Interest (Competing Bidders are not allowed to post
          negative comments regarding other Bidders).
        </p>
        <p>
          Comments are not endorsed by Akicomo nor do they represent
          Akicomo&apos;s opinion. However, they are the property of Akicomo and
          as a consequence, we have the right to reproduce, translate, transmit
          and/or distribute all materials related to comments.
        </p>
        <p>
          The delivery of the Order may be made with the Bidder&apos;s couriers
          or others made available by Akicomo. The User understands and accepts
          that Akicomo will not be liable to the User for delays or non-delivery
          of the Order or in the event that it is altered or damaged when the
          Offeror is in charge of this service.
        </p>
        <h2>9. Price of Goods</h2>
        <p>
          The price of the Goods will be presented on the Portal, according to
          the information provided by the Bidder, before the transaction is
          confirmed by the User. Said price will include all possible surcharges
          for taxes, additions, etc., that are applicable to each transaction.
          The minimum amount of the necessary purchase and the shipping cost
          will always be informed, which will be detailed in the summary prior
          to the confirmation of the transaction.
        </p>
        <p>
          The User understands and accepts that, depending on the status of the
          order, it may not be feasible to make changes to the order, although
          it may be canceled in accordance with the provisions of these Terms
          and Conditions.
        </p>
        <p>
          It is the responsibility of the User to ensure at the time of delivery
          of the Order, that it corresponds to the Goods requested and that
          everything received is correct. Otherwise, the User can reject the
          Order by returning it to the delivery person.
        </p>
        <h2>10. Service Fee</h2>
        <p>
          Akicomo will receive a charge for the provision of the service called
          Service Fee, which will be informed and detailed to the Users at the
          time of check out in each of the Orders.
        </p>
        <h2>11. Means of Payment</h2>
        <p>
          The payment methods for each Order depend on each Bidder, so the User
          may verify this information in their respective profiles.
        </p>
        <h4>1. Payment on delivery</h4>
        <p>
          Payment in cash: The User may pay the Order to the distributor in cash
          in legal tender in the country. Likewise, the User may indicate the
          exact amount with which the Order will be paid, so that it can be
          established if there is any difference charge that must be returned to
          the User.
        </p>
        <p>
          POS: The User may pay for the Order with POS at the address indicated
          for delivery.
        </p>
        <h4>2. Online payment</h4>
        <p>
          The User may pay the price of the Order through electronic means of
          online payment such as credit card or debit card, as well as Akicomo
          discount coupons, virtual wallet, and others that are enabled and
          informed to Users in the future).
        </p>
        <p>When the User chooses to pay online:</p>
        <ul>
          <li>
            <p>
              they must charge a credit card, debit card or other authorized
              electronic payment method to pay the amount of the Order. This
              information is managed securely; your data is not stored in
              Akicomo; or
            </p>
          </li>
          <li>
            <p>
              you must select an electronic payment method previously uploaded
              on the Portal.
            </p>
          </li>
        </ul>
        <p>
          The User must take particular care in supplying personal data, which
          must be complete and true at the time of placing the Order. Likewise,
          when entering the data, the User guarantees that
        </p>
        <ul>
          <li>
            <p>
              the data that he provides of his electronic means of payment are
              his property and that he has sufficient funds to make the payment,
              when this is the preferred method;
            </p>
          </li>
          <li>
            <p>
              your identity corresponds to the information contained in the
              official and original identification granted by the national
              authorities.
            </p>
          </li>
        </ul>
        <p>
          The amount is deducted when placing the Order. If the Order is
          subsequently canceled -either by Akicomo, by the User or by the
          Offeror-, Akicomo will process the return instantly. However, the
          reimbursement depends on the times stipulated in the policies of the
          issuer of each electronic payment method (eg card), over which Akicomo
          has no responsibility or ability to modify the times that these
          refunds take, resulting in sufficient means of proof of Akicomo&apos;s
          diligent action, the accreditation through payment processing
          documentation, of the request for return of the sums by Akicomo to the
          payment processing companies and card issuing companies, as
          appropriate.
        </p>
        <p>
          If the aforementioned refunds cannot be executed due to factors
          associated with the issuers of the electronic means of payment, the
          amount will be credited by means of a Coupon in the User&apos;s
          account and they will be notified of said credit.
        </p>
        <p>
          Online transactions will be confirmed with the validation and
          acceptance of payment by the User&apos;s bank or the chosen means of
          payment. Akicomo is not responsible for the internal authorization
          procedures established by the bank/financial institution that issues
          the payment instruments, nor for the permits or limits on purchases
          established between the User and their bank or that the means of
          payment require. to make purchases online.
        </p>
        <p>
          In the detail of the transaction, the User will be able to verify the
          complete payment information. If the User paid for the order with
          Online Payment and the payment was confirmed, they should not make
          another payment for the Order, unless they had made changes to the
          Order and these have been confirmed by Akicomo.
        </p>
        <p>
          When the Order is paid with Online Payment, the User may (depending on
          the delivery area) allocate an extra amount or percentage as a tip to
          the delivery man who delivers his Order.
        </p>
        <p>
          Akicomo does not designate any portion of your payment as a driver tip
          or gratuity. Any statement by Akicomo that tipping is
          &quot;voluntary&quot; and/or &quot;not required&quot; in payments you
          make for Services or Goods is not intended to suggest that Akicomo
          provides additional amounts to the driver. The User is free to provide
          an additional payment as a gratuity to the courier who delivers his
          Order.
        </p>
        <p>
          In case the order is canceled -whether by Akicomo, by the User or by
          the Offeror-, the return of what is delivered by the User as a tip,
          will operate in the same way as the refund of the amount of the Order,
          as indicated more up.
        </p>
        <p>
          The User has the right to revoke the acceptance of the Order within 10
          days of placing or receiving it. In such cases, you must inform
          Akicomo by email at akicomoapp@gmail.com of your intention to revoke
          the acceptance or repentance. The product or products to be returned
          must be in perfect condition, unused and with their packaging or
          wrapping intact. Repentance will not proceed in the case of perishable
          goods.
        </p>
        <h2>12. Advertising</h2>
        <p>
          Akicomo has an advertising service through which it is sent to Users
          through flags (banners), emails and/or other means.
        </p>
        <p>
          The User can request not to receive more emails or other notifications
          related to advertising by configuring the profile of his account.
        </p>
        <h2>13. Promotions, Contests and Events</h2>
        <p>
          The promotions, contests, discounts, raffles and events that are
          implemented through the Portal will be subject to the rules and
          conditions that are established at each opportunity as well as these
          Terms and Conditions. The User accepts that Akicomo may enter into
          commercial agreements with third parties for the purpose of carrying
          out promotional communications, including the delivery of free samples
          at home together with the Order, which will be informed together with
          each promotional action that is carried out. Likewise, you can make
          rebates on the charges to be received such as shipping cost or Service
          Fee.
        </p>
        <h2>14. Participation in Challenges</h2>
        <p>
          Akicomo users may be invited, based on their purchasing or search
          preferences, to participate in particular and individually directed
          challenges that will be communicated in the app (the
          &quot;Challenges&quot;). The Challenges will indicate a challenge or
          slogan to be fulfilled in exchange for a benefit that will be informed
          and that may consist of a discount, special edition of products, etc.
          (&quot;the benefit&quot;).
        </p>
        <p>
          The Challenges are personal to the users who receive them and may not
          be assigned or transferred, nor may they be exchanged for cash.
        </p>
        <p>
          Mode of Use: In the communication of the Challenges, the slogan to be
          fulfilled and the Benefit that can be accessed will be indicated, as
          well as the particular conditions of validity, validity and
          restrictions.
        </p>
        <p>Restrictions and clarifications:</p>
        <ol>
          <li>
            <p>
              The Challenges will have an effective date and must be completed
              before said date, which is indicated in them.
            </p>
          </li>
          <li>
            <p>
              The Benefits will be reflected, in case of having fulfilled the
              proposed Challenges, in the Coupons section.
            </p>
          </li>
          <li>
            <p>The Benefits that are not used, will expire losing validity.</p>
          </li>
          <li>
            <p>
              Once they have been used, the Benefits will lose all validity and
              validity.
            </p>
          </li>
        </ol>
        <h2>15. Liability</h2>
        <p>
          Akicomo only makes available to the Users a virtual intermediation
          space that allows them to communicate through the Internet with the
          Users, the Offerors and the distributors and thus buy and sell,
          respectively, the Goods. Akicomo is not the supplier or owner of the
          Goods, does not have possession of them or offer them for sale. The
          Bidder is solely responsible for the existence, quality, quantity,
          condition, integrity, safety or legitimacy of the Goods. The User
          knows and accepts that when placing Orders to the Offerors he does so
          at his own risk.
        </p>
        <p>
          In the event that the Goods offer guarantees (for example, electronic
          products displayed on the Portal), the Bidder will be obliged to offer
          these guarantees and in no case, Akicomo.
        </p>
        <p>
          If the Goods do not meet the proper and necessary quality, suitability
          or safety, the User may request the exchange of these, or the return,
          as appropriate; and provided that there is a legal and rational basis
          for it, for which Akicomo will transfer such request to the Bidder,
          who will decide whether or not it is appropriate in its capacity as
          producer and sole party responsible for the objective characteristics
          offered. The guarantee only applies to objective characteristics of
          the products.
        </p>
        <h2>16. General Rules</h2>
        <p>
          Users may not use the Portal or the App for the purpose of
          transmitting, distributing, storing or destroying material (i) in
          violation of any applicable law or regulation, (ii) in a way that
          infringes the laws on copyright, property industrial, trade secrets or
          any other intellectual property rights of third parties or in a manner
          that violates the privacy, publicity or other personal rights of third
          parties, or (iii) in a way that is defamatory, obscene, threatening or
          abusive. This is without prejudice to particular rules on the matter
          that are mandatory in each of the legal systems corresponding to the
          territories in which Akicomo will provide its service.
        </p>
        <h2>17. Safety Rules</h2>
        <p>
          Users are prohibited from violating or attempting to violate the
          security of the Portal. Specifically, the Users, by way of example and
          without limitation, may not (i) access information that is not
          directed or authorized to said User or access servers or accounts to
          which the User is not authorized to access; (ii) attempting to test
          the vulnerability of a system or network without proper authorization
          or breaching security or authentication measures; (iii) attempt to
          interfere with the services provided to a User, server or network,
          including but not limited to sending viruses through the Portal or
          overloading traffic for denial of service; (iv) send unsolicited
          email, including promotions and/or advertising of products or
          services. Violation of any security system or network may result in
          civil and criminal liability. Akicomo will investigate the occurrence
          of events that may constitute violations of the foregoing and will
          cooperate with any competent authority in the prosecution of Users who
          are involved in such violations; (v) impersonate the identity of other
          users or natural or legal persons of any kind; (vi) provide incorrect,
          incomplete or false identity information; and (vii) under no
          circumstances will the action of making false orders or creating users
          for fraudulent purposes be tolerated, act that will be denounced and
          will be subject to the applicable legal prosecutions.
        </p>
        <p>
          The Portal may only be used for lawful purposes. Its use in any of the
          following ways is prohibited:
        </p>
        <ol>
          <li>
            <p>
              Include in the Portal any right of franchise, pyramid scheme,
              membership to a club or group, sales representation, commercial
              agency or any business opportunity that requires advance payment
              or periodic payments, requesting the recruitment of other members,
              sub -distributors or sub-agents.
            </p>
          </li>
          <li>
            <p>
              Delete or review any material included in the Portal by any other
              person or entity, without proper authorization.
            </p>
          </li>
          <li>
            <p>
              Use any element, design, software or routine to interfere or
              attempt to interfere with the proper functioning of the Portal or
              any activity that is carried out on the Portal.
            </p>
          </li>
          <li>
            <p>
              Attempt to decipher, compile or disassemble any software included
              in the Portal or that is part of it in any way.
            </p>
          </li>
          <li>
            <p>
              As already mentioned, it is strictly forbidden to include false,
              inaccurate, incomplete, incorrect or misleading information on the
              Portal.
            </p>
          </li>
        </ol>
        <h2>18. No Anauthorized Resale, Assignment or Commercial Use</h2>
        <p>
          Users agree not to resell or assign their rights or obligations by
          accepting these Terms and Conditions. They also undertake not to make
          unauthorized commercial use of the Portal.
        </p>
        <h2>19. Termination</h2>
        <p>
          In cases of suspected fraud or commission of a crime by Users, Akicomo
          may suspend or cancel the User&apos;s registration.
        </p>
        <p>
          For their part, at any time during the relationship, the User may
          cancel their registered account, for which to manage and/or delete
          their personal data, the User may go to the Portal to &quot;Menu&quot;
          &gt; &quot;Online Help&quot; &gt; &quot;My account&quot; and there
          manage the request to unsubscribe for free from your account or update
          your personal data.
        </p>
        <h2>20. Additional Information</h2>
        <p>
          Akicomo guarantees to have sufficient security measures so that the
          Portal operates error-free and that its server is virus-free. If the
          Users&apos; own devices require maintenance and/or updates, it will be
          the sole responsibility of the Users to carry out the Portal updates
          that are indicated and verify before placing an order that there are
          no pending updates in the app.
        </p>
        <p>
          The Portal and the Material are made available to users in the state
          in which they are found and as informed by the offerors.
        </p>
        <p>
          Our collection and use of personal information in connection with the
          Services is in accordance with Akicomo&apos;s Privacy Policy. Akicomo
          may provide a claims processor or an insurer with any necessary
          information (including your contact information) if there are any
          complaints, disputes or conflicts, which may include an accident,
          involving you and a third party and such information or data is
          necessary to resolve the complaint, dispute or conflict.
        </p>
        <h2>21. Modification and Updates of the Terms and Conditions</h2>
        <p>
          Akicomo may make modifications and updates to these Terms and
          Conditions, for which the consent of the Users will be requested as a
          requirement to continue using the Portal.
        </p>
        <p>
          Akicomo may modify the Terms relating to the Services from time to
          time. Modifications will be effective upon Akicomo&apos;s posting of
          such updated Terms at this location or the modified policies or
          supplemental terms on the applicable Service. Your continued access or
          use of the Services after such posting constitutes your consent to be
          bound by the Terms and their modifications.
        </p>
        <h2>22. Ppplicable Law and Jurisdiction</h2>
        <p>
          The relationship between the User and Akicomo, as well as the
          interpretation and application of these Terms and Conditions will be
          governed in accordance with the legislation of the United Kingdom.
        </p>
      </div>
    </div>
  );
};

ApplicationTermsPage.propTypes = propTypes;
ApplicationTermsPage.defaultProps = defaultProps;
