import React from 'react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';

import { cn, getSlots } from '@utils';

import { IconBox, Slot } from '@components';

const propTypes = {
  hidden: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  className: undefined,
  hidden: false,
  children: null,
};

export const WithDotSeparator = ({ className, hidden, children }) => {
  const { separator, content } = getSlots(children);

  if (hidden) {
    return content;
  }

  return (
    <>
      <Slot slot={separator}>
        <IconBox
          className={cn('mx-1 inline-flex h-2 w-2 icon-3', className)}
          icon={faCircle}
        />
      </Slot>
      {content}
    </>
  );
};

WithDotSeparator.propTypes = propTypes;
WithDotSeparator.defaultProps = defaultProps;
