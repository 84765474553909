import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  faBox,
  faFileInvoice,
  faLocationDot,
  faPhone,
  faWallet,
} from '@fortawesome/pro-light-svg-icons';
import {
  faExternalLink,
  faHeartCrack,
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import {
  ORDER_STATUS,
  PAGES_NAME,
  PAYMENT_METHOD,
  SHIPPING,
  STATUS,
  USER_ROLE,
} from '@constants';
import { pusher } from '@services';
import { useRole } from '@hooks';
import {
  as,
  getFormattedPriceWithCurrency,
  getFullName,
  isObject,
  noop,
} from '@utils';

import { getOrderById } from '@actions';
import { configSelector, userIdSelector } from '@selectors';

import {
  Avatar,
  Button,
  IconBox,
  Layout,
  Link,
  Modal,
  Modals,
  Render,
  Theme,
  WithLoader,
} from '@components';
import * as Order from '@components/Order';
import { Review } from '@components/Review';
import { RowItem } from '@components/ui';

const propTypes = {};
const defaultProps = {};

export const OrderPage = () => {
  const dispatch = useDispatch();
  const { whatsAppLink } = useSelector(configSelector);
  const userId = useSelector(userIdSelector);
  const { management } = useRole();

  const { t } = useTranslation();
  const { orderId } = useParams();

  const [order, setOrder] = useState({});

  const loading = isObject.empty(order);
  const {
    comment,
    courierLocation,
    customerLocation,
    dishes,
    payment: paymentMethod,
    preparedCash,
    restaurant,
    review,
    shipping,
    status,
    statuses,
    taxReceipt,
    total,
  } = order;
  const title = `${t(PAGES_NAME.ORDER)} ${orderId}`;
  const courier = as.o(order.courier);
  const customer = as.o(order.customer);
  const {
    currency,
    deliveryCharge,
    id: restaurantId,
    name: restaurantTitle,
  } = as.o(restaurant);
  const { name: locationName, comment: locationComment } =
    as.o(customerLocation);
  const delivery = shipping === SHIPPING.DELIVERY;
  const {
    id: reviewId,
    createdAt,
    restaurant: { rating, review: orderReview } = {},
    status: reviewStatus,
  } = as.o(review);
  const assigningCourier =
    [ORDER_STATUS.ACCEPTED, ORDER_STATUS.DELIVERING].includes(status) &&
    delivery;

  useEffect(() => {
    dispatch(getOrderById(orderId)).then(setOrder).catch(noop);
  }, [orderId, dispatch]);

  useEffect(() => {
    const channel = pusher.subscribe(`orders.${orderId}`);

    channel.bind('order', ({ order }) => {
      setOrder(order);
    });

    return () => {
      pusher.unsubscribe(`orders.${orderId}`);
    };
  }, [orderId]);

  const deleted = ({ id, status }) =>
    !id || status === STATUS.VERIFICATION.DELETED;

  const anonymous = (user, text) => (deleted(user) ? t('anonymous') : text);

  const handleReviewUpdate = (review) => {
    setOrder((order) => ({ ...order, review }));
  };

  const user = {
    name: ({ firstName, lastName }) => getFullName(firstName, lastName),
    phone: ({ phone }) => `+${phone}`,
  };

  const getDeliveryCharge = () => (delivery ? deliveryCharge : 0);

  const renderHighlighText = ({ title, text, if: source = true }) => {
    return (
      <p className="text-body-2-m">
        {title}
        <Render if={source}>
          :<span className="space-before font-700">{text}</span>
        </Render>
      </p>
    );
  };

  return (
    <Layout className="flex flex-1 flex-col" title={title}>
      <Helmet>
        <title>Order Details</title>
      </Helmet>
      <div className="flex items-center justify-end">
        <Render if={!loading}>
          <Order.Actions
            orderId={orderId}
            shipping={shipping}
            status={status}
          />
        </Render>
      </div>
      <WithLoader in={loading}>
        <div className="mt-10 flex gap-6">
          <div className="flex flex-1 flex-col gap-6">
            <Theme.Container className="px-4 py-6">
              <Theme.Title className="mb-3" title={t('Customer order')} />
              <Order.Details currency={currency} menu={dishes} />
              <p className="mt-4 bg-grey-8 px-20 py-4 text-subtitle font-600 text-grey-1">
                {t('web:orderComment')}
              </p>
              <p className="whitespace-pre-wrap px-20 py-6 text-footnote italic text-grey-1">
                <Render if={comment} else="-">
                  {comment}
                </Render>
              </p>
              <div className="h-px bg-grey-7" />
              <div className="flex flex-col justify-end gap-2 px-14 py-6 text-right text-footnote">
                <p>
                  {t('label.deliveryCharge')}:
                  <span className="space-before font-700">
                    {getFormattedPriceWithCurrency(
                      getDeliveryCharge(),
                      currency
                    )}
                  </span>
                </p>
                <p>
                  {t('label.total')}:
                  <span className="space-before font-700">
                    {getFormattedPriceWithCurrency(total, currency)}
                  </span>
                </p>
              </div>
            </Theme.Container>
            <Render if={customerLocation && courierLocation}>
              <Theme.Container>
                <Order.Map
                  customerLocation={customerLocation}
                  courierLocation={courierLocation}
                />
              </Theme.Container>
            </Render>
            <Render if={review}>
              <Review
                isAuthor={userId === customer.id}
                managable={management}
                avatar={customer.avatar}
                createdAt={createdAt}
                userName={user.name(customer)}
                rating={rating}
                review={orderReview}
                reviewId={reviewId}
                status={reviewStatus}
                restaurantId={restaurantId}
                restaurantTitle={restaurantTitle}
                onStatusChange={handleReviewUpdate}
              />
            </Render>
          </div>
          <div className="flex flex-col gap-6">
            <Theme.Card>
              <Theme.Title title="Order status" className="mb-4" />
              <Order.Tracker currentStatus={status} statuses={statuses} />
            </Theme.Card>

            <Theme.Card className="flex flex-col gap-6">
              <Theme.Title title={t('web:label.customerInfo')} />
              <Avatar.User
                role={USER_ROLE.CUSTOMER}
                src={customer.avatar}
                name={anonymous(customer, user.name(customer))}
              />
              <div>
                <RowItem
                  className={cx({ italic: deleted(customer) })}
                  icon={faPhone}
                  text={anonymous(customer, user.phone(customer))}
                />
                <Render if={locationName}>
                  <RowItem
                    icon={faLocationDot}
                    text={locationName}
                    footnote={locationComment}
                  />
                </Render>
                <RowItem icon={faBox} text={t(`shipping.${shipping}`)} />
                <RowItem icon={faWallet}>
                  {renderHighlighText({
                    title: t(`paymentMethod.${paymentMethod}`),
                    if: preparedCash && paymentMethod === PAYMENT_METHOD.CASH,
                    text: getFormattedPriceWithCurrency(preparedCash, currency),
                  })}
                </RowItem>
                <Render if={taxReceipt}>
                  <RowItem icon={faFileInvoice}>
                    {renderHighlighText({
                      title: t('label.taxReceipt'),
                      text: 'B01',
                    })}
                  </RowItem>
                </Render>
                <Render if={deleted(customer)}>
                  <RowItem icon={faHeartCrack} className="text-other-2">
                    {t('web:accountDeleted')}
                  </RowItem>
                </Render>
              </div>
            </Theme.Card>

            <Render if={assigningCourier}>
              <Modal
                className="max-w-[500px]"
                content={
                  <Modals.AssignCourier
                    orderId={orderId}
                    restaurantId={restaurantId}
                    setOrder={setOrder}
                  />
                }
              >
                <Button className="w-full">{t('web:assignCourier')}</Button>
              </Modal>
            </Render>

            <Render if={courier}>
              <Theme.Card className="flex flex-col gap-6">
                <Theme.Title title={t('web:label.courierInfo')} />
                <Avatar.User
                  role={USER_ROLE.COURIER}
                  src={courier.avatar}
                  name={anonymous(courier, user.name(courier))}
                />
                <div>
                  <RowItem
                    className={cx({ italic: deleted(courier) })}
                    icon={faPhone}
                  >
                    {anonymous(courier, user.phone(courier))}
                  </RowItem>
                  <Render if={deleted(courier)}>
                    <RowItem icon={faHeartCrack}>
                      {t('web:accountDeleted')}
                    </RowItem>
                  </Render>
                </div>
              </Theme.Card>
            </Render>

            <Render if={whatsAppLink}>
              <Link external blank to={whatsAppLink} variant="outline">
                <IconBox
                  icon={faExternalLink}
                  className="mr-1 h-4 w-4 text-primary-1"
                />
                Order support
              </Link>
            </Render>
          </div>
        </div>
      </WithLoader>
    </Layout>
  );
};

OrderPage.propTypes = propTypes;
OrderPage.defaultProps = defaultProps;
