import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_TYPE, CONFIG, PRODUCTION } from '@constants';
import { firebase, sentry } from '@services';
import { as } from '@utils';

import {
  authInitialize,
  setDeviceToken as setDeviceTokenAction,
} from '@actions';
import { createLoadingSelector } from '@selectors';

import { useFeature } from './useFeature';

const authLoadingSelector = createLoadingSelector(ACTION_TYPE.AUTH_LOGOUT);

export const useInitinalization = () => {
  const dispatch = useDispatch();
  const authLoading = useSelector(authLoadingSelector);
  const { enabled: maintenance } = useFeature(CONFIG.FEATURE.MAINTENANCE);

  const [initialized, setInitialization] = useState(false);

  useEffect(() => {
    const { protocol, host, pathname, search } = as.o(window?.location);

    if (PRODUCTION && protocol === 'http:') {
      window.location.replace(`https://${host}${pathname}${search}`);
    }
  }, []);

  useEffect(() => {
    const setDeviceToken = async (userId) => {
      try {
        if (userId) {
          await firebase.getToken();

          if (firebase.token) {
            dispatch(setDeviceTokenAction(userId, firebase.token));
          }
        }
      } catch (error) {
        /**
         * Continue regardless error
         */
      }
    };

    const init = async () => {
      try {
        const { user, location } = await dispatch(authInitialize());

        const { id: userId, phone, role, firstName } = as.o(user);

        /**
         * User is `null` when session is not authorized
         */
        if (user) {
          sentry.setUser({
            id: userId,
            phone,
            role,
            firstName,
            ip_address: location.ip,
            location,
          });

          setDeviceToken(userId);
        }
      } catch (error) {
        /**
         * Continue regardless error
         */
      } finally {
        setInitialization(true);
      }
    };

    init();
  }, [dispatch]);

  return {
    maintenance,
    authLoading,
    initialized,
  };
};
