import get from 'lodash.get';
import set from 'lodash.set';

import { as } from '@utils';

export const alertLocalStorage = {
  name: 'alerts',

  getAlerts(userId) {
    const alerts = as.o(JSON.parse(window.localStorage.getItem(this.name)));

    if (userId) {
      return get(alerts, userId, {});
    }

    return alerts;
  },

  skipped(userId, alertName) {
    const { [alertName]: alert } = this.getAlerts(userId);

    return !!alert;
  },

  skip(userId, alertName) {
    const alerts = this.getAlerts();

    set(alerts, [userId, alertName], true);
    window.localStorage.setItem(this.name, JSON.stringify(alerts));
  },
};
