import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';

import { toast } from '@services';
import { useClickableState, useTranslationWithPrefix } from '@hooks';
import { as } from '@utils';

import { setDefaultPaymentMethod as setDefaultPaymentMethodAction } from '@actions';
import { userIdSelector, userPaymentMethodsSelector } from '@selectors';

import {
  Button,
  IconBox,
  Modal,
  Modals,
  Render,
  Transition,
} from '@components';

const propTypes = {
  paymentMethodId: PropTypes.number.isRequired,
};
const defaultProps = {};

export const CardContextMenu = ({ paymentMethodId }) => {
  const dispatch = useDispatch();
  const paymentMethods = useSelector(userPaymentMethodsSelector);
  const userId = useSelector(userIdSelector);

  const { createPrefix } = useTranslationWithPrefix();

  const menuRef = useRef(null);
  const [open, setOpen] = useClickableState(menuRef);

  const tt = createPrefix('web:billing');
  const paymentMethod = paymentMethods.find(({ id }) => id === paymentMethodId);
  const { default: isDefault, lastFour, brand } = as.o(paymentMethod);

  const handleOpen = () => {
    setOpen((state) => !state);
  };

  const setDefaultPaymentMethod = async () => {
    try {
      handleOpen();
      await dispatch(
        setDefaultPaymentMethodAction({ userId, paymentMethodId })
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  if (!paymentMethod) {
    return null;
  }

  return (
    <div ref={menuRef} className="relative">
      <Button theme className="grey-3-with-hover" onClick={handleOpen}>
        <IconBox icon={faEllipsis} className="text-18" />
      </Button>
      <Transition mountOnEnter unmountOnExit in={open} className="fade">
        <div className="absolute -right-4 top-full z-20 flex w-[192px] flex-col gap-2 rounded-lg bg-white p-4 shadow-[0px_0px_4px_rgba(0,0,0,0.16)]">
          <Render if={!isDefault}>
            <Button
              theme
              className="w-full py-2 text-left text-body-1-r text-black"
              onClick={setDefaultPaymentMethod}
            >
              {tt('setDefault')}
            </Button>
          </Render>
          <Modal
            content={
              <Modals.DeleteCard
                paymentMethodId={paymentMethodId}
                lastFour={lastFour}
                brand={brand}
              />
            }
          >
            <Button
              theme
              className="w-full text-left text-body-1-r text-other-3"
            >
              {tt('title.deleteCard')}
            </Button>
          </Modal>
        </div>
      </Transition>
    </div>
  );
};

CardContextMenu.propTypes = propTypes;
CardContextMenu.defaultProps = defaultProps;
