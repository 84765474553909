import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';

import { FIREBASE, LOCAL } from '@constants';
import { toast } from '@services/toast';

export const firebase = {
  token: null,
  config: FIREBASE.CONFIG,

  async init() {
    this.isSupported = await isSupported();

    if (this.isSupported) {
      this.app = initializeApp(this.config);
      this.messaging = getMessaging(this.app);
    }
  },

  async getToken() {
    if (!this.isSupported) {
      return;
    }

    try {
      const serviceWorkerRegistration = await this.getServiceWorker();
      this.token = await getToken(this.messaging, {
        vapidKey: FIREBASE.VAPID_KEY,
        serviceWorkerRegistration,
      });
    } catch (error) {
      if (LOCAL) {
        toast.warning(error.message);
      }
    }
  },

  onForegroundMessage() {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => onMessage(this.messaging, resolve));
  },

  getServiceWorker() {
    if (!this.isSupported) {
      return null;
    }

    if ('serviceWorker' in navigator) {
      return window.navigator.serviceWorker
        .getRegistration()
        .then((serviceWorker) => {
          const params = new URLSearchParams(this.config).toString();

          if (serviceWorker) {
            return serviceWorker;
          }

          return window.navigator.serviceWorker.register(
            `/firebase-messaging-sw.js?${params}`
          );
        });
    }

    throw new Error('The browser does not support service workers');
  },
};
