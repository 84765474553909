import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';

import { TRANSLATIONS_URL } from '@constants';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${TRANSLATIONS_URL}/translations/{{lng}}/{{ns}}.json`,
    },
    fallbackLng: 'en',
    ns: ['shared', 'web', 'locale', 'backend', 'misc'],
    defaultNS: 'shared',
  });

i18n.services.formatter.add('lowercase', (value) => value.toLowerCase());

export default i18n;
