import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Typography as TypographyType } from '@types';
import { getSlots } from '@utils';

import { Render, Slot } from '@components';

const propTypes = {
  divider: PropTypes.bool,
  required: PropTypes.bool,
  variant: TypographyType,
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  /**
   * Right content of Title Raw component
   * {@link https://www.figma.com/file/p8lpkqtwDuj3BT10dWDsHq/Akicomo-UI-Kit?type=design&node-id=9605%3A40610&t=JnTEXTVuV6HZjorF-1}
   */
  children: PropTypes.node,
};
const defaultProps = {
  divider: true,
  required: false,
  variant: 'text-title-1',
  className: undefined,
  title: undefined,
  subtitle: undefined,
  children: null,
};

export const ThemeTitle = ({
  required,
  divider,
  variant,
  className,
  title,
  subtitle,
  children,
}) => {
  const { content, ...slot } = getSlots(children);

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <Slot slot={slot.title}>
            <h3
              className={cx('text-grey-1', variant, {
                'label-required': required,
              })}
            >
              {title}
            </h3>
          </Slot>
          <Slot slot={slot.subtitle}>
            <p className="text-subtitle text-text-2">{subtitle}</p>
          </Slot>
        </div>
        {content}
      </div>
      <Render if={divider}>
        <div className={cx('mt-2 h-px w-full bg-grey-7')} />
      </Render>
    </div>
  );
};

ThemeTitle.propTypes = propTypes;
ThemeTitle.defaultProps = defaultProps;
