import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { PROJECT_NAME } from '@constants';
import { cn } from '@utils';

import { ReactComponent as Logo } from '@images/layout-logo.svg';

const propTypes = {
  className: PropTypes.string,
  iconContainerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
};
const defaultProps = {
  className: '',
  iconContainerClassName: undefined,
  iconClassName: undefined,
};

export const ThemeLogo = ({
  className,
  iconContainerClassName,
  iconClassName,
}) => {
  return (
    <div className={cx('flex items-center gap-1 rounded-[4px]', className)}>
      <div
        className={cn(
          'flex content-center rounded-[4px]',
          iconContainerClassName
        )}
      >
        <Logo className={iconClassName} />
      </div>
      <div className="flex flex-col content-center">
        <span className="text-[18px] leading-5">{PROJECT_NAME}</span>
        <span className="text-[10px]">food delivery</span>
      </div>
    </div>
  );
};

ThemeLogo.propTypes = propTypes;
ThemeLogo.defaultProps = defaultProps;
