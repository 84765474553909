import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SUBSCRIPTION } from '@constants';
import { useTranslationWithPrefix } from '@hooks';

import { setSubscriptionPeriod } from '@actions';
import { subscriptionPeriodSelector } from '@selectors';

import { TabSwitcher } from './TabSwitcher';

const propTypes = {};
const defaultProps = {};

export const SubscriptionPeriodSwitcher = () => {
  const dispatch = useDispatch();
  const period = useSelector(subscriptionPeriodSelector);

  const { createPrefix } = useTranslationWithPrefix();

  const tt = createPrefix('web:subscription');

  const periods = Object.fromEntries(
    Object.values(SUBSCRIPTION.PERIOD).map((period) => [
      period,
      tt('period', period),
    ])
  );

  const handlePeriodChange = (period) => {
    dispatch(setSubscriptionPeriod(period));
  };

  return (
    <TabSwitcher
      activeTab={period}
      tabs={periods}
      onChange={handlePeriodChange}
    />
  );
};

SubscriptionPeriodSwitcher.propTypes = propTypes;
SubscriptionPeriodSwitcher.defaultProps = defaultProps;
