import axios from 'axios';

import { SERVER_URL } from '@constants';
import { toast } from '@services';
import { jwt } from '@utils';

const instance = axios.create({
  baseURL: SERVER_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'App-Name': 'web',
  },
});

instance.interceptors.request.use(
  (config) => {
    const state = { ...config };
    const token = jwt.get();

    state.headers.Authorization =
      config.headers.Authorization || `Bearer ${token}`;

    return state;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  ({ data: responseData }) => {
    const { data, message } = responseData;

    if (data) {
      return { ...data, message };
    }

    return responseData;
  },
  ({ response = {} }) => {
    const { status, data } = response;

    if (status >= 500) {
      toast.error(data?.message, { showEmptyMessage: true });
    }

    return Promise.reject(data ?? response);
  }
);

export default instance;
